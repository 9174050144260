import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utils/apiUrls";
import api from "../../utils/api";

const initialState = {
    fetchReviewerPolicyData: [],
    fetchGlobalReviewerEmpData : [],
    teamError : null,
    employeeCountData : [],
    isLoading: false,
    departmentData : [],
    countriesData : [],
    departments : null,
    departmentEmployees: null,
};

export const addDepartment = createAsyncThunk("registerTax/addDepartment", async (postData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.addDepartment,
            data: postData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const fetchGlobalReviewerEmp = createAsyncThunk("registerTax/fetchGlobalReviewerEmp", async (id) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.fetchGlobalReviewerEmp +`?departmentId=${id}`,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const employeeCount = createAsyncThunk("registerTax/employeeCount", async (departmenttId) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.employeeCount + `?departmentId=${departmenttId}`,
        };
        const response = await api(data);
        console.log('response', response);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getEmployeeTransactions = createAsyncThunk("pettycash/employee/transactions", async (queryParams) => {
    let url = queryParams
    ? `${API_ENDPOINTS.getEmployeeTransactions}${queryParams}`
    : `${API_ENDPOINTS.getEmployeeTransactions}`;
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: url,
        };
        const response = await api(data);
        return response?.data;
    } catch (error) {
        throw error?.response?.data;
    }
});

export const addEmployee = createAsyncThunk("registerTax/addEmployee", async (postData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.addEmployee,
            data: postData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const getAllDepartment = createAsyncThunk("registerTax/getAllDepartment", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getAllDepartment,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const getDepartmentEmployee = createAsyncThunk("department/fetchEmpDetails", async (ids) => {
    let url = `${API_ENDPOINTS.fetchEmpDetails}`;
    if (ids.departmentId && ids.para) url = `${API_ENDPOINTS.fetchEmpDetails}?departmentId=${ids.departmentId}&${ids.para}&eventType=${ids.eventType}`;
    if ((ids.departmentId && !ids.para)) url = `${API_ENDPOINTS.fetchEmpDetails}?departmentId=${ids.departmentId}&eventType=${ids.eventType}`;
    if ((!ids.departmentId && ids.para)) url = `${API_ENDPOINTS.fetchEmpDetails}?${ids.para}&eventType=${ids.eventType}`;
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: url,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const getFecthReviewerByDepartment = createAsyncThunk("department/fechReviewers", async (id) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: `${API_ENDPOINTS.fechReviewers}?departmentId=${id}`,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const updateFecthReviewerEmployee = createAsyncThunk("department/updateEmpReviewer", async (putData) => {
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.updateEmpReviewer,
            data:putData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const uploadDocument = createAsyncThunk("department/uploadDocument", async (image) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.uploadDocument+"?docType=employeeProfile",
            data: image,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getAllCountries = createAsyncThunk("country/getCountries", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getAllCountries,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const updateEmployeeState = createAsyncThunk("employee/employeeStateChange", async (putData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.employeeStateChange,
            data: putData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const fetchAllDepartments = createAsyncThunk("teams/fetchAllDepartments", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchAllDepartments
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const deleteDepartment = createAsyncThunk("teams/deleteDepartment", async (departmentId) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: `${API_ENDPOINTS.deleteDepartment}?departmentId=${departmentId}`,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

const registerTax = createSlice({
    name: "tax",
    initialState,
    reducers: {
        addTaxData: (state, action) => {
            return {
                ...state,
                registerTax: action.payload.data
            };
        }
    },
    extraReducers: (builder) => {
        const handleLoading = (state, action) => {
            state.isLoading = action.meta.requestStatus === 'pending';
        };
        builder
            .addCase(getDepartmentEmployee.fulfilled, (state, action) => {
                state.departmentEmployees = action.payload;
            })
            .addCase(getFecthReviewerByDepartment.fulfilled, (state, action) => {
                state.isLoading = false;
                state.fecthReviewerByDepartment = action.payload;
            })
            .addCase(employeeCount.fulfilled, (state, action) => {
                state.isLoading = false;
                state.employeeCountData = action.payload.data;
            })
            .addCase(getEmployeeTransactions.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(fetchGlobalReviewerEmp.fulfilled, (state, action) => {
                state.isLoading = false;
                state.fetchGlobalReviewerEmpData = action.payload.message;
            })
            .addCase(getAllDepartment.fulfilled, (state, action) => {
                state.isLoading = false;
                state.departmentData = action.payload.data;
            })
            .addCase(getAllCountries.fulfilled, (state, action) => {
                state.isLoading = false;
                state.countriesData = action.payload.data;
            })
            .addCase(addEmployee.fulfilled, (state) => {
                state.isLoading = false;
                state.teamError = null;
            })
            .addCase(fetchAllDepartments.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.teamError = null;
                state.departments = payload.data;
            })
            .addMatcher(
                (action) =>
                    action.type === addDepartment.pending.type ||
                    action.type === addDepartment.fulfilled.type ||
                    action.type === addDepartment.rejected.type ||
                    action.type === getDepartmentEmployee.pending.type ||
                    action.type === getDepartmentEmployee.fulfilled.type ||
                    action.type === getDepartmentEmployee.rejected.type ||
                    action.type === getFecthReviewerByDepartment.pending.type ||
                    action.type === getFecthReviewerByDepartment.fulfilled.type ||
                    action.type === getFecthReviewerByDepartment.rejected.type ||
                    action.type === employeeCount.pending.type ||
                    action.type === employeeCount.rejected.type ||
                    action.type === employeeCount.fulfilled.type ||
                    action.type === getEmployeeTransactions.pending.type ||
                    action.type === getEmployeeTransactions.rejected.type ||
                    action.type === getEmployeeTransactions.fulfilled.type ||
                    action.type === fetchGlobalReviewerEmp.pending.type ||
                    action.type === fetchGlobalReviewerEmp.fulfilled.type ||
                    action.type === fetchGlobalReviewerEmp.rejected.type ||
                    action.type === updateFecthReviewerEmployee.pending.type ||
                    action.type === updateFecthReviewerEmployee.fulfilled.type ||
                    action.type === updateFecthReviewerEmployee.rejected.type ||
                    action.type === getAllDepartment.pending.type ||
                    action.type === getAllDepartment.fulfilled.type ||
                    action.type === getAllDepartment.rejected.type ||
                    action.type === getAllCountries.pending.type ||
                    action.type === getAllCountries.fulfilled.type ||
                    action.type === getAllCountries.rejected.type ||
                    action.type === updateEmployeeState.pending.type ||
                    action.type === updateEmployeeState.fulfilled.type ||
                    action.type === updateEmployeeState.rejected.type ||
                    action.type === deleteDepartment.pending.type ||
                    action.type === deleteDepartment.fulfilled.type ||
                    action.type === deleteDepartment.rejected.type ,
                handleLoading
            )
            .addMatcher(isAnyOf(addEmployee.pending, fetchAllDepartments.pending), (state) => {
                state.isLoading = true;
            }).addMatcher(isAnyOf(addEmployee.rejected, fetchAllDepartments.rejected), (state, { error }) => {
                state.isLoading = false;
                state.teamError = error.message ? error.message : "Request Failed Please Try Again ";
            }
        );
    }
});
export const { addTaxData } = registerTax.actions;
export default registerTax.reducer;
