import React, { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import { useForm } from 'react-hook-form';
function OwnersDetails({ owner, onSubmit, onUpdate, setDeleteDetails, companyStatus, companyStatusDetails }) {
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const OwnerSchema = yup.object().shape({
        firstName: yup.string()
        .trim()
        .required("Name is required")
        .matches(/^[A-Za-z\s]+$/, `${t('company.valid_name')}`)
        .min(2, `${t('company.min_name_length')}`)
        .max(30, `${t('company.max_name_length')}`),
        lastName: yup.string()
        .trim()
        .required("Name is required")
        .matches(/^[A-Za-z\s]+$/, `${t('company.valid_name')}`)
        .min(2, `${t('company.min_name_length')}`)
        .max(30, `${t('company.max_name_length')}`),
        mobileNumber: yup.string()
            .trim()
            .required("Phone Number is required")
            .matches(/^[0-9]+$/, "Please enter valid Phone Number")
            .max(10, "Phone Number must be 10 digits"),
        email: yup.string()
            .trim()
            .matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/, 'Invalid email')
            .required("Email is required")
            .email("Please enter valid email"),
    });

    const { register, setValue: setOwnerInfo, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(OwnerSchema),
    });

    useEffect(() => {
        if (owner) {
            setOwnerInfo("firstName", owner?.firstName);
            setOwnerInfo("lastName", owner?.lastName);
            setOwnerInfo("mobileNumber", parseInt(owner?.mobileNumber?.replace(/^\+974/, '')));
            setOwnerInfo("email", owner?.email);
        }
    }, [owner]);

    const languages = [
        {
            code: 'en',
            name: 'English',
            country_code: 'gb',
        },
        {
            code: 'ar',
            name: 'العربية',
            dir: 'rtl',
            country_code: 'sa',
        },
    ];
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr';
        document.title = t('app_title');
    }, [currentLanguage, t]);

    const requiredFieldLabel = (labelText, htmlFor) => {
        return (
            <label className="form-label" htmlFor={htmlFor}>
                {labelText}
                <span className="absolute top-0 left-0" style={{ transform: 'translate(-50%, -50%)', color: '#FF0000' }}>*</span>
            </label>
        );
    };

    const handleUpdate = (data) => {
        if(companyStatus === 'Active' && companyStatusDetails.kybStatus === 'Verified'){
            return;
        }
        if(owner){
            onUpdate(owner.employeeId, data);
            console.log(JSON.stringify(data));
        }else{
            onSubmit(data);
        }    
    };

    return (
        <form onSubmit={handleSubmit(handleUpdate)}>
            <p className='text-green mb-3' style={{ fontSize: 20, fontWeight: '500' }}>{t('company.verify_account')}</p>
            <h3 className='text-grey mb-3 fw-normal'>{owner?.isAdmin ? t('company.verify_account_desc_admin') : t('company.verify_account_desc')}</h3>
            <div className="form-outline mt-2 mb-3">
                {requiredFieldLabel(t('company.first_name'), 'firstName')}
                <input
                    type="text"
                    id="firstName"
                    className={`form-control form-control-lg ${errors.firstName ? 'is-invalid' : ''}`}
                    placeholder={t('company.enter_first_name')}
                    {...register('firstName', {
                        required: `${t('company.enter_first_name')}`,
                        minLength: {
                            value: 2,
                            message: `${t('company.min_name_length')}`,
                        },
                        maxLength: {
                            value: 30,
                            message: `${t('company.max_name_length')}`,
                        },
                        pattern: {
                            value: /^[a-zA-Z\s]+$/,
                            message: t('company.valid_name'),
                        },
                    })}
                />
                {errors?.firstName && (
                    <div className="invalid-feedback">{errors?.firstName?.message}</div>
                )}
            </div>
            <div className="form-outline mt-2 mb-3">
                {requiredFieldLabel(t('company.last_name'), 'lastName')}
                <input
                    type="text"
                    id="lastName"
                    className={`form-control form-control-lg ${errors.lastName ? 'is-invalid' : ''}`}
                    placeholder={t('company.enter_last_name')}
                    {...register('lastName', {
                        required: `${t('company.enter_last_name')}`,
                        minLength: {
                            value: 2,
                            message: `${t('company.min_name_length')}`,
                        },
                        maxLength: {
                            value: 30,
                            message: `${t('company.max_name_length')}`,
                        },
                        pattern: {
                            value: /^[a-zA-Z\s]+$/,
                            message: t('company.valid_name'),
                        },
                    })}
                />
                {errors?.lastName && (
                    <div className="invalid-feedback">{errors?.lastName?.message}</div>
                )}
            </div>
            <div className="form-outline mt-2 mb-3">
                {requiredFieldLabel(t('company.email'), 'email')}
                <input
                    type="text"
                    id="email"
                    name="email"
                    autoComplete="email"
                    className={`form-control form-control-lg ${errors.email ? 'is-invalid' : ''}`}
                    placeholder={t('company.enter_email')}
                    {...register('email', {
                        required: `${t('company.enter_email')}`,
                        pattern: {
                            value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
                            message: t('company.valid_email'),
                        },
                    })}
                />
                {errors?.email && (
                    <div className="invalid-feedback">{errors?.email?.message}</div>
                )}
            </div>
            <div className="form-outline mt-2 mb-3">
                {requiredFieldLabel(t('company.phone_number'), 'phoneNumber')}
                <div className="input-group">
                    {
                        <span className="input-group-text">+974</span>
                    }
                    <input
                        type="tel"
                        id="phoneNumber"
                        className={`form-control form-control-lg ${errors?.mobile ? 'is-invalid' : ''}`}
                        placeholder={`${t('company.enter_phone_number')}`}
                        {...register('mobileNumber', {
                            required: `${t('company.enter_phone_number')}`,
                            pattern: {
                                value: /^[0-9]{8,10}$/,
                                message: t('company.valid_mobile_number'),
                            },
                        })}
                    />
                    {errors?.mobileNumber && (
                        <div className="invalid-feedback">{errors?.mobileNumber?.message}</div>
                    )}
                </div>
            </div>
            <div style={{ borderBottom: '1px solid #EAECF0', marginBottom: 18 }}></div>
            <div className='sendBtnLineCss'>
                {!owner?.isAdmin && 
                    <button 
                        type='button' 
                        className={owner && !owner?.isAdmin && companyStatus === 'InActive' ? 'btn-primary-small deleteStyledBtn' : 'disabledButton'} 
                        onClick={owner && !owner?.isAdmin && companyStatus === 'InActive' ? () => { setDeleteDetails((prev) => ({ ...prev, showModal: true, ownerID: owner.employeeId})); } : null}
                        id='owners_delete'
                        >
                            {t('delete')}
                    </button>
                }
                <button 
                    type='submit' 
                    className={companyStatus === 'InActive' ? 'btn-primary-small sendBtnPadding truncated-text' : 'disabledButton'}
                    id='owner_save'
                > 
                    {owner?.isAdmin ? t('save') : t('send_invitation_link')}
                </button>
            </div>
        </form>
    );
}

export default OwnersDetails;
