// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardTabs .form-switch .form-check-input {
  background-color: #F2F4F7 !important;
  height: 25px !important;
  width: 40px;
  margin-top: 0px;
  margin-right: 10px;
}
@media only screen and (max-width: 500px) {
  .cardTabs .form-switch .form-check-input {
    font-size: 12px;
  }
}
.cardTabs .form-check-input:checked {
  background-color: #00D094 !important;
  border-color: #00D094 !important;
}
.cardTabs .form-check-input:focus {
  box-shadow: none;
}
.cardTabs .form-check-label {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #333333;
}
@media only screen and (max-width: 500px) {
  .cardTabs .form-check-label {
    font-size: 12px;
    line-height: normal;
    width: 80%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Cards/Cards.scss"],"names":[],"mappings":"AACI;EACI,oCAAA;EACA,uBAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;AAAR;AAEQ;EAPJ;IAQQ,eAAA;EACV;AACF;AAEI;EACI,oCAAA;EACA,gCAAA;AAAR;AAGI;EACI,gBAAA;AADR;AAII;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,cAAA;AAFR;AAIQ;EAPJ;IAQQ,eAAA;IACA,mBAAA;IACA,UAAA;EADV;AACF","sourcesContent":[".cardTabs {\n    .form-switch .form-check-input {\n        background-color: #F2F4F7 !important;\n        height: 25px !important;\n        width: 40px;\n        margin-top: 0px;\n        margin-right: 10px;\n\n        @media only screen and (max-width: 500px) {\n            font-size: 12px;\n        }\n    }\n\n    .form-check-input:checked {\n        background-color: #00D094 !important;\n        border-color: #00D094 !important;\n    }\n\n    .form-check-input:focus {\n        box-shadow: none;\n    }\n\n    .form-check-label {\n        font-size: 16px;\n        font-weight: 400;\n        line-height: 24px;\n        letter-spacing: 0em;\n        color: #333333;\n\n        @media only screen and (max-width: 500px) {\n            font-size: 12px;\n            line-height: normal;\n            width: 80%;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
