import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './Cards.scss';
import { useDispatch } from 'react-redux';
import { formatTime } from '../../utils/XpendlessUtils';

function SingleCardTabs({ details, spendings, fetchCardSpendingFunc, handleCardControl, activeTab, setActiveTab }) {
    const [pageNo, setPageNo] = useState(1);
    const [triggerEffect, setTriggerEffect] = useState(false);

    const [checkboxValues, setCheckboxValues] = useState({
        cardcontroldata: {
            inPerson: false,
            contactless: false,
            atm: false,
            online: false,
        },
        cardcategorycontroldata: {
            gasFuel: false,
            hotels: false,
            travels: false
        }
    });

    const dispatch = useDispatch();

    // Fetch master transaction list when 'pageNo' changes
    useEffect(() => {
        let queryParams = `?page=${pageNo}&cardId=${details?.cardId}`;
        dispatch(fetchCardSpendingFunc(queryParams));
    }, [dispatch, pageNo]);

    const onPreviousClick = () => {
        setPageNo(prev => prev - 1);
    };

    const onNextClick = () => {
        setPageNo(prev => prev + 1);
    };

    useEffect(() => {
        // Set initial values based on the prop data
        if (details) {
            setCheckboxValues(
                {
                    cardcontroldata: {
                        inPerson: details?.cardcontroldata?.inPerson || false,
                        contactless: details?.cardcontroldata?.contactless || false,
                        atm: details?.cardcontroldata?.atm || false,
                        online: details?.cardcontroldata?.online || false,
                    },
                    cardcategorycontroldata: {
                        gasFuel: details?.cardcategorycontroldata?.gasFuel || false,
                        hotels: details?.cardcategorycontroldata?.hotels || false,
                        travels: details?.cardcategorycontroldata?.travels || false,
                    }
                }
            );
        }
    }, [details]);

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        const [category, key] = name.split('.');
        setCheckboxValues((prevCheckboxValues) => ({
            ...prevCheckboxValues,
            [category]: {
                ...prevCheckboxValues[category],
                [key]: checked,
            },
        }));
        setTriggerEffect(true);
    };

    useEffect(() => {
        if (triggerEffect) {
            setTriggerEffect(false);
            handleCardControl(checkboxValues);
        }
    }, [checkboxValues]);

    const { t } = useTranslation();
    return (
        <div className='cards-custom p-3 mt-4'>
            <div className='cardTabs settings'>
                <Tabs defaultActiveKey={activeTab} animation={false} id="noanim-tab-example" onSelect={(eventKey) => {
                    setActiveTab(eventKey);
                }}>
                    <Tab eventKey={1} title={`${t('cards.card_controls')}`}>
                        <div className='border-grey p-3 mt-4'>
                            <form>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={'inPerson'}
                                        name={'cardcontroldata.inPerson'}
                                        checked={checkboxValues['cardcontroldata']['inPerson']}
                                        onChange={handleCheckboxChange}
                                        disabled={details?.isFreezed}
                                    />

                                    <label className="form-check-label" htmlFor="inPerson">{t('cards.in_person_transactions')}</label>
                                </div>
                                <div className="form-check form-switch mt-4">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={'contactless'}
                                        name={'cardcontroldata.contactless'}
                                        checked={checkboxValues['cardcontroldata']['contactless']}
                                        onChange={handleCheckboxChange}
                                        disabled={details?.isFreezed}
                                    />
                                    <label className="form-check-label" htmlFor="contactless">{t('cards.contactless_payment')}</label>
                                </div>
                                <div className="form-check form-switch mt-4">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={'atm'}
                                        name={'cardcontroldata.atm'}
                                        checked={checkboxValues['cardcontroldata']['atm']}
                                        onChange={handleCheckboxChange}
                                        disabled={details?.isFreezed}
                                    />
                                    <label className="form-check-label" htmlFor="atm">{t('cards.ATM')}</label>
                                </div>
                                <div className="form-check form-switch mt-4">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={'online'}
                                        name={'cardcontroldata.online'}
                                        checked={checkboxValues['cardcontroldata']['online']}
                                        onChange={handleCheckboxChange}
                                        disabled={details?.isFreezed}
                                    />
                                    <label className="form-check-label" htmlFor="online">{t('cards.online')}</label>
                                </div>
                            </form>
                        </div>
                    </Tab>
                    <Tab eventKey={2} title={`${t('cards.category_control')}`}>
                        <div className='border-grey p-3 mt-4'>
                            <form>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={'gasFuel'}
                                        name={'cardcategorycontroldata.gasFuel'}
                                        checked={checkboxValues['cardcategorycontroldata']['gasFuel']}
                                        onChange={handleCheckboxChange}
                                        disabled={details?.isFreezed}
                                    />
                                    <label className="form-check-label" htmlFor="gasFuel">{t('cards.gas_stations')}</label>
                                </div>
                                <div className="form-check form-switch mt-4">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={'hotels'}
                                        name={'cardcategorycontroldata.hotels'}
                                        checked={checkboxValues['cardcategorycontroldata']['hotels']}
                                        onChange={handleCheckboxChange}
                                        disabled={details?.isFreezed}
                                    />
                                    <label className="form-check-label" htmlFor="hotels">{t('cards.hotels_motels_and_resorts')}</label>
                                </div>
                                <div className="form-check form-switch mt-4">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={'travels'}
                                        name={'cardcategorycontroldata.travels'}
                                        checked={checkboxValues['cardcategorycontroldata']['travels']}
                                        onChange={handleCheckboxChange}
                                        disabled={details?.isFreezed}
                                    />
                                    <label className="form-check-label" htmlFor="travels">{t('cards.travel_agencies_and_tour')}</label>
                                </div>
                            </form>
                        </div>
                    </Tab>
                    <Tab eventKey={3} title={`${t('cards.spending')}`}>
                        <div className='table-responsive mt-4 p-3'>
                            <table id="dtBasicExample" style={{ borderCollapse: 'separate', borderSpacing: '0 16px' }} cellSpacing="0" width="100%">
                                <thead>
                                    <tr className='table-head'>
                                        <th scope="col">{t('cards.no')}</th>
                                        <th scope="col">{t('cards.date_and_time')}</th>
                                        <th scope="col">{t('teams.description')}</th>
                                        <th scope="col">{t('teams.amount')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (Object.keys(spendings).length > 0 && Object.keys(spendings['rows']).length > 0) && spendings['rows'].map((spending, index) => {
                                            return <tr key={index} className='table-body-tr'>
                                                <td>{index + 1}</td>
                                                <td>{formatTime(spending.createdAt)}</td>
                                                <td>{spending.description}</td>
                                                <td>{spending.amount.toLocaleString()} QAR</td>
                                            </tr>;
                                        })
                                    }
                                </tbody>
                            </table>
                          
                        </div>
                        <div className='d-flex align-items-center justify-content-between'
                                style={{ width: "100%", borderTop: '0px !imporant', }}>
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination mt-2 pt-3 ms-6 pe-0">
                                        <li className="page-item">
                                            <button className="btn page-link" disabled={pageNo === 1} onClick={() => onPreviousClick()}>Previous</button>
                                        </li>
                                        <li className="page-item">
                                            <button className="btn page-link mx-3" disabled={pageNo == spendings?.totalPages || spendings?.totalPages == 0} onClick={() => onNextClick()}>Next</button>
                                        </li>
                                    </ul>
                                </nav>
                                <div className='page-count d-flex align-items-center' style={{ color: "#00D094" }}>
                                    <h4 className='me-3'>Page {spendings?.currentPage} of {spendings?.totalPages}</h4>
                                </div>
                            </div>
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
}

export default SingleCardTabs;
