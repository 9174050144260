/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import UploadImage from '../../assets/images/upload_image_icon.png';
import ErrorImage from '../../assets/images/warning-orange-icon.png';
import RecepitIcon from '../../assets/images/greyRecipt.png';
import GreenRecepitIcon from '../../assets/images/doneRecipt.png';
import ReviewGreyIcon from '../../assets/images/defDoc.png';
import ReviewGreenIcon from '../../assets/images/docAcept.png';
import ReviewRedIcon from '../../assets/images/rejected.png';
import CategoryGreyIcon from '../../assets/images/menu.png';
import CategoryGreenIcon from '../../assets/images/menuGreen.png';
import UploadLogogIconSmall from '../../assets/images/uploadlogoIcon.png';
import ExportGreyIcon from '../../assets/images/docArrow.png';
import ExportGreenIcon from '../../assets/images/export_icon_green.png';
import ErrorImagesmall from '../../assets/images/warning-orange-icon.png';
import UnsettledIcon from '../../assets/images/unsettled.png';
import SettledIcon from '../../assets/images/settled.png';
import './Expenses.scss';
import { fetchExpenseActivityLog, getExpenseDetails, saveTags, updateCardTransaction, uploadDocument } from '../../pages/Expenses/expensesSlice';
import { useSelector } from 'react-redux';
import DynamicFieldArray from '../DynamicFieldArray/DynamicFieldArray';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import Accordion from 'react-bootstrap/Accordion';
import { decodeToken } from "react-jwt";
import { Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import PdfComponent from './PdfComponent';
import ImagesCross from '../../assets/images/close-square.png';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

import Slider from 'react-slick';
import { fetchReimbursementActivityLog, fetchReimbursementDetails, getAllEmployees, getEmployeeDetails, updateConversionRate, updateReimbursementTransaction } from '../../pages/Reimbursement/reimbursementSlice';
import { formatTime, timeUTCToLocal } from '../../utils/XpendlessUtils';
import {
    TransformWrapper,
    TransformComponent,
} from "react-zoom-pan-pinch";
import CloseImage from '../../assets/images/close_square_white.png';

const moment = require('moment');

function ExpensesModalTabs(props) {
    const token = localStorage.getItem('token');
    // const [showSplit, setShowSplit] = useState(false);
    const [showFullScreen, setShowFullScreen] = useState(false);
    const [showFullScreen2, setShowFullScreen2] = useState(false);
    const { details, dispatch, activeTab, updateCardTransactionData, categoryList, tagGroups, cardTransactionId, type, type1 } = props;
    const [imageUrl, setImageUrl] = useState(false);
    const { expenseActivityLogs } = useSelector(state => state.expenseReducer);
    const { reimbursementActivityLogs } = useSelector(state => state.reimbursementSlice);
    const { t } = useTranslation();
    const [description, setDiscription] = useState('');
    const [event, setEvent] = useState('');
    const [attachments, setAttachments] = useState([]);
    const validationSchemas = {};
    const [selectedFile, setSelectedFile] = useState(null);
    const [disable, setDisable] = useState(false);
    const [conversionRate, setConversionRate] = useState(null);
    const isFirstRender = useRef(true);
    tagGroups.forEach((tagGroup) => {
        const fieldName = tagGroup.label.replace(/ /g, '_');
        const fieldSchema = yup.object().shape({
            amount: yup.number().min(0).typeError('Amount Should be Number').required("Amount is required"),
            tagId: yup.string().required("Please select Tag"),
        });
        validationSchemas[fieldName] = yup.array().of(fieldSchema);
    });
    const [addTagFormValid, setAddTagFormValid] = useState(false);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);

    const toggleFormVisibility = () => {
        setIsFormVisible(!isFormVisible);
    };

    const handleSaveClick = () => {
        if (!conversionRate) {
            toast.error("Please enter conversion rate.");
            return;
        }

        if (isNaN(conversionRate)) {
            toast.error("Please enter proper conversion rate.");
            return;
        }

        dispatch(updateConversionRate({ reimbursementRequestId: cardTransactionId, rateToQAR: conversionRate }))
            .unwrap()
            .then(res => {
                toast.success(res.message);
                dispatch(fetchReimbursementDetails(`?reimbursementRequestId=${cardTransactionId}`));
                if (details?.employeeId)
                    dispatch(getEmployeeDetails(`?employeeId=${details?.employeeId}`));
                dispatch(getAllEmployees(`?page=1`));
                setIsFormVisible(false);
            })
            .catch(err => {
                toast.error(err.message);
            });
    };

    const decodedToken = decodeToken(token);
    const { handleSubmit, control, register, formState: { errors }, reset, setValue, watch } = useForm({
        resolver: yupResolver(yup.object().shape(validationSchemas)),
    });
    useEffect(() => {
        if (event === '2') {
            changeTab(event);
        }
    }, [details, event]);
    const [wholePart, decimalPart] = (details?.amount) ? details?.amount.toString().split('.').map(Number) : ['00', '00'];
    const [wholePart2, decimalPart2] = (details?.selectedCurrencyAmount) ? details?.selectedCurrencyAmount.toString().split('.').map(Number) : ['', ''];
    useEffect(() => {
        if (!isFirstRender.current) {
            if (attachments?.length > 0 && attachments?.some(item => typeof item === 'object')) {
                setDisable(true);
                const timer = setTimeout(() => {
                    handleAddAttachments();
                }, 0.5);

                return () => {
                    clearTimeout(timer);
                };
            } else {
                const timer = setTimeout(() => {
                    handleUpdateCardTransaction();
                }, 0.5);

                return () => {
                    clearTimeout(timer);
                };
            }
        }
    }, [attachments]);

    const calculateTotalAmount = (data) => {
        if (Array.isArray(data)) {
            return data.reduce((result, category) => {
                const totalAmount = category
                    .map(item => (item?.amount) ? parseInt(item.amount) : 0)
                    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                return result + totalAmount;
            }, 0);
        } else if (typeof data === 'object') {
            return Object.values(data).reduce((result, category) => {
                const totalAmount = category
                    .map(item => (item?.amount) ? parseInt(item.amount) : 0)
                    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                return result + totalAmount;
            }, 0);
        }
        return 0; // Default value if data is not an array or object
    };

    const isWrongDetail = useMemo(() => {
        return details &&
            details.receiptOCRdata !== null && (
                (details?.receiptOCRdata?.amount !== details?.amount)
                || (details?.receiptOCRdata?.merchantName?.toLowerCase() !== details?.merchantName?.toLowerCase())
                || (formatTime(details?.createdAt) !== formatTime(details?.receiptOCRdata?.date))
            );
    }, [details]);

    /*const handleFieldsValues = async (data) => {

        if (typeof data === 'object' && Object.keys(data).length === 0) {
            setAddTagFormValid(false);
            return;
        }

        let status = false;

        for (const fieldItem in data) {
            let totalAmount = 0;
            const items = data[fieldItem] || [];
            for (const item of items) {
                if (item && item.amount !== undefined && item.amount !== '' && item.tagId !== undefined && item.tagId !== '') {
                    totalAmount += parseFloat(item.amount);
                } else {
                    setAddTagFormValid(false);
                }
            }

            if (items?.length > 0) {
                if (totalAmount === details.amount)
                    status = true;
                else status = false;
            }

        }

        status ? setAddTagFormValid(true) : setAddTagFormValid(false);
    };*/

    const handleImageClick = () => {
        setShowFullScreen(true);
    };
    const reviewIcons = {
        pending: ReviewGreyIcon,
        approve: ReviewGreenIcon,
        reject: ReviewRedIcon,
        null: ReviewGreyIcon
    };

    const changeTab = (eventKey) => {
        if (eventKey === '2') {
            const reimbursementQuery = `?reimbursementRequestId=${cardTransactionId}`;
            const expenseQuery = `?cardTransactionId=${details?.cardtransactionid}`;
            const exportQueryParam = '&eventType=export';

            if (type === 'reimbursement') {
                const query = type1 === 'export' ? `${reimbursementQuery}${exportQueryParam}` : reimbursementQuery;
                dispatch(fetchReimbursementActivityLog(query));
            } else {
                const query = type1 === 'export' ? `${expenseQuery}${exportQueryParam}` : expenseQuery;
                dispatch(fetchExpenseActivityLog(query));
            }
        }
    };

    const [selectedImage, setSelectedImage] = useState(null);

    const fileInputRef = useRef(null);
    const fileInputRef2 = useRef(null);

    const handleImageChange2 = (event) => {
        isFirstRender.current = false;
        const files = event.target.files;
        const allowedExtensions = ['jpg', 'png', 'jpeg', 'pdf'];

        const existingAttachments = attachments ? [...attachments] : [];
        const newAttachments = [];

        for (const file of files) {
            const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
            const fileType = allowedExtensions.includes(fileExtension.toLowerCase())
                ? (fileExtension.toLowerCase() === 'pdf' ? 'pdf' : 'image')
                : 'invalid';

            if (fileType !== 'invalid') {
                const url = URL.createObjectURL(file);
                newAttachments.push({ type: fileType, url: url, file: file });
            } else {
                console.error(`Please select a file with one of the allowed extensions: ${allowedExtensions.join(', ')}`);
            }
        }

        setAttachments([...existingAttachments, ...newAttachments]);

    };

    const handleRemoveImage = (index) => {
        isFirstRender.current = false;
        const updatedAttachments = [...attachments];
        updatedAttachments.splice(index, 1);
        setAttachments(updatedAttachments);
    };

    const filePermission = useMemo(() => {
        return decodedToken?.isAccountant || decodedToken?.isAdmin || decodedToken?.isGlobalReviewer || decodedToken?.reviewer?.length > 0 || (activeTab && activeTab === '2');

    }, [decodedToken, activeTab]);

    const handleImageUploadClick = () => {
        if (filePermission)
            fileInputRef.current.click();
    };
    const handleImageUploadClick2 = () => {
        if (filePermission)
            fileInputRef2.current.click();
    };

    const handleImageChange = (event) => {
        isFirstRender.current = false;
        const file = event.target.files[0];
        if (file) {
            const allowedExtensions = ['jpg', 'png', 'jpeg', 'pdf'];
            const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
            if (allowedExtensions?.includes(fileExtension.toLowerCase())) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    setSelectedImage(file);
                    setImageUrl(e.target.result);
                };
                reader.readAsDataURL(file);
            } else {
                // Handle invalid file type (not JPG or PNG)
                alert('Please select a JPG or PNG image.');
                event.target.value = ''; // Clear the file input
            }
        }
    };

    useEffect(() => {
        if (type1 !== 'export')
            updateCardTransactionData(selectedImage, 'imageUpload');
    }, [selectedImage]);

    useEffect(() => {
        setDiscription(details?.description);
    }, [details?.description]);

    useEffect(() => {
        setAttachments(details?.attachments);
    }, [details?.attachments]);

    const onSubmit = (payload) => {
        const data = Object.values(payload).flatMap((items) => {
            return items.map(item => {
                if (item.tagId !== undefined && item.amount !== undefined) {
                    if (type === 'reimbursement') {
                        return {
                            tagId: item.tagId,
                            amount: item.amount
                        };
                    } else {

                        return {
                            cardTransactionId: cardTransactionId, // You can set a specific value here
                            tagId: item.tagId,
                            amount: item.amount
                        };
                    }
                }
            }).filter(Boolean);
        });

        if (data.length > 0 || details?.tags && details.tags.length > 0 && data.length < 1) {
            const [date, timeWithMilliseconds] = details?.date.split('T');
            const time = timeWithMilliseconds.split('.')[0]; // Remove milliseconds and time zone information
            console.log(details);
            const postData = 
            {
                reimbursementRequestId: cardTransactionId,
                amount: details?.amount,
                date: date,
                time: time,
                tags: data,
                currencyId: details?.currencyId,
                merchantName: details?.merchantName
            };
            if (type === 'reimbursement') {
                dispatch(updateReimbursementTransaction({postData})).unwrap().then(() => {
                    reset();
                    toast.success('Reimbursement Updated Successfully');
                    dispatch(fetchReimbursementDetails(`?reimbursementRequestId=${cardTransactionId}`));
                }).catch((error) => {
                    toast.error(error.message || "Request Failed Please Try Again ");
                });
            } else {
                dispatch(saveTags({ data, cardTransactionId })).unwrap().then(() => {
                    reset();
                    toast.success('Tags Added Successfully');
                    dispatch(getExpenseDetails(`?cardTransactionId=${cardTransactionId}`));
                }).catch((error) => {
                    toast.error(error.message || "Request Failed Please Try Again ");
                });
            }
        } else {
            toast.error("Please select at least one tag");
        }
    };

    useEffect(() => {
        if (details?.receiptPath && details.receiptPath) {
            setImageUrl(details?.receiptPath);
        } else {
            setImageUrl(null);
        }
        if (type === 'reimbursement') {
            setConversionRate(details?.rateToQAR);
        }
    }, [details]);

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: () => [],
    });

    function createFilesArray(inputArray) {
        const resultArray = inputArray.map(obj => {
            let newObj;
            if (typeof obj !== 'string') {
                newObj = { ...obj };
                delete newObj.url;
                delete newObj.type;
            } else {
                newObj = obj;
            }

            return newObj;
        });

        return resultArray;
    }


    const handleAddAttachments = async () => {
        const attachCopy = createFilesArray(attachments);
        for (let i = 0; i < attachCopy.length; i++) {
            if (attachCopy[i]?.file) {
                const formData = new FormData();
                formData.append('file', attachCopy[i]?.file);
                const response = await dispatch(uploadDocument(formData)).unwrap();
                let url;
                for (const iterator of response) {
                    url = iterator?.data?.url;
                }
                attachCopy[i] = url;
            }
        }
        handleUpdateCardTransaction(attachCopy);
    };

    const handleUpdateCardTransaction = async (attachCopy) => {
        let attach = {
            cardTransactionId: cardTransactionId,
            attachments: attachCopy ? attachCopy : attachments
        };
        await dispatch(updateCardTransaction(attach)).unwrap().then(() => {
            fileInputRef2.current.value = '';
            setDisable(false);
            toast.success(`${t('expenses.Attachments_updated_successfully')}`);
        }).catch((error) => {
            setDisable(false);
            toast.error(error.message || "Request Failed Please Try Again ");
        });
    };

    var settings = {
        dots: true,
        arrows: false,
        speed: 500,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        centerMode: false,
        infinite: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    centerMode: false,
                }
            },
        ],
    };

    /*const handleApplyClick = () => {
        const formData = new FormData(document.querySelector('form'));
        const formObject = {};

        console.log(formData);
        formData.forEach((value, key) => {
            //const [group, index, field] = key.split(/[[\]]{1,2}/).filter(Boolean);
            console.log(`key`);
            console.log(key);
            console.log(`value`);
            console.log(value);
            //if (!formObject[group]) {
            //    formObject[group] = [];
            //}
            //if (!formObject[group][index]) {
            //    formObject[group][index] = { tagId: formData.get(`${group}[${index}].tagId`) }; // include tagId
            //}
      
            //formObject[group][index][field] = value;
        })

        //onApplySubmit(formObject);
    };*/

    return (
        <div className='settings mt-3'>
            <Tabs defaultActiveKey={1} animation="true" id="noanim-tab-example" onSelect={(eventKey) => setEvent(eventKey)}>
                <Tab eventKey={1} title={`${t('expenses.details')}`}>
                    <div className='col-12 border-grey mt-4 p-3'>
                        <div className='d-flex align-items-center justify-content-between'>
                            {
                                wholePart2 &&
                                <h2 className='text-black fw-bold mb-0' style={{ fontSize: 22 }}>{wholePart2.toLocaleString()}.<span className='text-grey' style={{ fontSize: 12, fontWeight: '500' }}>{`${(decimalPart2) ? decimalPart2 : '00'} ${details?.code}`}</span></h2>
                            }
                            {
                                !wholePart2 &&
                                <h2 className='text-black fw-bold mb-0' style={{ fontSize: 22 }}>{wholePart.toLocaleString()}.<span className='text-grey' style={{ fontSize: 12, fontWeight: '500' }}>{`${(decimalPart) ? decimalPart : '00'}  QAR`}</span></h2>
                            }
                            <p className='text-black fw-bold mb-0' style={{ fontSize: 14 }}>{details?.merchantName}</p>
                        </div>
                        {wholePart2 && <div className='d-flex align-item-center justify-content-start mt-3'>
                            {(details?.code !== "QAR") && <h2 className='text-black fw-bold mb-0' style={{ fontSize: wholePart2 ? 20 : 22 }}>{wholePart2 && '('}{wholePart.toLocaleString()}.<span className='text-grey' style={{ fontSize: wholePart2 ? 10 : 12, fontWeight: '500' }}>{`${(decimalPart) ? decimalPart : '00'} QAR`}</span>{wholePart2 && ')'}</h2>}
                        </div>}
                        {
                            type === 'reimbursement' && details?.code !== "QAR" &&
                            <div className='d-flex align-items-center mt-3' style={{ backgroundColor: '#EAFEF8', padding: '4px 4px', borderRadius: 10 }}>
                                <div><div className='text-black mb-0 fw-bold' style={{ fontSize: 18 }}>{details?.rateToQAR}<span className='text-grey' style={{ fontSize: 14 }}> {details?.spotAt ? `( ${formatTime(details?.spotAt)} UTC )` : ""}</span><img src={UploadLogogIconSmall} width={13} style={{ objectFit: 'contain', marginLeft: 10, cursor: 'pointer', marginRight: 5 }} onClick={toggleFormVisibility} /></div></div>
                            </div>
                        }
                        {isFormVisible && (
                            <div className='mt-3'>
                                <p className='text-black mb-2 fw-bold' style={{ fontSize: 14 }}>Exchange rate</p>
                                <input type="text" value={conversionRate ?? ''} onChange={(e) => setConversionRate(e.target.value)} id="form3Example3" className="form-control form-control-lg mb-2" style={{ padding: '4px 8px' }}
                                    placeholder={``}
                                />
                                <div className='d-flex justify-content-end'>
                                    <button className='btn-primary-small' style={{ padding: '6.5px 24px', height: 40 }} onClick={handleSaveClick}>{t('save')}</button>
                                </div>
                            </div>
                        )}
                        {
                            type === 'reimbursement' && details?.rateChangedBy &&
                            <div className="border-orange d-flex flex-column mt-3" style={{ width: '100%', backgroundColor: '#FFFAF2', padding: '4px', borderRadius: 10 }}>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <div>
                                        <img src={ErrorImagesmall} style={{ height: '18px', objectFit: 'contain', width: '18px', marginRight: 10, marginLeft: 5 }} />
                                    </div>
                                    <div className='d-flex flex-nowrap'>
                                        <h3 className='text-warning mb-0'>{'Rate has been changed by ' + details?.changedBy}</h3>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className='d-flex align-items-center justify-content-between mt-3'>
                            <p className='text-grey mb-0 fw-bold' style={{ fontSize: 14 }}>{type === "reimbursement" ? formatTime(details?.date) : formatTime(details?.createdAt)}</p>
                            <p className='text-grey mb-0 fw-bold' style={{ fontSize: 14 }}>{type === "reimbursement" ? timeUTCToLocal(details?.time) : moment(details?.createdAt).format('hh:mm A')}</p>
                        </div>
                    </div>
                    {<div className='col-12 border-grey mt-4 p-3'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <img
                                src={imageUrl ? GreenRecepitIcon : RecepitIcon}
                                style={{
                                    width: 24,
                                    objectFit: 'contain',
                                    filter: (!imageUrl) ? 'grayscale(100%)' : '',
                                    marginRight: 15,
                                    marginLeft: 15
                                }}
                                title={imageUrl ? "Receipt is uploaded" : "No Receipt"}
                            />
                            <img
                                src={reviewIcons[(details?.decision) || null]}
                                style={{
                                    width: 24,
                                    objectFit: 'contain',
                                    marginRight: 15,
                                    marginLeft: 15
                                }}
                                title={
                                    (details?.decision) === 'reject'
                                        ? "Rejected"
                                        : (details?.decision)
                                            ? "Approved"
                                            : "No Action"
                                }
                                className={(details?.decision === null || details?.decision === 'pending') ? 'grayscale-image' : ''}
                            />
                            <img
                                src={details?.categoryId ? CategoryGreenIcon : CategoryGreyIcon}
                                style={{
                                    width: 24,
                                    objectFit: 'contain',
                                    marginRight: 15,
                                    marginLeft: 15
                                }}
                                title={details?.categoryId ? "Category is selected" : "No Category"}
                            />
                            <img
                                src={details?.isExported ? ExportGreenIcon : ExportGreyIcon}
                                style={{
                                    width: 24,
                                    objectFit: 'contain',
                                    marginRight: 15,
                                    marginLeft: 15
                                }}
                                title={details?.isExported ? "Exported" : "Not Exported Yet"}
                            />
                            {
                                type === 'reimbursement' &&
                                <img
                                    src={details?.isSettled ? SettledIcon : UnsettledIcon}
                                    style={{ width: 28, objectFit: 'contain', marginRight: 15, marginLeft: 15 }}
                                    title={details?.isSettled ? 'Settled' : 'un-settled'}
                                />
                            }
                        </div>
                    </div>}
                    <div className='col-12 border-grey mt-3 p-3 pb-0'>
                        <div className='d-flex align-items-center justify-content-between mt-2'>
                            <p className='text-black mb-0 fw-bold' style={{ fontSize: 14 }}>{t('name')}</p>
                            <p className='text-grey mb-0 fw-bold' style={{ fontSize: 14 }}>{details?.firstName} {details?.lastName}</p>
                        </div>
                        <div className='d-flex align-items-center justify-content-between mt-3'>
                            <p className='text-black mb-0 fw-bold' style={{ fontSize: 14 }}>{t('cards.department')}</p>
                            <p className='text-grey mb-0 fw-bold' style={{ fontSize: 14 }}>{details?.departmentName}</p>
                        </div>
                        <div className='d-flex align-items-center justify-content-between mt-3'>
                            <p className='text-black mb-0 fw-bold' style={{ fontSize: 14 }}>{t('expenses.category')}</p>
                            {
                                details?.iconPath &&
                                <img onError={(e) => { e.target.onerror = null; e.target.style.display = 'none'; }} src={details?.iconPath} style={{ display: 'flex', width: 32, height: 32, objectFit: 'fill', borderRadius: 20, marginRight: 10, marginLeft: 10 }} />
                            }
                            {
                                !filePermission ? <p className='text-grey mb-0 fw-bold' style={{ fontSize: 14 }}>{details?.category}</p>
                                    : (<select className="form-control form-select form-control-lg" value={details?.categoryId ?? ''} style={{ width: ' 60%' }} onChange={(event) => { type1 !== 'export' && updateCardTransactionData({ categoryId: event.target.value, }); }}
                                        disabled={type1 === 'export' ? true : false}
                                        id='selectCard'
                                    >
                                        {
                                            type === "reimbursement" ?
                                                <option value={null} key={'cardlist' + 'rem'}>Select category</option> :
                                                <option value="">Select category</option>
                                        }
                                        {
                                            categoryList && categoryList.length > 0 &&
                                            categoryList.map((option, i) => (
                                                <option value={option?.categoryId} key={'cardlist' + i}  >{option?.name}</option>
                                            ))}

                                    </select>)
                            }
                        </div>
                        <div className='d-flex align-items-center justify-content-between mt-3'>
                            <p className='text-black mb-0 fw-bold' style={{ fontSize: 14 }}>{t('teams.description')}</p>
                            {
                                !filePermission ? <p className='text-grey mb-0 fw-bold' style={{ fontSize: 14 }}>{details?.description}</p>
                                    : (<input
                                        style={{ width: ' 60%' }}
                                        onBlur={() => { type1 !== 'export' && updateCardTransactionData({ description: description }); }}
                                        onChange={(event) => (setDiscription(event.target.value))}
                                        value={description}
                                        type="text" id="form3Example3" className="form-control form-control-lg"
                                        disabled={type1 === 'export' ? true : false}
                                    />)
                            }
                        </div>
                        {!filePermission ? (
                            <div className="accordion custom-accordiun-expenses mt-3" id="accordionExample">
                                {
                                    (typeof details?.tags != 'undefined' && details?.tags.length > 0) &&
                                    <>
                                        <p className="text-black mb-1 fw-bold" style={{ fontSize: 14 }}>{t('settingstab.tags')}</p>
                                        <Accordion defaultActiveKey={'tagGroups0'}>{
                                            (details?.tags).map((tagGroup, i) => {
                                                return (
                                                    <Accordion.Item eventKey={'tagGroups' + i}>
                                                        <Accordion.Header> <p className='text-black mb-0 fw-bold text-capitalize' >{tagGroup?.label}</p></Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className='row'>
                                                                <div className='col'>{tagGroup?.amount.toLocaleString()} <strong style={{ fontSize: 14 }}>QAR</strong></div>
                                                                <div className='col'>{tagGroup?.value}</div>

                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                );
                                            })
                                        }
                                        </Accordion>

                                    </>
                                }
                            </div>

                        ) : (
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="accordion custom-accordiun-expenses mt-3" id="accordionExample">
                                    {
                                        (typeof tagGroups != 'undefined' && tagGroups.length > 0) &&

                                        tagGroups?.map((tagGroup, i) => {
                                            return (
                                                <div key={i} className='mt-3'>
                                                    {
                                                        tagGroup['tags']?.length > 0 &&
                                                        <div className="accordion-item" key={i}>
                                                            <h2 className="accordion-header">
                                                                <button
                                                                    disabled={type1 === 'export' ? true : false}
                                                                    className="accordion-button"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#collapse-${i}`}
                                                                    aria-expanded={true}
                                                                    aria-controls={`collapse-${i}`}
                                                                >
                                                                    <div className='d-flex align-items-center justify-content-between'>
                                                                        <p className='text-grey mb-0 fw-bold' style={{ fontSize: 14 }}>{tagGroup?.label}</p>
                                                                    </div>
                                                                </button>
                                                            </h2>
                                                            <div id={`collapse-${i}`} className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                <div className="accordion-body">
                                                                    <DynamicFieldArray
                                                                        key={i}
                                                                        t={t}
                                                                        tags={tagGroup}
                                                                        register={register}
                                                                        deletedTag={details?.tags?.length > 0 && details?.tags}
                                                                        existingValue={details?.tags?.length > 0 && details?.tags?.filter(tag => tag?.label === tagGroup?.label)}
                                                                        setValue={setValue}
                                                                        name={(tagGroup?.label).replace(/ /g, '_')}
                                                                        control={control}
                                                                        errors={errors}
                                                                        watch={watch}
                                                                        //onUpdate={handleFieldsValues} 
                                                                        onUpdate={(isUpdated) => { setIsEnabled(isUpdated); }}
                                                                        details={details}
                                                                        calculateTotalAmount={calculateTotalAmount}
                                                                        reset={reset}
                                                                        placeholderData={wholePart2 ? wholePart2 : wholePart}
                                                                        disabled={type1 === 'export' ? true : false}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                                {/*
                                    type1 !== 'export' && (addTagFormValid && <div className='d-flex align-items-end justify-content-end mt-3'>
                                        <button disabled={!isEnabled} type="submit" className={isEnabled ? 'filled-green-button w-100' : 'disabled-filled-button w-100'}>{t('apply')}</button>
                                    </div>)
                                */}
                                <button disabled={!isEnabled} type="submit" className={isEnabled ? 'filled-green-button my-3 w-100' : 'disabled-filled-button my-3 w-100'}>{t('apply')}</button>
                            </form>
                        )}
                    </div>
                    {
                        (type !== 'reimbursement' && isWrongDetail) &&
                        <div className='row g-1'>
                            <div className='col-lg-12 col-md-12 mt-4'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <div className="border-orange p-3 d-flex flex-column" style={{ width: '100%', backgroundColor: '#FFFAF2' }}>
                                        <div className='row justify-content-center'>
                                            <div className='col-lg-1 col-md-1'>
                                                <img src={ErrorImage} style={{ height: '25px' }} />
                                            </div>
                                            <div className='col-lg-11 col-md-11 d-flex flex-nowrap'>
                                                <h3 className='text-warning mb-0'>{t('expenses.error_heading')}</h3>
                                            </div>
                                        </div>
                                        <div style={{ color: '#FFA500', fontWeight: 400, marginLeft: 30, marginTop: 10 }}>
                                            {
                                                details?.receiptOCRdata?.amount !== details?.amount &&
                                                <p className='mb-0'>{t('teams.amount')} - {details?.receiptOCRdata?.amount.toLocaleString()}</p>
                                            }
                                            {
                                                (details?.receiptOCRdata?.merchantName?.toLowerCase() !== details?.merchantName?.toLowerCase()) &&
                                                <p className='mb-0'>{t('expenses.merchant_name')} - {details?.receiptOCRdata?.merchantName}</p>
                                            }
                                            {
                                                (formatTime(details?.createdAt) !== formatTime(details?.receiptOCRdata?.date)) &&
                                                <p>{t('teams.date')} - {(details?.receiptOCRdata?.date) ? formatTime(details?.receiptOCRdata?.date) : ''}</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className='row g-1 mb-3'>
                        <div className='col-lg-6 col-md-12 mt-4'>
                            <div className='d-flex align-items-center justify-content-center'>
                                {(imageUrl || details?.receiptPath) && (
                                    <div>
                                        {imageUrl && !details?.receiptPath?.includes('.pdf') ? (
                                            <img
                                                src={imageUrl}
                                                style={{ height: 141, objectFit: 'cover', width: '100%', cursor: 'pointer', backgroundColor: '#f2f2f2' }}
                                                onClick={handleImageClick}
                                                alt="Receipt Image"
                                            />
                                        ) : (
                                            <>
                                                <PdfComponent handleImageClick={handleImageClick} url={details?.receiptPath}></PdfComponent>
                                            </>
                                        )}
                                    </div>
                                )}
                                {(!imageUrl && !details?.receiptPath) && <div className="border-grey p-2 d-flex align-items-center flex-column justify-content-center" style={{ height: 141, width: '100%' }}>
                                    <p className='text-blue mb-0 fw-bold text-center'>No Receipt</p>
                                </div>}

                                {showFullScreen && (
                                    <div
                                        className="fullscreen-overlay active"
                                    >
                                        <div className="close-image">
                                            <img
                                                src={CloseImage}
                                                onClick={(e) => { e.stopPropagation(); setShowFullScreen(false); }}
                                                className='close-image-fullscreen'
                                            />
                                        </div>
                                        {imageUrl && !details?.receiptPath?.includes('.pdf') ? (
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <TransformWrapper
                                                    initialScale={1}
                                                    initialPositionX={0}
                                                    initialPositionY={0}
                                                    centerOnInit={true}
                                                >
                                                    <TransformComponent wrapperStyle={{ width: '100%', height: window.innerHeight }}>
                                                        <img
                                                            src={imageUrl}
                                                            className='receipt_img_big'
                                                            onClick={handleImageClick}
                                                            alt="Receipt Image"
                                                            style={{ width: '100%', objectFit: 'contain', cursor: 'pointer', height: window.innerHeight }}
                                                        />
                                                    </TransformComponent>
                                                </TransformWrapper>
                                            </div>
                                        ) : (
                                            <div style={{ width: '50%', height: '100%' }}>
                                                <Worker workerUrl={pdfjsWorker}>
                                                    <Viewer
                                                        fileUrl={details?.receiptPath}
                                                        plugins={[defaultLayoutPluginInstance]}
                                                    />
                                                </Worker>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 mt-4'>
                            <div className='border-grey p-2 align-items-center flex-column' style={{ cursor: 'pointer', display: type1 === 'export' ? 'none' : 'flex' }} onClick={handleImageUploadClick}>
                                <img src={UploadImage} style={{ height: 40, objectFit: 'contain' }} />
                                <p className='text-blue mb-0 fw-bold text-center' style={{ fontSize: 14, cursor: 'pointer' }}>{t('click_to_upload_new_receipt')}</p>
                                <p className='text-grey mb-0 text-center ' style={{ fontSize: 14 }}>{t('PNG_or_JPG')}</p>
                                {filePermission &&
                                    <input disabled={type1 === 'export' ? true : false} type="file" accept=".jpg, .jpeg, .png .pdf" ref={fileInputRef} style={{ display: 'none' }} onChange={handleImageChange} />
                                }
                            </div>
                        </div>
                        <div className='col-lg-12 col-md-12 mt-4' >
                            <div >
                                <Slider
                                    {...settings}>
                                    {attachments?.length > 0 && attachments?.map((el, i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                {typeof el === 'string' ? (
                                                    !el.includes('.pdf') ? (
                                                        <div>
                                                            <div onClick={() => handleRemoveImage(i)} className='clo-2' style={{ cursor: 'pointer' }}>
                                                                <img src={ImagesCross} style={{ width: 20, objectFit: 'contain', display: type1 === 'export' ? 'none' : '' }} alt="Cross" />
                                                            </div>
                                                            <div onClick={() => { setShowFullScreen2(true); setSelectedFile(el); }} style={{ marginRight: 10, height: 120 }}>
                                                                <img
                                                                    src={el}
                                                                    style={{ height: 120, objectFit: 'cover', width: '100%', cursor: 'pointer', backgroundColor: '#f2f2f2' }}
                                                                    alt="Receipt Image"
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div style={{ height: '100%' }}>
                                                            <div aria-disabled={disable} onClick={() => handleRemoveImage(i)} className='clo-2' style={{ cursor: 'pointer' }}>
                                                                <img src={ImagesCross} style={{ width: 20, objectFit: 'contain', display: type1 === 'export' ? 'none' : '' }} alt="Cross" />
                                                            </div>
                                                            <div onClick={() => { setShowFullScreen2(true); setSelectedFile(el); }} style={{ height: 120, objectFit: 'cover', width: 120, cursor: 'pointer' }}>
                                                                <PdfComponent url={el}></PdfComponent>
                                                            </div>
                                                        </div>
                                                    )
                                                ) : (
                                                    el?.type !== 'pdf' ? (
                                                        <div>
                                                            <div onClick={() => handleRemoveImage(i, el?.url)} className='clo-2' style={{ cursor: 'pointer' }}>
                                                                <img src={ImagesCross} style={{ width: 20, objectFit: 'contain', display: type1 === 'export' ? 'none' : '' }} alt="Cross" />
                                                            </div>
                                                            <div onClick={() => { setShowFullScreen2(true); setSelectedFile({ type: 'image', url: el?.url }); }} style={{ marginRight: 10, height: 120 }}>
                                                                <img
                                                                    src={el?.url}
                                                                    style={{ height: 120, objectFit: 'cover', width: '100%', cursor: 'pointer', backgroundColor: '#f2f2f2' }}
                                                                    alt="Receipt Image"
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div style={{ height: '100%' }}>
                                                            <div onClick={() => handleRemoveImage(i)} className='clo-2' style={{ cursor: 'pointer' }}>
                                                                <img src={ImagesCross} style={{ width: 20, objectFit: 'contain', display: type1 === 'export' ? 'none' : '' }} alt="Cross" />
                                                            </div>
                                                            <div onClick={() => { setShowFullScreen2(true); setSelectedFile({ type: 'pdf', url: el?.url }); }} style={{ height: 120, objectFit: 'cover', width: 120, cursor: 'pointer' }}>
                                                                <PdfComponent url={el?.url}></PdfComponent>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </React.Fragment>
                                        );
                                    })}

                                </Slider>
                            </div>
                            {showFullScreen2 && (
                                <div
                                    className="fullscreen-overlay active"
                                >
                                    <div className="close-image">
                                        <img
                                            src={CloseImage}
                                            onClick={(e) => { e.stopPropagation(); setShowFullScreen2(false); }}
                                            className='close-image-fullscreen'
                                        />
                                    </div>
                                    {(typeof selectedFile === 'string' ? (selectedFile?.includes('.pdf') ? false : true) : selectedFile?.type !== 'pdf' ? true : false) ? (
                                        <TransformWrapper
                                            initialScale={1}
                                            initialPositionX={0}
                                            initialPositionY={0}
                                            centerOnInit={true}
                                        >
                                            <TransformComponent wrapperStyle={{ width: '100%', height: window.innerHeight }}>
                                                <img
                                                    src={(selectedFile?.url ? selectedFile?.url : selectedFile)}
                                                    className='receipt_img_big'
                                                    onClick={handleImageClick}
                                                    alt="Receipt Image"
                                                    style={{ width: '100%', objectFit: 'contain', cursor: 'pointer', height: window.innerHeight }}
                                                />
                                            </TransformComponent>
                                        </TransformWrapper>
                                    ) : (
                                        <div style={{ width: '50%', height: '100%' }}>
                                            <Worker workerUrl={pdfjsWorker}>
                                                <Viewer
                                                    fileUrl={(selectedFile?.url ? selectedFile?.url : selectedFile)}
                                                    plugins={[defaultLayoutPluginInstance]}
                                                />
                                            </Worker>
                                        </div>
                                    )
                                    }
                                </div>
                            )}
                            {(details?.receiptOCRdata !== null && details?.receiptOCRdata !== undefined) && < div className=' align-items-center justify-content-end mt-4' style={{ display: type1 === 'export' ? 'none' : 'flex' }}>
                                <button onClick={handleImageUploadClick2} disabled={disable} className='btn-primary' >Add Attachments</button>
                                {
                                    <input type="file" multiple accept=".jpg, .jpeg, .png .pdf" ref={fileInputRef2} style={{ display: 'none' }} onChange={handleImageChange2} />
                                }
                            </div>}
                        </div>
                    </div>
                </Tab>
                <Tab eventKey={2} title={`${t('expenses.activities')}`}>
                    {(type === 'reimbursement' ? reimbursementActivityLogs : expenseActivityLogs) && (type === 'reimbursement' ? reimbursementActivityLogs : expenseActivityLogs)?.length > 0 &&
                        (type === 'reimbursement' ? reimbursementActivityLogs : expenseActivityLogs)?.map((activity) => {
                            return (
                                <div className='my-4'>
                                    <p className='text-black mb-0 fw-bold' style={{ fontSize: 20 }}>{formatTime(activity?.date)}</p>
                                    <div className='col-12 border-grey mt-3 p-3'>
                                        {activity.records && (activity.records).length > 0 &&
                                            (activity.records).map((record) => {
                                                return (
                                                    <div className='row mt-2 table-body-tr'>
                                                        <p className='col-lg-4 text-black mb-0 fw-bold' style={{ fontSize: 14 }}>{moment(record?.time).format('hh:mm A')}</p>
                                                        <p className='col-lg-8 text-grey mb-0 fw-bold' style={{ fontSize: 14 }}>{record?.description}</p>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            );
                        })
                    }
                </Tab>
            </Tabs>
        </div>
    );
}

export default ExpensesModalTabs;