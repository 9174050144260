import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import DebitCard from '../../components/Teams/DebitCard';
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

const PreviousBtn = (props) => {
    const { className, goToPrevSlide } = props;
    return (
        <div className={className} onClick={goToPrevSlide}>
            <img src={require(`../../assets/images/sliderprevimg.png`)} alt="Previous" />
        </div>
    );
};
const PreviousBtnArabic = (props) => {
    const { className, goToPrevSlide } = props;
    return (
        <div className={className} onClick={goToPrevSlide}>
            <img src={require(`../../assets/images/slidernextimg.png`)} alt="Previous" style={{position:"relative" , right:155}} />
        </div>
    );
};

const NextBtnArabic = (props) => {
    const { className, goToNextSlide } = props;

    return (
        <div className={className} onClick={goToNextSlide}>
            <img src={require(`../../assets/images/sliderprevimg.png`)} alt="Next"  style={{position:"relative" , left:170}}/>
        </div>
    );
};
const NextBtn = (props) => {
    const { className, goToNextSlide } = props;

    return (
        <div className={className} onClick={goToNextSlide} >
            <img src={require(`../../assets/images/slidernextimg.png`)} alt="Next" />
        </div>
    );
};

function CardSlider({ details, setActiveCard, handleCardFreezUnfreezAction }) {
    const sliderRef = React.createRef();
    const { t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState({});

    useEffect(() => {
        const languages = [
            {
                code: 'en',
                name: 'English',
                country_code: 'gb',
            },
            {
                code: 'ar',
                name: 'العربية',
                dir: 'rtl',
                country_code: 'sa',
            },
        ];
        const currentLanguageCode = cookies.get('i18next') || 'en';
        const language = languages.find((l) => l.code === currentLanguageCode);
        setCurrentLanguage(language || {});
        document.body.dir = language?.dir || 'ltr';
        document.title = t('app_title');
    }, [t]);

    const settings = {
        dots: true,
        arrows: true,
        speed: 500,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        className: "center",
        centerMode: true,
        responsive: [
            {
                breakpoint: 568,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    centerMode: false,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    centerMode: false,
                }
            },
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    centerMode: false,
                }
            },
        ],
        afterChange: (currentSlide) => {
            setActiveCard(currentSlide);
        }
    };

    const goToNextSlide = () => {
        sliderRef.current.slickNext();
    };

    const goToPrevSlide = () => {
        sliderRef.current.slickPrev();
    };

    return (
        <>
            {currentLanguage.code === 'en' ? (
                <Slider
                    ref={sliderRef}
                    prevArrow={<PreviousBtn goToPrevSlide={goToPrevSlide} />}
                    nextArrow={<NextBtn goToNextSlide={goToNextSlide} />}
                    {...settings}>
                    {details.map((detail, index) => (
                        <div className='p-2 px-2' key={index}>
                            <DebitCard backgroundColor={(index % 2 === 0) ? '' : 'grey'} detail={detail} handleCardFreezUnfreezAction={handleCardFreezUnfreezAction} />
                        </div>
                    ))}
                </Slider>
            ) : (
                <Slider
                    ref={sliderRef}
                    prevArrow={<PreviousBtnArabic goToPrevSlide={goToPrevSlide} />}
                    nextArrow={<NextBtnArabic goToNextSlide={goToNextSlide} />}
                    {...settings}>
                    {details.map((detail, index) => (
                        <div className='p-2 px-2' key={index}>
                            <DebitCard backgroundColor={(index % 2 === 0) ? '' : 'grey'} detail={detail} handleCardFreezUnfreezAction={handleCardFreezUnfreezAction} />
                        </div>
                    ))}
                </Slider>
            )}
        </>
    );
}

export default CardSlider;
