import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CrossImage from '../../assets/images/close-square-8.png';
import CustomDropdown from '../CustomDropdown/CustomDropdown';
import * as yup from 'yup';
import cookies from 'js-cookie';
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';


const PettyCashModal1 = ({ show, onClose, onSubmit, selectOptions, title }) => {
    const { t } = useTranslation();
    const languages = [
        {
            code: 'en',
            name: 'English',
            country_code: 'gb',
        },
        {
            code: 'ar',
            name: 'العربية',
            dir: 'rtl',
            country_code: 'sa',
        },
    ];
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr';
        document.title = t('app_title');
    }, [currentLanguage, t]);

    const { control: controlModalForm,
        handleSubmit: handleModalSubmit,
        formState: { errors: errorsModalForm }, reset: resetModalForm, } = useForm({
            resolver: yupResolver(yup.object().shape({
                amount: yup
                    .string()
                    .required(`${t('pettyCash.amount_required')}`)
                    .matches(/^\d*\.?\d*$/, 'Please enter valid amount'),
                description: yup
                    .string()
                    .min(2, `${t('pettyCash.desc_min_length')}`)
                    .max(255, `${t('pettyCash.desc_max_length')}`)
                    .required(`${t('pettyCash.desc_required')}`),
                employee_name: yup.string().required(t('pettyCash.employee_name_required')),
            })),
            defaultValues: {
                amount: '',
                description: '',
                employee_name: ''
            }
        });

    const handleCloseModal = () => {
        resetModalForm();
        onClose();
    };

    const onSubmitModal = (data) => {
        console.log("Form Submitted");
        onSubmit(data);
    };

    return (
        <div className={`${currentLanguageCode === 'en' ? 'white-container' : 'white-container-arabic'}`}>
            <Modal className='TaxcodeModalOne' show={show} onHide={handleCloseModal} style={{ paddingRight: 0 }}>
                <form onSubmit={handleModalSubmit(onSubmitModal)}>
                    <Modal.Body>
                        <div className='d-flex justify-content-between align-items-start green-header'>
                            <div className='col-9'>
                                <p className='text-green addjust pettyCach-model-title' style={{ fontSize: 16, marginRight: 15 }}>
                                    {title}
                                </p>
                            </div>
                            <div onClick={handleCloseModal} className='clo-2' style={{ cursor: 'pointer' }}>
                                <img src={CrossImage} className='small-addjust' style={{ width: 20, objectFit: 'contain', marginLeft: 15 }} />
                            </div>
                        </div>
                        <div className="form-outline mt-4 mb-3">
                            <label className="label-form" htmlFor="employee_name">{t('pettyCash.employee_name')}</label>
                            <Controller
                                control={controlModalForm}
                                name="employee_name"
                                render={({ field }) => (
                                    <CustomDropdown
                                        options={selectOptions}
                                        selectedOption={field.label || 'Select Employee'}
                                        onChange={(option) => field.onChange(option.value)}
                                    />
                                )}
                            />
                        </div>
                        <div className="form-outline mt-4 mb-3">
                            <label className="label-form" htmlFor="amount">{t('pettyCash.amount')}</label>
                            <Controller
                                name="amount"
                                control={controlModalForm}
                                render={({ field }) => (
                                    <CurrencyInput
                                        type="text"
                                        id="amount"
                                        className="form-control form-control-lg"
                                        placeholder={`${t('pettyCash.amount')}`}
                                        value={field.value || ''}
                                        decimalsLimit={2}
                                        decimalScale={2}
                                        onValueChange={
                                            (value) => {
                                                if (/^\d*\.?\d*$/.test(value)) {
                                                    field.onChange(value.startsWith('.') ? `0${value}` : value);
                                                }
                                            }}
                                    />
                                )}
                            />
                            {errorsModalForm?.amount && (
                                <p className="text-danger">{errorsModalForm?.amount?.message}</p>
                            )}
                        </div>
                        <div className="form-outline mt-4 mb-4">
                            <label className="label-form" htmlFor="description">{t('pettyCash.desc')}</label>
                            <Controller
                                name="description"
                                control={controlModalForm}
                                render={({ field }) => (
                                    <input
                                        type="text"
                                        {...field}
                                        id="description"
                                        className="form-control form-control-lg"
                                        placeholder={`${t('pettyCash.desc')}`}
                                        value={field.value || ''}
                                    />
                                )}
                            />
                            {errorsModalForm?.description && (
                                <p className="text-danger">{errorsModalForm?.description?.message}</p>
                            )}
                        </div>
                        <div style={{ borderBottom: '1px solid #EAECF0', marginBottom: 24 }}></div>
                        <div className='d-flex justify-content-end align-items-center'>
                            <button type="submit" className='btn-primary-small' >{t('pettyCash.submit')}</button>
                        </div>
                    </Modal.Body>
                </form>
            </Modal>
        </div>
    );
};

export default PettyCashModal1;
