import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import FileUploadComponent from './FileUploadComponent';

function Attachments({ handleBackButton, onSubmit, uploadDocument, attachedDocs, remainingDocs, removeDocument, companyStatus }) {
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get('i18next') || 'en';

    const languages = [
        { code: 'en', name: 'English', country_code: 'gb' },
        { code: 'ar', name: 'العربية', dir: 'rtl', country_code: 'sa' },
    ];
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr';
        document.title = t('app_title');
    }, [currentLanguage, t]);

    // Ensure the lists are always arrays
    const safeAttachedDocs = Array.isArray(attachedDocs) ? attachedDocs : [];
    const safeRemainingDocs = Array.isArray(remainingDocs) ? remainingDocs : [];

    // Combine attached and remaining docs while maintaining order
    const allDocs = [...safeAttachedDocs, ...safeRemainingDocs].sort((a, b) => a.attachmentTypeId - b.attachmentTypeId);

    const handleUpload = (file) => {
        if (file) {
            uploadDocument(file);
        }
    };

    const handleRemove = (file) => {
        if (attachedDocs.some(item => item.attachmentTypeId === file.typeID)) {
            removeDocument(file.typeID);
        }
    };

    return (
        <>
            <p className='text-green mb-3' style={{ fontSize: 20, fontWeight: '500', color: "#8B9299" }}>{t('company.attachments')}</p>
            <h3 className='text-grey mb-3 fw-normal'>{t('company.attachments_desc')}</h3>
            <div className="responsive-file-grid">
                {allDocs.map((document) => (
                    <FileUploadComponent 
                        key={document.attachmentTypeId} 
                        docTypeId={document.attachmentTypeId} 
                        uploadedDoc={document.attachmentPath} 
                        docTitle={document.attachmentType} 
                        handleUpload={handleUpload} 
                        handleRemove={handleRemove} 
                        companyStatus={companyStatus} 
                    />
                ))}
            </div>
            <div style={{ borderBottom: '1px solid #EAECF0', marginBottom: 18 }}></div>
            <div className='d-flex align-items-center justify-content-center'>
                <button type='button' onClick={handleBackButton} className={companyStatus === 'InActive' ? 'btn-secondary-small' : 'disabledButton'} style={{ width: '45%', textAlign: "center" }}>{t('users.back')}</button>
                <div className='pe-3'></div>
                <button type="button" onClick={onSubmit} className={companyStatus === 'InActive' ? 'btn-primary-small' : 'disabledButton'} style={{ width: '45%' }}>{t('next')}</button>
            </div>
        </>
    );
}

export default Attachments;
