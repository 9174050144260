import React, { useEffect, useState } from 'react';
import { Modal, Tab, Tabs, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ImagesCross from '../../assets/images/cross.png';
import { useDispatch, useSelector } from 'react-redux';
import { createAuditTrail, exportAllPagesTopUpRecords, exportTopUpRecords, getCardTopUpRequest, updateCardTopUpRequest } from './slice/topUpSlice';
import Loader from '../../components/Loader/Loader';
import moment from 'moment';
import { toast } from 'react-toastify';
import cookies from 'js-cookie';
import SearchIcon from '../../assets/images/search_img.png';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import OtpInput from 'react-otp-input';
import Logo2 from '../../assets/images/polo.png';
import { cleanAmount, formatDate, formatQAR, formatTime, formatTimeHours, truncateDescription } from '../../utils/XpendlessUtils';
import "./topUpRequest.scss";
import notFound from '../../assets/images/not_found.png';
import CrossImage from '../../assets/images/close-square-8.png';
import CurrencyInput from 'react-currency-input-field';
import { MAX_VALUE } from '../../utils/constants';

function TopUpRequest() {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [showTwo, setShowTwo] = useState(false);
    const dispatch = useDispatch();
    const handleClose = () => setShow(false);
    const [employeeData, setEmployeeData] = useState('');
    const [comments, setComments] = useState('');
    const [checked, setChecked] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [status, setStatus] = useState('pending');
    const handleCloseTwo = () => setShowTwo(false);
    const handleShowTwo = () => setShowTwo(true);
    const [pendingCount, setPendingCount] = useState(0);
    const [approveCount, setApproveCount] = useState(0);
    const [rejectCount, setRejectCount] = useState(0);
    const [activeKey, setActiveKey] = useState(1);
    const { fetchTopUp, isLoading } = useSelector(state => state?.topUpRequestSlice);
    const [selectedItems, setSelectedItems] = useState([]);
    const [showVerifyModal, setShowVerifyModal] = useState(false);
    const handleShowVerifyModal = () => setShowVerifyModal(true);
    const [timer, setTimer] = useState(60);
    const [topupType, setTopupType] = useState('approve');
    const [checkedAllPages, setChekedAllPages] = useState(false);
    const [pseudoCheckedAll, setPseudoCheckedAll] = useState(false);
    const [uncheckedTransaction, setUncheckedTransaction] = useState([]);
    const [itemDetails, setItemDetails] = useState(null);
    const [showThree, setShowThree] = useState(false);
    const [isDescription1Expanded, setIsDescription1Expanded] = useState(false);

    const handleCloseModalThree = () => setShowThree(false);
    const handleShowThree = (item) => {
        setItemDetails(item);
        setShowThree(true);
    };

    const toggleView = () => {
        setIsDescription1Expanded(!isDescription1Expanded);
    };

    const getDescriptionText = (text, isExpanded) => {
        return text ? isExpanded ? text : `${text?.substring(0, 120)}${text?.length > 50 ? '...' : ''}` : '';
    };

    const getToggleText = (isExpanded) => {
        return isExpanded ? 'View Less' : 'View More';
    };
    const languages = [
        {
            code: 'en',
            name: 'English',
            country_code: 'gb',
        },
        {
            code: 'ar',
            name: 'العربية',
            dir: 'rtl',
            country_code: 'sa',
        },
    ];
    const [hoveredRowIndexPending, setHoveredRowIndexPending] = useState(null);
    const [hoveredRowIndexApproved, setHoveredRowIndexApproved] = useState(null);
    const [hoveredRowIndexRejected, setHoveredRowIndexRejected] = useState(null);

    const handleMouseEnter = (index, status) => {
        if (status === 'pending') { setHoveredRowIndexPending(index); }
        if (status === 'approved') { setHoveredRowIndexApproved(index); }
        if (status === 'rejected') { setHoveredRowIndexRejected(index); }
    };

    const handleMouseLeave = (status) => {
        if (status === 'pending') { setHoveredRowIndexPending(null); }
        if (status === 'approved') { setHoveredRowIndexApproved(null); }
        if (status === 'rejected') { setHoveredRowIndexRejected(null); }
    };

    const currentLanguageCode = cookies.get('i18next') || 'en';
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

    const {
        control: controlFilterForm,
        handleSubmit: handleFilterSubmit,
        formState: { errors: errorsFilterForm },
        reset: resetFilterForm,
        watch, } = useForm({
            resolver: yupResolver(yup.object().shape({
                startDate: yup.string().optional(),
                endDate: yup.string().optional().test('is-valid-end-date', 'End date should not be before start date', function (value) {
                    const startDate = this.resolve(yup.ref('startDate'));
                    return !startDate || !value || value >= startDate;
                }),
                updatedStartDate: yup.string().optional(),
                updatedEndDate: yup.string().optional().test('is-valid-end-date', 'End date should not be before start date', function (value) {
                    const startDate = this.resolve(yup.ref('startDate'));
                    return !startDate || !value || value >= startDate;
                }),
                fromAmount: yup.string().optional().test('is-number', 'Please enter a number for From amount', function (value) {
                    return !value || !isNaN(parseFloat(value));
                }),
                toAmount: yup.string().optional().test('is-number', 'Please enter a number for To amount', function (value) {
                    return !value || !isNaN(parseFloat(value));
                }).test('is-greater-than-from', 'To amount must be greater than from amount', function (value) {
                    const fromAmount = this.resolve(yup.ref('fromAmount'));
                    return !fromAmount || !value || parseFloat(value) > parseFloat(fromAmount);
                }),
                reviewerName: yup.string().optional(),
                search: yup.string().optional(),
            })
            )
        });

    const { control: controlVerifyModal, handleSubmit, formState: { errors }, reset: otpFormReset } = useForm({
        resolver: yupResolver(yup.object().shape({
            otp: yup.string().required("Otp is required").matches(/^123456$/, 'OTP must be exactly 6 digits'),
        }))
    });
    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr';
        document.title = t('app_title');
    }, [currentLanguage, t]);

    useEffect(() => {
        dispatch(createAuditTrail({ eventType: 'access', module: 'cardTopUp', description: `Fetch reviewer card top up request.` }));
    }, []);

    useEffect(() => {
        fetchData();
    }, [pageNo]);

    const fetchData = async () => {
        try {
            const startDate = watch('startDate');
            const endDate = watch('endDate');
            const updatedStartDate = watch('updatedStartDate');
            const updatedEndDate = watch('updatedEndDate');
            const fromAmount = watch('fromAmount');
            const toAmount = watch('toAmount');
            const reviewerName = watch('reviewerName');
            const search = watch('search');

            let queryParams = ``;

            if (startDate) {
                queryParams += `&startDate=${startDate}`;
            }

            if (endDate) {
                queryParams += `&endDate=${endDate}`;
            }

            if (updatedStartDate) {
                queryParams += `&updatedStartDate=${updatedStartDate}`;
            }

            if (updatedEndDate) {
                queryParams += `&updatedEndDate=${updatedEndDate}`;
            }

            const cleanFromAmount = fromAmount ? cleanAmount(fromAmount) : '';
            const cleanToAmount = toAmount ? cleanAmount(toAmount) : '';

            if (cleanFromAmount) {
                queryParams += `&fromAmount=${cleanFromAmount}`;
            }

            if (cleanFromAmount && cleanToAmount) {
                queryParams += `&toAmount=${cleanToAmount}`;
            }

            if (reviewerName !== undefined && reviewerName !== "") {
                queryParams += `&reviewerName=${reviewerName}`;
            }

            if (search !== undefined && search !== "") {
                queryParams += `&search=${search}`;
            }

            const rejectResponse = await dispatch(getCardTopUpRequest({ details: `?filterType=reject&page=${pageNo}${activeKey === 3 ? queryParams : ''}`, saveData: activeKey === 3 }));
            if (!queryParams)
                setRejectCount(rejectResponse.payload.data.count);

            if (activeKey === 3 && rejectResponse?.payload?.data?.rows?.length > 0) {
                if (pseudoCheckedAll) {
                    const selected = [...selectedItems];
                    for (const row of rejectResponse.payload.data.rows) {
                        if (!uncheckedTransaction?.includes(row?.cardTopupRequestId) && !selected.some(element => element?.cardTopupRequestId == row?.cardTopupRequestId))
                            selected.push({ cardTopupRequestId: row?.cardTopupRequestId });
                    }
                    setSelectedItems(selected);
                }
            }

            const approveResponse = await dispatch(getCardTopUpRequest({ details: `?filterType=approve&page=${pageNo}${activeKey === 2 ? queryParams : ''}`, saveData: activeKey === 2 }));
            if (!queryParams)
                setApproveCount(approveResponse.payload.data.count);

            if (activeKey === 2 && approveResponse?.payload?.data?.rows?.length > 0) {
                if (pseudoCheckedAll) {
                    const selected = [...selectedItems];
                    for (const row of approveResponse.payload.data.rows) {
                        if (!uncheckedTransaction?.includes(row?.cardTopupRequestId) && !selected.some(element => element?.cardTopupRequestId == row?.cardTopupRequestId))
                            selected.push({ cardTopupRequestId: row?.cardTopupRequestId });
                    }
                    setSelectedItems(selected);
                }
            }

            const pendingResponse = await dispatch(getCardTopUpRequest({ details: `?filterType=pending&page=${pageNo}${activeKey === 1 ? queryParams : ''}`, saveData: activeKey === 1 }));
            if (!queryParams)
                setPendingCount(pendingResponse.payload.data.count);

            if (activeKey === 1 && pendingResponse?.payload?.data?.rows?.length > 0) {
                if (pseudoCheckedAll) {
                    const selected = [...selectedItems];
                    for (const row of pendingResponse.payload.data.rows) {
                        if (!uncheckedTransaction?.includes(row?.cardTopupRequestId) && !selected.some(element => element?.cardTopupRequestId == row?.cardTopupRequestId))
                            selected.push({ cardTopupRequestId: row?.cardTopupRequestId });
                    }
                    setSelectedItems(selected);
                }
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const onPreviousClick = () => {
        setPageNo(prev => prev - 1);
    };

    const onNextClick = () => {
        setPageNo(prev => prev + 1);
    };
    const approveRejectRequest = async () => {
        const data = {
            cardTopupRequestId: employeeData?.cardTopupRequestId,
            approveStatus: topupType
        };
        if (comments) {
            data.comments = comments;
        }
        dispatch(updateCardTopUpRequest(data)).unwrap()
            .then(async (data) => {
                handleCloseTwo();
                handleClose();
                handleCloseModalThree();
                setChecked(false);
                handleCloseVerifyModal();
                otpFormReset({ otp: '' });
                toast.success(data.message);
                await fetchData();
            }).catch((error) => {
                setChecked(false);
                handleCloseTwo();
                handleClose();
                handleCloseVerifyModal();
                otpFormReset({ otp: '' });
                toast.error(error?.message || "Request Failed Please Try Again ");
            });
    };

    const handleChecked = () => {
        setChecked(prev => !prev);
    };

    const handleTab = (key) => {
        setPageNo(1);
        switch (key) {
            case '1':
                setSelectedItems([]);
                setPseudoCheckedAll(false);
                setUncheckedTransaction([]);
                setChekedAllPages(false);
                dispatch(getCardTopUpRequest({ details: `?filterType=pending`, saveData: true }));
                setActiveKey(1);
                setStatus('pending');
                resetFilterForm();
                break;
            case '2':
                setSelectedItems([]);
                setPseudoCheckedAll(false);
                setUncheckedTransaction([]);
                setChekedAllPages(false);
                dispatch(getCardTopUpRequest({ details: `?filterType=approve`, saveData: true }));
                setActiveKey(2);
                setStatus('approve');
                resetFilterForm();
                break;
            case '3':
                setSelectedItems([]);
                setPseudoCheckedAll(false);
                setUncheckedTransaction([]);
                setChekedAllPages(false);
                dispatch(getCardTopUpRequest({ details: `?filterType=reject`, saveData: true }));
                setActiveKey(3);
                setStatus('reject');
                resetFilterForm();
                break;
            default:
                break;
        }
    };

    const handleResetForm = () => {
        resetFilterForm();
        setSelectedItems([]);
        setPseudoCheckedAll(false);
        setUncheckedTransaction([]);
        setChekedAllPages(false);
    };

    const onFilterFormSubmit = (requestData) => {
        setPageNo(1);
        setChekedAllPages(false);
        setSelectedItems([]);
        let queryParams = `?filterType=${status}`;

        if (requestData?.startDate) {
            queryParams += `&startDate=${requestData.startDate}`;
        }

        if (requestData?.endDate) {
            queryParams += `&endDate=${requestData.endDate}`;
        }

        const fromAmount = requestData?.fromAmount ? cleanAmount(requestData.fromAmount) : '';
        const toAmount = requestData?.toAmount ? cleanAmount(requestData.toAmount) : '';

        if (fromAmount) {
            queryParams += `&fromAmount=${fromAmount}`;
        }

        if (toAmount) {
            queryParams += `&toAmount=${toAmount}`;
        }

        if (requestData?.updatedStartDate !== undefined) {
            queryParams += `&updatedStartDate=${requestData.updatedStartDate}`;
        }

        if (requestData?.updatedEndDate !== undefined) {
            queryParams += `&updatedEndDate=${requestData.updatedEndDate}`;
        }

        if (requestData?.reviewerName !== undefined && requestData?.reviewerName !== "") {
            queryParams += `&reviewerName=${requestData.reviewerName}`;
        }

        if (requestData?.search !== undefined && requestData?.search !== "") {
            queryParams += `&search=${requestData.search}`;
        }

        dispatch(getCardTopUpRequest({ details: queryParams, saveData: true })).unwrap().then(() => {
            setSelectedItems([]);
            setPseudoCheckedAll(false);
            setUncheckedTransaction([]);
            setChekedAllPages(false);
        }).catch((error) => {
            toast.error(error.message || "Request Failed Please Try Again ");
        });
    };
    const filterFormComponent = (
        <div className='white_container mt-4 p-3 py-0'>
            <div className='d-flex align-items-center justify-content-between green-header px-2'>
                <div className='d-flex align-items-center'>
                    <img src={SearchIcon} style={{ width: 24, objectFit: 'contain' }} />
                    <h2 className='text-green px-1'>{t('cards.search')}</h2>
                </div>
            </div>
            <form
                onSubmit={handleFilterSubmit(onFilterFormSubmit)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        handleFilterSubmit(onFilterFormSubmit)();
                    }
                }}
            >
                <div className='row'>
                    <div className="col-lg-3 col-md-6 col-sm-12 form-outline mt-4 ">
                        <label className="form-label" htmlFor="search">{t('cards.employee_name')}</label>

                        <Controller
                            name="search"
                            control={controlFilterForm}
                            render={({ field }) => (
                                <input
                                    type="text"
                                    id="search"
                                    className="form-control form-control-lg"
                                    placeholder="Name"
                                    autoComplete="off"
                                    value={field.value || ''}
                                    onChange={field.onChange}
                                    onBlur={field.onBlur}
                                />
                            )}
                        />
                    </div>
                    {activeKey !== 1 &&
                        <>
                            <div className="col-lg-3 col-md-6 col-sm-12 form-outline mt-4 ">
                                <label className="form-label" htmlFor="reviewerName">{t('teams.reviewer_name')}</label>
                                <Controller
                                    name="reviewerName"
                                    control={controlFilterForm}
                                    render={({ field }) => (
                                        <input
                                            type="text"
                                            id="reviewerName"
                                            className="form-control form-control-lg"
                                            placeholder="Name"
                                            autoComplete="off"
                                            value={field.value || ''}
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 form-outline mt-4 ">
                                <label className="form-label" htmlFor="updatedStartDate">{status === 'pending' ? 'Requested Date from' : 'Action Date from'}</label>
                                <Controller
                                    name="updatedStartDate"
                                    control={controlFilterForm}
                                    render={({ field }) => (
                                        <input
                                            type="date"
                                            id="updatedStartDate"
                                            className="form-control form-control-lg"
                                            placeholder="Action Date from"
                                            autoComplete="off"
                                            value={field.value || ''}
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                            style={{
                                                color: field.value ? 'black' : 'grey',
                                            }}
                                            max={moment().add(1, 'days').format('YYYY-MM-DD')}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 form-outline mt-4 ">
                                <label className="form-label" htmlFor="updatedEndDate">{status === 'pending' ? 'Requested Date to' : 'Action Date to'}</label>
                                <Controller
                                    name="updatedEndDate"
                                    control={controlFilterForm}
                                    render={({ field }) => (
                                        <input
                                            type="date"
                                            id="updatedEndDate"
                                            className="form-control form-control-lg"
                                            placeholder="Action Date to"
                                            autoComplete="off"
                                            value={field.value || ''}
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                            style={{
                                                color: field.value ? 'black' : 'grey',
                                            }}
                                            max={moment().add(1, 'days').format('YYYY-MM-DD')}
                                        />
                                    )}
                                />
                                {errorsFilterForm?.updatedEndDate && (
                                    <div style={{ color: 'red' }}>{errorsFilterForm?.updatedEndDate?.message}</div>
                                )}
                            </div>
                        </>
                    }
                    <div className="col-lg-3 col-md-6 col-sm-12 form-outline mt-4 ">
                        <label className="form-label" htmlFor="fromAmount">From Amount</label>
                        <Controller
                            name="fromAmount"
                            control={controlFilterForm}
                            render={({ field }) => (
                                <CurrencyInput
                                    type="text"
                                    id="fromAmount"
                                    className="form-control form-control-lg"
                                    placeholder="From Amount"
                                    autoComplete="off"
                                    value={field.value || ''}
                                    decimalsLimit={2}
                                    decimalScale={2}
                                    onBlur={field.onBlur}
                                    disablenegative={'true'}
                                    onKeyDown={(e) => {
                                        if (e.key === '-') {
                                            e.preventDefault();
                                        }
                                    }}
                                    onValueChange={(value) => {
                                        const numericValue = parseFloat(value?.replace(/,/g, '')) || 0;
                                        if (numericValue < MAX_VALUE) {
                                            field.onChange(value === '' ? '' : value?.startsWith('.') ? `0${value}` : value);
                                        }

                                    }}
                                />
                            )}
                        />
                        {errorsFilterForm?.fromAmount && (
                            <div style={{ color: 'red' }}>{errorsFilterForm?.fromAmount?.message}</div>
                        )}
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 form-outline mt-4 ">
                        <label className="form-label" htmlFor="toAmount">{t('expenses.to_amount')}</label>
                        <Controller
                            name="toAmount"
                            control={controlFilterForm}
                            render={({ field }) => (
                                <CurrencyInput
                                    type="text"
                                    id="toAmount"
                                    className="form-control form-control-lg"
                                    placeholder="To Amount"
                                    autoComplete="off"
                                    value={field.value || ''}
                                    decimalsLimit={2}
                                    decimalScale={2}
                                    onBlur={field.onBlur}
                                    disablenegative={'true'}
                                    onKeyDown={(e) => {
                                        if (e.key === '-') {
                                            e.preventDefault();
                                        }
                                    }}
                                    onValueChange={(value) => {
                                        const numericValue = parseFloat(value?.replace(/,/g, '')) || 0;
                                        if (numericValue < MAX_VALUE) {
                                            field.onChange(value === '' ? '' : value?.startsWith('.') ? `0${value}` : value);
                                        }
                                    }}
                                />
                            )}
                        />
                        {errorsFilterForm?.toAmount && (
                            <div style={{ color: 'red' }}>{errorsFilterForm?.toAmount?.message}</div>
                        )}
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 form-outline mt-4 ">
                        <label className="form-label" htmlFor="startDate">Request Date from</label>
                        <Controller
                            name="startDate"
                            control={controlFilterForm}
                            render={({ field }) => (
                                <input
                                    type="date"
                                    id="startDate"
                                    className="form-control form-control-lg"
                                    placeholder="Start Date"
                                    autoComplete="off"
                                    value={field.value || ''}
                                    onChange={field.onChange}
                                    onBlur={field.onBlur}
                                    style={{
                                        color: field.value ? 'black' : 'grey',
                                    }}
                                    max={moment().add(1, 'days').format('YYYY-MM-DD')}
                                />
                            )}
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 form-outline mt-4 ">
                        <label className="form-label" htmlFor="endDate">Request Date to</label>
                        <Controller
                            name="endDate"
                            control={controlFilterForm}
                            render={({ field }) => (
                                <input
                                    type="date"
                                    id="endDate"
                                    className="form-control form-control-lg"
                                    placeholder="Start Date"
                                    autoComplete="off"
                                    value={field.value || ''}
                                    onChange={field.onChange}
                                    onBlur={field.onBlur}
                                    style={{
                                        color: field.value ? 'black' : 'grey',
                                    }}
                                    max={moment().add(1, 'days').format('YYYY-MM-DD')}
                                />
                            )}
                        />
                        {errorsFilterForm?.endDate && (
                            <div style={{ color: 'red' }}>{errorsFilterForm?.endDate?.message}</div>
                        )}
                    </div>

                </div>
                <div className='d-flex justify-content-end'>
                    <button onClick={handleResetForm} className='btn-secondary-small mt-3 mx-3' id='reset_filter_topup'>{`Clear Search`}</button>
                    <button type='submit' className='btn-primary-small mt-3' id='search_filter_topup'>{t('cards.search')}</button>
                </div>
            </form>
        </div>
    );

    const handleCheckboxChange = (id) => {
        const selected = [...selectedItems];
        const unchecked = [...uncheckedTransaction];
        const index = selected.findIndex(
            (data) => data.cardTopupRequestId == id
        );

        if (index === -1) {
            if (pseudoCheckedAll) {
                const uncheckedIndex = unchecked.findIndex((trans) => trans == id);
                unchecked.splice(uncheckedIndex, 1);
                setUncheckedTransaction(unchecked);
            }
            selected.push({ cardTopupRequestId: id });
        } else {
            if (pseudoCheckedAll) {
                unchecked.push(id);
                setUncheckedTransaction(unchecked);
            }
            selected.splice(index, 1);
        }
        if (checkedAllPages)
            setChekedAllPages(false);
        setSelectedItems(selected);
    };

    const exportRecords = async (type = 'csv') => {
        try {
            let response = null;
            if (pseudoCheckedAll) {
                const startDate = watch('startDate');
                const endDate = watch('endDate');
                const updatedStartDate = watch('updatedStartDate');
                const updatedEndDate = watch('updatedEndDate');
                const fromAmount = watch('fromAmount');
                const toAmount = watch('toAmount');
                const reviewerName = watch('reviewerName');
                const search = watch('search');

                let queryParams = {};

                if (startDate && endDate) {
                    queryParams["startDate"] = startDate;
                    queryParams["endDate"] = endDate;
                }

                if (updatedStartDate && updatedEndDate) {
                    queryParams["updatedStartDate"] = updatedStartDate;
                    queryParams["updatedEndDate"] = updatedEndDate;
                }

                const cleanFromAmount = fromAmount ? cleanAmount(fromAmount) : '';
                const cleanToAmount = toAmount ? cleanAmount(toAmount) : '';

                if (cleanFromAmount && cleanToAmount) {
                    queryParams["fromAmount"] = cleanFromAmount;
                    queryParams["toAmount"] = cleanToAmount;
                }

                if (reviewerName !== undefined && reviewerName !== "") {
                    queryParams["reviewerName"] = reviewerName;
                }

                if (search !== undefined && search !== "") {
                    queryParams["search"] = search;
                }
                queryParams["filterType"] = status;
                queryParams["unchecked"] = uncheckedTransaction;

                response = await dispatch(exportAllPagesTopUpRecords(queryParams)).unwrap();
            } else {
                const postData = { type: 'csv', selectedRecords: selectedItems };
                response = await dispatch(exportTopUpRecords(postData)).unwrap();
            }
            let blob = '';
            if (type == 'csv') {
                blob = new Blob([response.data], { type: response.headers['content-type'] });
            }
            if (type == 'json') {
                blob = await new Blob([JSON.stringify(response.data)], { type: response.headers['content-type'] });
            }
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            const contentDisposition = response.headers['Content-disposition'];
            const match = contentDisposition && contentDisposition.match(/filename=(.*)/);
            const filename = match ? match[1] : 'exported';
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setSelectedItems([]);
            setChekedAllPages(false);
            setPseudoCheckedAll(false);
            setUncheckedTransaction([]);
            toast.success('Record Exported Successfully!');
        } catch (error) {
            toast.error(error.message || "Request Failed Please Try Again ");
        }
    };



    const handleCloseVerifyModal = () => {
        setShowVerifyModal(false);
        otpFormReset({ otp: '' });
    };

    useEffect(() => {
        let interval;

        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [timer]);

    const onClickVerifyModal = (data) => {
        setTimer(60);
        if (topupType === 'reject') {

            setComments(data);
        }
        handleClose();
        handleCloseTwo();
        otpFormReset({ otp: '' });
        setTimeout(() => {
            handleShowVerifyModal();
        }, 200);
    };
    const handleCheckAllPagesboxChange = () => {
        if (checkedAllPages) {
            setSelectedItems([]);
            setChekedAllPages(false);
            setPseudoCheckedAll(false);
        } else {
            let allRecords = [];
            fetchTopUp?.rows?.map((element) =>
                allRecords.push({
                    cardTopupRequestId: element?.cardTopupRequestId
                })
            );
            setSelectedItems(allRecords);
            setChekedAllPages(true);
            setPseudoCheckedAll(true);
        }
    };

    return (
        <div className={`dashboard-css topup-request ${currentLanguageCode === 'en' ? 'white-container' : 'white-container-arabic'}`}>
            {isLoading && <Loader />}
            <div className='d-flex align-items-center justify-content-between'>
                <h1 className='text-green mb-4'>{t('sidebar.card_topUp_request')}</h1>
            </div>
            <div className='settings setting-font'>
                <Tabs onSelect={(key) => handleTab(key)} defaultActiveKey={1} animation='false' id="topup-tabs">
                    <Tab eventKey={1} title={`${t('expenses.pending')} (${pendingCount})`} id='pending'>
                        {filterFormComponent}
                        <div className='white_container mt-4 p-3 py-0'>
                            <div className='d-flex justify-content-between'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value=""
                                        id="pendingCheckAll"
                                        checked={checkedAllPages}
                                        onChange={handleCheckAllPagesboxChange}
                                    />
                                    <label className="form-check-label" htmlFor="pendingCheckAll">
                                        {t('export.select_all')}
                                    </label>
                                </div>
                                <button onClick={() => exportRecords('csv')} style={{ borderRadius: 20 }} className='btn btn-primary btn-sm export-btn-style' disabled={selectedItems.length < 1} id='export_pending_topup'>{t('export.export')}</button>
                            </div>
                            <div className='table-responsive' style={{ border: 0 }}>
                                <table id="pending_topup_requests" style={{ borderCollapse: 'separate', borderSpacing: '0 16px' }} cellSpacing="0" width="100%">
                                    <thead>
                                        <tr style={{ borderTopWidth: 0 }} className='table-head'>
                                            <th scope="col">{t('company.select')}</th>
                                            <th scope="col">Employee Name</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Amount</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            fetchTopUp?.rows?.length > 0 &&
                                            fetchTopUp?.rows?.map((item, index) => {
                                                return <tr
                                                    key={index}
                                                    className={`${currentLanguageCode === 'en' ? 'table-body-tr' : 'table-body-tr-arabic'}`}
                                                >
                                                    <td>
                                                        <div>
                                                            <input className="form-check-input m-2" type="checkbox" value=""
                                                                id={`pending_${item?.cardTopupRequestId}`}
                                                                checked={selectedItems.some(
                                                                    (res) =>
                                                                        (res.cardTopupRequestId === item?.cardTopupRequestId)
                                                                )}
                                                                onChange={() => handleCheckboxChange(item.cardTopupRequestId)}
                                                            /></div>
                                                    </td>
                                                    <td style={{ minWidth: '200px' }}>{item?.firstName} {item?.lastName}</td>
                                                    <td
                                                        onMouseOver={() => handleMouseEnter(index, 'pending')}
                                                        onMouseLeave={() => handleMouseLeave('pending')}
                                                    >
                                                        <OverlayTrigger
                                                            trigger={['hover', 'focus']}
                                                            placement="top"
                                                            show={hoveredRowIndexPending === index}
                                                            delay={{ show: 100, hide: 0 }}
                                                            overlay={item?.description?.length > 50 ?
                                                                <Tooltip
                                                                    className="custom-tooltip"
                                                                    onMouseEnter={() => handleMouseEnter(index, 'pending')}
                                                                    onMouseLeave={() => handleMouseLeave('pending')}
                                                                >
                                                                    {item?.description}
                                                                </Tooltip> : <></>}
                                                        >
                                                            <div>
                                                                {truncateDescription(item?.description || "")}
                                                            </div>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td style={{ minWidth: '200px' }}>{formatTime(item?.createdAt)}</td>
                                                    <td style={{ minWidth: '200px' }}>{formatQAR(item?.amount || 0) + ' '}{t('teams.QAR')}</td>
                                                    <td onClick={() => handleShowThree(item)}>
                                                        <a id={`view_request_${item?.cardTopupRequestId}`}><span style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer', color: "#00D094" }}>{t('view')}</span></a>
                                                    </td>
                                                </tr>;
                                            }
                                            )
                                        }
                                    </tbody>
                                </table>
                                {!(fetchTopUp?.rows && fetchTopUp?.rows?.length > 0) && (
                                    <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                                        <img src={notFound} width={200} height={200} />
                                    </div>)}
                            </div>
                            <div className='d-flex align-items-center justify-content-between'
                                style={{ width: "100%", borderTop: '0px !imporant' }}>
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination mt-2 pt-1 mb-3 pe-0">
                                        <li className="page-item">
                                            <button className="btn page-link" disabled={pageNo === 1} onClick={() => onPreviousClick()} id='prev_pending_topup'>Previous</button>
                                        </li>
                                        <li className="page-item">
                                            <button className="btn page-link mx-3" disabled={pageNo == fetchTopUp?.totalPages || fetchTopUp?.totalPages == 0} onClick={() => onNextClick()} id='next_pending_topup'>Next</button>
                                        </li>
                                    </ul>
                                </nav>
                                <div className='page-count d-flex align-items-center green-primary'>
                                    <h4>Page {fetchTopUp?.currentPage} of {fetchTopUp?.totalPages || 1}</h4>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey={2} title={`${t('expenses.approved')} (${approveCount})`} id='approved'>
                        {filterFormComponent}
                        <div className='white_container mt-4 p-3 py-0'>
                            <div className='d-flex justify-content-between'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value=""
                                        id="approvedCheckAll"
                                        checked={checkedAllPages}
                                        onChange={handleCheckAllPagesboxChange}
                                    />
                                    <label className="form-check-label" htmlFor="approvedCheckAll">
                                        {t('export.select_all')}
                                    </label>
                                </div>
                                <button onClick={() => exportRecords('csv', selectedItems)} style={{ borderRadius: 20 }} className='btn btn-primary btn-sm export-btn-style' disabled={selectedItems.length < 1} id='export_approved_topup'>{t('export.export')}</button>
                            </div>
                            <div className='table-responsive' style={{ border: 0 }}>
                                <table id="approved_topup_requests" style={{ borderCollapse: 'separate', borderSpacing: '0 16px' }} >
                                    <thead>
                                        <tr style={{ borderTopWidth: 0 }} className='table-head'>
                                            <th scope="col">{t('company.select')}</th>
                                            <th scope="col">Employee Name</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Approved By</th>
                                            <th scope="col">Approved Date</th>
                                            <th scope="col">Amount</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            fetchTopUp?.rows?.length > 0 &&
                                            fetchTopUp?.rows?.map((item, index) => {
                                                return <tr
                                                    key={index}
                                                    className={`${currentLanguageCode === 'en' ? 'table-body-tr' : 'table-body-tr-arabic'}`}
                                                >
                                                    <td>
                                                        <div>
                                                            <input className="form-check-input m-2" type="checkbox" value=""
                                                                id={`approved_${item?.cardTopupRequestId}`}
                                                                checked={selectedItems.some(
                                                                    (res) =>
                                                                        (res.cardTopupRequestId === item?.cardTopupRequestId)
                                                                )}
                                                                onChange={() => handleCheckboxChange(item.cardTopupRequestId)}
                                                            /></div>
                                                    </td>
                                                    <td style={{ minWidth: '200px' }}>{item?.firstName} {item?.lastName}</td>
                                                    <td style={{ minWidth: '200px' }}
                                                        onMouseOver={() => handleMouseEnter(index, 'approved')}
                                                        onMouseLeave={() => handleMouseLeave('approved')}
                                                    >
                                                        <OverlayTrigger
                                                            trigger={['hover', 'focus']}
                                                            placement="top"
                                                            show={true}
                                                            delay={{ show: 300, hide: 300 }}
                                                            overlay={item?.description?.length > 50 ?
                                                                <Tooltip
                                                                    className="custom-tooltip"
                                                                    onMouseEnter={() => handleMouseEnter(index, 'approved')}
                                                                    onMouseLeave={() => handleMouseLeave('approved')}
                                                                    style={{ display: hoveredRowIndexApproved === index ? 'flex' : 'none' }}
                                                                >
                                                                    {item?.description}
                                                                </Tooltip> : <></>}
                                                        >
                                                            <div>
                                                                {truncateDescription(item?.description || "")}
                                                            </div>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td style={{ minWidth: '200px' }}>{item?.approvalFirstName + " " + item?.approvalLastName}</td>
                                                    <td style={{ minWidth: '200px' }}>{formatTime(item?.updatedAt)}</td>
                                                    <td style={{ minWidth: '200px' }}>{formatQAR(item?.amount || 0) + ' '}{t('teams.QAR')}</td>
                                                    <td onClick={() => handleShowThree(item)}>
                                                        <a id={`view_approved_${item?.cardTopupRequestId}`}><span style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer', color: "#00D094" }}>{t('view')}</span></a>
                                                    </td>
                                                </tr>;
                                            }
                                            )
                                        }
                                    </tbody>
                                </table>
                                {!(fetchTopUp?.rows && fetchTopUp?.rows?.length > 0) && (
                                    <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                                        <img src={notFound} width={200} height={200} />
                                    </div>)}
                            </div>
                            <div className='d-flex align-items-center justify-content-between'
                                style={{ width: "100%", borderTop: '0px !imporant' }}>
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination my-3 pe-0">
                                        <li className="page-item">
                                            <button className="btn page-link" disabled={pageNo === 1} onClick={() => onPreviousClick()} id='prev_approved_topup'>Previous</button>
                                        </li>
                                        <li className="page-item mx-3">
                                            <button className="btn page-link" disabled={pageNo == fetchTopUp?.totalPages || fetchTopUp?.totalPages == 0} onClick={() => onNextClick()} id='next_approved_topup'>Next</button>
                                        </li>
                                    </ul>
                                </nav>
                                <div className='page-count d-flex align-items-center green-primary'>
                                    <h4 className='me-3'>Page {fetchTopUp?.currentPage} of {fetchTopUp?.totalPages}</h4>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey={3} title={`${t('expenses.rejected')} (${rejectCount})`} id='rejected'>
                        {filterFormComponent}
                        <div className='white_container mt-4 p-3 py-0'>
                            <div className='d-flex justify-content-between'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value=""
                                        id="rejectedCheckAll"
                                        checked={checkedAllPages}
                                        onChange={handleCheckAllPagesboxChange}
                                    />
                                    <label className="form-check-label" htmlFor="rejectedCheckAll">
                                        {t('export.select_all')}
                                    </label>
                                </div>
                                <button onClick={() => exportRecords('csv', selectedItems)} style={{ borderRadius: 20 }} className='btn btn-primary btn-sm export-btn-style' disabled={selectedItems.length < 1} id='export_rejected_topup'>{t('export.export')}</button>
                            </div>
                            <div className='table-responsive' style={{ border: 0 }}>
                                <table id="rejected_topup_requests" style={{ borderCollapse: 'separate', borderSpacing: '0 16px' }} >
                                    <thead>
                                        <tr style={{ borderTopWidth: 0 }} className='table-head'>
                                            <th scope="col">{t('company.select')}</th>
                                            <th scope="col">Employee Name</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Rejected By</th>
                                            <th scope="col">Rejected Date</th>
                                            <th scope="col">Amount</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            fetchTopUp?.rows?.length > 0 &&
                                            fetchTopUp?.rows?.map((item, index) => {
                                                return <tr
                                                    key={index}
                                                    className={`${currentLanguageCode === 'en' ? 'table-body-tr' : 'table-body-tr-arabic'}`}
                                                >
                                                    <td>
                                                        <div>
                                                            <input className="form-check-input m-2" type="checkbox" value=""
                                                                id={`rejected_${item?.cardTopupRequestId}`}
                                                                checked={selectedItems.some(
                                                                    (res) =>
                                                                        (res.cardTopupRequestId === item?.cardTopupRequestId)
                                                                )}
                                                                onChange={() => handleCheckboxChange(item.cardTopupRequestId)}
                                                            /></div>
                                                    </td>
                                                    <td style={{ minWidth: '200px' }}>{item?.firstName} {item?.lastName}</td>
                                                    <td style={{ maxWidth: '300px' }}
                                                        onMouseOver={() => handleMouseEnter(index, 'rejected')}
                                                        onMouseLeave={() => handleMouseLeave('rejected')}
                                                    >
                                                        <OverlayTrigger
                                                            trigger={['hover', 'focus']}
                                                            placement="top"
                                                            show={true}
                                                            delay={{ show: 300, hide: 300 }}
                                                            overlay={item?.description?.length > 50 ?
                                                                <Tooltip
                                                                    className="custom-tooltip"
                                                                    onMouseEnter={() => handleMouseEnter(index, 'rejected')}
                                                                    onMouseLeave={() => handleMouseLeave('rejected')}
                                                                    style={{ display: hoveredRowIndexRejected === index ? 'flex' : 'none' }}
                                                                >
                                                                    {item?.description}
                                                                </Tooltip> : <></>}
                                                        >
                                                            <div style={{ maxWidth: '300px' }} className='third-modal-text-container'>
                                                                {truncateDescription(item?.description || "")}
                                                            </div>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td style={{ minWidth: '200px' }}>{item?.approvalFirstName + " " + item?.approvalLastName}</td>
                                                    <td style={{ minWidth: '200px' }}>{formatTime(item?.updatedAt)}</td>
                                                    <td style={{ minWidth: '200px' }}>{formatQAR(item?.amount || 0) + ' '}{t('teams.QAR')}</td>
                                                    <td onClick={() => handleShowThree(item)}>
                                                        <a id={`view_rejected_${item?.cardTopupRequestId}`}><span style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer', color: "#00D094" }}>{t('view')}</span></a>
                                                    </td>
                                                </tr>;
                                            }
                                            )
                                        }
                                    </tbody>
                                </table>
                                {!(fetchTopUp?.rows && fetchTopUp?.rows?.length > 0) && (
                                    <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                                        <img src={notFound} width={200} height={200} />
                                    </div>)}
                            </div>
                            <div className='d-flex align-items-center justify-content-between'
                                style={{ width: "100%", borderTop: '0px !imporant' }}>
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination pt-1 mt-3 pe-0">
                                        <li className="page-item">
                                            <button className="btn page-link" disabled={pageNo === 1} onClick={() => onPreviousClick()} id='prev_rejected_topup'>Previous</button>
                                        </li>
                                        <li className="page-item mx-3">
                                            <button className="btn page-link" disabled={pageNo == fetchTopUp?.totalPages || fetchTopUp?.totalPages == 0} onClick={() => onNextClick()} id='next_rejected_topup'>Next</button>
                                        </li>
                                    </ul>
                                </nav>
                                <div className='page-count d-flex align-items-center green-primary' >
                                    <h4>Page {fetchTopUp?.currentPage} of {fetchTopUp?.totalPages}</h4>
                                </div>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
            <Modal className='UserModal' show={show} onHide={handleClose} style={{ paddingRight: 0, height: '565px !important' }}>
                <Modal.Body>
                    <div className='d-flex justify-content-between align-items-start green-header p-3 py-2'>
                        <div className='col-9'>
                            <p className='text-black modal-title ' style={{ fontSize: 20 }}>
                                Top Up Request
                            </p>
                        </div>
                        <div onClick={handleClose} className='clo-2' style={{ cursor: 'pointer' }}>
                            <img src={ImagesCross} style={{ width: 20, objectFit: 'contain' }} />
                        </div>
                    </div>
                    {
                        <div>
                            <div className='mt-3'>
                                <p className='text-black mb-1' style={{ fontSize: 16, fontWeight: '600' }}>Name:</p>
                                <p className='text-black text-capitalize' style={{ fontSize: 14 }}>{employeeData?.firstName} {employeeData?.lastName}</p>
                            </div>
                            <div className='mt-3'>
                                <p className='text-black mb-1' style={{ fontSize: 16, fontWeight: '600' }}>Card:</p>
                                <p className='text-black' style={{ fontSize: 14 }}>{employeeData?.cardToken}</p>
                            </div>
                            <div className='mt-3'>
                                <p className='text-black mb-1' style={{ fontSize: 16, fontWeight: '600' }}>Description:</p>
                                <p className='text-black' style={{ fontSize: 14 }}>{employeeData?.description}</p>
                            </div>
                            <div className='mt-3'>
                                <p className='text-black mb-1' style={{ fontSize: 16, fontWeight: '600' }}>Date:</p>
                                <p className='text-black' style={{ fontSize: 14 }}>{formatTime(employeeData?.createdAt)}</p>
                            </div>
                            <div className='mt-3'>
                                <p className='text-black mb-1' style={{ fontSize: 16, fontWeight: '600' }}>Amount:</p>
                                <p className='text-black' style={{ fontSize: 14 }}>{formatQAR(employeeData?.amount || 0) + ' '}{t('teams.QAR')}</p>
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer className='px-0 pt-3'>
                    <button onClick={handleClose} className='btn-secondary-small' id='cancel_approve_topup'>{t('cancel')}</button>
                    <button onClick={onClickVerifyModal} className='btn-primary-small' type='submit' id='submit_approve_topup'>{t('approve')}</button>
                </Modal.Footer>
            </Modal>
            <Modal className='UserModal' show={showTwo} onHide={handleCloseTwo} style={{ paddingRight: 0 }}>
                <Modal.Body>
                    <div className='d-flex justify-content-between align-items-start green-header p-3 py-2'>
                        <div className='col-9'>
                            <p className='text-black modal-title' style={{ fontSize: 20 }}>
                                Top Up Request
                            </p>
                        </div>
                        <div onClick={handleCloseTwo} className='clo-2' style={{ cursor: 'pointer' }}>
                            <img src={ImagesCross} style={{ width: 20, objectFit: 'contain' }} />
                        </div>
                    </div>
                    {
                        <div>
                            <div className='mt-3'>
                                <p className='text-black mb-1' style={{ fontSize: 16, fontWeight: '600' }}>Name:</p>
                                <p className='text-black text-capitalize' style={{ fontSize: 14 }}>{employeeData?.firstName} {employeeData?.lastName}</p>
                            </div>
                            <div className='mt-3'>
                                <p className='text-black mb-1' style={{ fontSize: 16, fontWeight: '600' }}>Card:</p>
                                <p className='text-black' style={{ fontSize: 14 }}>{employeeData?.cardToken}</p>
                            </div>
                            <div className='mt-3'>
                                <p className='text-black mb-1' style={{ fontSize: 16, fontWeight: '600' }}>Description:</p>
                                <p className='text-black' style={{ fontSize: 14 }}>{employeeData?.description}</p>
                            </div>
                            <div className='mt-3'>
                                <p className='text-black mb-1' style={{ fontSize: 16, fontWeight: '600' }}>Date:</p>
                                <p className='text-black' style={{ fontSize: 14 }}>{formatTime(employeeData?.createdAt)}</p>
                            </div>
                            <div className='mt-3'>
                                <p className='text-black mb-1' style={{ fontSize: 16, fontWeight: '600' }}>Amount:</p>
                                <p className='text-black' style={{ fontSize: 14 }}>{formatQAR(employeeData?.amount || 0) + ' '}{t('teams.QAR')}</p>
                            </div>
                        </div>
                    }
                    <div className="form-outline my-3">
                        <label className="form-label" htmlFor="cardsComments">{t('cards.comments')}</label>
                        <textarea id="cardsComments" rows="5" className="form-control"
                            placeholder={`${t('cards.enter_a_description')}`} autoComplete='false' onChange={(e) => setComments(e.target.value)} />
                    </div>
                </Modal.Body>
                <div className="form-check mb-3">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={handleChecked}
                        id='reject_confirm'
                    />

                    <label className="form-check-label" htmlFor='reject_confirm' style={{ fontWeight: 600 }}>I agree to Reject this user Top Up</label>
                </div>
                <Modal.Footer className='px-0 pt-3'>
                    <button onClick={handleCloseTwo} className='btn-secondary-small' id='cancel_reject_topup'>{t('cancel')}</button>
                    <button onClick={() => approveRejectRequest()} disabled={!checked} className={checked ? 'btn-danger' : 'disabled-filled-button'} type='submit' style={{ borderRadius: 20 }} id='submit_reject_topup'>{t('reject')}</button>
                </Modal.Footer>
            </Modal>
            <Modal
                aria-labelledby="contained-modal-title-center"
                centered
                show={showVerifyModal}
                onHide={handleCloseVerifyModal}
            >

                <form className='form'
                    style={{ padding: 24 }}
                    onSubmit={handleSubmit(approveRejectRequest)}
                >
                    <div className='d-flex align-items-start justify-content-between'>
                        <img src={Logo2} className="logoso mt-1 mb-4" alt="Sample image" />
                        <div onClick={handleCloseVerifyModal}>
                            <img src={ImagesCross} className='small-addjust' style={{ width: 20, objectFit: 'contain', marginLeft: 15 }} />
                        </div>
                    </div>
                    <h1 className='text-blue-login mb-2'>
                        {t('login.verify_your_mobile_number')}
                    </h1>
                    <div className="divider d-flex align-items-center my-2">
                        <p className="mb-0 text-subtitle2" style={{ color: '#8B9299' }} >
                            {t('login.verify_your_mobile_number_desc')}
                        </p>
                    </div>

                    <div className="otp form-outline d-flex justify-content-center mt-4 mb-3">
                        <Controller
                            name="otp"
                            control={controlVerifyModal}
                            defaultValue={0}
                            render={({ field: { onChange, value }, }) => (
                                <OtpInput
                                    inputStyle="inputStyle"
                                    value={value}
                                    onChange={onChange}
                                    numInputs={6}
                                    shouldAutoFocus={true}
                                    renderInput={(props) => <input {...props} id='input_otp' onInput={(e) => {
                                        const value = e.target.value;
                                        if (!/^\d*$/.test(value)) {
                                            e.target.value = value.slice(0, -1);
                                        }
                                    }} />}
                                />
                            )}
                        />
                    </div>
                    <p className="text-danger">{errors?.otp?.message}</p>
                    <div className="d-flex justify-content-end">
                        {timer === 0 ? (
                            <a href="javascript:void(0)" id='resend_otp'>Resend OTP</a>
                        ) : (
                            <p>Resend OTP in <span className='text-blue'>{timer} seconds</span> </p>
                        )}
                    </div>
                    <div className="text-center text-lg-start mt-4 w-100">
                        <button type="submit" className="btn btn-primary btn-lg btn-block w-100" style={{ position: 'relative', zIndex: '1' }} id='continue'>{topupType === 'approve' ? 'Continue to Approve' : 'Continue to Reject'} </button>
                    </div>

                </form>

            </Modal>
            <Modal className='UserModal third-modal' show={showThree} onHide={handleCloseModalThree} style={{ paddingRight: 0 }}>
                <Modal.Body>
                    <div className='d-flex justify-content-between align-items-start green-header'>
                        <div className='col-9'>
                            <p className='text-green addjust modal-title' style={{ fontSize: 20, marginRight: 15 }}>
                                {t('expenses.details')}
                            </p>
                        </div>
                        <div onClick={handleCloseModalThree} className='clo-2' style={{ cursor: 'pointer' }} id='close_modal'>
                            <img src={CrossImage} className='small-addjust' style={{ width: 20, objectFit: 'contain', marginLeft: 15 }} />
                        </div>
                    </div>
                    <p className='text-grey' style={{ fontWeight: 400, fontSize: 16, paddingLeft: 13 }}>{t('accounts.Review_the_comprehensive_details_of_the_transaction')}</p>
                    <div className='green-box p-3 mt-4'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div className='third-modal-green-text'>{formatQAR(itemDetails?.amount || 0)}<span style={{ color: '#3EC279', fontSize: 14, fontWeight: '500' }}> QAR</span></div>
                            <div className='third-modal-black-text'>{itemDetails?.firstName + " " + itemDetails?.lastName}</div>
                        </div>
                        <div className='d-flex align-items-center justify-content-between mt-3'>
                            <div className='black-text'>{'Date'}</div>
                            <div className='third-modal-grey-text'>{formatDate(itemDetails?.createdAt)}</div>
                            <div className='third-modal-grey-text' style={{ fontSize: 16 }}>{formatTimeHours(itemDetails?.createdAt)}</div>
                        </div>
                        {activeKey !== 1 && <div className='d-flex align-items-center justify-content-between mt-3'>
                            <div className='black-text'>{activeKey === 2 ? 'Approvement Date' : 'Rejection Date'}</div>
                            <div className='third-modal-grey-text'>{formatDate(itemDetails?.updatedAt)}</div>
                            <div className='third-modal-grey-text' style={{ fontSize: 16 }}>{formatTimeHours(itemDetails?.updatedAt)}</div>
                        </div>}
                        {activeKey !== 1 && <div className='d-flex align-items-center justify-content-between mt-3'>
                            <div className='black-text'>{activeKey === 2 ? 'Approved By' : 'Rejected By'}</div>
                            <div className='third-modal-grey-text'>{itemDetails?.approvalFirstName + " " + itemDetails?.approvalLastName}</div>
                        </div>}
                    </div>
                    <div className='third-modal-border-grey mt-3 p-3' style={{ borderRadius: 15 }}>
                        <div className='mb-3'>
                            <div className='black-text'>Card Number</div>
                            <p className='text-grey' style={{ fontWeight: 400, fontSize: 16 }}>{itemDetails?.cardToken}</p>
                        </div>
                        <div className='mb-3 third-modal-text-container'>
                            <div className='black-text'>Description</div>
                            <p className={'text-grey third-modal-description-text'} style={{ fontWeight: 400, fontSize: 16 }}>{getDescriptionText(itemDetails?.description, isDescription1Expanded)}</p>
                            {itemDetails?.description?.length > 120 && (
                                <span onClick={toggleView} style={{ color: 'blue', cursor: 'pointer', fontSize: 16 }}>
                                    {getToggleText(isDescription1Expanded)}
                                </span>
                            )}
                        </div>
                    </div>
                    {activeKey === 1 && <div className='mb-3'>
                        <a id='approve' onClick={() => { onClickVerifyModal(); setEmployeeData(itemDetails); setTopupType('approve'); }}><span className='approve-button' style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer' }}>{t('Approve')}</span></a>
                        <a id='reject' onClick={() => { handleShowTwo(); setEmployeeData(itemDetails); setTopupType('reject'); }}><span className='reject-button' style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer' }}>{t('Reject')}</span></a>
                    </div>}
                </Modal.Body>
            </Modal>
        </div>

    );
}

export default TopUpRequest;