import React, { useEffect, useState } from 'react';
import './CustomDropdown.scss';
import DroupdownArrow from '../../assets/images/dropdownDownArrow.png';

const CustomDropdown = ({ options, onChange, selectedOption, placeholder, backgroundColor }) => {
    const [isActive, setIsActive] = useState(false);
    const [selectedValue, setSelectedValue] = useState(selectedOption?.value || -1);

    useEffect(() => {
        if (selectedOption) {
            setSelectedValue(selectedOption.value);
        }
    }, [selectedOption]);

    const selectedLabel = selectedValue === -1
        ? selectedOption
        : options?.find(option => option?.value === selectedValue)?.label || selectedOption;

    const handleCloseMenu = (option) => {
        setSelectedValue(option.value);
        setIsActive(false);
        onChange(option); 
    };

    return (
        <div className='custom-dropdown'>
            <div className="dropdown">
                <div
                    onClick={() => setIsActive(!isActive)}
                    className={`dropdown-btn ${isActive ? 'active' : ''} ${backgroundColor ? 'green-border' : 'normal-border'}`}
                    style={{ backgroundColor: backgroundColor ? backgroundColor : '#FFFFFF', fontSize: backgroundColor ?'20px' :'16px' }}
                >
                    {selectedLabel || placeholder}
                    <img
                        src={DroupdownArrow}
                        width={'12.81px'}
                        height={'6.41px'}
                        style={{ objectFit: 'contain', transform: isActive ? 'rotate(180deg)' : 'rotate(0)' }}
                    />
                </div>
                <div
                    className="dropdown-content"
                    style={{ display: isActive ? "block" : "none" }}
                >
                    {options?.map((option, index) => (
                        <div
                            key={index}
                            className="item text-capitalize"
                            onClick={() => handleCloseMenu(option)} 
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CustomDropdown;
