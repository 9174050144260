import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utils/apiUrls";
import api from "../../utils/api";

const initialState = {
    isLoading: false,
    error: null,
    forgotData:{}
};

export const verifyAndSendOtp = createAsyncThunk("employee/verifyCredentialsAndSendOtp", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.verifyCredentialsAndSendOtp,
            data: requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const verifyLoginOtp = createAsyncThunk("register/verifyLoginOtp", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.verifyLoginOtp,
            data: requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const sendForgotPasswordMail = createAsyncThunk("sendForgotPasswordMail", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.sendForgotPasswordMail,
            data: requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const resetPassword = createAsyncThunk("resetPassword", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.resetPassword,
            data: requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const fetchInvitationCodeDetails = createAsyncThunk("fetchInvitationCodeDetails", async (code) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: `${API_ENDPOINTS.getInvitationDetails}?inviteCode=${code}`,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const joinCompany = createAsyncThunk("joinCompany", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.joinCompany,
            data: requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {

        builder
            
            .addCase(verifyAndSendOtp.fulfilled, (state) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(verifyLoginOtp.fulfilled, (state) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(sendForgotPasswordMail.fulfilled, (state) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(resetPassword.fulfilled, (state) => {
                state.isLoading = false;
                state.error = null;
                state.forgotData= {};
            })
            .addCase(fetchInvitationCodeDetails.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(joinCompany.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addMatcher(isAnyOf( verifyAndSendOtp.pending, verifyLoginOtp.pending, sendForgotPasswordMail.pending,resetPassword.pending, fetchInvitationCodeDetails.pending, joinCompany.pending), (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(isAnyOf( verifyAndSendOtp.rejected, verifyLoginOtp.rejected,sendForgotPasswordMail.rejected, resetPassword.rejected, fetchInvitationCodeDetails.rejected, joinCompany.rejected), (state, { error }) => {
                state.isLoading = false;
                state.error = error.message ? error.message : "Request Failed Please Try Again ";
                
            });
    }

});

const loginSliceReducer = loginSlice.reducer; 

export default loginSliceReducer;