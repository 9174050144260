import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utils/apiUrls";
import api from "../../utils/api";

const initialState = {
    registerData: {},
    companyData: {},
    isLoading: false,
    error: null
};

export const generateOtp = createAsyncThunk("register/generateOtp", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.generateOtp,
            data: requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const registerCompany = createAsyncThunk("company/registerCompany", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.registerCompany,
            data: requestData
        };
        const response = await api(data);
        return { responseData: response.data, requestData };
    } catch (error) {
        throw error?.response?.data;
    }
});

export const verifyOtp = createAsyncThunk("register/verifyOtp", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.verifyOtp,
            data: requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getCompanyList = createAsyncThunk("register/getCompanyList", async (number) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getCompanyList + "?registrationNo=" + number,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const registration = createAsyncThunk("register/registration", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.registration,
            data: requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

const registerSlice = createSlice({
    name: "register",
    initialState,
    reducers: {
        addData: (state, action) => {
            return {
                ...state,
                registerData: action.payload.fromRegister ? action.payload.data : { ...state.registerData, ...action.payload.data }
            };
        }
    },
    extraReducers: (builder) => {
        const handleLoading = (state, action) => {
            state.isLoading = action.meta.requestStatus === 'pending';
        };

        builder
            .addCase(registerCompany.fulfilled, (state, action) => {
                state.companyData = action.payload.requestData;
            })
            .addMatcher(
                (action) =>
                    action.type === generateOtp.pending.type ||
                    action.type === generateOtp.fulfilled.type ||
                    action.type === generateOtp.rejected.type ||
                    action.type === verifyOtp.pending.type ||
                    action.type === verifyOtp.fulfilled.type ||
                    action.type === verifyOtp.rejected.type ||
                    action.type === getCompanyList.pending.type ||
                    action.type === getCompanyList.fulfilled.type ||
                    action.type === getCompanyList.rejected.type ||
                    action.type === registerCompany.pending.type ||
                    action.type === registerCompany.fulfilled.type ||
                    action.type === registerCompany.rejected.type ||
                    action.type === registration.pending.type ||
                    action.type === registration.fulfilled.type ||
                    action.type === registration.rejected.type,
                handleLoading
            );
    }

});

export const { addData } = registerSlice.actions;
export default registerSlice.reducer;