import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utils/apiUrls";
import api from "../../utils/api";

const initialState = {
    unExportedData: {},
    exportedData: {},
    exportQueueData: {},
    isLoading: false,
    error: null,
    exportResponseData: {},
    exportResponse:{}
};

export const getUnexportedRecords = createAsyncThunk("cards/getUnexportedRecords", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getExportRecords + '?type=unexported' + queryParams
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getExportedRecords = createAsyncThunk("cards/getExportedRecords", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getExportRecords + '?type=exported' + queryParams
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getQueueRecords = createAsyncThunk("export/getQueueRecords", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getQueueRecords + queryParams
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const mooveRecordToQueue = createAsyncThunk("export/moveRecordToQueue", async (postData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.moveRecordToQueue,
            data: postData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const exportTransactionRecords = createAsyncThunk("export/exportTransactionRecords", async (postData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.exportToFile,
            data: postData
        };
        const response = await api(data);
        return response;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const exportTransaction = createAsyncThunk("export/exportTransaction", async (postData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.exportToOddo,
            data: postData
        };
        const response = await api(data);
        return response;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const moveBackToUnExport = createAsyncThunk("export/moveBackToUnExport", async (postData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.moveBackToUnExport,
            data: postData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const exportAllPagesRecords = createAsyncThunk("export/exportAllPagesRecords", async (postData) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.exportAllPagesRecords+postData,
        };
        const response = await api(data);
        return response;

    } catch (error) {
        throw error?.response?.data;
    }
});

const exportSlice = createSlice({
    name: "exportSlice",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {

        builder
            .addCase(getUnexportedRecords.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.unExportedData = payload.data;
            })
            .addCase(getQueueRecords.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.exportQueueData = payload.data;
            })
            .addCase(getExportedRecords.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.exportedData = payload.data;
            })
            .addCase(mooveRecordToQueue.fulfilled, (state) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(moveBackToUnExport.fulfilled, (state) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(exportTransactionRecords.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.exportResponseData = payload.data;
            })
            .addCase(exportTransaction.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.exportResponse = payload.data;
            })
            .addCase(exportAllPagesRecords.fulfilled, (state, /*{ payload }*/) => {
                state.isLoading = false;
                state.error = null;
            })
            .addMatcher(isAnyOf(getUnexportedRecords.pending, getQueueRecords.pending, getExportedRecords.pending,mooveRecordToQueue.pending,exportTransactionRecords.pending, exportTransaction.pending, moveBackToUnExport.pending, exportAllPagesRecords.pending), (state) => {
                state.isLoading = true;
            })
            .addMatcher(isAnyOf(getUnexportedRecords.rejected, getQueueRecords.rejected, getExportedRecords.rejected,mooveRecordToQueue.rejected, exportTransactionRecords.rejected,exportTransaction.rejected, moveBackToUnExport.rejected, exportAllPagesRecords.rejected), (state, { error }) => {
                state.isLoading = false;
                state.error = error.message ? error.message : "Request Failed Please Try Again ";
                // if (error.message === "Request failed with status code 415") {
                //     return (localStorage.removeItem("token"), window.location.reload());
                // }
            });
    }
});

// const { updateCardDetails } = cardList.actions;
const exportReducer = exportSlice.reducer;

export { exportReducer };
export default exportReducer;