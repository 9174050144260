import "./Export.scss";

import React, { useEffect, useState } from "react";
import {
  getQueueRecords,
  getUnexportedRecords,
  mooveRecordToQueue,
} from "../../pages/Export/exportSlice";

import ExpensesComponent from "../Expenses/ExpensesComponent";
import Loader from "../../components/Loader/Loader";
import { formatQAR } from "../../utils/XpendlessUtils";
import moment from "moment";
import notFound from "../../assets/images/not_found.png";
import { toast } from "react-toastify";
import useDidUpdateEffect from "../../utils/useDidUpdateHook";
import { useTranslation } from "react-i18next";

function UnExported({
  unExportedData,
  searchListByEmployee,
  isLoading,
  dispatch,
  currencies,
  onPreviousClick,
  onNextClick,
  pageNo,
  showExpenseDetailModal,
  type,
  type1,
  prevPath,
  connectedIntegration
}) {
  const { t } = useTranslation();

  const [selectedItems, setSelectedItems] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [search, setSearch] = useState("");

  // Update "Select All" checkbox state when all individual checkboxes are selected
  useEffect(() => {
    const allRecords =
      unExportedData?.rows?.flatMap((element) =>
        element.records.map((record) => ({
          id: record?.cardTransactionId,
          type: record?.type,
        }))
      ) || [];
    if (allRecords.length > 0) {
      const isAllSelected = selectedItems.length === allRecords.length;
      setCheckedAll(isAllSelected);
    }
  }, [selectedItems, unExportedData]);

  useEffect(() => {
    setSearch("");
    setSelectedItems([]);
    setCheckedAll(false);
  }, [type]);

  useDidUpdateEffect(() => {
    const debounceSearch = setTimeout(() => {
      searchListByEmployee(search);
    }, 500);
    return () => {
      clearTimeout(debounceSearch);
    };
  }, [search]);

  const handleCheckboxChange = (id, type) => {
    const selected = [...selectedItems];
    const index = selected.findIndex(
      (item) => item.transactionId == id && item.transactionType == type
    );

    if (index === -1) {
      selected.push({ transactionId: id, transactionType: type });
    } else {
      selected.splice(index, 1);
    }

    setSelectedItems(selected);
  };

  const handleCheckAllboxChange = () => {
    if (checkedAll) {
      setSelectedItems([]);
      setCheckedAll(false);
    } else {
      let allRecords = [];
      unExportedData?.rows?.map((element) =>
        element?.records.map((record) =>
          allRecords.push({
            transactionId: record?.cardTransactionId,
            transactionType: record?.type,
          })
        )
      );
      setSelectedItems(allRecords);
      setCheckedAll(true);
    }
  };
  const moveRecordToQueueTable = () => {
    if (selectedItems.length < 1) {
      toast.info("Please select the record to move into queue");
    }
    const postData = { records: selectedItems };
    dispatch(mooveRecordToQueue(postData))
      .unwrap()
      .then(() => {
        toast.success("Record moved to queue successfully");
        setSelectedItems([]);
        dispatch(
          getUnexportedRecords(
            `&page=${unExportedData?.currentPage}&exportType=${type}`
          )
        );
        dispatch(getQueueRecords(`?exportType=${type}`));
      })
      .catch((error) => {
        toast.error(error.message || "Request Failed Please Try Again ");
      });
  };

  return (
    <div>
      {isLoading && <Loader />}
      <p className="text-grey pt-2" style={{ fontSize: 14 }}>
        <span className="text-black">
          {t("export.expenses", {
            number:
              Object.keys(unExportedData).length > 0
                ? unExportedData.totalRecords
                : 0,
          })}
        </span>
        {t("export.totaling")}
        <span className="text-black">
          {t("export.GBP", {
            GBP:
              Object.keys(unExportedData).length > 0
                ? formatQAR(unExportedData.sum || 0)
                : 0,
          })}
        </span>
        {t("export.with")}
        <span className="text-blue">
          {t("export.missing_receipt", {
            number:
              Object.keys(unExportedData).length > 0
                ? unExportedData.noReceipts
                : 0,
          })}
        </span>
        ,
        <span className="text-blue">
          {t("export.missing_category", {
            number:
              Object.keys(unExportedData).length > 0
                ? unExportedData.noCategory
                : 0,
          })}
        </span>
        {t("export.and")}
        <span className="text-blue">
          {t("export.awaiting_review_or_details", {
            number:
              Object.keys(unExportedData).length > 0
                ? unExportedData.noReview
                : 0,
          })}
        </span>
      </p>

      <div className="col-lg-5 col-md-12 mt-4">
        <div className="form-group has-search">
          <span className="fa fa-search form-control-feedback"></span>
          <input
            id="searchEmployee"
            type="text"
            value={search}
            className="form-control"
            placeholder="Search employee"
            onChange={(event) => setSearch(event.target.value)}
          />
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center mt-2">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="checkAllUnExported"
            checked={checkedAll}
            onChange={handleCheckAllboxChange}
          />
          <label className="form-check-label" htmlFor="checkAllUnExported">
            {t("export.select_all")}
          </label>
        </div>
        <button
          type="button"
          onClick={moveRecordToQueueTable}
          className="btn btn-primary btn-sm export-btn-style"
          disabled={selectedItems.length < 1}
          style={{ borderRadius: 20 }}
          id='move_to_queue'
        >
          {t("export.move_to_queue")}
        </button>
      </div>

      {unExportedData?.rows && unExportedData?.rows?.length > 0 ? (
        unExportedData["rows"]?.map((item, i) => {
          return (
            <div key={i} className="export-border mt-3">
              <h2 className="text-grey">
                {moment(item?.date).format("DD MMM YYYY")}
              </h2>
              <ExpensesComponent
                index={`unExported_${i}`}
                showCheckBox={true}
                data={item?.records}
                currencies={currencies}
                showExpenseDetailModal={showExpenseDetailModal}
                handleCheckboxChange={handleCheckboxChange}
                selectedItems={selectedItems}
                type={type}
                type1={type1}
                prevPath={prevPath}
                allowCheckBoxTooltip={true}
                isUnexported={Boolean(connectedIntegration?.name)}
              />
            </div>
          );
        })
      ) : (
        <div className="d-flex align-items-center justify-content-center p-3 px-2">
          <img src={notFound} width={200} height={200} />
        </div>
      )}
      <div
        className="d-flex align-items-center justify-content-between mt-4 px-2"
        style={{ width: "100%", borderTop: "0px !imporant" }}
      >
        <nav aria-label="Page navigation example">
          <ul className="pagination mt-2 pt-1 mb-3 pe-0">
            <li className="page-item">
              <button
                className="btn page-link"
                disabled={pageNo === 1}
                onClick={() => onPreviousClick()}
                id="previous"
              >
                Previous
              </button>
            </li>
            <div className="pe-3"></div>
            <li className="page-item">
              <button
                className="btn page-link"
                disabled={
                  pageNo == unExportedData?.totalPages ||
                  unExportedData?.totalPages == 0
                }
                onClick={() => onNextClick()}
                id="next"
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
        <div className="page-count d-flex align-items-center green-primary">
          <h4 className="me-3">
            Page {unExportedData?.currentPage} of{" "}
            {unExportedData?.totalPages || 1}
          </h4>
        </div>
      </div>
    </div>
  );
}

export default UnExported;
