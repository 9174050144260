import registerSlice from "../pages/Login/registerSlice";
import loginSliceReducer from "../pages/Login/loginSlice";
import companyVerifySlice from "../pages/CompanyVerification/companySlice";
import teamSlice from "../pages/Teams/teamSlice";
import masterAccountBalanceReducer from "../pages/Accounts/slice/masterAccountBalanceSlice";
import masterAccountTransactionReducer from "../pages/Accounts/slice/masterAccountTransactionSlice";
import cardListReducer from "../pages/Cards/slice/cardListSlice";
import taxSlice from "../pages/Settings/taxSlice";
import expenseReducer from "../pages/Expenses/expensesSlice";
import departmentSlice from "../pages/Teams/Slice/departmentSlice";
import userReducer from "../pages/Users/userSlice";
import dashboardReducer from "../pages/Dashboard/dashboardSlice";
import topUpRequestSlice from "../pages/TopUpRequest/slice/topUpSlice";
import exportReducer from "../pages/Export/exportSlice";
import reimbursementSlice from "../pages/Reimbursement/reimbursementSlice";
import auditLogSlice from "../pages/AuditLogViewer/AuditLogSlice";
import pettycashReducer from "../pages/PettyCash/pettyCashSlice";

export const reducers = {
    registerSlice: registerSlice,
    loginSliceReducer,
    masterAccountBalanceReducer,
    masterAccountTransactionReducer,
    companyVerification: companyVerifySlice,
    taxSlice,
    cardListReducer,
    expenseReducer,
    teamSlice,
    departmentSlice,
    userReducer,
    dashboardReducer,
    topUpRequestSlice,
    exportReducer,
    reimbursementSlice,
    auditLogSlice,
    pettycashReducer
};