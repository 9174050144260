import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Dashboard.scss';
import { Modal } from 'react-bootstrap';
import ImagesCross from '../../assets/images/close-square-8.png';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import List1 from '../../assets/images/list.png';
import List2 from '../../assets/images/list1.png';
import cardsIssued from '../../assets/images/cards_issued.png';
import UserProfile from '../../assets/images/profile-2user.png';
import { Dropdown } from 'react-bootstrap';

function DashboardCards(props) {
    const { cardIssueCountLoading, cardIssueCount, totalUsersCount, totalUsersCountLoading, totalTransactionCountLoading, totalTransactionCount, handleFilterClick, globalSelectedFil, setSelectedFilterCards, selectedFilterCards, selectedIndex, approvedReimbursmentTransaction, approvedReimbursmentTransactionLoading, pendingReimbursmentTransactionLoading, pendingReimbursmentTransaction, rejectedReimbursmentTransactionLoading, rejectedReimbursmentTransaction, settledReimbursmentTransactionLoading, settledReimbursmentTransaction } = props;
    const [selectedSection, setSelectedSection] = useState('');
    const [cardIsueeDate, setCardIsueeDate] = useState('');
    const [totalUserDate, setTotalUserDate] = useState('');
    const [transactionDate, setTransactionDate] = useState('');
    const [approvedUser, setApprovedUser] = useState('');
    const [pendingUser, setPendingUser] = useState('');
    const [rejectedUser, setRejectedUser] = useState('');
    const [settledUser, setsettledUser] = useState('');

    useEffect(() => {
      if(globalSelectedFil){
        setCardIsueeDate('');
        setTotalUserDate('');
        setTransactionDate('');
        setApprovedUser('');
        setPendingUser('');
        setRejectedUser('');
        setsettledUser('');
      }
    }, [globalSelectedFil]);

    const [show, setShow] = useState(false);
    const currentDate = new Date().toISOString().split('T')[0];
    const { t } = useTranslation();
    const options = [
        { value: 'current_month', label: t('dashboard.current_month') },
        { value: 'current_year', label: t('dashboard.current_year') },
        { value: 'last_3_year', label: t('dashboard.last_3_years') },
        { value: 'custom', label: t('dashboard.custom') },
    ];

    const schema = yup.object().shape({
        startDate: yup
            .string()
            .required('Start Date is required'),
        endDate: yup
            .string()
            .required('End Date is required')
            .test('is-valid-end-date', 'End date should not be before start date', function (value) {
                const startDate = this.resolve(yup.ref('startDate'));
                return !startDate || !value || value >= startDate;
            }),
    });

    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const handleClose = (e) => {
        e.preventDefault();
        setShow(false);
        reset();
    };

    const handleCloseMenu = (option, section) => {
        switch (section) {
            case 'card_issue':
                setSelectedSection('card_issue');
                if (option === 'current_month') {
                    setSelectedFilterCards(prev => ({ ...prev, card_issue: 'Current Month' }));
                }
                if (option === 'current_year') {
                    setSelectedFilterCards(prev => ({ ...prev, card_issue: 'Current Year' }));
                }
                if (option === 'last_3_year') {
                    setSelectedFilterCards(prev => ({ ...prev, card_issue: 'Last 3 Years' }));
                }
                break;
            case 'total_users':
                setSelectedSection('total_users');
                if (option === 'current_month') {
                    setSelectedFilterCards(prev => ({ ...prev, total_users: 'Current Month' }));
                }
                if (option === 'current_year') {
                    setSelectedFilterCards(prev => ({ ...prev, total_users: 'Current Year' }));
                }
                if (option === 'last_3_year') {
                    setSelectedFilterCards(prev => ({ ...prev, total_users: 'Last 3 Years' }));
                }
                break;
            case 'no_of_transactions':
                setSelectedSection('no_of_transactions');
                if (option === 'current_month') {
                    setSelectedFilterCards(prev => ({ ...prev, no_of_transactions: 'Current Month' }));
                }
                if (option === 'current_year') {
                    setSelectedFilterCards(prev => ({ ...prev, no_of_transactions: 'Current Year' }));
                }
                if (option === 'last_3_year') {
                    setSelectedFilterCards(prev => ({ ...prev, no_of_transactions: 'Last 3 Years' }));
                }
                break;
            case 'approved_user':
                setSelectedSection('approved_user');
                if (option === 'current_month') {
                    setSelectedFilterCards(prev => ({ ...prev, approved_user: 'Current Month' }));
                }
                if (option === 'current_year') {
                    setSelectedFilterCards(prev => ({ ...prev, approved_user: 'Current Year' }));
                }
                if (option === 'last_3_year') {
                    setSelectedFilterCards(prev => ({ ...prev, approved_user: 'Last 3 Years' }));
                }
                break;
            case 'pending_user':
                setSelectedSection('pending_user');
                if (option === 'current_month') {
                    setSelectedFilterCards(prev => ({ ...prev, pending_user: 'Current Month' }));
                }
                if (option === 'current_year') {
                    setSelectedFilterCards(prev => ({ ...prev, pending_user: 'Current Year' }));
                }
                if (option === 'last_3_year') {
                    setSelectedFilterCards(prev => ({ ...prev, pending_user: 'Last 3 Years' }));
                }
                break;
            case 'rejected_user':
                setSelectedSection('rejected_user');
                if (option === 'current_month') {
                    setSelectedFilterCards(prev => ({ ...prev, rejected_user: 'Current Month' }));
                }
                if (option === 'current_year') {
                    setSelectedFilterCards(prev => ({ ...prev, rejected_user: 'Current Year' }));
                }
                if (option === 'last_3_year') {
                    setSelectedFilterCards(prev => ({ ...prev, rejected_user: 'Last 3 Years' }));
                }
                break;
            case 'settled_user':
                setSelectedSection('settled_user');
                if (option === 'current_month') {
                    setSelectedFilterCards(prev => ({ ...prev, settled_user: 'Current Month' }));
                }
                if (option === 'current_year') {
                    setSelectedFilterCards(prev => ({ ...prev, settled_user: 'Current Year' }));
                }
                if (option === 'last_3_year') {
                    setSelectedFilterCards(prev => ({ ...prev, settled_user: 'Last 3 Years' }));
                }
                break;
            default:
                break;
        }
        if (option != '' && typeof option === 'string') {
            if (option === 'custom') {
                setSelectedFilterCards(prev => ({ ...prev, [section]: null }));
                setShow(true);
            } else {
                handleFilterClick(section, option, {}, true);
            }
        }
    };
    const onSubmit = (data) => {
        const date = `${moment(data?.startDate).format('DD/MM/YYYY')} - ${moment(data?.endDate).format('DD/MM/YYYY')}`;
        if (selectedSection === 'card_issue') {
            setCardIsueeDate(date);
        }
        if (selectedSection === 'total_users') {
            setTotalUserDate(date);
        }
        if (selectedSection === 'no_of_transactions') {
            setTransactionDate(date);
        }
        if (selectedSection === 'approved_user') {
            setApprovedUser(date);
        }
        if (selectedSection === 'pending_user') {
            setPendingUser(date);
        }
        if (selectedSection === 'rejected_user') {
            setRejectedUser(date);
        }
        if (selectedSection === 'settled_user') {
            setsettledUser(date);
        }
        handleFilterClick(selectedSection, 'custom', data, true);
        setShow(false);
        reset();
    };

    return (
        <div className='row dashboardCardpageCss g-4'>
            <div className='col-lg-4 col-md-4 col-sm-12'>
                <div className='border-grey small-graph-chart p-3 cards-custom position-relative marginbottoncustomDashboard'>
                    {totalTransactionCountLoading && <div className="dashboardloader-container" style={{ height: '100%', width: '100%', left: 0, top: 0 }}>
                        <div className="spinner-border text-light" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>}
                    <div className='d-flex align-items-center justify-content-between green-header'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <img src={List2} width={32} style={{ objectFit: 'contain' }} className='mx-2' />
                            <p className='green-header-text mb-0'>{t('dashboard.no_of_transactions')}</p>
                        </div>

                        <div className='d-flex align-items-center'>
                            <p style={{ fontSize: '12px' }} className='text-grey mb-0' >{selectedFilterCards?.no_of_transactions ? selectedFilterCards?.no_of_transactions : transactionDate ? transactionDate : globalSelectedFil}</p>
                            <div className='dashboard-custom-dropdown2'>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        <img src={List1} style={{ objectFit: 'contain' }} className='option-image' alt="Options" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {options.map((option, index) => (
                                            <Dropdown.Item key={index} onClick={() => handleCloseMenu(option.value, 'no_of_transactions')}>
                                                {option.label}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <h1 style={{ fontSize: 48, fontWeight: '600', color: '#FFA07A', marginLeft: 16 }}>{totalTransactionCount?.transactionCount}</h1>
                </div>
            </div>
            {(selectedIndex === 0) && (
                <div className='col-lg-4 col-md-4 col-sm-12'>
                    <div className='border-grey small-graph-chart p-3 cards-custom position-relative marginbottoncustomDashboard'>
                        {cardIssueCountLoading && <div className="dashboardloader-container" style={{ height: '100%', width: '100%', left: 0, top: 0 }}>
                            <div className="spinner-border text-light" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>}
                        <div className='d-flex align-items-center justify-content-between green-header'>
                            <div className='d-flex justify-content-center align-items-center'>
                                <img src={cardsIssued} width={28} style={{ objectFit: 'contain' }} className='mx-2' />
                                <p className='green-header-text mb-0'>{t('dashboard.card_issued')}</p>
                            </div>
                            <div className='d-flex align-items-center'>
                                <p style={{ fontSize: '12px' }} className='text-grey mb-0' >{selectedFilterCards?.card_issue ? selectedFilterCards?.card_issue : cardIsueeDate ? cardIsueeDate : globalSelectedFil}</p>
                                <div className='dashboard-custom-dropdown2'>
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic">
                                            <img src={List1} style={{ objectFit: 'contain' }} className='option-image' alt="Options" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {options.map((option, index) => (
                                                <Dropdown.Item key={index} onClick={() => handleCloseMenu(option.value, 'card_issue')}>
                                                    {option.label}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <h1 style={{ fontSize: 48, fontWeight: '600', color: '#FF5733', marginLeft: 16 }}>{cardIssueCount?.cardCount}</h1>
                    </div>
                </div>
            )}
            {(selectedIndex === 0) && (
                <div className='col-lg-4 col-md-4 col-sm-12'>
                    <div className='border-grey small-graph-chart p-3 cards-custom  position-relative marginbottoncustomDashboard'>
                        {totalUsersCountLoading && <div className="dashboardloader-container" style={{ height: '100%', width: '100%', left: 0, top: 0 }}>
                            <div className="spinner-border text-light" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>}
                        <div className='d-flex align-items-center justify-content-between green-header'>
                            <div className='d-flex justify-content-center align-items-center'>
                                <img src={UserProfile} width={28} style={{ objectFit: 'contain' }} className='mx-2' />
                                <h2 className='green-header-text mb-0'>{t('dashboard.total_users')}</h2>
                            </div>
                            <div className='d-flex align-items-center'>
                                <p style={{ fontSize: '12px' }} className='text-grey mb-0' >{selectedFilterCards?.total_users ? selectedFilterCards?.total_users : totalUserDate ? totalUserDate : globalSelectedFil}</p>
                                <div className='dashboard-custom-dropdown2'>
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic">
                                            <img src={List1} style={{ objectFit: 'contain' }} className='option-image' alt="Options" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {options.map((option, index) => (
                                                <Dropdown.Item key={index} onClick={() => handleCloseMenu(option.value, 'total_users')}>
                                                    {option.label}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <h1 style={{ fontSize: 48, fontWeight: '600', color: '#1A9A76', marginLeft: 16 }}>{totalUsersCount?.employeeCount}</h1>
                    </div>
                </div>
            )}
            {(selectedIndex === 1) && (
                <div className='col-lg-4 col-md-4 col-sm-12'>
                    <div className='border-grey small-graph-chart p-3 cards-custom position-relative marginbottoncustomDashboard'>
                        {approvedReimbursmentTransactionLoading && <div className="dashboardloader-container" style={{ height: '100%', width: '100%', left: 0, top: 0 }}>
                            <div className="spinner-border text-light" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>}
                        <div className='d-flex align-items-center justify-content-between green-header'>
                            <div className='d-flex justify-content-center align-items-center'>
                                <img src={cardsIssued} width={28} style={{ objectFit: 'contain' }} className='mx-2' />
                                <p className='green-header-text mb-0'>Approved Transactions</p>
                            </div>
                            <div className='d-flex align-items-center'>
                                <p style={{ fontSize: '12px' }} className='text-grey mb-0' >{selectedFilterCards?.approved_user ? selectedFilterCards?.approved_user : approvedUser ? approvedUser : globalSelectedFil}</p>
                                <div className='dashboard-custom-dropdown2'>
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic">
                                            <img src={List1} style={{ objectFit: 'contain' }} className='option-image' alt="Options" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {options.map((option, index) => (
                                                <Dropdown.Item key={index} onClick={() => handleCloseMenu(option.value, 'approved_user')}>
                                                    {option.label}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <h1 style={{ fontSize: 48, fontWeight: '600', color: '#FF5733', marginLeft: 16 }}>{approvedReimbursmentTransaction?.data?.transactionCount}</h1>
                    </div>
                </div>
            )}
            {(selectedIndex === 1) && (
                <div className='col-lg-4 col-md-4 col-sm-12'>
                    <div className='border-grey small-graph-chart p-3 cards-custom position-relative marginbottoncustomDashboard'>
                        {settledReimbursmentTransactionLoading && <div className="dashboardloader-container" style={{ height: '100%', width: '100%', left: 0, top: 0 }}>
                            <div className="spinner-border text-light" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>}
                        <div className='d-flex align-items-center justify-content-between green-header'>
                            <div className='d-flex justify-content-center align-items-center'>
                                <img src={cardsIssued} width={28} style={{ objectFit: 'contain' }} className='mx-2' />
                                <p className='green-header-text mb-0'>Settled Transactions</p>
                            </div>
                            <div className='d-flex align-items-center'>
                                <p style={{ fontSize: '12px' }} className='text-grey mb-0' >{selectedFilterCards?.settled_user ? selectedFilterCards?.settled_user : settledUser ? settledUser : globalSelectedFil}</p>
                                <div className='dashboard-custom-dropdown2'>
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic">
                                            <img src={List1} style={{ objectFit: 'contain' }} className='option-image' alt="Options" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {options.map((option, index) => (
                                                <Dropdown.Item key={index} onClick={() => handleCloseMenu(option.value, 'settled_user')}>
                                                    {option.label}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <h1 style={{ fontSize: 48, fontWeight: '600', color: '#FF5733', marginLeft: 16 }}>{settledReimbursmentTransaction?.data?.transactionCount}</h1>
                        </div>
                </div>
            )}
            {(selectedIndex === 1) && (
                <div className='col-lg-6 col-md-4 col-sm-12'>
                    <div className='border-grey small-graph-chart p-3 cards-custom  position-relative marginbottoncustomDashboard'>
                        {pendingReimbursmentTransactionLoading && <div className="dashboardloader-container" style={{ height: '100%', width: '100%', left: 0, top: 0 }}>
                            <div className="spinner-border text-light" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>}
                        <div className='d-flex align-items-center justify-content-between green-header'>
                            <div className='d-flex justify-content-center align-items-center'>
                                <img src={UserProfile} width={28} style={{ objectFit: 'contain' }} className='mx-2' />
                                <h2 className='green-header-text mb-0'>Pending Transactions</h2>
                            </div>
                            <div className='d-flex align-items-center'>
                                <p style={{ fontSize: '12px' }} className='text-grey mb-0' >{selectedFilterCards?.pending_user ? selectedFilterCards?.pending_user : pendingUser ? pendingUser : globalSelectedFil}</p>
                                <div className='dashboard-custom-dropdown2'>
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic">
                                            <img src={List1} style={{ objectFit: 'contain' }} className='option-image' alt="Options" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {options.map((option, index) => (
                                                <Dropdown.Item key={index} onClick={() => handleCloseMenu(option.value, 'pending_user')}>
                                                    {option.label}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <h1 style={{ fontSize: 48, fontWeight: '600', color: '#1A9A76', marginLeft: 16 }}>{pendingReimbursmentTransaction?.data?.transactionCount}</h1>
                    </div>
                </div>
            )}
            {(selectedIndex === 1) && (
                <div className='col-lg-6 col-md-4 col-sm-12'>
                    <div className='border-grey small-graph-chart p-3 cards-custom  position-relative marginbottoncustomDashboard'>
                        {rejectedReimbursmentTransactionLoading && <div className="dashboardloader-container" style={{ height: '100%', width: '100%', left: 0, top: 0 }}>
                            <div className="spinner-border text-light" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>}
                        <div className='d-flex align-items-center justify-content-between green-header'>
                            <div className='d-flex justify-content-center align-items-center'>
                                <img src={UserProfile} width={28} style={{ objectFit: 'contain' }} className='mx-2' />
                                <h2 className='green-header-text mb-0'>Rejected Transactions</h2>
                            </div>
                            <div className='d-flex align-items-center'>
                                <p style={{ fontSize: '12px' }} className='text-grey mb-0' >{selectedFilterCards?.rejected_user ? selectedFilterCards?.rejected_user : rejectedUser ? rejectedUser : globalSelectedFil}</p>
                                <div className='dashboard-custom-dropdown2'>
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic">
                                            <img src={List1} style={{ objectFit: 'contain' }} className='option-image' alt="Options" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {options.map((option, index) => (
                                                <Dropdown.Item key={index} onClick={() => handleCloseMenu(option.value, 'rejected_user')}>
                                                    {option.label}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <h1 style={{ fontSize: 48, fontWeight: '600', color: '#1A9A76', marginLeft: 16 }}>{rejectedReimbursmentTransaction?.data?.transactionCount}</h1>
                    </div>
                </div>
            )}
            <Modal className='TaxcodeModalOne' show={show} onHide={(e) => handleClose(e)} style={{ paddingRight: 0 }}>
                <form className='form' onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <div className='d-flex justify-content-between align-items-start green-header p-2'>
                            <div className='col-9'>
                                <p className='text-green modal-title' style={{ fontSize: 18 }}>
                                    Select Custom Date
                                </p>
                            </div>
                            <div onClick={handleClose} className='clo-2' style={{ cursor: 'pointer' }}>
                                <img src={ImagesCross} style={{ width: 20, objectFit: 'contain' }} />
                            </div>
                        </div>
                        <div className="form-outline my-3">
                            <label className="form-label" htmlFor="startDate">{`Start Date`}</label>
                            <Controller
                                name="startDate"
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                    <input
                                        type="date"
                                        id="startDate"
                                        className="form-control form-control-lg"
                                        placeholder="Start Date"
                                        autoComplete="off"
                                        max={currentDate}
                                        {...field}
                                    />
                                )}
                            />
                            {errors?.startDate && (
                                <div style={{ color: 'red' }}>{errors?.startDate?.message}</div>
                            )}
                        </div>
                        <div className="form-outline my-3">
                            <label className="form-label" htmlFor="endDate">{`End Date`}</label>
                            <Controller
                                name="endDate"
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                    <input
                                        type="date"
                                        id="endDate"
                                        className="form-control form-control-lg"
                                        placeholder="Start Date"
                                        autoComplete="off"
                                        max={currentDate}
                                        {...field}
                                    />
                                )}
                            />
                            {errors?.endDate && (
                                <div style={{ color: 'red' }}>{errors?.endDate?.message}</div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={(e) => handleClose(e)} className='btn-secondary-small' >{t('cancel')}</button>
                        <button className='btn-primary-small' type='submit'>{t('submit')}</button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>

    );
}

export default DashboardCards;
