import React from 'react';
import './Login.scss';
import LoginLeftImage from '../../assets/images/login_left_image.png';
import login_bottom_image from '../../assets/images/login_bottom_image.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import Loader from '../../components/Loader/Loader';
import { sendForgotPasswordMail } from './loginSlice';
import Logo2 from '../../assets/images/polo.png';

function ForgotPassword() {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const date =  new Date().getFullYear();

    const { register, handleSubmit, formState: { errors } } = useForm();

    const { isLoading } = useSelector(state => state?.loginSliceReducer);

    const onSubmit = async (data) => {
        dispatch(sendForgotPasswordMail({ emailId: data?.email }))
            .unwrap()
            .then(() => {
                navigate('/emailVerification', {state: {forgotData: data, registerData:{}}});
            }).catch((error) => {
                toast.error(error?.message);
            });
    };

    const routeLoginChange = () => {
        let path = `/login`;
        navigate(path);
    };
    return (
        <section className="vh-100">
            {
                isLoading && <Loader />
            }
            <div className="container-fluid" style={{ backgroundColor: '#24473D' }}>
                <div className="row login-forms " >
                    <div className="col-md-7 col-lg-5 col-xl-6 my-2 soop">
                        <div className='d-flex flex-column justify-content-between align-items-center h-100'>
                            <div></div>
                            <form className='form' onSubmit={handleSubmit(onSubmit)}  >
                                <div>
                                    <img src={Logo2} className="logo mt-3 mb-4" alt="Sample image" />
                                </div>
                                <h1 className='text-blue-login my-2'>
                                    {t('login.let’s_get_you_setup')}
                                </h1>
                                <div className="divider d-flex align-items-center my-2">
                                    <p className="mb-0 text-subtitle">
                                        {t('login.tell_us_a_little_about_yourself')}
                                    </p>
                                </div>
                                <div className="form-outline mt-3 mb-3">
                                    <label className="form-label" htmlFor="emailId">{t('login.email_address')}</label>
                                    <input
                                        type="text"
                                        id="emailId"
                                        className={`form-control form-control-lg ${errors.email ? 'is-invalid' : ''}`}
                                        placeholder={`${t('login.enter_your_email')}`}
                                        {...register('email', {
                                            required: `${t('login.enter_your_email')}`,
                                            pattern: {
                                                value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
                                                message: 'Please enter valid Email Id',
                                            },
                                        })}
                                    />
                                    {errors?.email && (
                                        <div className="invalid-feedback">{errors?.email?.message}</div>
                                    )}
                                </div>

                                <div className="text-center text-lg-start mt-4 w-100">
                                    <button type="submit" className="btn btn-primary btn-lg btn-block w-100" id='continue'>{t('login.continue')}</button>
                                </div>
                                <div className="divider d-flex align-items-center justify-content-center mt-4 pt-2">
                                    <p className="text-center mx-3 mb-0 text-or">
                                        {t('login.have_an_account?')} <a href='#!' onClick={routeLoginChange} className='text-blue forgotPassword' id='login'>{t('login.login')}</a>
                                    </p>
                                </div>
                            </form>

                            <div className='copyright'>
                               
                                <p className='copyright-text'>{`© Xpendless ${date}`}</p>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-8 col-lg-7 col-xl-6 h-100 d-flex justify-content-center align-items-center imagecontainer">
                        <img src={LoginLeftImage} className='loginImage' />
                        <img className='login_bottom_image' src={login_bottom_image} />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ForgotPassword;
