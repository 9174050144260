import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import ImagesCross from '../../assets/images/close-square-8.png';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ProfileIcon from '../ProfileIcon';
import moment from 'moment';
import { formatQAR } from '../../utils/XpendlessUtils';
import List1 from '../../assets/images/list.png';
import SpendsImg from '../../assets/images/card-send.png';
import Dropdown from 'react-bootstrap/Dropdown';
import './Dashboard.scss';
import cookies from 'js-cookie';

function DashboardSpends(props) {
    const { spendPerCategory, spendPerCategoryLoading, handleFilterClick, section, globalSelectedFil, selectedFilterSpends, setSelectedFilterSpends } = props;
    const [show, setShow] = useState(false);
    const { t } = useTranslation();
    const options = [
        { value: 'current_month', label: t('dashboard.current_month') },
        { value: 'current_year', label: t('dashboard.current_year') },
        { value: 'last_3_year', label: t('dashboard.last_3_years') },
        { value: 'custom', label: t('dashboard.custom') },
    ];
    const languages = [
        {
            code: 'en',
            name: 'English',
            country_code: 'gb',
        },
        {
            code: 'ar',
            name: 'العربية',
            dir: 'rtl',
            country_code: 'sa',
        },
    ];
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

    const schema = yup.object().shape({
        startDate: yup
            .string()
            .required('Start Date is required'),
        endDate: yup
            .string()
            .required('End Date is required')
            .test('is-valid-end-date', 'End date should not be before start date', function (value) {
                const startDate = this.resolve(yup.ref('startDate'));
                return !startDate || !value || value >= startDate;
            }),
    });

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const handleClose = () => setShow(false);
    const handleCloseMenu = (selectedOption) => {
        if (selectedOption) {
            const { value, label } = selectedOption;
            setSelectedFilterSpends(label);

            if (value === 'custom') {
                setShow(true);
            } else {
                handleFilterClick(section, value, {}, true);
            }
        }
    };


    const onSubmit = (data) => {
        setSelectedFilterSpends(`${moment(data?.startDate).format('DD/MM/YYYY')} - ${moment(data?.endDate).format('DD/MM/YYYY')}`);
        handleFilterClick(section, 'custom', data, true);
        setShow(false);
    };

    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr';
        document.title = t('app_title');
    }, [currentLanguage, t]);


    return (
        <div className='cards-custom position-relative p-3 dashboardspendsPageCss'>
            {spendPerCategoryLoading && <div className="dashboardloader-container" style={{ height: '100%', width: '100%', left: 0, top: 0 }}>
                <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>}
            <div className='d-flex align-items-center justify-content-between green-header'>
                <div className='d-flex justify-content-center align-items-center'>
                    <img src={SpendsImg} width={28} style={{ objectFit: 'contain' }} className='mx-2' />
                    <h2 className='green-header-text'>{t('dashboard.spends')} {t('dashboard.per_categories')}</h2>
                </div>
                <div className='d-flex align-items-center'>
                    <p style={{ fontSize: '12px' }} className='text-grey mb-0' >{selectedFilterSpends !== null ? selectedFilterSpends : globalSelectedFil}</p>
                    <div className='dashboard-custom-dropdown2'>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                                <img src={List1} style={{ objectFit: 'contain' }} className='option-image' alt="Options" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {options.map((option, index) => (
                                    <Dropdown.Item key={index} onClick={() => handleCloseMenu(option)}>
                                        {option.label}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div className='table-responsive mt-4 py-0'>
                <table id="dtBasicExample" style={{ borderCollapse: 'separate', borderSpacing: '0 16px' }} cellSpacing="0" width="100%">
                    <thead>
                        <tr style={{ borderTopWidth: 0 }} className='table-head'>
                            <th className='customTH' scope="col">{t('dashboard.categories')}</th>
                            <th className='customTH' scope="col">{t('dashboard.amount')}</th>
                        </tr>
                    </thead>
                    <tbody className='table-body'>
                        {spendPerCategory?.map((item, index) => (
                            <tr key={index} className={`${currentLanguageCode === 'en' ? 'table-body-tr' : 'table-body-tr-arabic'}`}>
                                <td className='customTD' style={{ marginRight: 10 }}>
                                    <ProfileIcon imageUrl={item?.icon} name={item?.name} />
                                    {item?.name}</td>
                                <td className='customTD'>{formatQAR(item?.total_amount)} QAR</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Modal className='TaxcodeModalOne' show={show} onHide={handleClose} style={{ paddingRight: 0 }}>
                <form className='form' onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <div className='d-flex justify-content-between align-items-start green-header p-2'>
                            <div className='col-9'>
                                <p className='text-green modal-title' style={{ fontSize: 18 }}>
                                    Select Custom Date
                                </p>
                            </div>
                            <div onClick={handleClose} className='clo-2' style={{ cursor: 'pointer' }}>
                                <img src={ImagesCross} style={{ width: 20, objectFit: 'contain' }} />
                            </div>
                        </div>
                        <div className="form-outline my-3">
                            <label className="form-label" htmlFor="startDate">
                                Start Date
                            </label>

                            <Controller
                                name="startDate"
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                    <input
                                        type="date"
                                        id="startDate"
                                        className="form-control form-control-lg"
                                        placeholder="Start Date"
                                        autoComplete="off"
                                        {...field}
                                    />
                                )}
                            />
                            {errors?.startDate && (
                                <div style={{ color: 'red' }}>{errors?.startDate?.message}</div>
                            )}
                        </div>
                        <div className="form-outline my-3">
                            <label className="form-label" htmlFor="endDate">
                                End Date
                            </label>

                            <Controller
                                name="endDate"
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                    <input
                                        type="date"
                                        id="endDate"
                                        className="form-control form-control-lg"
                                        placeholder="Start Date"
                                        autoComplete="off"
                                        {...field}
                                    />
                                )}
                            />
                            {errors?.endDate && (
                                <div style={{ color: 'red' }}>{errors?.endDate?.message}</div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={handleClose} className='btn-secondary-small' >{t('cancel')}</button>
                        <button className='btn-primary-small' type='submit'>{t('submit')}</button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
}

export default DashboardSpends;
