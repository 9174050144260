import React from 'react';
import './Login.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { getCompanyList, registerCompany } from './registerSlice';
import { registration } from './registerSlice';

import Loader from '../../components/Loader/Loader';
import login_bottom_image from '../../assets/images/login_bottom_image.png';
import Logo2 from '../../assets/images/polo.png';
import { setUserLoggedInData } from '../Users/userSlice';




function SearchCompany() {
    const { state: locationState } = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { register, handleSubmit, formState: { errors } } = useForm();

    const { isLoading } = useSelector(state => state?.registerSlice);

    const onSubmit = (data) => {
        const registerPayload = {
            ...locationState?.registerData,
            ...data
        };

        const keyValuesToCheck = {
            firstName: "First Name is missing.",
            lastName: "Last Name is missing.",
            email: "Email Id is missing.",
            mobileNumber: "Mobile Number is missing.",
        };
        for (const key in keyValuesToCheck) {
            if (!registerPayload[key] || registerPayload[key] === undefined) {
                toast.dismiss();
                toast.error(keyValuesToCheck[key]);
                return;
            }
        }
        dispatch(registration(registerPayload))
            .unwrap()
            .then((res) => {
                toast.success(res.message);
                dispatch(setUserLoggedInData(res?.data));
                navigate('/walkThrough');
            }).catch((error) => {
                toast.error(error?.message);
            });
    };

    return (
        <section className="vh-100">
            {
                isLoading && <Loader />
            }
            <div className="container-fluid vh-100" style={{ backgroundColor: '#24473D' }}>
                <div className="row d-flex justify-content-center align-items-center h-100 " >
                    <div className=" d-flex flex-column justify-content-center align-items-center my-2">
                        <div className='form-div search-form-dev'>
                            <form className='form' onSubmit={handleSubmit(onSubmit)} >
                                <div>
                                    <img src={Logo2} className="logoso mt-1 mb-4" alt="Sample image" />
                                </div>
                                <h1 className='text-blue-login mb-2'>
                                    {t('login.register_your_company')}
                                </h1>
                                <div className="divider d-flex align-items-center my-2">
                                    <p className="mb-0 text-subtitle2" style={{ color: '#8B9299' }} >
                                        {t('login.register_your_company_desc')}
                                    </p>
                                </div>
                                <div className="form-outline mt-4 mb-3">
                                    <label className="form-label" htmlFor="companyName">{t('login.company_name')}</label>
                                    <input
                                        type="text"
                                        id="companyName"
                                        className={`form-control form-control-lg ${errors?.companyName ? 'is-invalid' : ''}`}
                                        placeholder={`${t('login.enter_company_name')}`}
                                        {...register('companyName', {
                                            required: `${t('login.enter_company_name')}`,
                                        })}
                                    />
                                    {errors?.companyName && (
                                        <div className="invalid-feedback">{errors?.companyName?.message}</div>
                                    )}
                                </div>
                                <div className="form-outline mt-4 mb-3">
                                    <label className="form-label" htmlFor="registrationNumber">{t('login.registration_number')}</label>
                                    <input
                                        type="text"
                                        id="registrationNumber"
                                        className={`form-control form-control-lg ${errors?.registrationNumber ? 'is-invalid' : ''}`}
                                        placeholder={`${t('login.enter_company_registration_number')}`}
                                        {...register('registrationNumber', {
                                            required: `${t('login.enter_company_registration_number')}`,
                                        })}
                                    />
                                    {errors?.registrationNumber && (
                                        <div className="invalid-feedback">{errors?.registrationNumber?.message}</div>
                                    )}
                                </div>
                                <div className="text-center text-lg mt-4 w-100">
                                    <button type="submit" className="btn btn-primary btn-lg btn-block form-btn" style={{ position: 'relative', zIndex: '1' }}>{'Register'}</button>
                                </div>

                            </form>
                        </div>
                        <img className='login_bottom_image-1' src={login_bottom_image} />
                    </div>

                </div>
            </div>
        </section>
    );
}

export default SearchCompany;
