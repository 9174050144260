import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BackArrow from '../../assets/images/backarrow1.png';
import showEye from '../../assets/images/solar_eye-broken.png';
import ImagesCross from '../../assets/images/close-square-8.png';
import { useNavigate, useParams } from 'react-router-dom';
import SingleCardTabs from '../../components/Cards/SingleCardTabs';
import { Modal } from 'react-bootstrap';
import Slider from '@mui/material/Slider';
import CardSlider from '../../components/Cards/CardSlider';
import cookies from 'js-cookie';
import { getCardDetails, fetchCardSpendings, addCardMoney, withdrawCardMoney, updateCardLimit, updateCardControl, updateCardDetails, freezeUnfreezeCard } from './slice/cardListSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader/Loader';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { formatQAR } from '../../utils/XpendlessUtils';
import "./Cards.scss";
import OtpInput from 'react-otp-input';
import Logo2 from '../../assets/images/polo.png';

function valuetext(value) {
    return `${value}°C`;
}

function SingleCardDetails() {
    const [show, setShow] = useState(false);
    const [showAddMoney, setShowAddMoney] = useState(false);
    const [showWithdrawMoney, setShowWithdrawMoney] = useState(false);
    const [showVerifyModal, setShowVerifyModal] = useState(false);
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleShowAddMoney = () => setShowAddMoney(true);
    const handleShowVerifyModal = () => setShowVerifyModal(true);
    const handleCloseVerifyModal = () => setShowVerifyModal(false);
    const { employeeId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { cardDetails, isLoading, spendings } = useSelector(state => state.cardListReducer);
    const [activeCard, setActiveCard] = useState(null);
    const [activeTab, setActiveTab] = useState(1);
    const currentBalance = (activeCard !== null) ? cardDetails[activeCard].currentBalance : 0;
    const currentLimit = (activeCard !== null) ? cardDetails[activeCard].limit : 0;
    const [wholePart, decimalPart] = currentBalance.toString().split('.').map(Number);
    const [timer, setTimer] = useState(60);
    const [selectedType, setSelectedType] = useState('addFunds');
    const [selectedTypeData, setSelectedTypeData] = useState('');
    const [freezeCardId, setFreezeCardId] = useState(null);

    const {
        control: controlAddMoneyForm,
        handleSubmit: handleAddMoneySubmit,
        formState: { errors: errorsAddMoneyForm },
        reset: resetAddMoneyForm, } = useForm({
            resolver: yupResolver(yup.object().shape({
                amount: yup
                    .number()
                    .min(100, "Amount must be greater than or equal to 100")
                    .required()
                    .typeError('Please enter valid amount')
                    .test(
                        'max-amount',
                        `The total balance should not exceed the limit of ${currentLimit}`,
                        function(value) {
                            return currentLimit >= value + currentBalance;
                        }
                    ),
                comments: yup.string().required()
            })
            )
        });

    const {
        control: controlWithdrawMoneyForm,
        handleSubmit: handleWithdrawMoneySubmit,
        formState: { errors: errorsWithdrawMoneyForm },
        reset: resetWithdrawMoneyForm, } = useForm({
            resolver: yupResolver(yup.object().shape({
                amount: yup.number().min(1, "Amount must be greater than or equal to 1").max(wholePart, "Amount should not be greater then the card balance.").required().typeError('Please enter valid amount'),
                comments: yup.string().required()
            })
            )
        });

    const {
        control: controlCardLimitForm,
        handleSubmit: handleCardLimitSubmit,
        watch: watchCardLimit,
        setValue: setCardLimitValue,
        formState: { errors: errorsCardLimitForm },
        resetField,
        reset: resetCardLimitForm } = useForm({
            resolver: yupResolver(yup.object().shape({
                limit: yup.number().min(wholePart, `Minimum amount should be ${wholePart}`).max((wholePart + 10000), t('accounts.Value_should_not_be_greater_than_card_limit')).required().typeError('Limit is required')
            })),
            mode: 'onChange'
        });

    const { control: controlVerifyModal, handleSubmit: handleOtpSubmit, formState: { errors: errorsOtpForm }, reset: otpFormReset } = useForm({
        resolver: yupResolver(yup.object().shape({
            otp: yup.string().required("Otp is required").matches(/^123456$/, 'OTP must be exactly 6 digits'),
        }))
    });
    const limit = watchCardLimit('limit', ((cardDetails != null && activeCard != null) ? cardDetails[activeCard].limit : 0));
    useEffect(() => {
        setTimeout(() => {
            dispatch(getCardDetails(employeeId)).unwrap().then(() => {
                setActiveCard(0);
            }).catch((error) => {
                toast.error(error.message || "Request Failed Please Try Again ");
            });
        }, 500);
    }, [employeeId, dispatch]);

    const handleShowWithdrawMoney = () => {
        if(wholePart <= 0){
            toast.error("No balance to withdraw!.")
            return;
        }
        setShowWithdrawMoney(true);
        setSelectedType('withdrawFunds');
    };

    const clickedSpendingTab = (cardId) => {
        let queryParams = `?page=1&cardId=${cardId}`;
        dispatch(fetchCardSpendings(queryParams)).unwrap().then(() => {

        }).catch((error) => {
            toast.error(error.message || "Request Failed Please Try Again ");
        });
    };
    const handleCloseAddMoney = () => {setShowAddMoney(false);resetAddMoneyForm();};
    const handleCloseWithdrawMoney = () => {setShowWithdrawMoney(false);resetWithdrawMoneyForm();};
    
    const handleAddMoney = () => {
        selectedTypeData.cardId = cardDetails[activeCard].cardId;
        selectedTypeData.eventType = 'cards';
        dispatch(addCardMoney(selectedTypeData)).unwrap().then((data) => {
            toast.success(data.message || 'Money Added Successfully');
            dispatch(getCardDetails(employeeId));
            handleCloseAddMoney();
            handleCloseVerifyModal();
            resetAddMoneyForm();
            otpFormReset({ otp: '' });
        }).catch((error) => {
            handleCloseVerifyModal();
            toast.error(error.message || "Request Failed Please Try Again ");
        });
    };

    useEffect(() => {
        let interval;
    
        if (timer > 0) {
          interval = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
          }, 1000);
        }
    
        return () => {
          clearInterval(interval);
        };
      }, [timer]);
    
    const onClickVerifyModal = (data) => {
        setTimer(60)
        setSelectedTypeData(data);
        handleCloseAddMoney();
        handleCloseWithdrawMoney();
        otpFormReset({ otp: '' });
        setTimeout(() => {
            handleShowVerifyModal();
        }, 200);
    };

    const handleWithdrawMoney = () => {
        selectedTypeData.cardId = cardDetails[activeCard].cardId;
        resetWithdrawMoneyForm();
        dispatch(withdrawCardMoney(selectedTypeData)).unwrap().then((data) => {
            toast.success(data.message || 'Money Withdraw Successfully');
            dispatch(getCardDetails(employeeId));
            handleCloseWithdrawMoney();
            handleCloseVerifyModal();
            otpFormReset({ otp: '' });
        }).catch((error) => {
            handleCloseVerifyModal();
            toast.error(error.message || "Request Failed Please Try Again ");
        });
    };

    const handleCardLimit = (data) => {
        data.cardId = cardDetails[activeCard].cardId;
        data.userEmployeeId = employeeId;
        resetCardLimitForm();
        dispatch(updateCardLimit(data)).unwrap().then((data) => {
            toast.success(data.message || 'Limit Changed Successfully');
            dispatch(getCardDetails(employeeId));
            handleClose();
        }).catch((error) => {
            toast.error(error.message || "Request Failed Please Try Again ");
        });
    };

    const handleCardControl = (data) => {
        dispatch(updateCardControl({ payload: data, cardId: cardDetails[activeCard].cardId })).unwrap().then((data) => {
            toast.success(data.message || 'Updated Successfully');
            dispatch(getCardDetails(employeeId));
        }).catch((error) => {
            toast.error(error.message || "Request Failed Please Try Again ");
        });
    };

    const handleSliderChange = (event, newValue) => {
        setCardLimitValue('limit', newValue);
    };

    useEffect(() => {
        if (activeTab == 3) {
            const details = (activeCard !== null) ? cardDetails[activeCard] : {};
            clickedSpendingTab(details?.cardId);
        }
    }, [activeTab, activeCard]);

    const toggleMask = () => {
        if (cardDetails.length > 0) {
            const currentMaskValue = (typeof cardDetails[activeCard].maskCardNumber != 'undefined') ? !cardDetails[activeCard].maskCardNumber : true;
            dispatch(updateCardDetails({ index: activeCard, maskCardNumber: currentMaskValue }));
            handleCloseVerifyModal();
            otpFormReset({ otp: '' });
        }
    };

    const handleCardFreeze = (data, cardId) => {
        setFreezeCardId(cardId)
        onClickVerifyModal(data);
        setSelectedType('freezeCard');
    };
    
    const handleCardFreezUnfreezAction = () => {
        const payload = { isFreezed: selectedTypeData, cardId : freezeCardId };
        dispatch(freezeUnfreezeCard(payload)).unwrap().then((data) => {
            toast.success(data.message || 'Updated Successfully');
            dispatch(getCardDetails(employeeId));
            otpFormReset({ otp: '' });
            handleCloseVerifyModal();
        }).catch((error) => {
            handleCloseVerifyModal();
            toast.error(error.message || "Request Failed Please Try Again ");
        });
    };

    const requiredFieldLabel = (labelText) => {
        return (
          <label className="form-label" htmlFor="form3Example3">
            {labelText}
            <span className="absolute top-0 left-0" style={{ transform: 'translate(-50%, -50%)', color: '#FF0000' }}>*</span>
          </label>
        )
    }

    return (
        <>
            {
                activeCard === null ? (
                    isLoading && <Loader />
                ) : <div className='white-container cardslider cards-section-css singleCradDetailsPageCss'>
                    {(isLoading) && <Loader />}
                    <div className='d-flex align-items-center justify-content-between'>
                        <h1 className='text-green mb-3'>{`${cardDetails[activeCard].firstName}’s Card`}</h1>
                        <button onClick={() => navigate(-1)} className='btn-secondary-small mb-3'><img src={BackArrow} style={{ width: 20, objectFit: 'contain', marginRight: 5, marginLeft: 5, transform: currentLanguageCode === 'en' ? 'rotate(0deg)' : 'rotate(180deg)' }} />{t('cards.back_to_cards')}</button>
                    </div>

                    <div className='cards-custom p-3'>
                        <div className='row mb-3'>
                            <div className='col-lg-5 marginbottoncustomCards'>
                                <div className='border-grey p-3 ps-0 pb-5 pr-0 mr-1 sliderpadding'>
                                    <CardSlider activeTab={activeTab} setActiveCard={setActiveCard} details={(activeCard !== null) ? cardDetails : []} handleCardFreezUnfreezAction={handleCardFreeze} />
                                </div>
                            </div>
                            <div className='col-lg-7 col-md-12'>
                                <div className='border-grey p-3'>
                                    <div className='green-box'>
                                        <h2 className='balance-text '>{t('users.current_balance')}</h2>
                                        <h1 className='text-green mb-1'>{wholePart.toLocaleString()}.<span className='text-grey' style={{ fontSize: 16, fontWeight: '500' }}>{`${(decimalPart) ? decimalPart : '00'} QAR`}</span></h1>
                                        <h2 className={` mt-2 text-${(cardDetails[activeCard].status === 'active' && cardDetails[activeCard].isFreezed === false) ? 'blue' : 'danger'} mb-1`} style={{ fontSize: 16 }}>{t('cards.card_is_active')}{cardDetails[activeCard].isFreezed === true ? 'inactive' : cardDetails[activeCard].status}</h2>
                                    </div>
                                    <div className='cardlimitBoxCss'>
                                        <p className='balance-text mb-1' style={{ fontSize: 20, fontWeight: '600' }}>{t('cards.card_limit')}</p>
                                        <button onClick={handleShow} className='green-button'>{t('cards.change_card_limit')}</button>
                                    </div>
                                    <div className="progress">
                                        <div className="progress-bar" role="progressbar" style={{ width: `${(cardDetails[activeCard].totalspends / cardDetails[activeCard].limit) * 100}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <p className='text-grey mt-3 mb-0' style={{ fontSize: 12, fontWeight: '500' }}>{t('cards.spend', { spend: (cardDetails[activeCard].totalspends) ? formatQAR(cardDetails[activeCard].totalspends) : 0 })}<span className='text-blue'>{t('cards.out_of', { outof: formatQAR(cardDetails[activeCard]?.limit) })}</span></p>
                                </div>
                            </div>
                        </div>
                        <div className='threeBtnLineCss'>
                            <button onClick={()=>{handleShowAddMoney();setSelectedType('addFunds');}} className='btn-success-green threeBtnCustomMargin'>{t('accounts.add_funds')}</button>
                            <div className='pe-3'></div>
                            <button onClick={handleShowWithdrawMoney} className='btn-danger threeBtnCustomMargin'>{t('accounts.withdraw_funds')}</button>
                            <div className='pe-3'></div>
                                <button type="button" onClick={() => {
                                    if (typeof cardDetails[activeCard].maskCardNumber !== 'undefined' && cardDetails[activeCard].maskCardNumber) {
                                        toggleMask();
                                    } else {
                                        onClickVerifyModal();
                                        setSelectedType('showDetails');
                                    }
                                }} className='btn-secondary'>
                                {(typeof cardDetails[activeCard].maskCardNumber != 'undefined' && cardDetails[activeCard].maskCardNumber) ? t('cards.hide_Details') : t('cards.show_details')}
                                <img src={showEye} style={{ width: 20, objectFit: 'contain', marginLeft: 5 }} />
                            </button>
                        </div>
                    </div>
                    <SingleCardTabs activeTab={activeTab} setActiveTab={setActiveTab} fetchCardSpendingFunc={fetchCardSpendings} clickedSpendingTab={clickedSpendingTab} spendings={(activeCard !== null && spendings !== null) ? spendings : []} details={(activeCard !== null) ? cardDetails[activeCard] : {}} handleCardControl={handleCardControl} />

                    <Modal className='TaxcodeModalOne' show={show} onHide={handleClose} style={{ paddingRight: 0 }}>
                        <form onSubmit={handleCardLimitSubmit(handleCardLimit)}>
                            <Modal.Body>
                                <div className='d-flex justify-content-between align-items-start green-header p-2'>
                                    <div className='col-9'>
                                        <p className='text-green modal-title' style={{ fontSize: 20 }}>
                                            {t('cards.change_card_limit')}
                                        </p>
                                    </div>
                                    <div onClick={() => { handleClose(); resetField('limit'); }} className='clo-2' style={{ cursor: 'pointer' }}>
                                        <img src={ImagesCross} style={{ width: 20, objectFit: 'contain' }} />
                                    </div>
                                </div>
                                <p className='text-grey'>{t('cards.change_card_limit_desc')}</p>
                                <div className='border-grey p-3' style={{ borderRadius: 15 }}>
                                    <p className='modal-header-text' style={{ fontSize: 16, fontWeight: 600 }}>
                                        {t('cards.current_limit')}
                                    </p>
                                    <p className='text-grey modal-text-limit m-0' style={{ fontSize: 14 }}><span className='text-black' style={{ fontSize: 20 }}>{formatQAR(cardDetails[activeCard].limit)} </span>QAR</p>
                                </div>
                                <div className='border-grey pt-3 pb-2 px-4 mt-4' style={{ borderRadius: 15 }}>
                                    <Slider
                                        aria-label="Inter"
                                        defaultValue={cardDetails[activeCard]?.limit}
                                        getAriaValueText={valuetext}
                                        value={limit}
                                        valueLabelDisplay="auto"
                                        step={50}
                                        marks
                                        min={wholePart}
                                        max={wholePart + 10000}
                                        onChange={handleSliderChange}
                                    />
                                </div>
                                <div className="form-outline my-3 mb-4">
                                    <label className="form-label" for="form3Example3">{t('teams.newLimit')}</label>
                                    <Controller
                                        name="limit"
                                        control={controlCardLimitForm}
                                        render={({ field }) => (
                                            <input {...field} type="text" id="form3Example3" className="form-control form-control-lg"
                                                min={wholePart} max={10000} placeholder={`${t('cards.enter_amount')}`} autoComplete='false' value={formatQAR(limit || 0)} />
                                        )}
                                    />
                                    <p className="text-danger">{errorsCardLimitForm?.limit?.message}</p>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button type="button" onClick={() => { handleClose(); resetField('limit'); }} className='btn-secondary-small mt-2' >{t('cancel')}</button>
                                <button type="submit" className='btn-primary-small mt-2' >{t('save')}</button>
                            </Modal.Footer>
                        </form>
                    </Modal>
                    <Modal className='TaxcodeModalOne' show={showAddMoney} onHide={handleCloseAddMoney} style={{ paddingRight: 0 }}>
                        <form onSubmit={handleAddMoneySubmit(onClickVerifyModal)}>
                            <Modal.Body>
                                <div className='d-flex justify-content-between align-items-start green-header p-2'>
                                    <div className='col-9'>
                                        <p className='text-green modal-title' style={{ fontSize: 20 }}>
                                            {t('accounts.add_funds')}
                                        </p>

                                    </div>
                                    <div onClick={handleCloseAddMoney} className='clo-2' style={{ cursor: 'pointer' }}>
                                        <img src={ImagesCross} style={{ width: 20, objectFit: 'contain' }} />
                                    </div>
                                </div>
                                <p className='text-grey' style={{ fontWeight: 400 }}>{t('cards.add_money_desc')}</p>
                                <div className="form-outline my-3">
                                    {requiredFieldLabel(t('teams.amount'))}
                                    {/* <label className="form-label" for="form3Example3">{t('teams.amount')}</label> */}
                                    <Controller
                                        name="amount"
                                        control={controlAddMoneyForm}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <input {...field} type="text" id="form3Example3" className="form-control form-control-lg"
                                                placeholder={`${t('cards.enter_amount')}`} autoComplete='false' onInput={(e) => {
                                                    const value = e.target.value;
                                                    if (!/^\d*$/.test(value)) {
                                                        e.target.value = value.slice(0, -1);
                                                    }
                                                }} />
                                        )}
                                    />
                                    <p className="text-danger">{errorsAddMoneyForm?.amount?.message}</p>
                                </div>
                                <div className="form-outline my-3 mb-4">
                                    {requiredFieldLabel(t('cards.comments'))}
                                    {/* <label className="form-label" for="form3Example3">{t('cards.comments')}</label> */}
                                    <Controller
                                        name="comments"
                                        control={controlAddMoneyForm}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <textarea {...field} id="exampleFormControlTextarea1" rows="5" className="form-control"
                                                placeholder={`${t('cards.enter_a_description')}`} autoComplete='false' />
                                        )}
                                    />
                                    <p className="text-danger">{errorsAddMoneyForm?.comments?.message}</p>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className='px-0 pt-3'>
                                <button onClick={handleCloseAddMoney} className='btn-secondary-small mt-2' >{t('cancel')}</button>
                                <button type="submit" className='btn-primary-small mt-2' >{t('save')}</button>
                            </Modal.Footer>
                        </form>
                    </Modal>
                    <Modal className='TaxcodeModalOne' show={showWithdrawMoney} onHide={handleCloseWithdrawMoney} style={{ paddingRight: 0 }}>
                        <form onSubmit={handleWithdrawMoneySubmit(onClickVerifyModal)}>
                            <Modal.Body>
                                <div className='d-flex justify-content-between align-items-start green-header p-2'>
                                    <div className='col-9'>
                                        <p className='text-black modal-title' style={{ fontSize: 20 }}>
                                            {t('accounts.withdraw_funds')}
                                        </p>
                                    </div>
                                    <div onClick={handleCloseWithdrawMoney} className='clo-2' style={{ cursor: 'pointer' }}>
                                        <img src={ImagesCross} style={{ width: 20, objectFit: 'contain' }} />
                                    </div>
                                </div>
                                <p className='text-grey' style={{ fontWeight: 400 }}>{t('cards.withdraw_money_desc')}</p>
                                <div className="form-outline my-3">
                                    {requiredFieldLabel(t('teams.amount'))}
                                    {/* <label className="form-label" for="form3Example3">{t('teams.amount')}</label> */}
                                    <Controller
                                        name="amount"
                                        control={controlWithdrawMoneyForm}
                                        render={({ field }) => (
                                            <input {...field} type="text" id="form3Example3" className="form-control form-control-lg"
                                                placeholder={`${t('cards.enter_amount')}`} autoComplete='false' onInput={(e) => {
                                                    const value = e.target.value;
                                                    if (!/^\d*$/.test(value)) {
                                                        e.target.value = value.slice(0, -1);
                                                    }
                                                }} />
                                        )}
                                    />
                                    <p className="text-danger">{errorsWithdrawMoneyForm?.amount?.message}</p>
                                </div>
                                <div className="form-outline my-3 mb-4">
                                    {requiredFieldLabel(t('cards.comments'))}
                                    {/* <label className="form-label" for="form3Example3">{t('cards.comments')}</label> */}
                                    <Controller
                                        name="comments"
                                        control={controlWithdrawMoneyForm}
                                        render={({ field }) => (
                                            <textarea {...field} id="exampleFormControlTextarea1" rows="5" className="form-control"
                                                placeholder={`${t('cards.enter_a_description')}`} autoComplete='false' />
                                        )}
                                    />
                                    <p className="text-danger">{errorsWithdrawMoneyForm?.comments?.message}</p>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className='px-0 pt-3'>
                                <button onClick={handleCloseWithdrawMoney} className='btn-secondary-small mt-2' >{t('cancel')}</button>
                                <button type="submit" className='btn-primary-small mt-2' >{t('save')}</button>
                            </Modal.Footer>
                        </form>
                    </Modal>
                    <Modal
                    aria-labelledby="contained-modal-title-center"
                    centered
                    show={showVerifyModal}
                    onHide={handleCloseVerifyModal}
                >

                    <form className='form'
                        style={{ padding: 24 }}
                        onSubmit={handleOtpSubmit(selectedType ==='addFunds' ?  handleAddMoney : selectedType === 'withdrawFunds' ? handleWithdrawMoney : selectedType === 'showDetails' ? toggleMask : handleCardFreezUnfreezAction)}
                    >
                        <div className='d-flex align-items-start justify-content-between'>
                            <img src={Logo2} className="logoso mt-1 mb-4" alt="Sample image" />
                            <div onClick={handleCloseVerifyModal}>
                                <img src={ImagesCross} className='small-addjust' style={{ width: 20, objectFit: 'contain', marginLeft: 15 }} />
                            </div>
                        </div>
                        <h1 className='text-blue-login mb-2'>
                            {t('login.verify_your_mobile_number')}
                        </h1>
                        <div className="divider d-flex align-items-center my-2">
                            <p className="mb-0 text-subtitle2" style={{ color: '#8B9299' }} >
                                {t('login.verify_your_mobile_number_desc')}
                            </p>
                        </div>

                        <div className="otp form-outline d-flex justify-content-center mt-4 mb-3">
                            <Controller
                                name="otp"
                                control={controlVerifyModal}
                                defaultValue={0}
                                render={({ field: { onChange, value, ref }, }) => (
                                    <OtpInput
                                        inputStyle="inputStyle"
                                        value={value}
                                        onChange={onChange}
                                        numInputs={6}
                                        shouldAutoFocus={true}
                                        renderInput={(props) => <input {...props} onInput={(e) => {
                                            const value = e.target.value;
                                            if (!/^\d*$/.test(value)) {
                                                e.target.value = value.slice(0, -1);
                                            }
                                        }}/>}
                                    />
                                )}
                            />
                        </div>
                        <p className="text-danger">{errorsOtpForm?.otp?.message}</p>
                        <div className="d-flex justify-content-end">
                            {timer === 0 ? (
                                <a href="javascript:void(0)" >Resend OTP</a>
                            ) : (
                                <p>Resend OTP in <span className='text-blue'>{timer} seconds</span> </p>
                            )}
                        </div>
                        <div className="text-center text-lg-start mt-4 w-100">
                            <button type="submit" className="btn btn-primary btn-lg btn-block w-100" style={{ position: 'relative', zIndex: '1' }}>{selectedType ===  'addFunds' ? 'Continue to Add Funds' : selectedType ===  'withdrawFunds' ?  'Continue to Withdraw Funds' : selectedType ===  'showDetails' ? 'Continue to Show Details' : 'Continue to Freeze Card'} </button>
                        </div>

                    </form>

                </Modal>
                </div>
            }
        </>
    );
}

export default SingleCardDetails;
