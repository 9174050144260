import React, { useEffect } from 'react';
import DonutChart from '../../components/DonutChart/DonutChart';
import Graph from '../../components/Ghaph/Graph';
import { useTranslation } from 'react-i18next';
import DashboardSpends from '../../components/Dashboard/DashboardSpends';
import DashboardCards from '../../components/Dashboard/DashboardCards';
import { dashboardCardIssueCount, dashboardFetchMostSpend, dashboardFetchTransactionByVendors, dashboardFetchTransactionByVolume, dashboardSpendPerCategory, dashboardTotalUsersCount, dashboardTransactionCount, dashboardReimbursmentTransaction } from './dashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import DashboardTopSpends from '../../components/Dashboard/DashboardTopSpends';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import ImagesCross from '../../assets/images/close-square-8.png';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Calender from '../../assets/images/solar_calendar-broken-1.png';
import './index.scss';
import DroupdownArrow from '../../assets/images/dropdownDownArrow.png';
import cookies from 'js-cookie';
import { createAuditTrail } from '../TopUpRequest/slice/topUpSlice';
import CustomDropdown from '../../components/CustomDropdown/CustomDropdown';

const moment = require('moment');
const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
];

function Dashboard() {
  const currentLanguageCode = cookies.get('i18next') || 'en';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const [isActive, setIsActive] = useState(false);
  const { t } = useTranslation();
  const options = [
    { value: 'current_month', label: t('dashboard.current_month') },
    { value: 'current_year', label: t('dashboard.current_year') },
    { value: 'last_3_year', label: t('dashboard.last_3_years') },
    { value: 'custom', label: t('dashboard.custom') },
  ];
  const [selectedValue, setSelectedValue] = useState(options[0].value);
  const selectedLabel = options.find(option => option.value === selectedValue)?.label;
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [globalSelectedFil, setGlobalSelectedFil] = useState(null);
  const filters = [t('sidebar.expenses'), t('sidebar.reimbursements')];
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleClose = () => setShow(false);
  const [selectedFilterDounut, setSelectedFilterDounut] = useState(null);
  const [selectedFilterGraph, setSelectedFilterGraph] = useState(null);
  const [selectedFilterCards, setSelectedFilterCards] = useState(null);
  const [selectedFilterSpends, setSelectedFilterSpends] = useState(null);
  const [selectedFilterSpendsOne, setSelectedFilterSpendsOne] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState('current_month');
  const [customDate, setCustomDate] = useState({});

  const schema = yup.object().shape({
    startDate: yup
      .string()
      .required('Start Date is required'),
    endDate: yup
      .string()
      .required('End Date is required')
      .test('is-valid-end-date', 'End date should not be before start date', function (value) {
        const startDate = this.resolve(yup.ref('startDate'));
        return !startDate || !value || value >= startDate;
      }),
  });

  const { control, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const handleCloseMenu = (option) => {
    setSelectedOptions(option.value);
    if (option.value != '' && typeof option.value === 'string') {
      if (option.value === 'custom') {
        reset();
        setShow(true);
      } else {
        handleFilterClick('all', option.value);
      }
    }
  };

  const { transactionByVendorsLoading, transactionByVendors, spendPerCategoryLoading, spendPerCategory, cardIssueCountLoading, cardIssueCount, totalUsersCountLoading, totalUsersCount, totalTransactionLoading, totalTransaction, totalTransactionCountLoading, totalTransactionCount, fetchTransactionByVolumeLoading, fetchTransactionByVolume, mostSpendPercategory, mostSpendPerCategoryLoading, approvedReimbursmentTransaction, approvedReimbursmentTransactionLoading, pendingReimbursmentTransactionLoading, pendingReimbursmentTransaction, rejectedReimbursmentTransactionLoading, rejectedReimbursmentTransaction, settledReimbursmentTransactionLoading, settledReimbursmentTransaction } = useSelector(state => state.dashboardReducer);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr';
    document.title = t('app_title');
  }, [currentLanguage, t]);

  useEffect(() => {
    dispatch(createAuditTrail({ eventType: 'access', module: 'dashboard', description: `Featched all dashboard data.` }))
    const dashboardType = selectedIndex === 0 ? 'expense' : 'reimbursement';
    dispatch(dashboardFetchTransactionByVendors({ startDate: moment().clone().startOf('month').format('YYYY-MM-DD'), endDate: moment().clone().endOf('month').format('YYYY-MM-DD'), type: dashboardType }));
    dispatch(dashboardSpendPerCategory({ startDate: moment().clone().startOf('month').format('YYYY-MM-DD'), endDate: moment().clone().endOf('month').format('YYYY-MM-DD'), type: dashboardType }));
    dispatch(dashboardFetchMostSpend({ startDate: moment().clone().startOf('month').format('YYYY-MM-DD'), endDate: moment().clone().endOf('month').format('YYYY-MM-DD'), filter: 'employee', type: dashboardType }));
    dispatch(dashboardCardIssueCount({ startDate: moment().clone().startOf('month').format('YYYY-MM-DD'), endDate: moment().clone().endOf('month').format('YYYY-MM-DD') }));
    dispatch(dashboardTotalUsersCount({ startDate: moment().clone().startOf('month').format('YYYY-MM-DD'), endDate: moment().clone().endOf('month').format('YYYY-MM-DD') }));
    dispatch(dashboardTransactionCount({ startDate: moment().clone().startOf('month').format('YYYY-MM-DD'), endDate: moment().clone().endOf('month').format('YYYY-MM-DD'), type: dashboardType }));
    dispatch(dashboardReimbursmentTransaction({ startDate: moment().clone().startOf('month').format('YYYY-MM-DD'), endDate: moment().clone().endOf('month').format('YYYY-MM-DD'), type: 'approved' }));
    dispatch(dashboardReimbursmentTransaction({ startDate: moment().clone().startOf('month').format('YYYY-MM-DD'), endDate: moment().clone().endOf('month').format('YYYY-MM-DD'), type: 'pending' }));
    dispatch(dashboardReimbursmentTransaction({ startDate: moment().clone().startOf('month').format('YYYY-MM-DD'), endDate: moment().clone().endOf('month').format('YYYY-MM-DD'), type: 'rejected' }));
    dispatch(dashboardReimbursmentTransaction({ startDate: moment().clone().startOf('month').format('YYYY-MM-DD'), endDate: moment().clone().endOf('month').format('YYYY-MM-DD'), type: 'settled' }));
    dispatch(dashboardFetchTransactionByVolume({ filterType: 'current_month', type: dashboardType }));
  }, []);

  useEffect(() => {
    if (selectedValue === "custom") {
      handleFilterClick('all', 'custom', customDate);
    } else {
      handleFilterClick('all', selectedValue);
    }
  }, [selectedIndex]);

  const handleFilterClick = (section, type, data = {}, childPara = false) => {
    if (!childPara) {
      setSelectedFilterDounut(null);
      setSelectedFilterGraph(null);
      setSelectedFilterSpends(null);
      setSelectedFilterCards(null);
      setSelectedFilterSpendsOne(null);
      if (type === 'current_month') {
        setGlobalSelectedFil('Current Month');
      }
      if (type === 'current_year') {
        setGlobalSelectedFil('Current Year');
      }
      if (type === 'last_3_year') {
        setGlobalSelectedFil('Last 3 Years');
      }
    }
    if (type != '' && section != '') {
      let startDate = moment().clone().startOf('month').format('YYYY-MM-DD');
      let endDate = moment().clone().endOf('month').format('YYYY-MM-DD');

      if (type === 'current_year') {
        startDate = moment().clone().startOf('year').format('YYYY-MM-DD');
        endDate = moment().clone().endOf('year').format('YYYY-MM-DD');
      }

      if (type === 'last_3_year') {
        startDate = moment().clone().subtract(2, 'years').startOf('year').format('YYYY-MM-DD');
        endDate = moment().clone().endOf('year').format('YYYY-MM-DD');
      }

      if (type === 'custom') {
        startDate = data?.startDate;
        endDate = data?.endDate;
      }
      if (section === 'transaction_by_vendor' || section === 'all') {
        dispatch(dashboardFetchTransactionByVendors({ startDate, endDate, type: selectedIndex === 0 ? 'expense' : 'reimbursement' }));
      }

      if (section === 'transaction_by_volume' || section === 'all') {
        dispatch(dashboardFetchTransactionByVolume({ filterType: type, startDate, endDate, type: selectedIndex === 0 ? 'expense' : 'reimbursement' })).unwrap().then(() => {
        }).catch((error) => {
          toast.error(error.message || "Request Failed Please Try Again ");
        });
      }

      if (section === 'card_issue' || section === 'all') {
        dispatch(dashboardCardIssueCount({ startDate, endDate }));
      }

      if (section === 'total_users' || section === 'all') {
        dispatch(dashboardTotalUsersCount({ startDate, endDate }));
      }

      if (section === 'approved_user' || section === 'all') {
        dispatch(dashboardReimbursmentTransaction({ startDate, endDate, type: 'approved' }));
      }

      if (section === 'pending_user' || section === 'all') {
        dispatch(dashboardReimbursmentTransaction({ startDate, endDate, type: 'pending' }));
      }

      if (section === 'rejected_user' || section === 'all') {
        dispatch(dashboardReimbursmentTransaction({ startDate, endDate, type: 'rejected' }));
      }

      if (section === 'settled_user' || section === 'all') {
        dispatch(dashboardReimbursmentTransaction({ startDate, endDate, type: 'settled' }));
      }

      if (section === 'no_of_transactions' || section === 'all') {
        dispatch(dashboardTransactionCount({ startDate, endDate, type: selectedIndex === 0 ? 'expense' : 'reimbursement' }));
      }

      if (section === 'spend_per_category' || section === 'all') {
        dispatch(dashboardSpendPerCategory({ startDate, endDate, type: selectedIndex === 0 ? 'expense' : 'reimbursement' }));
      }

      if (section === 'most_spend_employee' || section === 'all') {
        dispatch(dashboardFetchMostSpend({ startDate, endDate, filter: 'employee', type: selectedIndex === 0 ? 'expense' : 'reimbursement' }));
      }
      if (section === 'most_spend_department') {
        dispatch(dashboardFetchMostSpend({ startDate, endDate, filter: 'department', type: selectedIndex === 0 ? 'expense' : 'reimbursement' }));
      }

    } else {
      toast.error('Require Payload not found for filter');
    }
  };

  const onSubmit = (data) => {
    setCustomDate(data);
    setGlobalSelectedFil(`${moment(data?.startDate).format('DD/MM/YYYY')} - ${moment(data?.endDate).format('DD/MM/YYYY')}`);
    handleFilterClick('all', 'custom', data);
    setShow(false);
  };

  const resetState = () => {
    setSelectedOptions(null);
    setCustomDate(null);
  };

  return (
    <div className={`dashboard-css ${currentLanguageCode === 'en' ? 'white-container' : 'white-container-arabic'}`}>
      <div className='welcomeTextLine'>
        <h1 className='welcomeNameTextCss'>{t('dashboard.welcome_back')}{localStorage.getItem('firstName').charAt(0).toUpperCase() + localStorage.getItem('firstName').slice(1)}</h1>
        <div className='dropdownLineCss'>
          <div className='PeriodLineCss'>
            <img src={Calender} className='ela' style={{ width: 32, objectFit: 'contain' }} />
            <p className='periodTextCss'>{t('dashboard.period')}</p>
          </div>
          <div style={{width: '300px'}}>
            <CustomDropdown 
              options={options} 
              onChange={handleCloseMenu} 
              selectedOption={t('dashboard.current_month')} 
              placeholder={t('dashboard.current_month')}
              backgroundColor={'#F3F3F3'} />
          </div>
          {/*<div className='dashboard-custom-dropdown'>
            <div className="dropdown">
              <div
                onClick={() => {
                  setIsActive(!isActive);
                }}
                className={`dropdown-btn ${isActive ? 'active' : ''}`}
              >
                {selectedLabel}
                <img
                  src={DroupdownArrow}
                  width={'12.81px'}
                  height={'6.41px'}
                  style={{ objectFit: 'contain', transform: isActive ? 'rotate(180deg)' : 'rotate(0)' }}
                />
              </div>
              <div
                className="dropdown-content"
                style={{ display: isActive ? "block" : "none" }}
              >
                {options.map((option, index) => (
                  <div
                    key={index}
                    className="item"
                    onClick={() => {
                      setSelectedValue(option.value);
                      setIsActive(false);
                      handleCloseMenu(option.value);
                    }}
                  >
                    {option.label}
                  </div>
                ))}
              </div>
            </div>
          </div>*/}
        </div>
      </div>
      <div className='row justify-content-start'>
        <div className='col-lg-4 col-md-12 mb-4'>
          <div style={{ backgroundColor: '#FFFFFF', padding: 4, borderRadius: 15, border: '1px solid #01DD9D', borderWidth: 1, marginTop: window.innerWidth < 700 ? 15 : 0 }} className='d-flex align-items-center justify-content-between green-radius'>
            {
              filters?.map((item, index) => (
                <div key={index} onClick={() => setSelectedIndex(index)} style={{ backgroundColor: selectedIndex === index ? '#01DD9D' : 'transparent', color: selectedIndex === index ? '#FFFFFF' : '#667085', width: '49%', textAlign: 'center', paddingTop: 9, paddingBottom: 9, borderRadius: 12, cursor: 'pointer' }}>
                  {item}
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <div className={`row ${currentLanguageCode === 'en' ? '' : ''}`}>
        {/* Donut Chart Start */}
        <div className={`col-lg-5 col-md-12 ${currentLanguageCode === 'en' ? '' : ''}`}>
          <div className='cards-custom border-grey marginbottoncustomDashboard'>
            <DonutChart globalSelectedFil={globalSelectedFil} setSelectedFilterDounut={setSelectedFilterDounut} selectedFilterDounut={selectedFilterDounut} handleFilterClick={handleFilterClick} section='transaction_by_vendor' transactionByVendorsLoading={transactionByVendorsLoading} transactionByVendors={transactionByVendors} />
          </div>
        </div>
        {/* Donut Chart end */}
        <div className="col-lg-7 col-md-12">
          <div className='marginbottoncustomDashboard'>
            <Graph section='transaction_by_volume' setSelectedFilterGraph={setSelectedFilterGraph} selectedFilterGraph={selectedFilterGraph} globalSelectedFil={globalSelectedFil} handleFilterClick={handleFilterClick} fetchTransactionByVolumeLoading={fetchTransactionByVolumeLoading} fetchTransactionByVolume={fetchTransactionByVolume} />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-12 col-md-12'>
          <div className='customMarginforWeb'>
            <DashboardCards setSelectedFilterCards={setSelectedFilterCards} selectedFilterCards={selectedFilterCards} globalSelectedFil={globalSelectedFil} handleFilterClick={handleFilterClick} cardIssueCountLoading={cardIssueCountLoading} cardIssueCount={cardIssueCount} totalUsersCountLoading={totalUsersCountLoading} totalUsersCount={totalUsersCount} totalTransactionLoading={totalTransactionLoading} totalTransaction={totalTransaction} totalTransactionCountLoading={totalTransactionCountLoading} totalTransactionCount={totalTransactionCount} selectedIndex={selectedIndex} approvedReimbursmentTransaction={approvedReimbursmentTransaction} approvedReimbursmentTransactionLoading={approvedReimbursmentTransactionLoading} pendingReimbursmentTransaction={pendingReimbursmentTransaction} pendingReimbursmentTransactionLoading={pendingReimbursmentTransactionLoading} rejectedReimbursmentTransactionLoading={rejectedReimbursmentTransactionLoading} rejectedReimbursmentTransaction={rejectedReimbursmentTransaction} settledReimbursmentTransactionLoading={settledReimbursmentTransactionLoading} settledReimbursmentTransaction={settledReimbursmentTransaction} />
          </div>
        </div>
      </div>
      <div className={`row customMarginforWeb ${currentLanguageCode === 'en' ? '' : ''}`}>
        <div className={`col-lg-6 col-md-12  ${currentLanguageCode === 'en' ? '' : ''}`}>
          <div className='cards-custom marginbottoncustomDashboard'>
            <DashboardSpends globalSelectedFil={globalSelectedFil} selectedFilterSpends={selectedFilterSpends} setSelectedFilterSpends={setSelectedFilterSpends} section='spend_per_category' handleFilterClick={handleFilterClick} spendPerCategoryLoading={spendPerCategoryLoading} spendPerCategory={spendPerCategory} />
          </div>
        </div>
        <div className='col-lg-6 col-md-12'>
          <DashboardTopSpends globalSelectedFil={globalSelectedFil} selectedFilterSpendsOne={selectedFilterSpendsOne} setSelectedFilterSpendsOne={setSelectedFilterSpendsOne} handleFilterClick={handleFilterClick} spendPerCategoryLoading={mostSpendPerCategoryLoading} spendPerCategory={mostSpendPercategory} globalOption={selectedOptions} globalCustomDate={customDate} resetState={resetState} />
        </div>
      </div>
      <Modal className='TaxcodeModalOne' show={show} onHide={handleClose} style={{ paddingRight: 0 }}>
        <form className='form' onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className='d-flex justify-content-between align-items-start green-header p-2'>
              <div className='col-9'>
                <p className='text-green modal-title' style={{ fontSize: 20 }}>
                  Select Custom Date
                </p>
              </div>
              <div onClick={handleClose} className='clo-2' style={{ cursor: 'pointer' }}>
                <img src={ImagesCross} style={{ width: 20, objectFit: 'contain' }} />
              </div>
            </div>
            <div className="form-outline my-3">
              <label className="form-label" htmlFor="startDate">
                Start Date
              </label>

              <Controller
                name="startDate"
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <input
                    type="date"
                    id="startDate"
                    className="form-control form-control-lg"
                    placeholder="Start Date"
                    autoComplete="off"
                    {...field}
                  />
                )}
              />
              {errors?.startDate && (
                <div style={{ color: 'red' }}>{errors?.startDate?.message}</div>
              )}
            </div>
            <div className="form-outline my-3">
              <label className="form-label" htmlFor="endDate">
                End Date
              </label>

              <Controller
                name="endDate"
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <input
                    type="date"
                    id="endDate"
                    className="form-control form-control-lg"
                    placeholder="Start Date"
                    autoComplete="off"
                    {...field}
                  />
                )}
              />
              {errors?.endDate && (
                <div style={{ color: 'red' }}>{errors?.endDate?.message}</div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={handleClose} className='btn-secondary-small' >{t('cancel')}</button>
            <button className='btn-primary-small' type='submit'>{t('submit')}</button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default Dashboard;
