import "./index.scss";

import React, { useEffect } from "react";
import {
  getOddoEmployeeList,
  updateEmployee,
} from "../../pages/Settings/taxSlice";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../Loader/Loader";
import cookies from "js-cookie";
import { getDepartmentEmployee } from "../../pages/Teams/teamSlice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function Employee(props) {
  const { connectedIntegration } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isLoading, oddoEmployeeList } = useSelector(
    (state) => state?.taxSlice
  );
  const { departmentEmployees, } = useSelector((state) => state?.teamSlice);

  useEffect(() => {
    if (connectedIntegration?.name) {
      dispatch(getOddoEmployeeList());
      dispatch(getDepartmentEmployee({ departmentId: null, para: null }));
    }
  }, [connectedIntegration]);



  const handelEmployeeMapping = (e, employeeId) => {
    const { value } = e.target;
    const payload = {
      oddoEmployeeId: value || "",
      employeeId,
    };
    dispatch(updateEmployee(payload))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        dispatch(getDepartmentEmployee({ departmentId: null, para: null }));
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "ar",
      name: "العربية",
      dir: "rtl",
      country_code: "sa",
    },
  ];

  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);




  return (
    <div className="taxcodes">
      {isLoading && <Loader />}
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ marginBottom: 24 }}
      >
        <h2 className="card-title-black-text ">
          {t("settingstab.list_of_employees")}
        </h2>

      </div>
      <div>
        {departmentEmployees?.data.length > 0 && (
          <>
            <div className="table-responsive p-3 pb-0">
              <table
                id="dtBasicExample"
                style={{ borderCollapse: "separate", borderSpacing: "0 16px" }}
                cellSpacing="0"
                width="100%"
              >
                <thead>
                  <tr className="table-head">
                    <th scope="col">{t("name")}</th>

                    <th scope="col">{t("settingstab.mapping")}</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {departmentEmployees?.data.map((department) => (
                    <tr
                      className={`${currentLanguageCode === "en"
                        ? "table-body-tr"
                        : "table-body-tr-arabic"
                        }`}
                      key={department?.employeeId}
                    >
                      <td>{department?.firstName}</td>
                      <td>
                        <select className="form-select form-control-lg"
                          onChange={(e) => { handelEmployeeMapping(e, department?.employeeId) }}
                          value={department?.oddoEmployeeId}
                        >
                          <option value="">Select account</option>
                          {oddoEmployeeList?.map((employee) => (
                            <option
                              key={employee?.id}
                              value={employee?.id}
                            >
                              {employee?.name}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                  ))}

                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Employee;
