import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ExpensesComponent from '../../components/Expenses/ExpensesComponent';
import MytrascationTabs from '../../components/Expenses/MytrascationTabs';
import ForYourReviewTabs from '../../components/Expenses/ForYourReviewTabs';
import Loader from '../../components/Loader/Loader';
import {
  getAllCorporateCardsExpenses,
  getExpenseTransactionList,
  getExpenseReviewList,
} from './expensesSlice';
import './expense.scss';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { decodeToken } from 'react-jwt';
import cookies from 'js-cookie';
import useDidUpdateEffect from '../../utils/useDidUpdateHook';
// import { useLocation } from 'react-router-dom';
import notFound from '../../assets/images/not_found.png';

function CorporateExpenses() {
  // const { state } = useLocation();
  const token = localStorage.getItem('token');
  // const [showFilter, setShowFilter] = useState(false);
  // const [show, setShow] = useState(false);
  const [checkedAllPages, setChekedAllPages] = useState(false);
  const [pseudoCheckedAll, setPseudoCheckedAll] = useState(false);
  const [uncheckedTransaction, setUncheckedTransaction] = useState([]);
  // const [cardTransactionId, setCardTransactionId] = useState(null);
  const {
    isLoading,
    expenseAllCorporateCardList,
    currencies,
    // filters,
    expenseCardCorporateList,
  } = useSelector((state) => state.expenseReducer);

  const { isLoading: exportLoading } = useSelector(
    (state) => state.exportReducer
  );
  const cardReducer = useSelector((state) => state.cardListReducer);
  // const { departmentData } = useSelector((state) => state?.teamSlice);
  const dispatch = useDispatch();
  // const { employees } = useSelector((state) => state?.cardListReducer);
  const decodedToken = decodeToken(token);
  const { t } = useTranslation();

  // const handleClose = () => setShow(false);
  const handleShow = (expenseCorporateCardsId) => { 
    // setShow(true);
    // setCardTransactionId(expenseCorporateCardsId);
    if (expenseCorporateCardsId) {
  
      dispatch(getAllCorporateCardsExpenses(`?expenseCorporateCardsId=${expenseCorporateCardsId}`));
      //   dispatch(getAllTagByGroup());
    }
  };
  const [filterType, setFilterType] = useState('');
  const [activeTab, setActiveTab] = useState('1');
  // const [defaultActiveKey, setDefaultActiveKey] = useState(
  //   state?.parentTab ? state?.parentTab : '1'
  // );
  const [pageNo, setPageNo] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);  
  // const handleCloseModalFilter = () => setShowFilter(false);

  const [timer, setTimer] = useState(60);
  const [searchQuery, setSearchQuery] = useState('');




  const filePermission = useMemo(() => {
    return (
      decodedToken?.isAccountant ||
      decodedToken?.isAdmin ||
      decodedToken?.isGlobalReviewer ||
      decodedToken?.reviewer?.length > 0 ||
      (activeTab && activeTab === '2')
    );
  }, [decodedToken, activeTab]);

  // const hasAccess = useMemo(() => {
  //   return (
  //     decodedToken?.isAccountant ||
  //     decodedToken?.isAdmin ||
  //     decodedToken?.isGlobalReviewer ||
  //     decodedToken?.reviewer?.length > 0
  //   );
  // }, [decodedToken]);

  useEffect(() => {
    let queryParams = `?page=${pageNo}&`;

    if (activeTab === '1') {
      dispatch(getAllCorporateCardsExpenses(queryParams))
        .unwrap()
        .then((res) => {
          if (pseudoCheckedAll) {
            if (res.data.rows.length > 0) {
              const selected = [...selectedItems];
              for (const row of res.data.rows) {
                for (const data of row.records) {
                  if (
                    !uncheckedTransaction?.includes(data?.cardTransactionId) &&
                    !selected.some(
                      (element) =>
                        element?.transactionId == data?.cardTransactionId
                    )
                  )
                    selected.push({
                      transactionId: data?.cardTransactionId,
                      transactionType: data?.type
                        ? data?.type
                        : 'cardTransaction',
                    });
                }
              }
              setSelectedItems(selected);
            }
          }
        });
    }
  }, []);

  // Fetch Avalia bleBalance when 'filterType' changes
  useDidUpdateEffect(() => {
    // Fetch Avalia bleBalance when 'filterType' changes
    let queryParams = `?page=${pageNo}&`;

    //  queryParams = queryParams.slice(0, -1);
    if (activeTab === '1') {
      dispatch(getAllCorporateCardsExpenses(queryParams))
        .unwrap()
        .then((res) => {
          if (pseudoCheckedAll && res.data.rows.length > 0) {
            const selected = [...selectedItems];
            res.data.rows.forEach((row) => {
              row.records.forEach((data) => {
                if (
                  !uncheckedTransaction?.includes(data.cardTransactionId) &&
                  !selected.some(
                    (element) =>
                      element.transactionId === data.cardTransactionId
                  )
                ) {
                  selected.push({
                    transactionId: data.cardTransactionId,
                    transactionType: data.type ? data.type : 'cardTransaction',
                  });
                }
              });
            });
            setSelectedItems(selected);
          }
        });
    } else if (activeTab === '2') {
      dispatch(
        getExpenseTransactionList(`?page=${pageNo}&filterType=${filterType}`)
      )
        .unwrap()
        .then((res) => {
          if (pseudoCheckedAll && res?.data?.rows?.length > 0) {
            const selected = [...selectedItems];
            res.data.rows.forEach((row) => {
              row.records.forEach((data) => {
                if (
                  !uncheckedTransaction?.includes(data.cardTransactionId) &&
                  !selected.some(
                    (element) =>
                      element.transactionId === data.cardTransactionId
                  )
                ) {
                  selected.push({
                    transactionId: data.cardTransactionId,
                    transactionType: data.type ? data.type : 'cardTransaction',
                  });
                }
              });
            });
            setSelectedItems(selected);
          }
        });
    } else {
      dispatch(getExpenseReviewList(`?page=${pageNo}&filterType=${filterType}`))
        .unwrap()
        .then((res) => {
          if (pseudoCheckedAll) {
            if (res?.data?.rows?.length > 0) {
              const selected = [...selectedItems];
              for (const row of res.data.rows) {
                for (const data of row.records) {
                  if (
                    !uncheckedTransaction?.includes(data?.cardTransactionId) &&
                    !selected.some(
                      (element) =>
                        element?.transactionId == data?.cardTransactionId
                    )
                  )
                    selected.push({
                      transactionId: data?.cardTransactionId,
                      transactionType: data?.type
                        ? data?.type
                        : 'cardTransaction',
                    });
                }
              }
              setSelectedItems(selected);
            }
          }
        });
    }
  }, [pageNo]);

  const changeTab = (eventKey) => {
    if (eventKey === '1') {
      //resetFilterForm();
      //  dispatch(getAllCorporateCardsExpenses(''));
      setActiveTab('1');
      setFilterType('');
      setPageNo(1);
      setSelectedItems([]);
      setChekedAllPages(false);
      setPseudoCheckedAll(false);
      setUncheckedTransaction([]);
    }
    if (eventKey === '2') {
      setFilterType('all');
      let queryParams = `?filterType=all&`;
      dispatch(getExpenseTransactionList(queryParams));
      setActiveTab('2');
      setPageNo(1);
      setSelectedItems([]);
      setChekedAllPages(false);
      setPseudoCheckedAll(false);
      setUncheckedTransaction([]);
    }
    if (eventKey === '3') {
      setFilterType('pending');
      let queryParams = `?filterType=pending`;
      dispatch(getExpenseReviewList(queryParams));
      setActiveTab('3');
      setPageNo(1);
      setSelectedItems([]);
      setChekedAllPages(false);
      setPseudoCheckedAll(false);
      setUncheckedTransaction([]);
    }
  };

  const changeTabOnTabClick = (eventKey, filterPkey) => {
    setFilterType(filterPkey);
    if (eventKey === '2') {
      let queryParams = `?filterType=${filterPkey}`;
      dispatch(getExpenseTransactionList(queryParams));
      setSelectedItems([]);
      setChekedAllPages(false);
      setPageNo(1);
      setPseudoCheckedAll(false);
      setUncheckedTransaction([]);
    }
    if (eventKey === '3') {
      let queryParams = `?filterType=${filterPkey}`;
      dispatch(getExpenseReviewList(queryParams));
      setSelectedItems([]);
      setChekedAllPages(false);
      setPageNo(1);
      setPseudoCheckedAll(false);
      setUncheckedTransaction([]);
    }
  };
  useDidUpdateEffect(() => {
    const timer = setTimeout(() => {
      if (searchQuery === '') {
        searchListByEmployee();
      } else {
        searchListByEmployee();
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [searchQuery]);

  const searchListByEmployee = () => {
    let queryParams = '?page=1&';
    setPageNo(1);

    if (searchQuery && searchQuery !== '') {
      queryParams += `search=${searchQuery}&`;
    }

    queryParams = queryParams.slice(0, -1);
    if (activeTab === '1') {
      dispatch(getAllCorporateCardsExpenses(queryParams))
        .unwrap()
        .then(() => {
          // handleCloseModalFilter();
        })
        .catch((error) => {
          toast.error(error.message || 'Request Failed Please Try Again ');
        });
    } else if (activeTab === '2') {
      dispatch(getExpenseTransactionList(queryParams))
        .unwrap()
        .catch((error) => {
          toast.error(error.message || 'Request Failed. Please Try Again.');
        });
    } else { dispatch(getExpenseReviewList(queryParams))
      .unwrap()
      .catch((error) => {
        toast.error(error.message || 'Request Failed. Please Try Again.');
      });}
  };

  const languages = [
    {
      code: 'en',
      name: 'English',
      country_code: 'gb',
    },
    {
      code: 'ar',
      name: 'العربية',
      dir: 'rtl',
      country_code: 'sa',
    },
  ];

  const currentLanguageCode = cookies.get('i18next') || 'en';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr';
    document.title = t('app_title');
  }, [currentLanguage, t]);

  const onPreviousClick = () => {
    setPageNo((prev) => prev - 1);
  };

  const onNextClick = () => {
    setPageNo((prev) => prev + 1);
  };


  const handleCheckboxChange = (id, type) => {
  
    const selected = [...selectedItems];
    const unchecked = [...uncheckedTransaction];
    const index = selected.findIndex(
      (item) => item.transactionId == id && item.transactionType == type
    );
    if (index === -1) {
      if (pseudoCheckedAll) {
        const uncheckedIndex = unchecked.findIndex((trans) => trans == id);
        unchecked.splice(uncheckedIndex, 1);
        setUncheckedTransaction(unchecked);
      }
      selected.push({
        transactionId: id,
        transactionType: type ? type : 'corporateCardsExpense',
      });
    } else {
      if (pseudoCheckedAll) {
        unchecked.push(id);
        setUncheckedTransaction(unchecked);
      }
      selected.splice(index, 1);
    }
    if (checkedAllPages) setChekedAllPages(false);
    setSelectedItems(selected);
  };
  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer]);
  return (
    <div
      className={`${
        currentLanguageCode === 'en'
          ? 'white-container expensesPageCss'
          : 'white-container-arabic expensesPageCss'
      }`}
    >
      {(isLoading || (exportLoading && cardReducer?.isLoading)) && <Loader />}
      <h1 className='text-green mb-3'>Corporate Card Expenses</h1>

      <div
        className='settings setting-font mt-4'
        style={{ position: 'relative', zIndex: 1 }}
      >
        <Tabs
          // defaultActiveKey={defaultActiveKey}
          animation='true'
          id='hasAccess'
          onSelect={(eventKey) => changeTab(eventKey)}
          style={{ fontSize: 17 }}
        >
          {(decodedToken?.isAdmin || decodedToken?.isAccountant) && (
            <Tab
              eventKey={1}
              title={`${t('expenses.all')}`}
              className='expenses-tab-pad'
            >
              <div className=' cards-custom p-3 mt-2'>
                <div
                  className={
                    window.innerWidth > 500
                      ? 'd-flex align-items-center justify-content-between'
                      : ''
                  }
                >
                  <div className='col-lg-4 col-md-12'>
                    <div className='form-group has-search'>
                      <span className='fa fa-search form-control-feedback mt-1'></span>
                      <input
                        style={{ height: 48, borderRadius: 40 }}
                        type='text'
                        id='searchEmployee'
                        className='form-control'
                        placeholder={`${t('search_employee')}`}
                        onKeyUp={(event) => setSearchQuery(event.target.value)}
                      />
                    </div>{' '}
                  </div>
                </div>
                {expenseAllCorporateCardList &&
                expenseAllCorporateCardList['rows']?.length > 0 ? (
                  expenseAllCorporateCardList['rows']?.map((item, i) => {
                    return (
                      <div
                        className='export-border table-border p-3 mt-5'
                        key={'listsData' + i}
                      >
                        <h2 className='text-grey'>
                          {moment(item?.date).format('DD MMM YYYY')}
                        </h2>
                        <ExpensesComponent
                          data={item?.records}
                          showExpenseDetailModal={handleShow}
                          parentTab={'1'}
                          currencies={currencies}
                          showCheckBox={true}
                          selectedItems={selectedItems}
                          handleCheckboxChange={handleCheckboxChange}
                          filePermission={filePermission}
                          prevPath={'null'}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                    <img src={notFound} width={200} height={200} />
                  </div>
                )}
                <div
                  className='d-flex align-items-center justify-content-between px-2'
                  style={{ width: '100%', borderTop: '0px !imporant' }}
                >
                  <nav aria-label='Page navigation example'>
                    <ul className='pagination mt-2 pt-1 mt-3 pe-0'>
                      <li className='page-item'>
                        <button
                          className='btn page-link'
                          disabled={pageNo === 1}
                          onClick={() => onPreviousClick()}
                        >
                          {t('previous')}
                        </button>
                      </li>
                      <li className='page-item mx-3'>
                        <button
                          className='btn page-link'
                          disabled={
                            pageNo == expenseAllCorporateCardList?.totalPages ||
                            expenseAllCorporateCardList?.totalPages == 0
                          }
                          onClick={() => onNextClick()}
                        >
                          {t('next')}
                        </button>
                      </li>
                    </ul>
                  </nav>
                  <div
                    className='page-count d-flex align-items-center'
                    style={{ color: '#00D094' }}
                  >
                    <h4>
                      Page{' '}
                      {expenseAllCorporateCardList?.count == 0
                        ? 0
                        : expenseAllCorporateCardList?.currentPage}{' '}
                      of {expenseAllCorporateCardList?.totalPages}
                    </h4>
                  </div>
                </div>
              </div>
            </Tab>
          )}
          <Tab
            eventKey={2}
            title={`${t('expenses.my_transactions')}`}
            className='expenses-tab-pad'
          >
            <div className='inner-tabs mt-4 mb-5'>
              {' '}
              <div className='col-lg-4 col-md-12'>
                <div className='form-group has-search'>
                  <span className='fa fa-search form-control-feedback mt-1'></span>
                  <input
                    style={{ height: 48, borderRadius: 40 }}
                    type='text'
                    id='searchEmployee'
                    className='form-control'
                    placeholder={`${t('search_employee')}`}
                    onKeyUp={(event) => setSearchQuery(event.target.value)}
                  />
                </div>{' '}
              </div>
              <MytrascationTabs
                listData={expenseCardCorporateList['rows']}
                clickOnTabChange={changeTabOnTabClick}
                activeTab={activeTab}
                parentTab={'2'}
                currencies={currencies}
                showExpenseDetailModal={handleShow}
                selectedItems={selectedItems}
                handleCheckboxChange={handleCheckboxChange}
                filePermission={filePermission}
                    prevPath={'null'}
              />
              <div
                className='d-flex align-items-center justify-content-between px-2'
                style={{ width: '100%', borderTop: '0px !imporant' }}
              >
                <nav aria-label='Page navigation example'>
                  <ul className='pagination mt-2 pt-1 mt-3 pe-0'>
                    <li className='page-item'>
                      <button
                        className='btn page-link'
                        disabled={pageNo === 1}
                        onClick={() => onPreviousClick()}
                      >
                        {t('previous')}
                      </button>
                    </li>
                    <li className='page-item mx-3'>
                      <button
                        className='btn page-link'
                        disabled={
                          pageNo == expenseCardCorporateList?.totalPages ||
                          expenseCardCorporateList?.totalPages == 0
                        }
                        onClick={() => onNextClick()}
                      >
                        {t('next')}
                      </button>
                    </li>
                  </ul>
                </nav>
                <div
                  className='page-count d-flex align-items-center'
                  style={{ color: '#00D094' }}
                >
                  <h4>
                    Page{' '}
                    {expenseCardCorporateList?.count == 0
                      ? 0
                      : expenseCardCorporateList?.currentPage}{' '}
                    of {expenseCardCorporateList?.totalPages}
                  </h4>
                </div>
              </div>
            </div>
          </Tab>
          {(decodedToken?.isAdmin ||
            decodedToken?.isAccountant ||
            decodedToken?.isGlobalReviewer ||
            decodedToken?.reviewer?.length > 0) && (
            <Tab
              eventKey={3}
              title={`${t('expenses.for_your_review')}`}
              className='expenses-tab-pad'
            >
              <div className='inner-tabs mt-4'>
                {' '}
                <div className='col-lg-4 col-md-12'>
                  <div className='form-group has-search'>
                    <span className='fa fa-search form-control-feedback mt-1'></span>
                    <input
                      style={{ height: 48, borderRadius: 40 }}
                      type='text'
                      id='searchEmployee'
                      className='form-control'
                      placeholder={`${t('search_employee')}`}
                      onKeyUp={(event) => setSearchQuery(event.target.value)}
                    />
                  </div>{' '}
                </div>
                <ForYourReviewTabs
                  listData={expenseCardCorporateList['rows']}
                  clickOnTabChange={changeTabOnTabClick}
                  activeTab={activeTab}
                  parentTab={'3'}
                  currencies={currencies}
                  showExpenseDetailModal={handleShow}
                  selectedItems={selectedItems}
                  handleCheckboxChange={handleCheckboxChange}
                  filePermission={filePermission}
                  showCheckBox={true}
                        prevPath={'null'}
                />
                <div
                  className='d-flex align-items-center justify-content-between px-2'
                  style={{ width: '100%', borderTop: '0px !imporant' }}
                >
                  <nav aria-label='Page navigation example'>
                    <ul className='pagination mt-2 pt-1 mt-3 pe-0'>
                      <li className='page-item'>
                        <button
                          className='btn page-link'
                          disabled={pageNo === 1}
                          onClick={() => onPreviousClick()}
                        >
                          {t('previous')}
                        </button>
                      </li>
                      <li className='page-item mx-3'>
                        <button
                          className='btn page-link'
                          disabled={
                            pageNo == expenseCardCorporateList?.totalPages ||
                            expenseCardCorporateList?.totalPages == 0
                          }
                          onClick={() => onNextClick()}
                        >
                          {t('next')}
                        </button>
                      </li>
                    </ul>
                  </nav>
                  <div
                    className='page-count d-flex align-items-center'
                    style={{ color: '#00D094' }}
                  >
                    <h4>
                      Page{' '}
                      {expenseCardCorporateList?.count == 0
                        ? 0
                        : expenseCardCorporateList?.currentPage}{' '}
                      of {expenseCardCorporateList?.totalPages}
                    </h4>
                  </div>
                </div>
              </div>
            </Tab>
          )}
        </Tabs>
      </div>
    </div>
  );
}

export default CorporateExpenses;
