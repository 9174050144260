import "./index.scss";

import { Card, Modal } from "react-bootstrap";
import React, { memo, useEffect, useState } from "react";
import {
  getCompany,
  getExpenseCategory,
  getJournals,
  updateData,
} from "../../pages/Settings/taxSlice";

import Loader from "../Loader/Loader";
// import axios from "axios";
import cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "sa",
  },
];

function Mapping(props) {
  const { setAccountingTabs, handleDisconnect, connectedIntegration } = props;
  const { t } = useTranslation();

  const { isLoading } = useSelector((state) => state?.taxSlice);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState("connected");
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const {
    oddoCompanyList,
    oddoJournalsList,
    oddoExpenseCategory,
    oddoReimbursementJournalsList,
  } = useSelector((state) => state?.taxSlice);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getJournals());
    dispatch(getCompany());
    dispatch(getExpenseCategory());
  }, []);

  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  const handleStatusChange = (e) => {
    const value = e.target.value;
    setConnectionStatus(value);
    if (e.target.value === "disconnected") {
      setShowDisconnectModal(true);
    } else {
      handleDisconnect();
    }
  };

  const confirmDisconnect = () => {
    handleDisconnect();
    setShowDisconnectModal(false);
  };

  const handleCancel = () => {
    setConnectionStatus("connected");
    setShowDisconnectModal(false);
  };

  const handleUpdateData = (e, reload = false) => {
    const { id, value } = e.target;
    let { data = {}, companyIntegrationId } = connectedIntegration;
    data = { ...data };
    data[id] = value || "";
    const payload = {
      companyIntegrationId: companyIntegrationId,
      data,
    };
    dispatch(updateData(payload))
      .unwrap()
      .then((res) => {
        setSelectedCompanyId(null);
        if (reload) {
          const currentUrl = new URL(window.location.href);
          currentUrl.searchParams.set('tab', '2');
          window.location.href = currentUrl.toString();
        }
      })
      .catch((error) => {
        setSelectedCompanyId(null);
        console.log(error?.message);
      });
  };

  const handleCompanyChange = (e) => {
    if (!connectedIntegration?.data?.company) {
      handleUpdateData(e, true);
    } else {
      setSelectedCompanyId(e.target.value);
      setShowModal(true);
    }

  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div className="taxcodes">
      {isLoading && <Loader />}{" "}
      <div className="">
        <h2 className="card-title-black-text">
          {t("settingstab.application")}
        </h2>
      </div>
      <Card className={`setup-card text-center `}>
        <Card.Body>
          <div className="icon-container">
            <img
              src={connectedIntegration?.logoUrl}
              alt={"integration"?.name}
              className="card-icon"
            />
          </div>
          <Card.Title>{connectedIntegration?.name}</Card.Title>
          <Card.Text>{connectedIntegration?.shortDescription}</Card.Text>
        </Card.Body>
      </Card>
      <div className="d-flex justify-content-center">
        <div className="col-lg-2 col-md-6 col-sm-12 form-outline mb-3 ">
          <select
            className="form-select form-control-lg"
            aria-label="Default select example"
            onChange={handleStatusChange}
            value={connectionStatus}
          >
            {" "}
            <option value="connected"> Connected</option>
            <option value="disconnected"> Disconnected</option>
          </select>
        </div>
        <Modal
          centered
          show={showDisconnectModal}
          onHide={handleCancel}
        >
          <Modal.Body className="mt-2 p-4">
            <div className="p-3">
              <div className="text my-1" >Are you sure you want to disconnect?</div>
              <div className="border-bottom pb-2"></div>
              <div className="d-flex justify-content-center gap-3 mt-4 mb-2 ">
                <button onClick={handleCancel} className="btn-secondary-small">
                  {t("cancel")}
                </button>
                <button className="btn-primary-small" onClick={confirmDisconnect}>
                  {t("login.continue")}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <div className="py-3">
        <div>
          <h2 className="card-title-black-text">{t("settingstab.company")}</h2>
        </div>
        <div className="py-2 text-gray-400">
          <p>{t("settingstab.setup_description")}</p>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 form-outline">
          <select
            className="form-select form-control-lg"
            aria-label="Default select example"
            onChange={(e) => handleCompanyChange(e)}
            id="company"
            value={connectedIntegration?.data?.company}
          >
            {" "}
            <option value="">Select Company</option>
            {oddoCompanyList?.map((company, index) => (
              <option
                selected={connectedIntegration?.data?.company === company.id}
                key={index}
                value={company.id}
              >
                {company.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <Modal centered show={showModal} onHide={handleClose}>
        <Modal.Body className="mt-2 p-4">
          <div className="p-3">
            <div className="text my-1">
              If you change your company, your data will be removed. Are you
              sure you want to continue?
            </div>
            <div className="border-bottom pb-2"></div>
            <div className="d-flex justify-content-center gap-3 mt-4 mb-2 ">
              <button onClick={handleClose} className="btn-secondary-small">
                {t("cancel")}
              </button>
              <button
                className="btn-primary-small"
                onClick={() => handleUpdateData({ target: { id: 'company', value: selectedCompanyId } }, true)}
              >
                {t("login.continue")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="py-3">
        <div className="mt-3">
          <h2 className="card-title-black-text">{t("settingstab.account")}</h2>
        </div>
        <p>{t("settingstab.account_description")}</p>

        <div className="col-lg-4 col-md-6 col-sm-12 form-outline">
          <label className="form-labels" htmlFor="searchName">
            {t("settingstab.expense-journal")}
          </label>

          <select
            className="form-select form-control-lg"
            aria-label="Default select example"
            onChange={handleUpdateData}
            id="journal"
            value={connectedIntegration?.data?.journal}
          >
            <option value="">Select Journal</option>
            {oddoJournalsList.map((journals, index) => (
              <option
                selected={connectedIntegration?.data?.journal === journals.id}
                key={index}
                value={journals.id}
              >
                {journals.name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 form-outline ">
          <label className="form-labels mt-2" htmlFor="searchName">
            {t("settingstab.reimbursement_journal")}
          </label>

          <select
            className="form-select form-control-lg"
            aria-label="Default select example"
            id="reimbursementJournal"
            onChange={handleUpdateData}
            value={connectedIntegration?.data?.reimbursementJournal}
          >
            {" "}
            <option value=""> Select Reimbursement Journal</option>
            {oddoReimbursementJournalsList.map((journals, index) => (
              <option
                selected={
                  connectedIntegration?.data?.reimbursementJournal ===
                  journals.id
                }
                key={index}
                value={journals.id}
              >
                {journals.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 form-outline ">
          <label className="form-labels" htmlFor="searchName">
            {t("settingstab.payment_account")}
          </label>

          <select
            className="form-select form-control-lg"
            aria-label="Default select example"
            onChange={handleUpdateData}
            id="paymentAccount"
            value={connectedIntegration?.data?.paymentAccount}
          >
            {" "}
            <option value=""> Select Payment Account</option>
            {oddoExpenseCategory.map((expense, index) => (
              <option
                selected={
                  connectedIntegration?.data?.paymentAccount === expense.id
                }
                key={index}
                value={expense.id}
              >
                {expense.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="py-3">
        <div className="py-3">
          <h2 className="card-title-black-text">
            {t("settingstab.configure")}
          </h2>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 form-outline grid-container">
          <button
            onClick={() => setAccountingTabs("second")}
            className="styled-button"
          >
            Match Tax Codes with Taxes
          </button>
          <button
            className="styled-button"
            onClick={() => setAccountingTabs("third")}
          >
            Match Account Numbers With Caregories
          </button>
          <button
            className="styled-button"
            onClick={() => setAccountingTabs("forth")}
          >
            Match Taga with Tags
          </button>
        </div>
      </div>
    </div>
  );
}

export default memo(Mapping);
