// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.container-main-class {
  height: 100vh;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
@media only screen and (max-width: 992px) {
  .container-main-class {
    margin: 0px !important;
    height: 0vh !important;
  }
}

.rightapp {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
@media only screen and (max-width: 992px) {
  .rightapp {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 992px) {
  .leftbarapp {
    height: 60px !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.capitalize-first-letter {
  text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,cAAA;EACA,oBAAA;AACF;;AAEA;EACE;IACE,4CAAA;EACF;AACF;AAEA;EACE,yBAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,6BAAA;EACA,YAAA;AAAF;;AAGA;EACE,cAAA;AAAF;;AAEA;EACE,aAAA;EACA,2BAAA;EACA,4BAAA;AACF;AAAE;EAJF;IAKI,sBAAA;IACA,sBAAA;EAGF;AACF;;AADA;EACE,6BAAA;EACA,4BAAA;AAIF;AAHE;EAHF;IAII,iCAAA;IACA,gCAAA;IACA,sBAAA;EAMF;AACF;;AAHE;EADF;IAEI,uBAAA;EAOF;AACF;;AAJA;EACE;IACE,uBAAA;EAOF;EALA;IACE,yBAAA;EAOF;AACF;AAJA;EACE,0BAAA;AAMF","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n.container-main-class {\n  height: 100vh;\n  margin-left: 0px !important;\n  margin-right: 0px !important;\n  @media only screen and (max-width: 992px) {\n    margin: 0px !important;\n    height: 0vh !important;\n  }\n}\n.rightapp{\n  padding-right: 0px !important;\n  padding-left: 0px !important;\n  @media only screen and (max-width: 992px) {\n    padding-right: calc(1.5rem * 0.5) !important;\n    padding-left: calc(1.5rem * 0.5) !important;\n    width: 100% !important;\n  }\n}\n.leftbarapp {\n  @media only screen and (max-width: 992px) {\n    height: 60px !important;\n  }\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.capitalize-first-letter {\n  text-transform: capitalize;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
