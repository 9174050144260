import React, { useEffect, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ExpensesComponent from './ExpensesComponent';
import moment from 'moment-timezone';
import { useLocation } from 'react-router-dom';
import notFound from '../../assets/images/not_found.png';

function ForYourReviewTabs(props) {
    const { state } = useLocation();
    const { t } = useTranslation();
    const [activeKey, setActiveKey] = useState('first');
    const { clickOnTabChange, parentTab, listData, showCheckBox = false, handleShow, currencies, activeTab, showExpenseDetailModal, type, selectedItems, handleCheckboxChange, filePermission, prevPath } = props;

    useEffect(() => {
        console.log(state);
        if ((state?.prevPath && state?.prevPath === "/expenses/expense-details" || state?.prevPath && state?.prevPath === "/reimbursement/reimbursement-details")) {
            setActiveKey(state?.activeKey);
        }
        else {
            setActiveKey('first');
        }
    }, []);

    return (
        <>
            <div className='inner-tabs mt-4 pb-3 '>
                <Tab.Container activeKey={activeKey}>
                    <Nav variant="pills">
                        <Nav.Item onClick={() => { setActiveKey('first'); clickOnTabChange(parentTab, 'pending'); }}>
                            <Nav.Link eventKey="first">{t('expenses.pending')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item onClick={() => { setActiveKey('second'); clickOnTabChange(parentTab, 'reject'); }}>
                            <Nav.Link eventKey="second">{t('expenses.rejected')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item onClick={() => { setActiveKey('third'); clickOnTabChange(parentTab, 'approve'); }}>
                            <Nav.Link eventKey="third">{t('expenses.approved')}</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content className='mt-4'>
                        <Tab.Pane eventKey="first">
                            {(listData && listData.length > 0) ?
                                (listData.map((item, i) => {
                                    return (
                                        <div className='export-border table-border p-3 mt-3' key={'listsData' + i}>
                                            <h2 className='text-grey'>{moment(item?.date).format('DD MMM YYYY')}</h2>
                                            <ExpensesComponent
                                                data={item?.records}
                                                onClick={handleShow}
                                                forReview={true}
                                                parentTab={parentTab}
                                                currencies={currencies}
                                                showExpenseDetailModal={showExpenseDetailModal}
                                                type={type}
                                                showCheckBox={showCheckBox}
                                                selectedItems={selectedItems}
                                                handleCheckboxChange={handleCheckboxChange}
                                                filePermission={filePermission}
                                                prevPath={prevPath}
                                                activeKey={activeKey}
                                            />
                                        </div>
                                    );
                                })) : (
                                    <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                                        <img src={notFound} width={200} height={200} />
                                    </div>
                                )
                            }
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            {(listData && listData.length > 0) ?
                                (listData.map((item, i) => {
                                    return (
                                        <div className='export-border table-border p-3 mt-3' key={'listsData' + i}>
                                            <h2 className='text-grey'>{moment(item?.date).format('DD MMM YYYY')}</h2>
                                            <ExpensesComponent
                                                data={item?.records}
                                                onClick={handleShow}
                                                parentTab={parentTab}
                                                currencies={currencies}
                                                showExpenseDetailModal={showExpenseDetailModal}
                                                type={type}
                                                showCheckBox={showCheckBox}
                                                selectedItems={selectedItems}
                                                handleCheckboxChange={handleCheckboxChange}
                                                filePermission={filePermission}
                                                prevPath={prevPath}
                                                activeKey={activeKey}
                                            />
                                        </div>
                                    );
                                })) : (
                                    <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                                        <img src={notFound} width={200} height={200} />
                                    </div>
                                )
                            }
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                            {(listData && listData.length > 0) ?
                                (listData.map((item, i) => {
                                    return (
                                        <div className='export-border table-border p-3 mt-3' key={'listsData' + i}>
                                            <h2 className='text-grey'>{moment(item?.date).format('DD MMM YYYY')}</h2>
                                            <ExpensesComponent
                                                data={item?.records}
                                                onClick={handleShow}
                                                parentTab={parentTab}
                                                currencies={currencies}
                                                showExpenseDetailModal={showExpenseDetailModal}
                                                type={type}
                                                showCheckBox={showCheckBox}
                                                selectedItems={selectedItems}
                                                handleCheckboxChange={handleCheckboxChange}
                                                filePermission={filePermission}
                                                prevPath={prevPath}
                                                activeKey={activeKey}
                                            />
                                        </div>
                                    );
                                })) : (
                                    <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                                        <img src={notFound} width={200} height={200} />
                                    </div>
                                )
                            }
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </>
    );
}

export default ForYourReviewTabs;
