import React, { useState } from 'react';
import './Login.scss';
import OtpInput from 'react-otp-input';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { generateOtp } from './registerSlice';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader/Loader';
import { useEffect } from 'react';
import login_bottom_image from '../../assets/images/login_bottom_image.png';
import Logo2 from '../../assets/images/polo.png';

function NumberVerification() {
    const { state } = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [timer, setTimer] = useState(60);

    const { control, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(yup.object().shape({
            otp: yup.string().required("Otp is required").matches(/^\d{6}$/, 'OTP must be exactly 6 digits'),
        }))
    });

    const { isLoading } = useSelector(state => state?.registerSlice);

    useEffect(() => {
        let interval;

        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [timer]);

    const handleResendClick = () => {
        dispatch(generateOtp({ type: "mobile", mobile: state?.registerData?.mobileNumber }))
            .unwrap()
            .then(() => {
                setValue('otp', '');
                toast.success('OTP Sent');
                setTimer(60);
            }).catch((error) => {
                toast.error(error?.message);
            });
    };

    const onSubmit = (data) => {
        if (data?.otp === '123456') {
            navigate('/setPassword', { state: { registerData: state?.registerData } });
        } else {
            toast.error('Invalid Otp');
        }
    };

    return (
        <section className="vh-100">
            {
                isLoading && <Loader />
            }
            <div className="container-fluid vh-100" style={{ backgroundColor: '#24473D' }}>
                <div className="row d-flex justify-content-between align-items-center h-100 " >
                    <div className=" d-flex flex-column justify-content-between align-items-center my-2">
                        <div className='form-div'>
                            <form className='form' onSubmit={handleSubmit(onSubmit)} >
                                <div>
                                    <img src={Logo2} className="logoso mt-1 mb-4" alt="Sample image" />
                                </div>
                                <h1 style={{ maxWidth: '320px' }} className='text-blue-login mb-2'>
                                    {t('login.verify_your_mobile_number')}
                                </h1>
                                <div style={{ maxWidth: '320px' }} className="divider d-flex align-items-center my-2">
                                    <p className="mb-0 text-subtitle2" style={{ color: '#8B9299' }} >
                                        {t('login.verify_your_mobile_number_desc')}
                                    </p>
                                </div>

                                <div className="otp form-outline d-flex justify-content-center mt-4 mb-3">
                                    <Controller
                                        name="otp"
                                        control={control}
                                        defaultValue={0}
                                        render={({ field: { onChange, value, ref }, }) => (
                                            <OtpInput
                                                inputStyle="inputStyle"
                                                ref={ref}
                                                value={value}
                                                onChange={onChange}
                                                shouldAutoFocus
                                                numInputs={6}
                                                renderInput={(props) => <input {...props} id='input_otp' onInput={(e) => {
                                                    const value = e.target.value;
                                                    if (!/^\d*$/.test(value)) {
                                                        e.target.value = value.slice(0, -1);
                                                    }
                                                }} />}
                                            />
                                        )}
                                    />
                                </div>
                                <p className="text-danger">{errors?.otp?.message}</p>
                                <div className="d-flex justify-content-end">
                                    {timer === 0 ? (
                                        <a href="javascript:void(0)" onClick={handleResendClick} id='resend_otp'>Resend OTP</a>
                                    ) : (
                                        <p>Resend OTP in <span className='text-blue'>{timer} seconds</span> </p>
                                    )}
                                </div>
                                <div className="text-center text-lg-start mt-4 w-100">
                                    <button type="submit" className="btn btn-primary btn-lg btn-block w-100" style={{ position: 'relative', zIndex: '1' }} id='continue'>{t('login.continue')}</button>
                                </div>

                            </form>
                        </div>

                        <img className='login_bottom_image-1' src={login_bottom_image} />
                    </div>

                </div>
            </div>
        </section>
    );
}

export default NumberVerification;
