import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import './Users.scss';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import UsersCard from '../../components/UsersComponent/UsersCard';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addEmployee, getAllDepartment, getDepartmentEmployee, getAllCountries, updateEmployeeState } from '../Teams/teamSlice';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Loader from '../../components/Loader/Loader';
import { useEffect } from 'react';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import CrossImage from '../../assets/images/close-square-8.png';
import notFound from '../../assets/images/not_found.png';

function Users() {
    const location = useLocation();
    const [show, setShow] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchTab, setSearchTab] = useState('');
    const [details, setDetails] = useState({ showModal: false, item: null, checked: false, tab: '1' });

    const { t } = useTranslation();
    const { isLoading, departmentData, departmentEmployees, countriesData } = useSelector((state) => state?.teamSlice);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const schema = yup.object().shape({
        firstName: yup
            .string()
            .matches(/^[A-Za-z ]+$/, "Only alphabets are required")
            .min(2, `${t('login.min_name_length')}`)
            .max(30, `${t('login.max_name_length')}`)
            .required(t('login.first_name')),
        lastName: yup
            .string()
            .matches(/^[A-Za-z ]+$/, "Only alphabets are required")
            .min(2, `${t('login.min_name_length')}`)
            .max(30, `${t('login.max_name_length')}`)
            .required(t('login.enter_your_last_name')),
        email: yup.string().matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/, 'Invalid email').required(t('enter_email_address')),
        gender: yup.string().required(t('users.select_gender')),
        nationalId: yup.string()
            .required('National ID is required')
            .min(11, "National ID should be at least 11 digits")
            .max(11, "National ID should not exceeds 11 digits")
            .matches(/^\d{11}$/, 'National ID must be a valid number'),
        nationality: yup.string().required(t('users.select_nationality')),
        departmentId: yup.string().required('select departmentId'),
        mobileNumber: yup
            .string()
            .required('Mobile Number is required')
            .min(8, "Mobile Number should be at least 8 digits")
            .max(16, "Mobile Number should not exceeds 16 digits")
            .matches(/^\d{8,}$/, 'Mobile number must be at least 8 digits and contain only numbers'),
    });
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });
    const dispatch = useDispatch();

    let navigate = useNavigate();

    const routeChange = (id) => {
        let path = `/team/team-details`;
        navigate(path, { state: { employeeId: id, eventType: 'users', prevPath: location.pathname } });
    };

    const deleteRoute = (id) => {
        let path = `/team/team-details`;
        navigate(path, { state: { employeeId: id, isDeleted: true, prevPath: location.pathname } });
    };

    useEffect(() => {
        dispatch(getAllDepartment());
        dispatch(getAllCountries());
        dispatch(getDepartmentEmployee({ departmentId: null, para: null }));
    }, []);

    useEffect(() => {
        if (searchTab === false) {
            const getData = setTimeout(() => {
                let params = ``;
                switch (details?.tab) {
                    case '1':
                        params = ``;
                        break;
                    case '2':
                        params = `isActive=true`;
                        break;
                    case '3':
                        params = `isActive=false`;
                        break;
                    case '4':
                        params = `invited=true`;
                        break;
                    case '5':
                        params = `isAccountant=true`;
                        break;
                    case '6':
                        params = `isDeleted=true`;
                        break;
                    case '7':
                        params = `isAdmin=true`;
                        break;
                    default:
                        break;
                }

                dispatch(getDepartmentEmployee({ departmentId: null, para: `search=${searchQuery}&${params}` }));
            }, 1000);
            return () => clearTimeout(getData);
        }
    }, [searchQuery, dispatch]);


    const onSubmit = (data) => {
        const fullMobileNumber = `+974${data?.mobileNumber}`;
        const newData = { ...data, mobileNumber: fullMobileNumber, eKYCStatus: false };
        dispatch(addEmployee(newData))
            .unwrap()
            .then((res) => {
                toast.success(res?.message);
                dispatch(getDepartmentEmployee({ departmentId: null, para: null }));
                reset();
                handleClose();
                dispatch(getDepartmentEmployee({ departmentId: null, para: null }));
            }).catch((error) => {
                toast.error(error?.message);
            });
    };
    const handleTab = (key) => {
        setSearchQuery('');
        switch (key) {
            case '1':
                dispatch(getDepartmentEmployee({ departmentId: null, para: null }));
                break;
            case '2':
                dispatch(getDepartmentEmployee({ departmentId: null, para: 'isActive=true' }));
                break;
            case '3':
                dispatch(getDepartmentEmployee({ departmentId: null, para: 'isActive=false' }));
                break;
            case '4':
                dispatch(getDepartmentEmployee({ departmentId: null, para: 'invited=true' }));
                break;
            case '5':
                dispatch(getDepartmentEmployee({ departmentId: null, para: 'isAccountant=true' }));
                break;
            case '6':
                dispatch(getDepartmentEmployee({ departmentId: null, para: 'isDeleted=true' }));
                break;
            case '7':
                dispatch(getDepartmentEmployee({ departmentId: null, para: 'isAdmin=true' }));
                break;
            default:
                break;
        }
    };

    const handleConfirm = () => {
        let data = { userEmployeeId: details?.item?.employeeId };
        if (!details?.isDeleted && ((details?.checked === true) || (details?.checked === false))) data.isActive = details?.checked;
        if (details?.isDeleted) data.isDeleted = details?.isDeleted;
        dispatch(updateEmployeeState(data))
            .unwrap()
            .then((res) => {
                toast.success(res?.message);
                handleTab(details.tab);
                if (details?.isDeleted) setDetails((prev) => ({ ...prev, isDeleted: false }));
            }).catch((error) => {
                toast.error(error?.message);
                if (details?.isDeleted) setDetails((prev) => ({ ...prev, isDeleted: false }));
            });
        setDetails((prev) => ({ ...prev, showModal: false }));
    };

    const requiredFieldLabel = (labelText) => {
        return (
            <label className="form-label" htmlFor="form3Example3">
                {labelText}
                <span className="absolute top-0 left-0" style={{ transform: 'translate(-50%, -50%)', color: '#FF0000' }}> *</span>
            </label>
        )
    }

    return (
        <div className='white-container settings'>
            {
                isLoading && <Loader />
            }
            <div className='d-flex align-items-center justify-content-between mb-4'>
                <h1 className='text-green'>{t('sidebar.users')}</h1>
                <button className='btn-primary-small' onClick={handleShow}>{t('users.+_add_employee')}</button>
            </div>
            <Modal className='UserModal' show={show} onHide={handleClose} style={{ paddingRight: 0 }}>
                <Modal.Body>
                    <div className='d-flex justify-content-between align-items-start green-header p-2'>
                        <div className='col-9'>
                            <p className='text-green modal-title' style={{ fontSize: 20 }}>
                                {t('users.add_employee')}
                            </p>
                        </div>
                        <div onClick={handleClose} className='clo-2' style={{ cursor: 'pointer' }}>
                            <img src={CrossImage} style={{ width: 20, objectFit: 'contain' }} />
                        </div>
                    </div>
                    <p className='text-grey'>{t('users.add_employee_desc')}</p>
                    <form>
                        <div className="form-outline mt-4 mb-3">
                            {requiredFieldLabel(t('login.first_name'))}
                            <input type="text" id="addFirstName" className="form-control form-control-lg"
                                placeholder={`${t('login.enter_your_first_name')}`}
                                {...register('firstName')}
                            />
                            {errors?.firstName && (
                                <p style={{ color: 'red' }}>{errors?.firstName?.message}</p>
                            )}
                        </div>
                        <div className="form-outline mt-4 mb-3">
                            {requiredFieldLabel(t('login.last_name'))}
                            <input type="text" id="addLastName" className="form-control form-control-lg"
                                placeholder={`${t('login.enter_your_last_name')}`}
                                {...register('lastName')}
                            />
                            {errors?.lastName && (
                                <p style={{ color: 'red' }}>{errors?.lastName?.message}</p>
                            )}
                        </div>
                        <div className="form-outline mt-4 mb-3">
                            {requiredFieldLabel(t('email_address'))}
                            <input type="email" id="addEmail" className="form-control form-control-lg"
                                placeholder={`${t('email_address')}`}
                                {...register('email', {
                                    pattern: {
                                        value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
                                        message: 'Invalid email address'
                                    }
                                })}
                            />
                            {errors?.email && (
                                <p style={{ color: 'red' }}>{errors?.email?.message}</p>
                            )}
                        </div>
                        <div className="form-outline mt-4 mb-3">
                            {requiredFieldLabel(t('mobile_number'))}
                            <div className="input-group">
                                <span className="input-group-text">+974</span>
                                <input
                                    type="text"
                                    id="addMobileNo"
                                    className={`form-control form-control-lg ${errors?.mobileNumber ? 'is-invalid' : ''}`}
                                    placeholder={`${t('login.enter_your_mobile_number')}`}
                                    {...register('mobileNumber', {
                                        required: `${t('login.enter_your_mobile_number')}`,
                                        minLength: {
                                            value: 8,
                                            message: 'Mobile number must be at least 8 digits',
                                        },
                                        maxLength: {
                                            value: 16,
                                            message: 'Mobile number should not exceed 16 digits',
                                        },
                                        pattern: {
                                            value: /^\d+$/,
                                            message: 'Mobile number must contain only digits',
                                        },
                                    })}
                                    onInput={(e) => {
                                        const value = e.target.value;
                                        if (!/^\d*$/.test(value)) {
                                            e.target.value = value.slice(0, -1);
                                        }
                                    }}
                                />
                                {errors?.mobileNumber && (
                                    <div className="invalid-feedback">{errors?.mobileNumber?.message}</div>
                                )}
                            </div>
                        </div>
                        <div className="form-outline mt-4 mb-3">
                            {requiredFieldLabel(t('cards.department'))}
                            <select className="form-control form-select" id="selectDepartment"
                                {...register('departmentId')} >
                                <option value="" >{t('cards.select_department')}</option>
                                {departmentData?.map((department) => (
                                    <option key={department.departmentId} value={department.departmentId}>
                                        {department.departmentName}
                                    </option>
                                ))}
                            </select>
                            {errors?.departmentId && (
                                <p style={{ color: 'red' }}>{errors?.departmentId?.message}</p>
                            )}
                        </div>

                        <div className="form-outline mt-4 mb-3">
                            {requiredFieldLabel(t('users.gender'))}
                            <select className="form-control form-select" id="selectGender"
                                {...register('gender')}
                            >
                                <option selected value=''>{t('users.select_gender')}</option>
                                <option value='male'>Male</option>
                                <option value='female'>Female</option>
                            </select>
                            {errors?.gender && (
                                <p style={{ color: 'red' }}>{errors?.gender?.message}</p>
                            )}
                        </div>
                        <div className="form-outline mt-4 mb-3">
                            {requiredFieldLabel(t('users.notional_ID'))}
                            <input type="text" id="addNationalId" className="form-control form-control-lg"
                                placeholder={`${t('users.enter_Id')}`}
                                {...register('nationalId')}
                            />
                            {errors?.nationalId && (
                                <p style={{ color: 'red' }}>{errors?.nationalId?.message}</p>
                            )}
                        </div>
                        <div className="form-outline mt-4 mb-3">
                            {requiredFieldLabel(t('users.nationality'))}
                            <select className="form-control form-select" id="selectNationality"
                                {...register('nationality')}
                            >
                                <option selected value=''>{t('users.select_nationality')}</option>
                                {countriesData?.map((country) => (
                                    <option key={country.countryId} value={country.countryId}>
                                        {country.name}
                                    </option>
                                ))}
                            </select>
                            {errors?.nationality && (
                                <p style={{ color: 'red' }}>{errors?.nationality?.message}</p>
                            )}
                        </div>
                        <div className="mb-3 form-check " style={{ marginRight: 20 }}>
                            <input type="checkbox" className="form-check-input" id="eKYCRequired"
                                {...register('eKYCRequired')} />
                            <label className="form-check-label" htmlFor="eKYCRequired">{t('users.kycRequired')}</label>
                        </div>
                        <div className='d-flex align-items-center mb-3'>
                            <div className="mb-3 form-check " style={{ marginRight: 20 }}>
                                <input type="checkbox" className="form-check-input" id="isAccountant"
                                    {...register('isAccountant')} />
                                <label className="form-check-label" htmlFor="isAccountant">{t('users.accountant')}</label>
                            </div>
                            <div className="mb-3 form-check">
                                <input type="checkbox" className="form-check-input" id="isAdmin"
                                    {...register('isAdmin')} />
                                <label className="form-check-label" htmlFor="isAdmin">{t('users.admin')}</label>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className='p-0 pt-3'>
                    <button onClick={(e) => { handleClose(); reset(); e.preventDefault(); }} className='btn-secondary-small' >{t('cancel')}</button>
                    <button onClick={handleSubmit(onSubmit)} className='btn-primary-small' >{t('save_and_invite')}</button>
                </Modal.Footer>
            </Modal>
            <ConfirmationDialog
                show={details.showModal}
                onConfirm={handleConfirm}
                onClose={() => setDetails((prev) => ({ ...prev, showModal: false }))}
                message="Are you sure you want to proceed ?"
            />
            <Tabs onSelect={(key) => { handleTab(key); setSearchTab(true); setDetails((prev) => ({ ...prev, tab: key })); }} defaultActiveKey={1} animation="false" id="noanim-tab-example">
                <Tab eventKey={1} title={`${t('users.all_employees')}`}>
                    <div className='col-lg-4 col-md-12 mt-4'>
                        <div className="form-group has-search justify-content-start d-flex">
                            <span className="fa fa-search form-control-feedback mt-1"></span>
                            <input type="text" id='searchEmployeeAll' className="form-control" value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value); setSearchTab(false); }} placeholder={`${t('search_employee')}`} style={{ borderRadius: 15 }} />
                        </div>
                    </div>
                    {
                        (departmentEmployees?.data && departmentEmployees?.data?.length > 0)
                            ? (<div className="user-cards-grid g-4 mt-4">
                                {departmentEmployees?.data?.map((item) => (
                                    <UsersCard
                                        username={item?.firstName + ' ' + item?.lastName}
                                        numberOfCards={item?.cardcount}
                                        price1={item?.currentbalance}
                                        price2={item?.pettyCashBalance}
                                        onClick={() => routeChange(item?.employeeId)}
                                        plusIcon={false}
                                        profilePic={item?.profilePic}
                                        lastName={item?.lastName}
                                        setDetails={setDetails}
                                        data={item}
                                        key={`allUsers-${item?.employeeId}`}
                                    />
                                ))}
                            </div>) : (
                                <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                                    <img src={notFound} width={200} height={200} />
                                </div>
                            )
                    }
                </Tab>
                <Tab eventKey={2} title={`${t('users.active_employees')}`}>
                    <div className='col-lg-4 col-md-12 mt-4'>
                        <div className="form-group has-search justify-content-start d-flex">
                            <span className="fa fa-search form-control-feedback mt-1"></span>
                            <input type="text" className="form-control" id='searchEmployeeActive' value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value); setSearchTab(false); }} placeholder={`${t('search_employee')}`} style={{ borderRadius: 15 }} />
                        </div>
                    </div>
                    {
                        (departmentEmployees?.data && departmentEmployees?.data?.length > 0)
                            ? (<div className="user-cards-grid g-4 mt-2">
                                {departmentEmployees?.data?.map((item) => (
                                    <UsersCard
                                        username={item?.firstName + ' ' + item?.lastName}
                                        numberOfCards={item?.cardcount}
                                        price1={item?.currentbalance}
                                        price2={item?.pettyCashBalance}
                                        onClick={() => routeChange(item?.employeeId)}
                                        plusIcon={false}
                                        profilePic={item?.profilePic}
                                        lastName={item?.lastName}
                                        setDetails={setDetails}
                                        data={item}
                                        key={`activeUsers-${item?.employeeId}`}
                                    />
                                ))}
                            </div>) : (
                                <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                                    <img src={notFound} width={200} height={200} />
                                </div>
                            )
                    }
                </Tab>
                <Tab eventKey={3} title={`${t('users.inactive_employees')}`}>
                    <div className='col-lg-4 col-md-12 mt-4'>
                        <div className="form-group has-search justify-content-start d-flex">
                            <span className="fa fa-search form-control-feedback mt-1"></span>
                            <input type="text" className="form-control" id='searchEmployeeInactive' value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value); setSearchTab(false); }} placeholder={`${t('search_employee')}`} style={{ borderRadius: 15 }} />
                        </div>
                    </div>
                    {
                        (departmentEmployees?.data && departmentEmployees?.data?.length > 0)
                            ? (<div className="user-cards-grid g-4 mt-2">
                                {departmentEmployees?.data?.map((item) => (
                                    <UsersCard
                                        username={item?.firstName + ' ' + item?.lastName}
                                        numberOfCards={item?.cardcount}
                                        price1={item?.currentbalance}
                                        price2={item?.pettyCashBalance}
                                        onClick={() => routeChange(item?.employeeId)}
                                        plusIcon={false}
                                        profilePic={item?.profilePic}
                                        lastName={item?.lastName}
                                        setDetails={setDetails}
                                        data={item}
                                        key={`inactiveUsers-${item?.employeeId}`}
                                    />
                                ))}
                            </div>) : (
                                <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                                    <img src={notFound} width={200} height={200} />
                                </div>
                            )
                    }
                </Tab>
                <Tab eventKey={4} title={`${t('users.invited_employees')}`}>
                    <div className='col-lg-4 col-md-12 mt-4'>
                        <div className="form-group has-search justify-content-start d-flex">
                            <span className="fa fa-search form-control-feedback mt-1"></span>
                            <input type="text" className="form-control" id='searchEmployeeInvited' value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value); setSearchTab(false); }} placeholder={`${t('search_employee')}`} style={{ borderRadius: 15 }} />
                        </div>
                    </div>
                    {
                        (departmentEmployees?.data && departmentEmployees?.data?.length > 0)
                            ? (<div className="user-cards-grid g-4 mt-2">
                                {departmentEmployees?.data?.map((item) => (
                                    <UsersCard
                                        username={item?.firstName + ' ' + item?.lastName}
                                        numberOfCards={item?.cardcount}
                                        price1={item?.currentbalance}
                                        price2={item?.pettyCashBalance}
                                        onClick={() => routeChange(item?.employeeId)}
                                        plusIcon={false}
                                        profilePic={item?.profilePic}
                                        lastName={item?.lastName}
                                        setDetails={setDetails}
                                        data={item}
                                        key={`invitedUsers-${item?.employeeId}`}
                                    />
                                ))}
                            </div>) : (
                                <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                                    <img src={notFound} width={200} height={200} />
                                </div>
                            )
                    }
                </Tab>
                <Tab eventKey={5} title={`${t('users.accountants')}`}>
                    <div className='col-lg-4 col-md-12 mt-4'>
                        <div className="form-group has-search justify-content-start d-flex">
                            <span className="fa fa-search form-control-feedback mt-1"></span>
                            <input type="text" className="form-control" id='searchAccountants' value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value); setSearchTab(false); }} placeholder={`${t('search_employee')}`} style={{ borderRadius: 15 }} />
                        </div>
                    </div>
                    {
                        (departmentEmployees?.data && departmentEmployees?.data?.length > 0)
                            ? (<div className="user-cards-grid g-4 mt-2">
                                {departmentEmployees?.data?.map((item) => (
                                    <UsersCard
                                        username={item?.firstName + ' ' + item?.lastName}
                                        numberOfCards={item?.cardcount}
                                        price1={item?.currentbalance}
                                        price2={item?.pettyCashBalance}
                                        onClick={() => routeChange(item?.employeeId)}
                                        plusIcon={false}
                                        profilePic={item?.profilePic}
                                        lastName={item?.lastName}
                                        setDetails={setDetails}
                                        data={item}
                                        key={`accountants-${item?.employeeId}`}
                                    />
                                ))}
                            </div>) : (
                                <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                                    <img src={notFound} width={200} height={200} />
                                </div>
                            )
                    }
                </Tab>
                <Tab eventKey={6} title={`${t('users.deleted_employees')}`}>
                    <div className='col-lg-4 col-md-12 mt-4'>
                        <div className="form-group has-search justify-content-start d-flex">
                            <span className="fa fa-search form-control-feedback"></span>
                            <input type="text" className="form-control" id='searchDeleted' value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value); setSearchTab(false); }} placeholder={`${t('search_employee')}`} style={{ borderRadius: 15 }} />
                        </div>
                    </div>
                    {
                        (departmentEmployees?.data && departmentEmployees?.data?.length > 0)
                            ? (<div className="user-cards-grid g-4 mt-2">
                                {departmentEmployees?.data?.map((item) => (
                                    <UsersCard
                                        username={item?.firstName + ' ' + item?.lastName}
                                        numberOfCards={item?.cardcount}
                                        price1={item?.currentbalance}
                                        price2={item?.pettyCashBalance}
                                        onClick={() => deleteRoute(item?.employeeId)}
                                        plusIcon={false}
                                        profilePic={item?.profilePic}
                                        lastName={item?.lastName}
                                        setDetails={setDetails}
                                        data={item}
                                        showDelete={false}
                                        isDeleted={true}
                                        key={`deletedUsers-${item?.employeeId}`}
                                    />
                                ))}
                            </div>) : (
                                <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                                    <img src={notFound} width={200} height={200} />
                                </div>
                            )
                    }
                </Tab>
                <Tab eventKey={7} title={`${t('users.admins')}`}>
                    <div className='col-lg-4 col-md-12 mt-4'>
                        <div className="form-group has-search justify-content-start d-flex">
                            <span className="fa fa-search form-control-feedback"></span>
                            <input type="text" className="form-control" id='searchAdmins' value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value); setSearchTab(false); }} placeholder={`${t('search_employee')}`} style={{ borderRadius: 15 }} />
                        </div>
                    </div>
                    {
                        (departmentEmployees?.data && departmentEmployees?.data?.length > 0)
                            ? (<div className="user-cards-grid g-4 mt-2">
                                {departmentEmployees?.data?.map((item) => (
                                    <UsersCard
                                        username={item?.firstName + ' ' + item?.lastName}
                                        numberOfCards={item?.cardcount}
                                        price1={item?.currentbalance}
                                        price2={item?.pettyCashBalance}
                                        onClick={() => routeChange(item?.employeeId)}
                                        plusIcon={false}
                                        profilePic={item?.profilePic}
                                        lastName={item?.lastName}
                                        setDetails={setDetails}
                                        data={item}
                                        key={`admins-${item?.employeeId}`}
                                    />
                                ))}
                            </div>) : (
                                <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                                    <img src={notFound} width={200} height={200} />
                                </div>
                            )
                    }
                </Tab>
            </Tabs>
        </div>
    );
}

export default Users;
