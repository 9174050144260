import React, { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import { Controller, useForm } from 'react-hook-form';

const validationSchema = yup.object().shape({
    answers: yup.array().of(
        yup.object().shape({
            questionId: yup.number().required('Please select answer.').typeError('Please select answer.'),
            answerId: yup.number().required('Please select answer.').typeError('Please select answer.'),
        })
    ),
});

function UsageDetails({ questionaire, selectedQuestionare, onSubmit, handleBackButton, companyStatus }) {
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const form = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            answers: questionaire?.map(question => ({
                questionId: question.questionId,
                answerId: ''
            })) || []
        }
    });
    const form2Error = form.formState?.errors;

    useEffect(() => {
        if (selectedQuestionare.length > 0) {
            const QData = selectedQuestionare.map(question => ({
                questionId: question?.questionId,
                answerId: question?.answerId || ''
            }));
            form.reset({ answers: QData });
        }
    }, [selectedQuestionare, form.reset]);

    const languages = [
        {
            code: 'en',
            name: 'English',
            country_code: 'gb',
        },
        {
            code: 'ar',
            name: 'العربية',
            dir: 'rtl',
            country_code: 'sa',
        },
    ];

    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr';
        document.title = t('app_title');
    }, [currentLanguage, t]);

    return (
        <form onSubmit={form?.handleSubmit(onSubmit)}>
            <p className='text-green mb-3' style={{ fontSize: 20, fontWeight: '500', color: "#8B9299" }}>{t('company.provide_usage_details')}</p>
            <h3 className='text-grey mb-3 fw-normal' >{t('company.provide_usage_details_desc')}</h3>
            {
                questionaire?.length > 0 && questionaire?.map((question, index) => (
                    <div className="form-outline mt-2 mb-3" key={index}>
                        <label className="form-label" htmlFor={`question_${index}`}>{question?.question}</label>
                        <Controller
                            name={`answers[${index}].questionId`}
                            control={form?.control}
                            defaultValue={Number(question.questionId)}
                            render={({ field }) => (
                                <input type="hidden" {...field} />
                            )}
                        />
                        <Controller
                            name={`answers[${index}].answerId`}
                            control={form?.control}
                            defaultValue=""
                            render={({ field }) => (
                                <select
                                    {...field}
                                    id={`question_${index}`}
                                    className="form-control form-select"
                                    onChange={(e) => field?.onChange(Number(e.target.value))}
                                >
                                    <option value="" disabled>{t('company.select')}</option>
                                    {question.answer.map((el, answerIndex) => (
                                        <option key={answerIndex} value={el.answerId}>
                                            {el.answer}
                                        </option>
                                    ))}
                                </select>
                            )}
                        />
                        {form2Error?.answers?.[index]?.answerId && (
                            <p className="error-message text-danger">{form2Error.answers[index].answerId.message}</p>
                        )}

                    </div>
                ))
            }
            <div style={{ borderBottom: '1px solid #EAECF0', marginBottom: 18 }}></div>
            <div className='d-flex align-items-center justify-content-center'>
                <button type='button' onClick={handleBackButton} className={companyStatus === 'InActive' ? 'btn-secondary-small': 'disabledButton'} style={{ width: '45%', textAlign: "center" }}>{t('users.back')}</button>
                <div className='pe-3'></div>
                <button type="submit" className= {companyStatus === 'InActive' ? 'btn-primary-small': 'disabledButton'} style={{ width: '45%' }}>{t('next')}</button>
            </div>
        </form>
    );
}

export default UsageDetails;
