// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GraphCustomCss .transaction-text {
  font-size: 18px;
  color: #248F71;
  margin-left: 4px;
  line-height: 24.51px;
  text-align: left;
}
.GraphCustomCss .dropdown .btn-primary {
  background-color: transparent !important;
  border: 0px;
}
.GraphCustomCss .dropdown .dropdown-toggle::after {
  display: none;
}
.GraphCustomCss .dropdown .dropdown-menu.show {
  background-color: #FFFFFF;
  border: 1px solid #E5E5E5;
  padding: 0px 8px;
  border-radius: 12px;
}
.GraphCustomCss .dropdown .dropdown-item {
  background-color: #FFFFFF;
  margin: 8px 0px;
  border: 0.5px solid #DCDCDC;
  border-radius: 8px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #6F6B7D;
}
.GraphCustomCss .dropdown .dropdown-item:hover {
  background-color: #00D094;
  margin: 8px 0px;
  border: 0.5px solid #DCDCDC;
  border-radius: 8px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/components/Ghaph/Graph.scss"],"names":[],"mappings":"AACI;EAEI,eAAA;EACA,cAAA;EACA,gBAAA;EACA,oBAAA;EACA,gBAAA;AADR;AAIQ;EACI,wCAAA;EACA,WAAA;AAFZ;AAKQ;EACI,aAAA;AAHZ;AAMQ;EACI,yBAAA;EACA,yBAAA;EACA,gBAAA;EACA,mBAAA;AAJZ;AAOQ;EACI,yBAAA;EACA,eAAA;EACA,2BAAA;EACA,kBAAA;EAEA,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AANZ;AASQ;EACI,yBAAA;EACA,eAAA;EACA,2BAAA;EACA,kBAAA;EAEA,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AARZ","sourcesContent":[".GraphCustomCss {\n    .transaction-text {\n        // margin-top: 22px;\n        font-size: 18px;\n        color: #248F71;\n        margin-left: 4px;\n        line-height: 24.51px;\n        text-align: left;\n    }\n    .dropdown {\n        .btn-primary {\n            background-color: transparent !important;\n            border: 0px;\n        }\n\n        .dropdown-toggle::after {\n            display: none;\n        }\n\n        .dropdown-menu.show {\n            background-color: #FFFFFF;\n            border: 1px solid #E5E5E5;\n            padding: 0px 8px;\n            border-radius: 12px;\n        }\n\n        .dropdown-item {\n            background-color: #FFFFFF;\n            margin: 8px 0px;\n            border: 0.5px solid #DCDCDC;\n            border-radius: 8px;\n\n            font-family: Open Sans;\n            font-size: 14px;\n            font-weight: 400;\n            line-height: 22px;\n            color: #6F6B7D;\n        }\n\n        .dropdown-item:hover {\n            background-color: #00D094;\n            margin: 8px 0px;\n            border: 0.5px solid #DCDCDC;\n            border-radius: 8px;\n\n            font-family: Open Sans;\n            font-size: 14px;\n            font-weight: 400;\n            line-height: 22px;\n            color: #FFFFFF;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
