import React, { useState } from 'react';
import './Login.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { joinCompany, resetPassword } from './loginSlice';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import Logo2 from '../../assets/images/polo.png';
import login_bottom_image from '../../assets/images/login_bottom_image.png';
import { setUserLoggedInData } from '../Users/userSlice';

function ConfirmPasscode() {
    const { t } = useTranslation();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const [passwordType, setPasswordType] = useState("password");
    const { isLoading } = useSelector(state => state?.loginSliceReducer);

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(yup.object().shape({
            confirm_password: yup
            .string()
            .required('Password is required')
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%?&+=*^<>/|()"':,.;`~{}_[\]\\-])[A-Za-z\d@#$!%?&+=*^<>/|()"':,.;`~{}_[\]\\-]{8,}$/,
              'Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long.'
            )
        }))
    });

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        }
        setPasswordType("password");
    };

    let navigate = useNavigate();

    const onSubmit = (data) => {
        if(state?.forgotData && state?.forgotData?.email){
            if(data.confirm_password !== state?.forgotData?.password){
                toast.error("Password should match");
            }else{
                const requestData = {
                    emailId:state?.forgotData?.email,
                    newPassword: state?.forgotData?.password
                };
                dispatch(resetPassword(requestData))
                .unwrap()
                .then((res) => {
                    toast.success(res?.message);
                    navigate('/login', {state: {forgotData: {}}});
                }).catch((error) => {
                    toast.error(error?.message);
                });
            }
        }else{
            if(data.confirm_password !== state?.registerData?.password){
                toast.error("Password should match");
            }else{
                if(state?.registerData?.fromInvite){
                    const requestData = {...state?.registerData};
                    delete requestData?.fromInvite;
                    delete requestData?.companyName;
                    delete requestData?.eKYCRequired;

                    dispatch(joinCompany(requestData)).unwrap()
                    .then(res => {
                        dispatch(setUserLoggedInData(res?.data));
                        navigate('/expenses');
                    })
                    .catch(error => {
                        toast.error(error?.message);
                    });
                } else {
                    navigate('/searchCompany', { state: { registerData: state?.registerData } });
                }
            }
        }
    };

    return (
        <section className="vh-100">
            {
                isLoading && <Loader />
            }
            <div className="container-fluid vh-100" style={{ backgroundColor: '#24473D' }}>
                <div className="row d-flex justify-content-between align-items-center h-100 " >
                    <div className=" d-flex flex-column justify-content-between align-items-center my-2">
                        <div className='form-div'>
                                <form className='form' onSubmit={handleSubmit(onSubmit)} >
                                <div>
                                    <img src={ Logo2} className="logo mt-1 mb-4" alt="Sample image" />
                                </div>
                                <h1 style={{ maxWidth: '320px' }} className='text-blue-login my-2'>
                                    {t('login.confirm_your_password')}
                                </h1>
                                <div className="divider d-flex align-items-center my-2">
                                    <p className="mb-0 text-subtitle2">
                                        {t('login.let’s_make_sure_you_got_it_right')}
                                    </p>
                                </div>

                                <div className="otp form-outline mt-4 mb-3">
                                    <div className="form-outline mb-4 position-relative">
                                        <Controller
                                            name="confirm_password"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type={passwordType}
                                                    className="form-control form-control-lg"
                                                    placeholder={`${t('login.enter_password')}`}
                                                />
                                            )}
                                        />

                                        <div className='eye-icon-password' onClick={togglePassword}>
                                            {passwordType === "password" ? <FaEyeSlash size={25} color='#037AD0' /> : <FaEye color='#037AD0' size={25} />}
                                        </div>
                                    </div>
                                    <p className="text-danger"  style={{width:305}}>{errors?.confirm_password?.message}</p>
                                </div>

                                <div className="text-center text-lg-start mt-4 w-100">
                                    <button type="submit" className="btn btn-primary btn-lg btn-block w-100" style={{ position: 'relative', zIndex:'1'}}>{t('login.continue')}</button>
                                </div>
                            </form>
                        </div>
                        <img className='login_bottom_image-1'  src={login_bottom_image} />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ConfirmPasscode;
