// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ConfirmationDialog .modal-header {
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
}
#ConfirmationDialog .modal-header .modal-title {
  margin-bottom: 0 !important;
}
#ConfirmationDialog .modal-body {
  padding: 30px 20px !important;
  font-size: 18px;
  font-weight: 600;
}

.profileicon {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  margin-right: 8px;
  margin-left: 8px;
}
@media only screen and (max-width: 500px) {
  .profileicon {
    margin-right: 3px;
    margin-left: 3px;
    width: 30px;
    height: 30px;
  }
}
.profileicon img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.profileicon div {
  background-color: #e5f0f9;
  color: #1570EF;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  padding: 10px;
  border-radius: 50%;
  height: 100%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/index.scss"],"names":[],"mappings":"AACI;EACI,4FAAA;AAAR;AAEQ;EACI,2BAAA;AAAZ;AAII;EACI,6BAAA;EACA,eAAA;EACA,gBAAA;AAFR;;AAMA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;AAHJ;AAKI;EAPJ;IAQQ,iBAAA;IACA,gBAAA;IACA,WAAA;IACA,YAAA;EAFN;AACF;AAII;EACI,WAAA;EACA,YAAA;EACA,kBAAA;AAFR;AAKI;EACI,yBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;AAHR","sourcesContent":["#ConfirmationDialog {\n    .modal-header {\n        border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);\n\n        .modal-title {\n            margin-bottom: 0 !important;\n        }\n    }\n\n    .modal-body {\n        padding: 30px 20px !important;\n        font-size: 18px;\n        font-weight: 600;\n    }\n}\n\n.profileicon {\n    width: 37px;\n    height: 37px;\n    border-radius: calc(50%);\n    margin-right: 8px;\n    margin-left: 8px;\n\n    @media only screen and (max-width: 500px) {\n        margin-right: 3px;\n        margin-left: 3px;\n        width:30px;\n        height: 30px;\n    }\n\n    img {\n        width: 100%;\n        height: 100%;\n        border-radius: calc(50%);\n    }\n\n    div {\n        background-color: #e5f0f9;\n        color: #1570EF;\n        font-size: 14px;\n        line-height: 20px;\n        letter-spacing: 0em;\n        text-align: center;\n        padding: 10px;\n        border-radius: 50%;\n        height: 100%;\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
