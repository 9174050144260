import "./index.scss";

import React, { useEffect, useState } from "react";
import {
  addTag,
  addTagGroup,
  deleteTag,
  deleteTagGroup,
  getAllTagByGroup,
  getTagDetailsById,
  getTagsList,
  updateTag,
  updateTagGroup,
  updateTags,
} from "../../pages/Settings/taxSlice";
import { useDispatch, useSelector } from "react-redux";

import AddIcon from "../../assets/images/table-add-icon.png";
import ConfirmationDialog from "../ConfirmationDialog";
import DeleteIcon from "../../assets/images/table-close-icon.png";
import Editicon from "../../assets/images/table-edit-icon.png";
import ImagesCross from "../../assets/images/close-square-8.png";
import Loader from "../../components/Loader/Loader";
import Modal from "react-bootstrap/Modal";
import cookies from "js-cookie";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

function Tags(props) {
  const { connectedIntegration } = props;
  const [show, setShow] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const { t } = useTranslation();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseShowSecondModal = () => setShowSecondModal(false);
  const handleShowSecondModal = () => setShowSecondModal(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const dispatch = useDispatch();
  const { isLoading, tagGroupData, tagDetail, oddoTagsList } = useSelector(
    (state) => state?.taxSlice
  );
  const form2 = useForm();
  const form2Error = form2.formState?.errors;
  const [tagGroupId, setTagGroupId] = useState(null);
  const [tagId, setTagId] = useState(null);
  const [startDeleteTag, setStartDeleteTag] = useState(null);
  const [startDeleteTagGroup, setStartDeleteTagGroup] = useState(null);
  const [updateConformation, setUpdateConformation] = useState(false);
  const form3 = useForm();
  const form3Error = form3?.formState?.errors;
  useEffect(() => {
    dispatch(getAllTagByGroup());
    dispatch(getTagsList());
  }, []);

  useEffect(() => {
    if (tagId && tagDetail && Object.keys(tagDetail)?.length > 0) {
      form2.setValue("valueId", tagDetail?.valueId);
      form2.setValue("value", tagDetail?.value);
      handleShowSecondModal();
    }
  }, [tagDetail]);

  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "ar",
      name: "العربية",
      dir: "rtl",
      country_code: "sa",
    },
  ];

  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  const onSubmit = (data) => {
    dispatch(addTagGroup(data))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        handleClose();
        dispatch(getAllTagByGroup());
        reset();
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const onSubmit2 = (data) => {
    if (tagId) {
      data["tagGroupId"] = tagDetail?.tagGroupId;
      data["tagId"] = tagId;
      dispatch(updateTag(data))
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          form2.reset();
          handleCloseShowSecondModal();
          dispatch(getAllTagByGroup());
        })
        .catch((error) => {
          toast.error(error?.message);
        });
    } else {
      data["valueId"] = data["value"];
      data["tagGroupId"] = tagGroupId;
      dispatch(addTag(data))
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          handleCloseShowSecondModal();
          dispatch(getAllTagByGroup());
          form2.reset();
        })
        .catch((error) => {
          toast.error(error?.message);
        });
    }
  };
  const handelTagDetail = (id) => {
    setTagId(id);
    dispatch(getTagDetailsById(id));
  };
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleConfirm = () => {
    if (startDeleteTag) {
      dispatch(deleteTag(tagId))
        .unwrap()
        .then((res) => {
          dispatch(getAllTagByGroup());
          toast.success(res?.message);
          setShowConfirmation(false);
          setStartDeleteTag(null);
          setTagId(null);
        })
        .catch((error) => {
          toast.error(error?.message);
        });
    }
    if (startDeleteTagGroup) {
      dispatch(deleteTagGroup(tagGroupId))
        .unwrap()
        .then((res) => {
          dispatch(getAllTagByGroup());
          toast.success(res?.message);
          setShowConfirmation(false);
          setStartDeleteTagGroup(null);
          setTagId(null);
        })
        .catch((error) => {
          toast.error(error?.message);
        });
    }
  };
  const handleUpdateConformationToggle = () => {
    setUpdateConformation((prev) => !prev);
  };
  const handleUpdate = (data) => {
    dispatch(updateTagGroup({ tagGroupId: tagGroupId, body: data }))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        setTagGroupId(null);
        setUpdateConformation();
        dispatch(getAllTagByGroup());
        form3.resetField("name");
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(error?.name);
      });
  };

  const handelTagMapping = (e, tagId) => {
    const { value } = e.target;
    const payload = {
      tagIntegrationId: value || "",
      tagId: tagId,
    };
    dispatch(updateTags(payload))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        setTagGroupId(null);
        dispatch(getAllTagByGroup());
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  return (
    <div className="taxcodes">
      {isLoading && <Loader />}
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ marginBottom: 12 }}
      >
        <h2 className="card-title-black-text">
          {t("settingstab.list_of_current_tags")}
        </h2>
        <button onClick={handleShow} className="btn-primary-small" id="show_adding_group">
          {t("New Tag group")}
        </button>
      </div>
      <div className="row">
        {tagGroupData &&
          tagGroupData?.map((tag) => (
            <div className="col-lg-12 col-md-12 p-0" key={tag?.tagGroupId}>
              <div className="border-grey p-3 pb-0" style={{ margin: "12px" }}>
                <div className="d-flex align-items-center justify-content-between mb-3 green-header px-3">
                  <h2 className="green-header-text">{t(tag?.label)}</h2>
                  <div className="d-flex align-items-center">
                    <div
                      onClick={() => {
                        setShowConfirmation(true);
                        setTagGroupId(tag?.tagGroupId);
                        setStartDeleteTagGroup(true);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={DeleteIcon}
                        style={{ width: 24, objectFit: "contain" }}
                      />
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleUpdateConformationToggle();
                        setTagGroupId(tag?.tagGroupId);
                        form3.setValue("name", tag?.label);
                      }}
                    >
                      <img
                        src={Editicon}
                        style={{
                          width: 24,
                          objectFit: "contain",
                          marginRight: 16,
                          marginLeft: 16,
                        }}
                      />
                    </div>
                    <div
                      onClick={() => {
                        form2.reset();
                        handleShowSecondModal();
                        setTagGroupId(tag?.tagGroupId);
                        setTagId(null);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={AddIcon}
                        style={{ width: 24, objectFit: "contain" }}
                      />
                    </div>
                  </div>
                </div>
                <div></div>
                <div className="table-responsive" style={{ border: 0 }}>
                  <table
                    id="dtBasicExample"
                    style={{
                      borderCollapse: "separate",
                      borderSpacing: "0 16px",
                    }}
                    cellSpacing="0"
                    width="100%"
                  >
                    <thead>
                      <tr className="table-head">
                        <th scope="col">{t("tag")}</th>
                        {connectedIntegration?.isConnected && (
                          <th scope="col">{t("settingstab.mapping")}</th>
                        )}
                        <th scope="col">{t("actions")}</th>
                      </tr>
                    </thead>
                    {tag &&
                      tag?.tags?.map((el) => (
                        <tbody className="table-body" key={el?.tagId}>
                          <tr
                            className={`${currentLanguageCode === "en"
                              ? "table-body-tr"
                              : "table-body-tr-arabic"
                              }`}
                          >
                            <td>{el?.value}</td>
                            {connectedIntegration?.isConnected && (
                              <td>
                                <select
                                  className="form-select form-control-lg"
                                  onChange={(e) => {
                                    handelTagMapping(e, el?.tagId);
                                  }}
                                  value={el?.tagIntegrationId}
                                >
                                  <option value="">Select account</option>
                                  {oddoTagsList.map((account, index) => (
                                    <option key={index} value={account?.id}>
                                      {account?.name}
                                    </option>
                                  ))}
                                </select>
                              </td>
                            )}
                            <td>
                              <a style={{ cursor: "pointer" }}>
                                <span
                                  onClick={() => {
                                    handelTagDetail(el?.tagId);
                                  }}
                                  className="tableEditBtn"
                                  id={`edit_tag_${el?.tagId}`}
                                >
                                  {t("edit")}
                                </span>
                              </a>
                              <a
                                style={{
                                  cursor: "pointer",
                                  marginLeft: 10,
                                  marginRight: 10,
                                }}
                                id={`delete_tag_${el?.tagId}`}
                              >
                                <span
                                  onClick={() => {
                                    setShowConfirmation(true);
                                    setTagId(el?.tagId);
                                    setStartDeleteTag(true);
                                  }}
                                  className="tableDeleteBtn"
                                >
                                  {t("delete")}
                                </span>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                  </table>
                </div>
              </div>
            </div>
          ))}
      </div>
      <Modal
        className="UserModal"
        show={show}
        onHide={handleClose}
        style={{ paddingRight: 0 }}
      >
        <Modal.Body>
          <div
            className="d-flex align-items-center justify-content-between green-header px-3"
            style={{ marginBottom: 8 }}
          >
            <h2 className="green-header-text modal-title">
              {t("settingstab.add_tag")}
            </h2>
            <div
              onClick={() => {
                handleClose();
                reset();
              }}
              className="clo-2"
              style={{ cursor: "pointer" }}
            >
              <img
                src={ImagesCross}
                style={{ width: 24, objectFit: "contain" }}
              />
            </div>
          </div>
          <p className="modal-text-gray-line" style={{ marginLeft: 15 }}>
            {t("settingstab.add_tag_desc")}
          </p>
          <form>
            <div className="form-outline mt-4 mb-3">
              <label className="form-label" htmlFor="label">
                {t("settingstab.label")}
              </label>
              <input
                type="text"
                id="label"
                className="form-control form-control-lg"
                placeholder={`${t("settingstab.enter_label")}`}
                {...register("label", {
                  required: `${t("settingstab.enter_label")}`,
                  pattern: {
                    value: /^[A-Za-z0-9\u0000-\uFFFF ]+$/,
                    message: `${t(
                      "settingstab.Only_alphaNumberic_values_are_required"
                    )}`,
                  },
                })}
              />
              {errors?.label && (
                <p style={{ color: "red" }}>{errors?.label?.message}</p>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="px-0 pt-4">
          <button
            onClick={() => {
              handleClose();
              reset();
            }}
            className="btn-secondary-small"
            id="canel_add_group"
          >
            {t("cancel")}
          </button>
          <button
            onClick={handleSubmit(onSubmit)}
            className="btn-primary-small"
            id="confirm_add_group"
          >
            {t("confirm")}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="UserModal h-100"
        show={showSecondModal}
        onHide={handleCloseShowSecondModal}
        style={{ paddingRight: 0 }}
      >
        <Modal.Body>
          <div
            className="d-flex align-items-center justify-content-between green-header px-3"
            style={{ marginBottom: 8 }}
          >
            <h2 className="green-header-text">
              {" "}
              {t(
                tagId
                  ? "settingstab.update_value_to_city_tag"
                  : "Add Tag to Project"
              )}
            </h2>
            <div
              onClick={() => {
                handleCloseShowSecondModal();
                setTagGroupId(null);
                form2.reset();
                setTagId(null);
              }}
              className="clo-2"
              style={{ cursor: "pointer" }}
            >
              <img
                src={ImagesCross}
                style={{ width: 24, objectFit: "contain" }}
              />
            </div>
          </div>
          <p className="modal-text-gray-line">
            {t(
              tagId ? "settingstab.update_value_to_city_tag_desc" : "Add a tag"
            )}
          </p>
          <form>
            <div className="form-outline mt-4 mb-3">
              <label className="form-label" htmlFor="Tag">
                {t("Tag")}
              </label>
              <input
                type="text"
                id="tag"
                className="form-control form-control-lg"
                placeholder={`${t("enter_tag")}`}
                {...form2.register("value", {
                  required: `${t("enter_tag")}`,
                  pattern: {
                    value: /^[A-Za-z0-9\u0000-\uFFFF ]+$/,
                    message: `${t(
                      "settingstab.Only_alphaNumberic_values_are_required"
                    )}`,
                  },
                })}
              />
              {form2Error?.value && (
                <p style={{ color: "red" }}>{form2Error?.value?.message}</p>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="px-0 pt-4">
          <button
            onClick={() => {
              handleCloseShowSecondModal();
              setTagGroupId(null);
              form2.reset();
              setTagId(null);
            }}
            id="cancel_add_tag"
            className="btn-secondary-small"
          >
            {t("cancel")}
          </button>
          <button
            onClick={form2.handleSubmit(onSubmit2)}
            className="btn-primary-small"
            id="confirm_add_tag"
          >
            {t("confirm")}
          </button>
        </Modal.Footer>
      </Modal>
      <ConfirmationDialog
        show={showConfirmation}
        onConfirm={handleConfirm}
        onClose={() => {
          setShowConfirmation(false);
          setTagId(null);
          setStartDeleteTagGroup(null);
          setStartDeleteTag(null);
        }}
        message="Are you sure you want to proceed to delete?"
      />
      <Modal
        className="TaxcodeModalOne"
        show={updateConformation}
        onHide={() => {
          handleUpdateConformationToggle();
          form3.resetField("name");
          setTagGroupId(null);
        }}
        centered
        id=""
        backdrop="static"
      >
        <Modal.Body>
          <div
            className="d-flex align-items-center justify-content-between green-header px-3"
            style={{ marginBottom: 8 }}
          >
            <h2 className="green-header-text">{t("confirmation")}</h2>
            <div
              onClick={() => {
                handleUpdateConformationToggle();
              }}
              className="clo-2"
              style={{ cursor: "pointer" }}
            >
              <img
                src={ImagesCross}
                style={{ width: 24, objectFit: "contain" }}
              />
            </div>
          </div>
          <p className="modal-text-gray-line">
            {t(
              tagId
                ? "settingstab.update_value_to_city_tag_desc"
                : "settingstab.add_value_to_city_tag_desc"
            )}
          </p>
          <div className="mt-4">
            {"Are you sure you want to update the group Name"}
            <input
              type="text"
              id="form3Example3"
              className="form-control form-control-lg mt-4"
              placeholder={`${t("settingstab.enter_groupName")}`}
              {...form3.register("name", {
                required: `${t("settingstab.enter_groupName")}`,
              })}
            />
            {form3Error?.name && (
              <p style={{ color: "red" }}>{form3Error?.name?.message}</p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="px-0 pt-4">
          <button
            className="btn-secondary-small"
            onClick={() => {
              handleUpdateConformationToggle();
              form3.resetField("name");
              setTagGroupId(null);
            }}
            id="cancel_edit_group"
          >
            {" "}
            {t("cancel")}{" "}
          </button>
          <button
            className="btn-primary-small me-2"
            onClick={form3.handleSubmit(handleUpdate)}
            id="confirm_edit_group"
          >
            {" "}
            {t("confirm")}{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Tags;
