/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { fetchMasterAccountBalance, addFund, withDrawFund } from './slice/masterAccountBalanceSlice';
import { exportMasterAccountRecords, fetchMasterTransactionList, fetchMasterTransactionDetail, exportAllMasterAccountRecords } from './slice/masterAccountTransactionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Loader from '../../components/Loader/Loader';
import { formatTime, formatQAR, cleanAmount } from '../../utils/XpendlessUtils';
import CrossImage from '../../assets/images/close-square-8.png';
import Transaction from '../../assets/images/transaction.png';
import AccountIcon from '../../assets/images/Accounts icon.png';
import lineBackground from '../../assets/images/backgroundLineDesign.png';
import cookies from 'js-cookie';
import "./Accounts.scss";
import moment from 'moment';
import SearchIcon from '../../assets/images/search_img.png';
import OtpInput from 'react-otp-input';
import Logo2 from '../../assets/images/polo.png';
import notFound from '../../assets/images/not_found.png';

function Accounts() {
  const [show, setShow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showTwo, setShowTwo] = useState(false);
  const [showThree, setShowThree] = useState(false);
  const [checkedAllPages, setChekedAllPages] = useState(false);
  const [pseudoCheckedAll, setPseudoCheckedAll] = useState(false);
  const [uncheckedTransaction, setUncheckedTransaction] = useState([]);
  const { masterBalance, masterBalanceIsLoading } = useSelector(state => state.masterAccountBalanceReducer);
  const { masterAccountTransactions, isLoading, transtionDetails } = useSelector(state => state.masterAccountTransactionReducer);
  const [pageNo, setPageNo] = useState(1);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const handleShowVerifyModal = () => setShowVerifyModal(true);
  const handleCloseVerifyModal = () => setShowVerifyModal(false);
  const [fundsData, setFundsData] = useState('');
  const [fundsType, setFundsType] = useState('addFunds');
  const [timer, setTimer] = useState(60);
  const [isDescription1Expanded, setIsDescription1Expanded] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [wholePart, decimalPart] = (masterBalance?.balance) ? masterBalance?.balance.toString().split('.').map((part, index) => (index === 1 ? part.padEnd(2, '0') : Number(part))) : ['00', '00'];
  const [detailWholePart, detailDecimalPart] = (transtionDetails?.amount) ? transtionDetails?.amount.toString().split('.').map((part, index) => (index === 1 ? part.padEnd(2, '0') : Number(part))) : ['00', '00'];
  const handleCloseModal = () => setShow(false);
  const handleShow = () => {
    resetAddForm();
    resetWithdrawForm();
    setShow(true);
    setFundsType('addFunds');
  };
  const languages = [
    {
      code: 'en',
      name: 'English',
      country_code: 'gb',
    },
    {
      code: 'ar',
      name: 'العربية',
      dir: 'rtl',
      country_code: 'sa',
    },
  ];

  const transactionTypes = {
    addFund: "Add Fund",
    withdrawFund: "Withdraw Fund",
    cardTopUpAdmin: "Card Topup by Admin",
    cardWithdrawAdmin: "Card Withdraw by Admin",
    cardTopUpRequest: "Card Topup based on request",
  };

  const currentLanguageCode = cookies.get('i18next') || 'en';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  const {
    control: controlFilterForm,
    handleSubmit: handleFilterSubmit,
    formState: { errors: errorsFilterForm },
    reset: resetFilterForm,
    watch,
  } = useForm({
    resolver: yupResolver(yup.object().shape({
      startDate: yup.string().optional(),
      endDate: yup.string().optional().test('is-valid-end-date', 'End date should not be before start date', function (value) {
        const startDate = this.resolve(yup.ref('startDate'));
        return !startDate || !value || value >= startDate;
      }),
      transactionType: yup.string().optional(),
      fromAmount: yup.string().optional().matches(/^\d*\.?\d*$/, 'Please enter valid amount'),
      toAmount: yup.string().optional().matches(/^\d*\.?\d*$/, 'Please enter valid amount').test('is-greater-than-from', 'To amount must be greater than from amount', function (value) {
        const fromAmount = this.resolve(yup.ref('fromAmount'));
        return !fromAmount || !value || parseFloat(value) > parseFloat(fromAmount);
      }),
      search: yup.string().optional(),
    })
    )
  });

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr';
    document.title = t('app_title');
  }, [currentLanguage, t]);

  const handleCloseModalTwo = () => setShowTwo(false);
  const handleShowTwo = () => {
    resetAddForm();
    resetWithdrawForm();
    setShowTwo(true);
    setFundsType('withdrawFunds');
  };

  const handleCloseModalThree = () => setShowThree(false);
  const handleShowThree = (id) => {
    dispatch(fetchMasterTransactionDetail(id))
      .then(() => {
        setShowThree(true);
      });
  };

  // Fetch master account balance on component mount
  useEffect(() => {
    dispatch(fetchMasterAccountBalance());
  }, [dispatch]);

  useEffect(() => {
    const startDate = watch('startDate');
    const endDate = watch('endDate');
    const transactionType = watch('transactionType');
    const fromAmount = watch('fromAmount');
    const toAmount = watch('toAmount');
    const search = watch('search');

    let queryParams = `?page=${pageNo}&`;

    // Check if startDate and endDate exist and are non-empty
    if (startDate && endDate) {
      queryParams += `startDate=${startDate}&endDate=${endDate}&`;
    }
    if (fromAmount && toAmount) {
      queryParams += `fromAmount=${fromAmount}&toAmount=${toAmount}&`;
    }

    // Check if transactionType exists and is not undefined
    if (transactionType !== undefined) {
      queryParams += `transactionType=${transactionType}&`;
    }
    if (search !== undefined && search !== "") {
      queryParams += `search=${search}&`;
    }

    // Remove trailing '&' if present
    queryParams = queryParams.slice(0, -1);

    // Dispatch action with query parameters
    dispatch(fetchMasterTransactionList(queryParams)).unwrap().then(res => {
      if (pseudoCheckedAll) {
        if (res?.data?.rows?.length > 0) {
          const selected = [...selectedItems];
          for (const row of res.data.rows) {
            if (!uncheckedTransaction?.includes(row?.masterAccountTransactionId) && !selected.some(element => element?.masterAccountTransactionId == row?.masterAccountTransactionId))
              selected.push({ masterAccountTransactionId: row?.masterAccountTransactionId });
          }
          setSelectedItems(selected);
        }
      }
    });
  }, [dispatch, pageNo, watch]);

  const onPreviousClick = () => {
    setPageNo(prev => prev - 1);
  };

  const onNextClick = () => {
    setPageNo(prev => prev + 1);
  };

  const { control: controlVerifyModal, handleSubmit, formState: { errors }, reset: otpFormReset } = useForm({
    resolver: yupResolver(yup.object().shape({
      otp: yup.string().required("Otp is required").matches(/^123456$/, 'OTP must be exactly 6 digits'),
    }))
  });

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  const onAddFundSubmit = () => {
    dispatch(addFund(fundsData)).unwrap().then(() => {
      handleCloseVerifyModal();
      dispatch(fetchMasterTransactionList(`?page=${pageNo}`));
      toast.success('Fund Added Successfully!');
      resetAddForm();
      otpFormReset({ otp: '' });
    }).catch((error) => {
      toast.error(error.message || "Request Failed Please Try Again ");
    });
  };

  const onClickVerifyModal = (data) => {
    if (fundsType === 'withdrawFunds' && data?.amount > masterBalance?.balance) {
      toast.error("Amount is greater then balance amount.")
      return;
    }
    setTimer(60)
    setFundsData(data);
    setShow(false);
    setShowTwo(false);
    otpFormReset({ otp: '' });
    setTimeout(() => {
      handleShowVerifyModal();
    }, 200);
  };

  const onWithdrawFundSubmit = () => {
    dispatch(withDrawFund(fundsData)).unwrap().then(() => {
      toast.success('Fund Withdrawal Successful!');
      dispatch(fetchMasterTransactionList(`?page=${pageNo}`));
      resetWithdrawForm();
      otpFormReset({ otp: '' });
      handleCloseVerifyModal();
    }).catch((error) => {
      toast.error(error.message || "Request Failed Please Try Again ");
    });
  };

  const { control: controlAddForm,
    handleSubmit: handleAddSubmit,
    formState: { errors: errorsAddForm },
    reset: resetAddForm, } = useForm({
      resolver: yupResolver(yup.object().shape({
        amount: yup
        .string()
        .required(`${t('pettyCash.amount_required')}`)
        .matches(/^\d*\.?\d*$/, 'Please enter valid amount'),
        transactionNarative: yup.string().required("Transaction Narative is required"),
      }))
    });

  const { control: controlWithdrawForm,
    handleSubmit: handleWithdrawSubmit,
    formState: { errors: errorsWithdrawForm },
    reset: resetWithdrawForm, } = useForm({
      resolver: yupResolver(yup.object().shape({
        amount: yup
        .string()
        .required(`${t('pettyCash.amount_required')}`)
        .matches(/^\d*\.?\d*$/, 'Please enter valid amount'),
        transactionNarative: yup.string().required("Transaction Narative is required"),
      }))
    });

  const handleCheckboxChange = (id) => {
    const selected = [...selectedItems];
    const unchecked = [...uncheckedTransaction];
    const index = selected.findIndex(
      (masterAccountTransaction) => masterAccountTransaction.masterAccountTransactionId == id
    );

    if (index === -1) {
      if (pseudoCheckedAll) {
        const uncheckedIndex = unchecked.findIndex((trans) => trans == id);
        unchecked.splice(uncheckedIndex, 1);
        setUncheckedTransaction(unchecked);
      }
      selected.push({ masterAccountTransactionId: id });
    } else {
      if (pseudoCheckedAll) {
        unchecked.push(id);
        setUncheckedTransaction(unchecked);
      }
      selected.splice(index, 1);
    }
    if (checkedAllPages)
      setChekedAllPages(false);
    setSelectedItems(selected);
  };

  const handleCheckAllPagesboxChange = () => {
    if (checkedAllPages) {
      setSelectedItems([]);
      setUncheckedTransaction([]);
      setChekedAllPages(false);
      setPseudoCheckedAll(false);
    } else {
      let allRecords = [];
      masterAccountTransactions?.rows?.map((element) => allRecords.push({
        masterAccountTransactionId: element?.masterAccountTransactionId,
      })
      );
      setUncheckedTransaction([]);
      setSelectedItems(allRecords);
      setChekedAllPages(true);
      setPseudoCheckedAll(true);
    }
  };

  const exportRecords = async (type = 'csv', selectedRecords = []) => {
    try {
      let reponse = null;
      if (pseudoCheckedAll) {
        const startDate = watch('startDate');
        const endDate = watch('endDate');
        const transactionType = watch('transactionType');
        const fromAmount = watch('fromAmount');
        const toAmount = watch('toAmount');
        const search = watch('search');

        let postData = {};

        if (startDate && endDate) {
          postData["startDate"] = startDate;
          postData["endDate"] = endDate;
        }
        if (fromAmount && toAmount) {
          postData["fromAmount"] = fromAmount;
          postData["toAmount"] = toAmount;
        }

        if (transactionType !== undefined) {
          postData["transactionType"] = transactionType;
        }
        if (search !== undefined && search !== "") {
          postData["search"] = search;
        }
        postData['unchecked'] = uncheckedTransaction;

        reponse = await dispatch(exportAllMasterAccountRecords(postData)).unwrap();
      } else {
        const postData = { type, selectedRecords };
        reponse = await dispatch(exportMasterAccountRecords(postData)).unwrap();

      }
      // Create a Blob containing the data
      let blob = '';
      if (type == 'csv') {
        blob = new Blob([reponse.data], { type: reponse.headers['content-type'] });
      }
      if (type == 'json') {
        blob = await new Blob([JSON.stringify(reponse.data)], { type: reponse.headers['content-type'] });
      }
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      const contentDisposition = reponse.headers['Content-disposition'];
      const match = contentDisposition && contentDisposition.match(/filename=(.*)/);
      const filename = match ? match[1] : 'exported';
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setSelectedItems([]);
      setChekedAllPages(false);
      setPseudoCheckedAll(false);
      setUncheckedTransaction([]);
      toast.success('Record Exported Successfully!');
    } catch (error) {
      toast.error(error.message || "Request Failed Please Try Again ");
    }
  };

  const onFilterFormSubmit = (requestData) => {
    setPageNo(1);

    let queryParams = `?page=${1}`;

    if (requestData?.startDate) {
      queryParams += `&startDate=${requestData.startDate}`;
    }

    if (requestData?.endDate) {
      queryParams += `&endDate=${requestData.endDate}`;
    }

    const fromAmount = requestData?.fromAmount ? cleanAmount(requestData.fromAmount) : '';
    const toAmount = requestData?.toAmount ? cleanAmount(requestData.toAmount) : '';

    if (fromAmount) {
      queryParams += `&fromAmount=${fromAmount.toLocaleString()}`;
    }

    if(toAmount){
      queryParams += `&toAmount=${toAmount.toLocaleString()}`;
    }

    if (requestData?.search !== undefined && requestData?.search !== "") {
      queryParams += `&search=${requestData.search}`;
    }

    if (requestData?.transactionType !== undefined) {
      queryParams += `&transactionType=${requestData.transactionType}`;
    }

    //queryParams = queryParams.slice(0, -1);

    console.log(queryParams);
    dispatch(fetchMasterTransactionList(queryParams)).unwrap().then(() => {
      setSelectedItems([]);
      setChekedAllPages(false);
      setPseudoCheckedAll(false);
      setUncheckedTransaction([]);
    }).catch((error) => {
      toast.error(error.message || "Request Failed Please Try Again ");
    });
  };

  const handleResetForm = () => {
    resetFilterForm();
    setSelectedItems([]);
    setChekedAllPages(false);
    setPseudoCheckedAll(false);
    setUncheckedTransaction([]);
  };

  const toggleView = () => {
    setIsDescription1Expanded(!isDescription1Expanded);
  };

  const getDescriptionText = (text, isExpanded) => {
    return text ? isExpanded ? text : `${text?.substring(0, 120)}${text?.length > 50 ? '...' : ''}` : '';
  };

  const getToggleText = (isExpanded) => {
    return isExpanded ? 'View Less' : 'View More';
  };

  const requiredFieldLabel = (labelText) => {
    return (
      <label className="form-label" htmlFor="form3Example3">
        {labelText}
        <span className="absolute top-0 left-0" style={{ transform: 'translate(-50%, -50%)', color: '#FF0000' }}>*</span>
      </label>
    )
  }

  return (
    <div className={`${currentLanguageCode === 'en' ? 'white-container accountsPageCss' : 'white-container-arabic accountsPageCss'}`}>
      {(isLoading || masterBalanceIsLoading) && <Loader />}
      <h1 className='text-green mb-3'>{t('sidebar.company_wallet')}</h1>
      <div className='col-12 cards-custom border-grey mt-4 p-3' style={{ position: 'relative' }}>
        <div className='d-flex align-items-center green-header p-2'>
          <img src={AccountIcon} style={{ width: 32, objectFit: 'contain' }} />
          <h2 className='text-green p-2'>{t('expenses.balance')}</h2>
        </div>
        <div className='d-flex align-items-center justify-content-between mt-4 mx-2'>
          <div className='d-flex align-items-end '>
            <h1 className='text-green box-1' style={{ fontSize: 36 }}>{wholePart.toLocaleString()}.<span className='text-grey' style={{ fontSize: 16, fontWeight: '600' }}>{`${(decimalPart) ? decimalPart : '00'} QAR`}</span></h1>
          </div>
        </div>
        <div className='d-flex align-items-center flex-wrap p-2 '>
          <button onClick={handleShow} style={{ zIndex: 2 }} className='btn-success-green cursor-pointer mt-3 '>{t('accounts.add_funds')}</button>
          <div className='pe-3'></div>
          <button onClick={handleShowTwo} style={{ zIndex: 2 }} className='btn-danger cursor-pointer mt-3 '>{t('accounts.withdraw_funds')}</button>
        </div>
        <img src={lineBackground} height={118} style={{ position: 'absolute', bottom: 0, right: 0, zIndex: 1, width: '100%' }} />
      </div>
      <div className='white_container mt-4 p-3 py-0'>
        <div className='d-flex align-items-center justify-content-between green-header px-2'>
          <div className='d-flex align-items-center'>
            <img src={SearchIcon} style={{ width: 24, objectFit: 'contain' }} />
            <h2 className='text-green px-1'>{t('cards.search')}</h2>
          </div>
        </div>
        <form onSubmit={handleFilterSubmit(onFilterFormSubmit)}>
          <div className='row align-items-end'>
            <div className="col-lg-4 col-md-6 col-sm-12 form-outline mt-4 ">
              <label className="form-label" htmlFor="search">{t('cards.employee_name')}</label>
              <Controller
                name="search"
                control={controlFilterForm}
                render={({ field }) => (
                  <input
                    type="text"
                    id="search"
                    className="form-control form-control-lg"
                    placeholder="Name"
                    autoComplete="off"
                    value={field.value || ''} // Set value to empty string if it's undefined
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  />
                )}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 form-outline mt-4 ">
              <label className="form-label" htmlFor="startDate">Start Date</label>
              <Controller
                name="startDate"
                control={controlFilterForm}
                render={({ field }) => (
                  <input
                    type="date"
                    id="startDate"
                    className="form-control form-control-lg"
                    placeholder="Start Date"
                    autoComplete="off"
                    value={field.value || ''} // Set value to empty string if it's undefined
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    max={moment().format('YYYY-MM-DD')}
                    style={{
                      color: field.value ? 'black' : 'grey',
                    }}
                  />
                )}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 form-outline mt-4 ">
              <label className="form-label" htmlFor="endDate">End Date</label>
              <Controller
                name="endDate"
                control={controlFilterForm}
                render={({ field }) => (
                  <input
                    type="date"
                    id="endDate"
                    className="form-control form-control-lg"
                    placeholder="Start Date"
                    autoComplete="off"
                    value={field.value || ''} // Set value to empty string if it's undefined
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    max={moment().format('YYYY-MM-DD')}
                    style={{
                      color: field.value ? 'black' : 'grey',
                    }}
                  />
                )}
              />
              {errorsFilterForm?.endDate && (
                <div style={{ color: 'red' }}>{errorsFilterForm?.endDate?.message}</div>
              )}
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 form-outline mt-4 ">
              <label className="form-label" htmlFor="transactionType">Transaction Type </label>
              <Controller
                name="transactionType"
                control={controlFilterForm}
                render={({ field }) => (
                  <select
                    id='transactionType'
                    value={field.value || ''}
                    onChange={(e) => {
                      field.onChange(e);
                      e.target.classList.add('select-placeholder-selected');
                    }}
                    onBlur={field.onBlur}
                    className={`form-select form-control-lg ${currentLanguageCode === 'en' ? 'form-control' : 'form-control-arabic'} ${!field.value ? 'select-placeholder' : ''}`}
                    defaultValue=""
                  >
                    <option disabled value="">{'Select Options'}</option>
                    {[{ label: 'Credit', value: 'credit' }, { label: 'Debit', value: 'debit' }, { label: 'Transfer to card', value: 'cardTopUp' }].map((option, i) => (
                      <option value={option?.value} key={'transactionType' + i}>{option?.label}</option>
                    ))}
                  </select>
                )}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 form-outline mt-4 ">
              <label className="form-label" htmlFor="fromAmount">{t('expenses.from_amount')}</label>
              <Controller
                name="fromAmount"
                control={controlFilterForm}
                render={({ field }) => (
                  <CurrencyInput
                    type="text"
                    id="fromAmount"
                    className="form-control form-control-lg"
                    placeholder="From Amount"
                    autoComplete="off"
                    value={field.value || ''}
                    decimalsLimit={2}
                    decimalScale={2}
                    onBlur={field.onBlur}
                    onValueChange={
                      (value) => {
                        if (/^\d*\.?\d*$/.test(value)) {
                          field.onChange(value.startsWith('.') ? `0${value}` : value);
                        }
                      }}
                  />
                )}
              />
              {errorsFilterForm?.fromAmount && (
                <div style={{ color: 'red' }}>{errorsFilterForm?.fromAmount?.message}</div>
              )}
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 form-outline mt-4 ">
              <label className="form-label" htmlFor="toAmount">{t('expenses.to_amount')}</label>
              <Controller
                name="toAmount"
                control={controlFilterForm}
                render={({ field }) => (
                  <CurrencyInput
                    type="text"
                    id="toAmount"
                    className="form-control form-control-lg"
                    placeholder="To Amount"
                    autoComplete="off"
                    value={field.value || ''}
                    decimalsLimit={2}
                    decimalScale={2}
                    onBlur={field.onBlur}
                    onValueChange={
                      (value) => {
                        if (/^\d*\.?\d*$/.test(value)) {
                          field.onChange(value.startsWith('.') ? `0${value}` : value);
                        }
                      }}
                  />
                )}
              />
              {errorsFilterForm?.toAmount && (
                <div style={{ color: 'red' }}>{errorsFilterForm?.toAmount?.message}</div>
              )}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end mt-4">
            <button type='button' onClick={handleResetForm} className='btn-secondary-small mt-3 mx-3' >{`Clear Search`}</button>
            <button type='submit' className='btn-primary-small mt-3' >{t('cards.search')}</button>
          </div>
        </form>
      </div>
      <div className='custom-table border-grey mt-4 pb-0'>
        <div className='d-flex align-items-center justify-content-between mb-2 green-header p-2'>
          <div className='d-flex align-items-center'>
            <img src={Transaction} style={{ width: 35 }} className='small-img-size1 me-2' />
            <h2 className='text-green'>{t('accounts.all_transaction')}</h2>
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" value=""
              id="checkAllPages"
              checked={checkedAllPages}
              onChange={handleCheckAllPagesboxChange}
            />
            <label className="form-check-label" htmlFor="checkAllPages">
              {t('export.select_all')}
            </label>
          </div>
          <button onClick={() => exportRecords('csv', selectedItems)} style={{ borderRadius: 20 }} className='btn btn-primary btn-sm export-btn-style' disabled={selectedItems.length < 1} >{t('export.export')}</button>
        </div>
        <div className='table-responsive border-zero p-3 pb-0'>
          <table id="dtBasicExample" style={{ borderCollapse: 'separate', borderSpacing: '0 16px' }} cellSpacing="0" width="100%">
            <thead>
              <tr style={{ borderTopWidth: 0 }} className='table-head'>
                <th scope="col">{t('company.select')}</th>
                <th scope="col">{t('cards.no')}</th>
                <th scope="col">{t('cards.date_and_time')}</th>
                <th scope="col">{t('accounts.type_of_transaction')}</th>
                <th scope="col" className="text-end">{t('settingstab.Amount')}</th>
              </tr>
            </thead>
            <tbody>
              {
                (Object.keys(masterAccountTransactions)?.length > 0 && Object.keys(['rows']).length > 0) && masterAccountTransactions['rows']?.map((masterAccountTransaction, index) => {
                  const serialNumber = (masterAccountTransactions?.currentPage - 1) * 10 + index + 1;
                  return <tr key={index} className={`${currentLanguageCode === 'en' ? 'table-body-tr' : 'table-body-tr-arabic'}`}>
                    <td> <div>
                      <input className="form-check-input m-2" type="checkbox" value=""
                        id={masterAccountTransaction?.masterAccountTransactionId}
                        checked={selectedItems.some(
                          (res) =>
                            (res.masterAccountTransactionId === masterAccountTransaction?.masterAccountTransactionId)
                        )}
                        onChange={() => handleCheckboxChange(masterAccountTransaction.masterAccountTransactionId)}
                      /></div></td>
                    <td>{serialNumber}</td>
                    <td>{formatTime(masterAccountTransaction?.createdAt)}</td>
                    <td>{transactionTypes[masterAccountTransaction?.description]}</td>
                    <td className={masterAccountTransaction?.transType === 'credit' ? 'text-success text-end' : 'text-danger text-end'}>{formatQAR(masterAccountTransaction?.amount)} QAR</td>
                    <td onClick={() => handleShowThree(masterAccountTransaction?.masterAccountTransactionId)}>
                      <a><span style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer', color: "#00D094" }}>{t('view')}</span></a>
                    </td>
                  </tr>;
                }
                )
              }
            </tbody>
          </table>
          {!(masterAccountTransactions?.rows && masterAccountTransactions?.rows?.length > 0) && (
            <div className='d-flex align-items-center justify-content-center p-3 px-2'>
              <img src={notFound} width={200} height={200} />
            </div>)}
        </div>
        <div className='d-flex align-items-center justify-content-between' style={{ width: "100%", borderTop: '0px !imporant' }}>
          <nav aria-label="Page navigation example">
            <ul className="pagination my-3 pt-1 pe-0">
              <li className="page-item">
                <button className="btn page-link" disabled={pageNo === 1} onClick={() => onPreviousClick()}>{t('previous')}</button>
              </li>
              <li className="page-item mx-3">
                <button className="btn page-link" disabled={pageNo == masterAccountTransactions?.totalPages || masterAccountTransactions?.totalPages == 0} onClick={() => onNextClick()}> {t('next')}</button>
              </li>
            </ul>
          </nav>
          <div className='page-count d-flex align-items-center'>
            <h4 style={{ color: "#00D094" }}>Page {masterAccountTransactions?.currentPage} of {masterAccountTransactions?.totalPages}</h4>
          </div>
        </div>
      </div>
      <Modal className='TaxcodeModalOne' show={show} onHide={handleCloseModal} style={{ paddingRight: 0 }}>
        <form onSubmit={handleAddSubmit(onClickVerifyModal)}>
          <Modal.Body>
            <div className='d-flex justify-content-between align-items-start green-header'>
              <div className='col-9'>
                <p className='text-green addjust modal-title' style={{ fontSize: 20, marginRight: 15 }}>
                  {t('accounts.add_funds')}
                </p>
              </div>
              <div onClick={handleCloseModal} className='clo-2' style={{ cursor: 'pointer' }}>
                <img src={CrossImage} className='small-addjust' style={{ width: 20, objectFit: 'contain', marginLeft: 15 }} />
              </div>
            </div>
            <div className="form-outline mt-4 mb-3">
              {requiredFieldLabel(t('settingstab.Amount'))}
              <Controller
                name="amount"
                control={controlAddForm}
                render={({ field }) => (
                  <CurrencyInput 
                    type="text" 
                    id="settingAmount" 
                    className="form-control form-control-lg"
                    placeholder={`${t('settingstab.Amount')}`}
                    autoComplete="off"
                    decimalsLimit={2}
                    decimalScale={2}
                    onValueChange={
                      (value) => {
                        if (/^\d*\.?\d*$/.test(value)) {
                          field.onChange(value.startsWith('.') ? `0${value}` : value);
                        }
                      }}
                    />
                )}
              />
              <p className="text-danger">{errorsAddForm?.amount?.message}</p>
            </div>
            <div className="form-outline my-4">
              {requiredFieldLabel(t('accounts.narrative'))}
              <Controller
                name="transactionNarative"
                control={controlAddForm}
                defaultValue=""
                render={({ field }) => (
                  <input {...field} type="text" id="narrative" className="form-control form-control-lg"
                    placeholder={`${t('accounts.narrative')}`} />
                )}
              />
              <p className="text-danger">{errorsAddForm?.transactionNarative?.message}</p>
            </div>
            <div style={{ borderBottom: '1px solid #EAECF0', marginBottom: 24 }}></div>
            <div className='d-flex justify-content-end align-items-center'>
              <button type='button' onClick={(e) => { e.preventDefault(); handleCloseModal(); }} className='btn-secondary-small' >{t('cancel')}</button>
              <div className='pe-3'></div>
              <button type="submit" className='btn-primary-small' >{t('save')}</button>
            </div>
          </Modal.Body>
        </form>
      </Modal>
      <Modal className='TaxcodeModalOne' show={showTwo} onHide={handleCloseModalTwo} style={{ paddingRight: 0 }}>
        <form onSubmit={handleWithdrawSubmit(onClickVerifyModal)}>
          <Modal.Body>
            <div className='d-flex justify-content-between align-items-start green-header'>
              <div className='col-9'>
                <p className='text-green addjust modal-title' style={{ fontSize: 20, marginRight: 15 }}>
                  {t('accounts.withdraw_funds')}
                </p>
              </div>
              <div onClick={handleCloseModalTwo} className='clo-2' style={{ cursor: 'pointer' }}>
                <img src={CrossImage} className='small-addjust' style={{ width: 20, objectFit: 'contain', marginLeft: 15 }} />
              </div>
            </div>
            <div className="form-outline mt-4 mb-3">
              {requiredFieldLabel(t('settingstab.Amount'))}
              <Controller
                name="amount"
                control={controlWithdrawForm}
                render={({ field }) => (
                  <CurrencyInput 
                    type="text" 
                    id="settingAmount" 
                    className="form-control form-control-lg"
                    placeholder={`${t('settingstab.Amount')}`}
                    decimalsLimit={2}
                    decimalScale={2}
                    onValueChange={
                      (value) => {
                        if (/^\d*\.?\d*$/.test(value)) {
                          field.onChange(value.startsWith('.') ? `0${value}` : value);
                        }
                      }}
                  />
                )}
              />
              <p className="text-danger">{errorsWithdrawForm?.amount?.message}</p>
            </div>
            <div className="form-outline mt-4 mb-4">
              {requiredFieldLabel(t('accounts.narrative'))}
              <Controller
                name="transactionNarative"
                control={controlWithdrawForm}
                render={({ field }) => (
                  <input {...field} type="text" id="narrative" className="form-control form-control-lg"
                    placeholder={`${t('accounts.narrative')}`} />
                )}
              />
              <p className="text-danger">{errorsWithdrawForm?.transactionNarative?.message}</p>
            </div>
            <div style={{ borderBottom: '1px solid #EAECF0', marginBottom: 24 }}></div>
            <div className='d-flex justify-content-end align-items-center'>
              <button type='button' onClick={(e) => { e.preventDefault(); handleCloseModalTwo(); }} className='btn-secondary-small' >{t('cancel')}</button>
              <div className='pe-3'></div>
              <button type="submit" className='btn-primary-small' >{t('save')}</button>
            </div>
          </Modal.Body>
        </form>
      </Modal>
      <Modal className='UserModal third-modal' show={showThree} onHide={handleCloseModalThree} style={{ paddingRight: 0 }}>
        <Modal.Body>
          <div className='d-flex justify-content-between align-items-start green-header'>
            <div className='col-9'>
              <p className='text-green addjust modal-title' style={{ fontSize: 20, marginRight: 15 }}>
                {t('expenses.details')}
              </p>
            </div>
            <div onClick={handleCloseModalThree} className='clo-2' style={{ cursor: 'pointer' }}>
              <img src={CrossImage} className='small-addjust' style={{ width: 20, objectFit: 'contain', marginLeft: 15 }} />
            </div>
          </div>
          <p className='text-grey' style={{ fontWeight: 400, fontSize: 16, paddingLeft: 13 }}>{t('accounts.Review_the_comprehensive_details_of_the_transaction')}</p>
          <div className='green-box p-3 mt-4'>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='green-text'>{detailWholePart.toLocaleString()}.<span style={{ color: '#3EC279', fontSize: 14, fontWeight: '500' }}>{detailDecimalPart ?? '00'} QAR</span></div>
              <div className='black-text'>{transtionDetails?.firstName + " " + transtionDetails?.lastName}</div>
            </div>
            <div className='d-flex align-items-center justify-content-between mt-3'>
              <div className='grey-text'>{formatTime(transtionDetails?.updatedAt)}</div>
              <div className='grey-text' style={{ fontSize: 16 }}>{moment(transtionDetails?.updatedAt).format('hh:mm A')}</div>
            </div>
          </div>
          <div className='border-grey mt-3 p-3' style={{ borderRadius: 15 }}>
            <div className='mb-3'>
              <div className='black-text'>Type of Transaction</div>
              <p className='text-grey' style={{ fontWeight: 400, fontSize: 16 }}>{transactionTypes[transtionDetails?.description]}</p>
            </div>
            <div className='mb-3'>
              <div className='black-text'>Description 1</div>
              <p className='text-grey' style={{ fontWeight: 400, fontSize: 16 }}>{getDescriptionText(transtionDetails?.description1, isDescription1Expanded)}</p>
              {transtionDetails?.description1?.length > 120 && (
                <span onClick={toggleView} style={{ color: 'blue', cursor: 'pointer', fontSize: 16 }}>
                  {getToggleText(isDescription1Expanded)}
                </span>
              )}
            </div>
            <div className='mb-3'>
              <div className='black-text'>Description 2</div>
              <p className='text-grey' style={{ fontWeight: 400, fontSize: 16 }}>{transtionDetails?.description2} </p>
            </div>
            <div className='mb-3'>
              <div className='black-text'>Description 3</div>
              <p className='text-grey' style={{ fontWeight: 400, fontSize: 16 }}>{transtionDetails?.description3} </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        aria-labelledby="contained-modal-title-center"
        centered
        show={showVerifyModal}
        onHide={handleCloseVerifyModal}
      >

        <form className='form'
          style={{ padding: 24 }}
          onSubmit={handleSubmit(fundsType === 'addFunds' ? onAddFundSubmit : onWithdrawFundSubmit)}
        >
          <div className='d-flex align-items-start justify-content-between'>
            <img src={Logo2} className="logoso mt-1 mb-4" alt="Sample image" />
            <div onClick={handleCloseVerifyModal}>
              <img src={CrossImage} className='small-addjust' style={{ width: 20, objectFit: 'contain', marginLeft: 15 }} />
            </div>
          </div>
          <h1 className='text-blue-login mb-2'>
            {t('login.verify_your_mobile_number')}
          </h1>
          <div className="divider d-flex align-items-center my-2">
            <p className="mb-0 text-subtitle2" style={{ color: '#8B9299' }} >
              {t('login.verify_your_mobile_number_desc')}
            </p>
          </div>

          <div className="otp form-outline d-flex justify-content-center mt-4 mb-3">
            <Controller
              name="otp"
              control={controlVerifyModal}
              defaultValue={0}
              render={({ field: { onChange, value, ref }, }) => (
                <OtpInput
                  inputStyle="inputStyle"
                  value={value}
                  onChange={onChange}
                  shouldAutoFocus={true}
                  numInputs={6}
                  renderInput={(props) => <input {...props} onInput={(e) => {
                    const value = e.target.value;
                    if (!/^\d*$/.test(value)) {
                      e.target.value = value.slice(0, -1);
                    }
                  }} />}
                />
              )}
            />
          </div>
          <p className="text-danger">{errors?.otp?.message}</p>
          <div className="d-flex justify-content-end">
            {timer === 0 ? (
              <a href="javascript:void(0)" >Resend OTP</a>
            ) : (
              <p>Resend OTP in <span className='text-blue'>{timer} seconds</span> </p>
            )}
          </div>
          <div className="text-center text-lg-start mt-4 w-100">
            <button type="submit" className="btn btn-primary btn-lg btn-block w-100" style={{ position: 'relative', zIndex: '1' }}>{fundsType === 'addFunds' ? 'Continue to Add Funds' : 'Continue to Withdraw Funds'} </button>
          </div>

        </form>

      </Modal>
    </div>
  );
}

export default Accounts;
