import React from 'react';
import NoImage from '../assets/images/no-image.jpg';

const RenderImage = (props) => {
    const { imageUrl, imageStyle, altText='' } = props;

    const handleImageError = (event) => {
        // This function will be called when an error occurs while loading the image
        event.target.src = NoImage;
    };

    if (imageUrl && imageUrl !== '') {
        return (
            <img src={imageUrl} style={{ width: 56, height: 56, objectFit: 'contain', border: "1px solid #EAECF0", borderRadius: 5, ...imageStyle }} onError={handleImageError} alt={altText} />
        );
    } else {
        return <img src={NoImage} style={{ width: 56, height: 56, objectFit: 'contain', border: "1px solid #EAECF0", borderRadius: 5, ...imageStyle }} alt={altText}/>;
    }
};

export default RenderImage;
