import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '../../assets/images/squre_close.png';
import './Teams.scss';
import Modal from 'react-bootstrap/Modal';
import ImagesCross from '../../assets/images/close-square.png';
import ImagesprofileIcon from '../../assets/images/profileicon.png';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import UsersCard from '../../components/UsersComponent/UsersCard';
import { Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addDepartment, getDepartmentEmployee, addEmployee, employeeCount, getFecthReviewerByDepartment, updateFecthReviewerEmployee, fetchGlobalReviewerEmp, getAllCountries, updateEmployeeState } from './teamSlice';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Loader from '../../components/Loader/Loader';
import { getAllReviewerPolicy } from '../Settings/taxSlice';
import Accordion from 'react-bootstrap/Accordion';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import CrossImage from '../../assets/images/close-square-8.png';
import Department from '../../assets/images/department-icon.png';
import Reviewicon from '../../assets/images/reviews-icon.png';
import cookies from 'js-cookie';
import { useForm } from 'react-hook-form';
import { formatQAR } from '../../utils/XpendlessUtils';
import notFound from '../../assets/images/not_found.png';
import BackArrow from '../../assets/images/backarrow1.png';


const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'sa',
    },
];

function MainCompany() {
    const [show, setShow] = useState(false);
    const [showTwo, setShowTwo] = useState(false);
    const [showThree, setShowThree] = useState(false);
    const [department, setDepartment] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
    const [searchTab, setSearchTab] = useState('');
    const { isLoading, employeeCountData, departmentEmployees, fecthReviewerByDepartment, fetchGlobalReviewerEmpData, countriesData } = useSelector((state) => state?.teamSlice);
    const { reviewerPolicyData } = useSelector((state) => state?.taxSlice);
    const { t } = useTranslation();
    const [selectedValue, setSelectedValue] = useState('');
    const [details, setDetails] = useState({ showModal: false, item: null, checked: false, tab: '1' });
    const schema = yup.object().shape({
        firstName: yup
            .string()
            .matches(/^[A-Za-z ]+$/, "Only alphabets are required")
            .min(2, `${t('login.min_name_length')}`)
            .max(30, `${t('login.max_name_length')}`)
            .required(t('login.first_name')),
        lastName: yup
            .string()
            .matches(/^[A-Za-z ]+$/, "Only alphabets are required")
            .min(2, `${t('login.min_name_length')}`)
            .max(30, `${t('login.max_name_length')}`)
            .required(t('login.enter_your_last_name')),
        email: yup.string().matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/, 'Invalid email').required(t('enter_email_address')),
        gender: yup.string().required(t('users.select_gender')),
        nationalId: yup.string().required(t('users.enter_Id')).matches(/^\d{11}$/, 'National ID must be a valid number'),
        nationality: yup.string().required(t('users.select_nationality')),
        mobileNumber: yup
            .string()
            .required('Phone is required')
            .matches(/^\d{8,}$/, 'Mobile number must be at least 8 digits and contain only numbers')
    });
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });
    const dispatch = useDispatch();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseModalTwo = () => setShowTwo(false);
    const handleShowModalTwo = () => setShowTwo(true);
    const [employeeId, setEmployeeId] = useState(null);
    const handleCloseModalThree = () => setShowThree(false);
    const handleShowModalThree = () => setShowThree(true);
    const location = useLocation();
    const {departmentName, departmentId} = useParams()
    const paramsDepartment = location.state || {};
    const [backButtonObj, setBackButtonObj] = useState([]);
    const [backButtonDepartmentId, setBackButtonDepartmentId] = useState(null);
    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
    };
    useEffect(() => {
        dispatch(getAllReviewerPolicy());
        dispatch(getAllCountries());
    }, []);
    useEffect(() => {
        if (selectedValue === '' && fecthReviewerByDepartment?.data?.length > 0) setSelectedValue(fecthReviewerByDepartment?.data[0]?.reviewerPolicyId);
    }, [fecthReviewerByDepartment]);

    useEffect(() => {
        if (typeof departmentName != 'undefined' && typeof departmentId != 'undefined') {
           const newDepartment = {...paramsDepartment,departmentName, departmentId} 
           
            setDepartment(newDepartment);
            const newArray = [newDepartment];
            setBackButtonObj(newArray);
        }
    }, [departmentId]);

    useEffect(() => {
        if (department.departmentId) {
            dispatch(employeeCount(department.departmentId));
            dispatch(getFecthReviewerByDepartment(department.departmentId));
            dispatch(fetchGlobalReviewerEmp(department.departmentId));
            dispatch(getDepartmentEmployee({ departmentId: department.departmentId, para: null, eventType: 'teams' }));
        }
    }, [department]);

    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr';
        document.title = t('app_title');
    }, [currentLanguage, t]);

    useEffect(() => {
        if (searchTab === false) {
            const getData = setTimeout(() => {
                let params = ``;
                switch (details?.tab) {
                    case '1':
                        params = ``;
                        break;
                    case '2':
                        params = `isActive=true`;
                        break;
                    case '3':
                        params = `isActive=false`;
                        break;
                    case '4':
                        params = `invited=true`;
                        break;
                    case '5':
                        params = `isAccountant=true`;
                        break;
                    case '6':
                        params = `isDeleted=true`;
                        break;
                    case '7':
                        params = `isAdmin=true`;
                        break;
                    default:
                        break;
                }
                dispatch(getDepartmentEmployee({ departmentId: department.departmentId, para: `search=${searchQuery}&${params}`, eventType: 'teams' }));
            }, 1000);
            return () => clearTimeout(getData);
        }
    }, [searchQuery, dispatch]);

    let navigate = useNavigate();

    const routeChange = (id) => {
        let path = `/team/team-details/${id}`;
        navigate(path, { state: { employeeId: id, prevPath: location.pathname } });
    };
    const form2 = useForm();
    const form2Error = form2.formState.errors;
    const form3 = useForm();
    const form3Error = form3.formState?.errors;

    const onSubmit = (data) => {
        data.parentId = department.departmentId;
        dispatch(addDepartment(data))
            .unwrap()
            .then((res) => {
                toast.success(res?.name);
                form3.reset();
                handleCloseModalThree();
                dispatch(employeeCount(department.departmentId));
                dispatch(getFecthReviewerByDepartment(department.departmentId));
                dispatch(fetchGlobalReviewerEmp(department.departmentId));
                dispatch(getDepartmentEmployee({ departmentId: department.departmentId, para: null, eventType: 'teams' }));
            }).catch((error) => {
                toast.error(error?.message);
            });
    };
    const onPoiclyUpdate = (id, e) => {
        e.preventDefault();
        const data = {
            employeeId: id,
            departmentId: department.departmentId,
            reviewerPolicyId: selectedValue,
            isReviewer: 'true'
        };
        dispatch(updateFecthReviewerEmployee(data))
            .then((res) => {
                console.log('res', res);
                toast.success(res?.payload?.message);
            }).catch((error) => {
                toast.error(error?.message);
            });
    };
    const OnDelete = () => {
        const data = {
            employeeId: employeeId,
            departmentId: department.departmentId,
            reviewerPolicyId: selectedValue,
            isReviewer: false
        };
        dispatch(updateFecthReviewerEmployee(data))
            .then((res) => {
                dispatch(getFecthReviewerByDepartment(department.departmentId));
                toast.success(res?.payload?.message || "Reviewer Removed Successfully");
                setShowConfirmation(false);
            }).catch((error) => {
                toast.error(error?.message);
            });
    };

    const onSubmit3 = (data) => {
        data['departmentId'] = department.departmentId;

        const fullMobileNumber = `+974${data?.mobileNumber}`;
        const newData = { ...data, mobileNumber: fullMobileNumber, eKYCStatus: false };

        dispatch(addEmployee(newData))
            .unwrap()
            .then((res) => {
                toast.success(res?.message);
                reset();
                handleClose();
                dispatch(employeeCount(department.departmentId));
                dispatch(getFecthReviewerByDepartment(department.departmentId));
                dispatch(fetchGlobalReviewerEmp(department.departmentId));
                dispatch(getDepartmentEmployee({ departmentId: department.departmentId, para: null, eventType: 'teams' }));
            }).catch((error) => {
                toast.error(error?.message);
            });
    };

    const filterData = () => {
        const data = reviewerPolicyData && reviewerPolicyData?.filter(el => {
            if ((el?.reviewerPolicyId === form2.watch('reviewerPolicyId'))) return el;
        });
        return data;
    };
    let data = filterData();

    const onSubmit2 = (data) => {
        data['isReviewer'] = true;
        data['departmentId'] = department.departmentId;
        dispatch(updateFecthReviewerEmployee(data))
            .unwrap()
            .then((res) => {
                dispatch(getFecthReviewerByDepartment(department.departmentId));
                toast.success(res?.message);
                form2.reset();
                handleCloseModalTwo();
                dispatch(employeeCount(department.departmentId));
                dispatch(getFecthReviewerByDepartment(department.departmentId));
                dispatch(fetchGlobalReviewerEmp(department.departmentId));
                dispatch(getDepartmentEmployee({ departmentId: department.departmentId, para: null, eventType: 'teams' }));
            }).catch((error) => {
                toast.error(error?.message);
            });
    };
    const handleTab = (key) => {
        setSearchQuery('');
        switch (key) {
            case '1':
                dispatch(getDepartmentEmployee({ departmentId: department.departmentId, para: null, eventType: 'teams' }));
                break;
            case '2':
                dispatch(getDepartmentEmployee({ departmentId: department.departmentId, para: 'isActive=true', eventType: 'teams' }));
                break;
            case '3':
                dispatch(getDepartmentEmployee({ departmentId: department.departmentId, para: 'isActive=false', eventType: 'teams' }));
                break;
            case '4':
                dispatch(getDepartmentEmployee({ departmentId: department.departmentId, para: 'invited=true', eventType: 'teams' }));
                break;
            case '5':
                dispatch(getDepartmentEmployee({ departmentId: department.departmentId, para: 'isAccountant=true' }));
                break;
            default:
                break;
        }
    };

    const routeChangeToTeam = (departmentId) => {
        if (departmentId) {
            const filteredArray = backButtonObj.filter(obj => obj.departmentId !== departmentId);
            setBackButtonObj(filteredArray);
            if (filteredArray.length > 0) {
                const lastIndex = filteredArray.length - 1;
                setDepartment(filteredArray[lastIndex]);
                setBackButtonDepartmentId(filteredArray[lastIndex].departmentId);
            } else {
                navigate(-1);
                setBackButtonDepartmentId(null);
            }
        } else {
            navigate(-1);
        }
    };

    const addObjects = (obj) => {
        setBackButtonDepartmentId(obj.departmentId);
        const newArray = [...backButtonObj, obj];
        setBackButtonObj(newArray);
    };

    const handleDepartmentClick = (emp) => {
        setDepartment({ departmentName: emp?.departmentName, departmentId: emp?.departmentId });
        addObjects({ departmentName: emp?.departmentName, departmentId: emp?.departmentId });
    };

    const handleConfirm = () => {
        let data = { userEmployeeId: details?.item?.employeeId, eventType: 'teams' };
        if (!details?.isDeleted && ((details?.checked === true) || (details?.checked === false))) data.isActive = details?.checked;
        if (details?.isDeleted) data.isDeleted = details?.isDeleted;
        dispatch(updateEmployeeState(data))
            .unwrap()
            .then((res) => {
                toast.success(res?.message);
                handleTab(details.tab);
                if (details?.isDeleted) setDetails((prev) => ({ ...prev, isDeleted: false }));
            }).catch((error) => {
                toast.error(error?.message);
                if (details?.isDeleted) setDetails((prev) => ({ ...prev, isDeleted: false }));
            });
        setDetails((prev) => ({ ...prev, showModal: false }));
    };

    const requiredFieldLabel = (labelText, htmlFor) => {
        return (
            <label className="form-label" htmlFor={htmlFor}>
                {labelText}
                <span className="absolute top-0 left-0" style={{ transform: 'translate(-50%, -50%)', color: '#FF0000' }}> *</span>
            </label>
        );
    };

    return (
        <>
            <div className={`mainCompnay ${currentLanguageCode === 'en' ? 'white-container' : 'white-container-arabic'}`} style={{ paddingBottom: 50 }}>
                {
                    isLoading && <Loader />
                }
                <div className='d-flex align-items-center justify-content-between'>
                    <h1 className='text-green mb-3'>{department.departmentName}</h1>
                    <div className='d-flex justify-content-end'>
                        <button className='btn-secondary-small me-2' onClick={() => routeChangeToTeam(backButtonDepartmentId)} id='back'>
                            <img src={BackArrow} style={{ width: 20, objectFit: 'contain', marginLeft: 5, marginRight: 5, transform: currentLanguageCode === 'en' ? 'rotate(0deg)' : 'rotate(180deg)' }} />
                            {t('users.back')}
                        </button>
                        {department?.departmentId &&
                            <button onClick={handleShow} className='btn-primary-small' id='show_add_user_main'>{t('users.+_add_employee')}</button>
                        }
                    </div>
                </div>
                <div className='row '>
                    <div className='col-lg-6 col-md-12 mt-3 '>
                        <div className='cards-custom p-3' style={{ border: 1, borderColor: 'green' }}>
                            <div className=' d-flex align-items-center justify-content-between mb-3 green-header'>
                                <div className=' d-flex align-items-center'>
                                    <img src={Department} width={32} style={{ objectFit: 'contain' }} className='mx-2' />
                                    <h2 className='green-header-text mb-0'>{t('teams.departments')}</h2>
                                </div>
                                <div onClick={handleShowModalThree} style={{ cursor: 'pointer' }}>
                                    <img src={AddIcon} width={28} style={{ objectFit: 'contain' }} className='mx-2' />
                                </div>
                            </div>
                            <div className='row'>
                                {
                                    employeeCountData && employeeCountData?.map(emp => (
                                        <div className='col-lg-6 mb-3 col-md-6 col-sm-12 ' onClick={() => handleDepartmentClick(emp)} style={{ cursor: 'pointer' }} key={emp?.departmentId}>
                                            <div className='d-flex align-items-center  whiteshadowBackground '>
                                                <h2 className='circle-text-green'>{t(emp?.departmentName).slice(0, 2).toUpperCase()}</h2>
                                                <div style={{ marginLeft: 10, marginRight: 10 }}>
                                                    <h3 className='text-black'>{t(emp?.departmentName)}</h3>
                                                    <h4 className='text-grey'>{emp?.employeeCount} {t('teams.employees')}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 mt-3'>
                        <div className='cards-custom p-3'>
                            <div className=' d-flex align-items-center justify-content-between mb-3 green-header'>
                                <div className=' d-flex align-items-center'>
                                    <img src={Reviewicon} width={32} style={{ objectFit: 'contain' }} className='mx-2' />
                                    <h2 className='green-header-text mb-0'>{t('teams.reviewers')}</h2>
                                </div>

                                <div onClick={handleShowModalTwo} style={{ cursor: 'pointer' }}>
                                    {department?.departmentId &&
                                        <img src={AddIcon} width={28} style={{ objectFit: 'contain' }} className='mx-2' />
                                    }
                                </div>
                            </div>
                            <p className='text-error' style={{ fontWeight: 600, fontSize: 16 }}>{t('teams.reviewers_desc_3')}</p>
                            <Accordion className='department-accordian'>
                                {
                                    fecthReviewerByDepartment?.data?.map((item, index) => (
                                        <Accordion.Item eventKey={index} key={`accordion-${index}`}>
                                            <Accordion.Header>{item?.firstName + ' ' + item?.lastName}</Accordion.Header>
                                            <Accordion.Body>
                                                <form>
                                                    <div className='d-flex align-items-center '>
                                                        {item?.profilePic ? (
                                                            <div className='position-relative' style={{ marginRight: 20 }}>
                                                                <img src={item?.profilePic} style={{ width: 80, objectFit: 'contain', borderRadius: 10 }} />
                                                                <img src={ImagesprofileIcon} style={{ width: 32, objectFit: 'contain', borderRadius: 10, position: 'absolute', top: 0, right: 0 }} />
                                                            </div>
                                                        ) : (
                                                            <div className='position-relative' style={{ marginRight: 20 }}>
                                                                <div style={{ width: 80, height: 80, objectFit: 'contain', borderRadius: 10, fontWeight: 600, backgroundColor: '#F2F4F7', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <span style={{ fontSize: 40, color: 'black' }}>
                                                                        {item?.firstName ? item.firstName.charAt(0) : ''}{item?.lastName ? item.lastName.charAt(0) : ''}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div style={{ marginRight: 20 }}>
                                                            <h1 className='text-black ' style={{ fontSize: 18 }}>{item?.firstName + ' ' + item?.lastName}</h1>
                                                            <p className='text-grey mt-1 mb-0'>{t('teams.ID')}<span> {item?.employeeHrId} </span></p>
                                                            <p className='text-grey mt-1 mb-0'>{t('teams.DOB')}<span> {item?.dob} </span></p>
                                                        </div>
                                                    </div>
                                                    <div className='row align-items-center align-self-center mt-3'>
                                                        <div className='col-lg-4 col-md-12 mt-3' style={{ fontSize: 14 }}>
                                                            <p>{t('teams.reviewer_policy')}</p>
                                                        </div>
                                                        <div className='col-lg-8 col-md-12'>
                                                            <div className="form-outline mt-2 mb-2">
                                                                <select className="form-control form-select"
                                                                    id="selectReviewerPolicyId"
                                                                    defaultValue={item?.reviewerPolicyId}
                                                                    onChange={handleSelectChange}
                                                                >
                                                                    {
                                                                        reviewerPolicyData?.map((policy) => (
                                                                            <option key={policy?.reviewerPolicyId} value={policy?.reviewerPolicyId}>{policy?.policyName}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row mt-4 mb-3'>
                                                        <div className='col-lg col-md-12' style={{ fontSize: 12 }}>
                                                            <p>{t('teams.max_card_transactions_amount')}</p>
                                                            <p>{t('teams.max_topup_requests_amount')}</p>
                                                            <p>{t('teams.max_reimbursement_requests_amount')}</p>
                                                        </div>
                                                        {reviewerPolicyData &&
                                                            reviewerPolicyData
                                                                .filter(item => selectedValue === item.reviewerPolicyId)
                                                                .map(filteredItem => (
                                                                    <div className='col-lg-3 col-md-12' style={{ fontSize: 12 }} key={filteredItem.reviewerPolicyId}>
                                                                        <p>{formatQAR(filteredItem?.maxcardTransactionsAmount) + ' '}{t('teams.QAR')}</p>
                                                                        <p>{formatQAR(filteredItem?.maxCardTopupAmount) + ' '}{t('teams.QAR')}</p>
                                                                        <p>{formatQAR(filteredItem?.maxReimbursementsAmount) + ' '}{t('teams.QAR')}</p>
                                                                    </div>
                                                                ))
                                                        }
                                                    </div>
                                                    <div className='d-flex justify-content-end mt-2'>
                                                        <button className='btn-secondary-small ' onClick={(e) => { setShowConfirmation(true); setEmployeeId(item?.employeeId, e); e.preventDefault(); }} style={{ marginLeft: 16, marginRight: 16 }} id='remove_reviewer'>{t('teams.remove_reviewer')}</button>
                                                        <button className='btn-primary-small' onClick={(e) => onPoiclyUpdate(item?.employeeId, e)} id='update_reviewer'>{t('save')}</button>
                                                    </div>
                                                </form>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))
                                }
                            </Accordion>
                        </div>
                    </div>
                </div>
                <ConfirmationDialog
                    show={details.showModal}
                    onConfirm={handleConfirm}
                    onClose={() => setDetails((prev) => ({ ...prev, showModal: false }))}
                    message="Are you sure you want to proceed ?"
                />
                <div className='settings mt-5'>
                    <Tabs className='main-comp-1' onSelect={(key) => { handleTab(key); setDetails((prev) => ({ ...prev, tab: key })); }} defaultActiveKey={1} animation="false" id="employees-tab">
                        <Tab eventKey={1} title={`${t('users.all_employees')}`}>
                            <div className='col-lg-4 col-md-12 mt-4'>
                                <div className="form-group has-search" >
                                    <span className="fa fa-search form-control-feedback mt-1"></span>
                                    <input type="text" id='search_all' style={{ height: 48 }} className="form-control" value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value); setSearchTab(false); }} placeholder={`${t('search_employee')}`} />
                                </div>
                            </div>
                            {
                                (departmentEmployees?.data && departmentEmployees?.data?.length > 0)
                                    ? (<div className="user-cards-grid g-4 mt-2">
                                        {departmentEmployees?.data?.map((item) => (
                                            <UsersCard
                                                username={item?.firstName + ' ' + item?.lastName}
                                                numberOfCards={item?.cardcount}
                                                price1={item?.currentbalance}
                                                price2={item?.pettyCashBalance}
                                                onClick={() => routeChange(item?.employeeId)}
                                                plusIcon={false}
                                                profilePic={item?.profilePic}
                                                lastName={item?.lastName}
                                                setDetails={setDetails}
                                                data={item}
                                                key={`allUsers-${item?.employeeId}`}
                                            />
                                        ))}
                                    </div>) : (
                                        <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                                            <img src={notFound} width={200} height={200} />
                                        </div>
                                    )
                            }
                        </Tab>
                        <Tab eventKey={2} title={`${t('users.active_employees')}`}>
                            <div className='col-lg-4 col-md-12 mt-4'>
                                <div className="form-group has-search">
                                    <span className="fa fa-search form-control-feedback"></span>
                                    <input type="text" id='search_active' className="form-control" value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value); setSearchTab(false); }} placeholder={`${t('search_employee')}`} />
                                </div>
                            </div>
                            {
                                (departmentEmployees?.data && departmentEmployees?.data?.length > 0) ?
                                    (<div className="user-cards-grid g-4 mt-2">
                                        {departmentEmployees?.data?.map((item) => (
                                            <UsersCard
                                                username={item?.firstName + ' ' + item?.lastName}
                                                numberOfCards={item?.cardcount}
                                                price1={item?.currentbalance}
                                                price2={item?.pettyCashBalance}
                                                onClick={() => routeChange(item?.employeeId)}
                                                plusIcon={false}
                                                profilePic={item?.profilePic}
                                                lastName={item?.lastName}
                                                setDetails={setDetails}
                                                data={item}
                                                key={`activeUsers-${item?.employeeId}`}
                                            />
                                        ))}
                                    </div>) : (
                                        <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                                            <img src={notFound} width={200} height={200} />
                                        </div>
                                    )
                            }
                        </Tab>
                        <Tab eventKey={3} title={`${t('users.inactive_employees')}`}>
                            <div className='col-lg-4 col-md-12 mt-4'>
                                <div className="form-group has-search">
                                    <span className="fa fa-search form-control-feedback"></span>
                                    <input type="text" id='search_inactive' className="form-control" value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value); setSearchTab(false); }} placeholder={`${t('search_employee')}`} />
                                </div>
                            </div>
                            {
                                (departmentEmployees?.data && departmentEmployees?.data?.length > 0) ?
                                    (<div className="user-cards-grid g-4 mt-2">
                                        {departmentEmployees?.data?.map((item) => (
                                            <UsersCard
                                                username={item?.firstName + ' ' + item?.lastName}
                                                numberOfCards={item?.cardcount}
                                                price1={item?.currentbalance}
                                                price2={item?.pettyCashBalance}
                                                onClick={() => routeChange(item?.employeeId)}
                                                plusIcon={false}
                                                profilePic={item?.profilePic}
                                                lastName={item?.lastName}
                                                setDetails={setDetails}
                                                data={item}
                                                key={`inactiveUsers-${item?.employeeId}`}
                                            />
                                        ))}
                                    </div>) : (
                                        <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                                            <img src={notFound} width={200} height={200} />
                                        </div>
                                    )
                            }
                        </Tab>
                        <Tab eventKey={4} title={`${t('users.invited_employees')}`}>
                            <div className='col-lg-4 col-md-12 mt-4'>
                                <div className="form-group has-search">
                                    <span className="fa fa-search form-control-feedback"></span>
                                    <input type="text" id='search_invited' className="form-control" value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value); setSearchTab(false); }} placeholder={`${t('search_employee')}`} />
                                </div>
                            </div>
                            {
                                (departmentEmployees?.data && departmentEmployees?.data?.length > 0) ?
                                    (<div className="user-cards-grid g-4 mt-2">
                                        {departmentEmployees?.data?.map((item) => (
                                            <UsersCard
                                                username={item?.firstName + ' ' + item?.lastName}
                                                numberOfCards={item?.cardcount}
                                                price1={item?.currentbalance}
                                                price2={item?.pettyCashBalance}
                                                onClick={() => routeChange(item?.employeeId)}
                                                plusIcon={false}
                                                profilePic={item?.profilePic}
                                                lastName={item?.lastName}
                                                setDetails={setDetails}
                                                data={item}
                                                key={`invitedUsers-${item?.employeeId}`}
                                            />
                                        ))}
                                    </div>) : (
                                        <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                                            <img src={notFound} width={200} height={200} />
                                        </div>
                                    )
                            }
                        </Tab>
                        <Tab eventKey={5} title={`${t('users.accountants')}`}>
                            <div className='col-lg-4 col-md-12 mt-4'>
                                <div className="form-group has-search">
                                    <span className="fa fa-search form-control-feedback"></span>
                                    <input type="text" id='search_accountants' className="form-control" value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value); setSearchTab(false); }} placeholder={`${t('search_employee')}`} />
                                </div>
                            </div>
                            {
                                (departmentEmployees?.data && departmentEmployees?.data?.length > 0) ?
                                    (<div className="user-cards-grid g-4 mt-2">
                                        {departmentEmployees?.data?.map((item) => (
                                            <UsersCard
                                                username={item?.firstName + ' ' + item?.lastName}
                                                numberOfCards={item?.cardcount}
                                                price1={item?.currentbalance}
                                                price2={item?.pettyCashBalance}
                                                onClick={() => routeChange(item?.employeeId)}
                                                plusIcon={false}
                                                profilePic={item?.profilePic}
                                                lastName={item?.lastName}
                                                setDetails={setDetails}
                                                data={item}
                                                key={`accountants-${item?.employeeId}`}
                                            />
                                        ))}
                                    </div>) : (
                                        <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                                            <img src={notFound} width={200} height={200} />
                                        </div>
                                    )
                            }
                        </Tab>
                    </Tabs>
                </div>
            </div>

            <Modal className='UserModal' show={show} onHide={handleClose} style={{ paddingRight: 0 }}>
                <Modal.Body>
                    <div className='d-flex justify-content-between align-items-start green-header p-2'>
                        <div className='col-9'>
                            <p className='text-green modal-title' style={{ fontSize: 20 }}>
                                {t('users.add_employee')}
                            </p>

                        </div>
                        <div onClick={() => { handleClose(); reset(); }} className='clo-2' style={{ cursor: 'pointer' }} id='close_modal'>
                            <img src={CrossImage} style={{ width: 20, objectFit: 'contain' }} />
                        </div>

                    </div>
                    <p className='text-grey'>{t('users.add_employee_desc')}</p>
                    <form>
                        <div className="form-outline mt-4 mb-3">
                            {requiredFieldLabel(t('login.first_name'), 'firstName')}
                            <input type="text" id="firstName" className="form-control form-control-lg"
                                placeholder={`${t('login.enter_your_first_name')}`}
                                {...register('firstName')}
                            />
                            {errors?.firstName && (
                                <p style={{ color: 'red' }}>{errors?.firstName?.message}</p>
                            )}
                        </div>
                        <div className="form-outline mt-4 mb-3">
                            {requiredFieldLabel(t('login.last_name'), 'lastName')}
                            <input type="text" id="lastName" className="form-control form-control-lg"
                                placeholder={`${t('login.enter_your_last_name')}`}
                                {...register('lastName')}
                            />
                            {errors?.lastName && (
                                <p style={{ color: 'red' }}>{errors?.lastName?.message}</p>
                            )}
                        </div>
                        <div className="form-outline mt-4 mb-3">
                            {requiredFieldLabel(t('email_address'), 'email')}
                            <input type="text" id="email" className="form-control form-control-lg" 
                                placeholder={`${t('email_address')}`} autoComplete='true'
                                {...register('email', {
                                    pattern: {
                                        value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
                                        message: 'Invalid email address'
                                    }
                                })}
                            />
                            {errors?.email && (
                                <p style={{ color: 'red' }}>{errors?.email?.message}</p>
                            )}
                        </div>
                        <div className="form-outline mt-4 mb-3">
                            {requiredFieldLabel(t('mobile_number'), 'mobileNumber')}
                            <div className="input-group">
                                {currentLanguageCode === 'en' ? (
                                    <span dir='ltr' className="input-group-text">+974</span>
                                ) : (
                                    <span dir='ltr' className="input-group-text" style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0, borderTopRightRadius: 12, borderBottomRightRadius: 12 }}>+974</span>
                                )}
                                {currentLanguageCode === 'en' ? (
                                    <input
                                        type="text"
                                        id="mobileNumber"
                                        className="form-control form-control-lg"
                                        placeholder={`${t('enter_mobile_number')}`}
                                        {...register('mobileNumber')}
                                    />
                                ) : (
                                    <input
                                        type="text"
                                        id="mobileNumber"
                                        className="form-control form-control-lg"
                                        placeholder={`${t('enter_mobile_number')}`}
                                        style={{ borderBottomLeftRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                        {...register('mobileNumber')}
                                    />
                                )}
                            </div>
                            {errors?.mobileNumber && (
                                <p style={{ color: 'red' }}>{errors?.mobileNumber?.message}</p>
                            )}
                        </div>
                        <div className="form-outline mt-4 mb-3">
                            {requiredFieldLabel(t('users.gender'), 'gender')}
                            <select
                                className="form-control form-select"
                                id="gender"
                                defaultValue={''}
                                {...register('gender')}
                            >
                                <option value=''>{t('users.select_gender')}</option>
                                <option value='male'>Male</option>
                                <option value='female'>Female</option>
                            </select>
                            {errors?.gender && (
                                <p style={{ color: 'red' }}>{errors?.gender?.message}</p>
                            )}
                        </div>
                        <div className="form-outline mt-4 mb-3">
                            {requiredFieldLabel(t('users.notional_ID'), 'nationalId')}
                            <input type="text" id="nationalId" className="form-control form-control-lg"
                                placeholder={`${t('users.enter_Id')}`}
                                {...register('nationalId')}
                            />
                            {errors?.nationalId && (
                                <p style={{ color: 'red' }}>{errors?.nationalId?.message}</p>
                            )}
                        </div>
                        <div className="form-outline mt-4 mb-3">
                            {requiredFieldLabel(t('users.nationality'), 'nationality')}
                            <select
                                className="form-control form-select"
                                id="nationality"
                                defaultValue={''}
                                {...register('nationality')}
                            >
                                <option value=''>{t('users.select_nationality')}</option>
                                {countriesData?.map((country) => (
                                    <option key={country.countryId} value={country.countryId}>
                                        {country.name}
                                    </option>
                                ))}
                            </select>
                            {errors?.nationality && (
                                <p style={{ color: 'red' }}>{errors?.nationality?.message}</p>
                            )}
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className="mb-3 form-check " style={{ marginRight: 20 }}>
                                <input type="checkbox" className="form-check-input" id="eKYCRequired"
                                    {...register('eKYCRequired')} />
                                <label className="form-check-label" htmlFor="eKYCRequired">{t('users.kycRequired')}</label>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className="mb-3 form-check " style={{ marginRight: 20 }}>
                                <input type="checkbox" className="form-check-input" id="isAccountant"
                                    {...register('isAccountant')}
                                />
                                <label className="form-check-label" htmlFor="isAccountant">{t('users.accountant')}</label>
                            </div>
                            <div className="mb-3 form-check">
                                <input type="checkbox" className="form-check-input" id="isAdmin"
                                    {...register('isAdmin')}
                                />
                                <label className="form-check-label" htmlFor="isAdmin">{t('users.admin')}</label>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={(e) => { handleClose(); reset(); e.preventDefault(); }} className='btn-secondary-small' id='cancel_add_user'>{t('cancel')}</button>
                    <button onClick={handleSubmit(onSubmit3)} className='btn-primary-small' id='submit_add_user'>{t('save_and_invite')}</button>
                </Modal.Footer>
            </Modal>
            <Modal className='TaxcodeModalOne' show={showTwo} onHide={handleCloseModalTwo} style={{ paddingRight: 0 }}>
                <Modal.Body>
                    <div className='d-flex justify-content-between align-items-start green-header px-3 py-2'>
                        <div className='col-9'>
                            <h2 className='green-header-text mb-0'>{t('teams.reviewers')}</h2>
                        </div>
                        <div onClick={() => { handleCloseModalTwo(); form2.reset(); }} className='clo-2' style={{ cursor: 'pointer' }}>
                            <img src={ImagesCross} style={{ width: 20, objectFit: 'contain' }} />
                        </div>
                    </div>
                    <p className='text-error' style={{ fontWeight: 600, fontSize: 16 }}>{t('teams.reviewers_desc_3')}</p>
                    <form>
                        <div className="form-outline mt-4 mb-3">
                            <label className="form-label" htmlFor="select_reviewer">{t('teams.reviewer_name')}</label>
                            <select
                                className="form-control form-select"
                                id="select_reviewer"
                                defaultValue={''}
                                {...form2.register('employeeId', {
                                    required: `${t('teams.select_reviewer')}`,
                                })}
                            >
                                <option value=''>{t('teams.select_reviewer')}</option>
                                {fetchGlobalReviewerEmpData && fetchGlobalReviewerEmpData?.map(el => (
                                    <option key={el?.employeeId} value={el?.employeeId}>{el?.firstName + ' ' + el?.lastName}</option>
                                ))}

                            </select>
                            {form2Error?.departmentId && (
                                <p style={{ color: 'red' }}>{form2Error?.departmentId?.message}</p>
                            )}
                        </div>
                        <div className='row align-items-center align-self-center mt-4 mb-3'>
                            <div className='col-lg-5 col-md-12 mt-3' style={{ fontSize: 14 }}>
                                <p>{t('teams.reviewer_policy')}</p>
                            </div>
                            <div className='col-lg-7 col-md-12'>
                                <div className="form-outline mt-2 mb-2">
                                    <select
                                        className="form-control form-select"
                                        id="reviewerPolicyId"
                                        defaultValue={''}
                                        {...form2.register('reviewerPolicyId', {
                                            required: `${t('teams.select_reviewer')}`,
                                        })}
                                    >
                                        <option value=''>{t('teams.default_policy')}</option>
                                        {reviewerPolicyData && reviewerPolicyData?.map(review => (
                                            <option key={review?.reviewerPolicyId} value={review?.reviewerPolicyId}>{review?.policyName}</option>
                                        ))}
                                    </select>
                                    {form2Error?.reviewerPolicyId && (
                                        <p style={{ color: 'red' }}>{form2Error?.reviewerPolicyId?.message}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='row mt-4 mb-3'>
                            <div className='col-lg col-md-12' style={{ fontSize: 12 }}>
                                <p>{t('teams.max_card_transactions_amount')}</p>
                                <p>{t('teams.max_topup_requests_amount')}</p>
                                <p>{t('teams.max_reimbursement_requests_amount')}</p>
                            </div>
                            {data && data?.map(el => (
                                <div className='col-lg-3 col-md-12' style={{ fontSize: 12 }}>
                                    <p>{formatQAR(el?.maxcardTransactionsAmount)} {t('teams.QAR')}</p>
                                    <p>{formatQAR(el?.maxCardTopupAmount)} {t('teams.QAR')}</p>
                                    <p>{formatQAR(el.maxReimbursementsAmount)} {t('teams.QAR')}</p>
                                </div>))}
                        </div>
                    </form>
                    <div style={{ borderBottom: '1px solid #EAECF0', marginBottom: 24 }}></div>
                    <div className='d-flex justify-content-end align-items-center'>
                        <button onClick={(e) => { handleCloseModalTwo(); form2.reset(); e.preventDefault(); }} className='btn-secondary-small' id='cancel_add_reviewer'>{t('cancel')}</button>
                        <div className='pe-3'></div>
                        <button onClick={form2.handleSubmit(onSubmit2)} className='btn-primary-small' id='submit_add_reviewer'>{t('add')}</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className='TaxcodeModalOne' show={showThree} onHide={handleCloseModalThree} style={{ paddingRight: 0 }}>
                {
                    isLoading && <Loader />
                }
                <form>
                    <Modal.Body>
                        <div className='d-flex justify-content-between align-items-start green-header p-2'>
                            <div className='col-9'>
                                <p className='text-green modal-title' style={{ fontSize: 20 }}>
                                    {t('teams.add_department')}
                                </p>

                            </div>
                            <div onClick={() => { handleCloseModalThree(); form3.reset(); }} className='clo-2' style={{ cursor: 'pointer' }} id='close_modal'>
                                <img src={CrossImage} style={{ width: 20, objectFit: 'contain' }} />
                            </div>

                        </div>

                        <p className='text-grey'>{t('teams.add_department_desc')}</p>
                        <div className="form-outline mt-4 mb-4">
                            <label className="form-label" htmlFor="departmentName">{t('teams.new_department_name')}</label>
                            <input type="text" id="departmentName" className="form-control form-control-lg"
                                placeholder={`${t('enter_name')}`}
                                {...form3.register('departmentName', {
                                    required: `${t('enter_name')}`,
                                    pattern: {
                                        value: /^[A-Za-z0-9 ]+$/,
                                        message: "Only alphanumeric are allowed"
                                    }
                                })}
                            />
                            {form3Error?.departmentName && (
                                <p style={{ color: 'red' }}>{form3Error?.departmentName?.message}</p>
                            )}
                        </div>
                        <hr />
                        <div className='d-flex align-items-center justify-content-end'>
                            <button onClick={(e) => { handleCloseModalThree(); form3.reset(); e.preventDefault(); }} className='btn-secondary-small mt-2' id='cancel_add_department'>{t('cancel')}</button>
                            <div className='pe-3'></div>
                            <button onClick={form3.handleSubmit(onSubmit)} className='btn-primary-small mt-2' id='submit_add_department'>{t('add')}</button>
                        </div>
                    </Modal.Body>
                </form>
            </Modal>
            <ConfirmationDialog
                show={showConfirmation}
                onConfirm={OnDelete}
                onClose={() => { setShowConfirmation(false); setEmployeeId(null); }}
                message="Are you sure you want to proceed to delete?"
            />
        </>
    );
}

export default MainCompany;
