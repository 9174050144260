import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Cards.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAllCards, getEmployees, issueNewCard } from './slice/cardListSlice';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import ImagesCross from '../../assets/images/close-square-8.png';
import SearchImg from '../../assets/images/search_img.png';
import CardsImg from '../../assets/images/cards_img.png';
import Loader from '../../components/Loader/Loader';
import Modal from 'react-bootstrap/Modal';
import DebitCard from '../../components/Teams/DebitCard';
import { toast } from 'react-toastify';
import { getAllDepartment } from '../Teams/teamSlice';
import { formatQARWithoutDecimal } from '../../utils/XpendlessUtils';
import cookies from 'js-cookie';
import Logo2 from '../../assets/images/polo.png';
import OtpInput from 'react-otp-input';
import notFound from '../../assets/images/not_found.png';

const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'sa',
    },
];

const maskCardNumber = (cardNumber, showUnmasked) => {
    const digitsOnly = cardNumber.replace(/\D/g, '');
    const masked = digitsOnly.slice(0, -4).replace(/\d/g, '*') + digitsOnly.slice(-4);
    const maskedCardNumber = masked.replace(/(.{4})/g, '$1 ').trim();
    return (typeof showUnmasked !== 'undefined' && showUnmasked) ? cardNumber : maskedCardNumber;
};

function Cards() {
    const [show, setShow] = useState(false);

    const handleClose1 = () => { setShow(false); resetAddCardForm(); };
    const handleShow = () => setShow(true);

    const { cardLists, isLoading, employees } = useSelector(state => state.cardListReducer);
    const { departmentData } = useSelector((state) => state?.teamSlice);
    const [pageNo, setPageNo] = useState(1);
    const [issueCardData, setIssueCardData] = useState(null);
    const [showVerifyModal, setShowVerifyModal] = useState(false);
    const [timer, setTimer] = useState(60);
    const dispatch = useDispatch();
    const selectRef = useRef();
    const employeeRef = useRef();
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

    const {
        control: controlSearchForm,
        handleSubmit: handleSearchSubmit,
        formState: { errors: errorsSearchForm },
        watch, } = useForm({
            resolver: yupResolver(yup.object().shape({
                employeeName: yup.string(),
                department: yup.string(),
                cardType: yup.string(),
            })
            )
        });

    const {
        control: controlAddCardForm,
        handleSubmit: handleAddCardSubmit,
        formState: { errors: errorsAddCardForm },
        watch: addFormWatch,
        setValue: addCardSetValue,
        reset: resetAddCardForm } = useForm({
            resolver: yupResolver(yup.object().shape({
                cardType: yup.string().required('Card type is required.'),
                employeeId: yup.string().required('Employee ID is required.')
            }))
        });

    const { control: controlVerifyModal, handleSubmit: handleOtpSubmit, formState: { errors: errorsOtpForm }, reset: otpFormReset } = useForm({
        resolver: yupResolver(yup.object().shape({
            otp: yup.string().required("Otp is required").matches(/^123456$/, 'OTP must be exactly 6 digits'),
        }))
    });

    const handleShowVerifyModal = () => setShowVerifyModal(true);
    const handleCloseVerifyModal = () => setShowVerifyModal(false);
    // const open = Boolean(anchorEl);
    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    const addFormValues = addFormWatch();
    const onPreviousClick = () => {
        setPageNo(prev => prev - 1);
    };

    const onNextClick = () => {
        setPageNo(prev => prev + 1);
    };

    useEffect(() => {
        let interval;

        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [timer]);

    useEffect(() => {
        dispatch(getAllDepartment());
        dispatch(getEmployees());
    }, []);
    // Fetch master transaction list when 'pageNo' changes
    useEffect(() => {
        const allValues = watch();
        const { cardType, department, employeeName } = allValues;
        let queryParams = `?page=${pageNo}`;
        if (cardType || department || employeeName) {
            queryParams += '&';
            queryParams += Object.keys(allValues)
                .filter((key) => allValues[key] !== undefined && allValues[key] !== '')
                .map((key) => `${key}=${encodeURIComponent(allValues[key])}`)
                .join('&');
        }
        dispatch(getAllCards(queryParams));
    }, [dispatch, pageNo]);

    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr';
        document.title = t('app_title');
    }, [currentLanguage, t]);


    const onSearchFormSubmit = (data) => {
        setPageNo(1);
        const { cardType, department, employeeName } = data;
        let queryParams = `?page=${1}&`;
        if (!cardType && !department && !employeeName) {
            dispatch(getAllCards(queryParams));
        } else {
            queryParams += Object.keys(data)
                .filter((key) => data[key] !== undefined && data[key] !== '')
                .map((key) => `${key}=${encodeURIComponent(data[key])}`)
                .join('&');
            dispatch(getAllCards(queryParams)).unwrap().then(() => {
            }).catch((error) => {
                toast.error(error.message || "Request Failed Please Try Again ");
            });
        }
    };
    let navigate = useNavigate();
    const routeChange = (id) => {
        let path = `/card/card-details/${id}`;
        navigate(path);
    };

    const handleAddCard = () => {
        issueCardData.limit = 0;
        issueCardData.status = 'active';
        issueCardData.currentBalance = 0;
        issueCardData.isFreezed = false;
        issueCardData.expiryDate = "2024-10-06";
        issueCardData.eventType = 'cards';
        dispatch(issueNewCard(issueCardData)).unwrap().then(() => {
            toast.success('Card Added Successfully!');
            let queryParams = `?page=${pageNo}`;
            dispatch(getAllCards(queryParams));
            resetAddCardForm();
            if (employeeRef.current)
                employeeRef.current.value = '';
            handleClose1();
            handleCloseVerifyModal();
            otpFormReset({ otp: '' });
        }).catch((error) => {
            toast.error(error.message || "Request Failed Please Try Again ");
        });
    };

    const onClickVerifyModal = (data) => {
        setIssueCardData(data);
        setTimer(60);
        handleClose1();
        otpFormReset({ otp: '' });
        setTimeout(() => {
            handleShowVerifyModal();
        }, 200);
    };

    const handleCardClick = (type) => {
        addCardSetValue("cardType", type, { shouldValidate: true })
    }

    return (
        <>
            <div className='white-container cards-section-css'>
                {(isLoading) && <Loader />}
                <div className='d-flex align-items-center justify-content-between'>
                    <h1 className='text-green  ms-3'>{t('sidebar.xpendless_cards')}</h1>
                    <button onClick={handleShow} className='btn-primary-small ms-3'>{t('cards.+_add_new_card')}</button>
                </div>
                <div className='col-12 mt-3'>
                    <div className='border-grey cards-custom  p-3'>
                        <div className=' d-flex align-items-center mb-4  green-header'>
                            <img src={SearchImg} className='small-img-size1 me-3' />
                            <h2 className='text-green ps-1 me-1'>{t('cards.search')}</h2>
                        </div>
                        <form className='row' onSubmit={handleSearchSubmit(onSearchFormSubmit)}>
                            <div className='cards-search'>
                                <div className='row'>
                                    <div className='col-lg-4 col-md-12'>
                                        <div className="form-outline">
                                            <label className="form-label" htmlFor="employeeName">{t('cards.employee_name')}</label>
                                            <Controller
                                                name="employeeName"
                                                control={controlSearchForm}
                                                defaultValue=""
                                                render={({ field }) => (
                                                    <input {...field} type="text" id="employeeName" className="form-control form-control-lg input1"
                                                        placeholder={`${t('cards.enter_employee_name')}`} />
                                                )}
                                            />
                                            <p className="text-danger">{errorsSearchForm?.employeeName?.message}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 form-outline">
                                        <label className="form-label" htmlFor="department">{t('cards.department')}</label>
                                        <Controller
                                            name="department"
                                            control={controlSearchForm}
                                            defaultValue=''
                                            render={({ field }) => (
                                                <select
                                                    className={`form-control form-select form-control-lg ${!field.value ? 'select-placeholder' : ''}`}
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        e.target.classList.add('select-placeholder-selected');
                                                    }}
                                                    onBlur={field.onBlur}
                                                    id='department'
                                                    ref={selectRef}
                                                    defaultValue=""
                                                >
                                                    <option value="">{t('cards.select_department')}</option>
                                                    {departmentData.map((department) => (
                                                        <option className='text-capitalize' key={department.departmentId} value={department.departmentName}>
                                                            {department.departmentName}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        />
                                        <p className="text-danger">{errorsSearchForm?.department?.message}</p>
                                    </div>
                                    <div className='col-lg-4 col-md-12'>
                                        <div className="form-outline">
                                            <label className="form-label" htmlFor="cardType">{t('cards.physical_virtual')}</label>

                                            <Controller
                                                name="cardType"
                                                control={controlSearchForm}
                                                defaultValue=''
                                                render={({ field }) => (
                                                    <select {...field} ref={selectRef} id='cardType' className="form-control form-select form-control-lg" style={{ color: field.value ? 'black' : 'grey' }}>
                                                        <option value="">{t('cards.select_card_type')}</option>
                                                        <option value="physical">physical</option>
                                                        <option value="virtual">virtual</option>
                                                    </select>
                                                )}
                                            />
                                            <p className="text-danger">{errorsSearchForm?.cardType?.message}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-2 col-md-12 search--button'>
                                    <button type="submit" className='btn-primary-small w-100' >{t('cards.search')}</button>
                                </div>
                            </div>

                            {errorsSearchForm.atLeastOneField && (
                                <p className="text-danger">{errorsSearchForm?.atLeastOneField?.message}</p>
                            )}
                        </form>
                    </div>
                </div>

                <div className='cards-custom mt-4 p-3'>
                    <div className=' d-flex align-items-center mb-3 green-header'>
                        <img src={CardsImg} className='small-img-size1 me-3' />
                        <h2 className='text-green ps-1'>{t('sidebar.xpendless_cards')}</h2>
                    </div>
                    <div className='table-responsive p-3 py-0'>
                        <table id="dtBasicExample" style={{ borderCollapse: 'separate', borderSpacing: '0 16px' }} cellSpacing="0" width="100%">
                            <thead>
                                <tr style={{ borderTopWidth: 0 }} className='table-head'>
                                    <th scope="col">{t('cards.no')}</th>
                                    <th scope="col">{t('cards.card_number')}</th>
                                    <th scope="col">{t('cards.card_holder_name')}</th>
                                    <th scope="col">{t('cards.card_type')}</th>
                                    <th style={{ textAlign: 'right' }} scope="col">{t('cards.limit')}</th>
                                    <th scope="col">{t('actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (Object.keys(cardLists).length > 0 && Object.keys(cardLists['rows']).length > 0) && cardLists['rows'].map((cardList, index) => {
                                        const [wholePart, decimalPart] = (cardList.limit) ? cardList.limit.toString().split('.').map(Number) : ['0', '00'];
                                        return <tr onClick={() => routeChange(cardList.employeeId)} style={{ cursor: 'pointer' }} key={index} className={`${currentLanguageCode === 'en' ? 'table-body-tr' : 'table-body-tr-arabic'}`}>
                                            <td>{index + 1}</td>
                                            <td>{maskCardNumber(cardList.cardToken, false)}</td>
                                            <td>{`${cardList.firstName} ${cardList.lastName}`}</td>
                                            <td>{cardList.cardType}</td>
                                            <td><h4 style={{ textAlign: 'right' }}>{formatQARWithoutDecimal(wholePart)}.<span style={{ fontSize: 12, fontWeight: '500' }}>{`${(decimalPart) ? decimalPart : '00'} QAR`}</span></h4></td>
                                            <td>
                                                <a><span style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer', color: "#00D094" }}>{t('view')}</span></a>
                                            </td>
                                        </tr>;
                                    }
                                    )
                                }
                            </tbody>
                        </table>
                        {!(cardLists?.rows && cardLists?.rows?.length > 0) && (
                            <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                                <img src={notFound} width={200} height={200} />
                            </div>)
                        }
                    </div>
                    <div className='d-flex align-items-center justify-content-between mt-4'
                        style={{ width: "100%", borderTop: '0px !imporant' }}>
                        <nav aria-label="Page navigation example">
                            <ul className="pagination mt-2 pt-1 mb-3 pe-0">
                                <li className="page-item">
                                    <button className="btn page-link" disabled={pageNo === 1} onClick={() => onPreviousClick()}>{t('previous')}</button>
                                </li>
                                <li className="page-item mx-3">
                                    <button className="btn page-link" disabled={pageNo == cardLists?.totalPages || cardLists?.totalPages == 0} onClick={() => onNextClick()}>{t('next')}</button>
                                </li>
                            </ul>
                        </nav>
                        <div className='page-count d-flex align-items-center' style={{ color: "#00D094" }}>
                            <h4>Page {cardLists?.currentPage} of {cardLists?.totalPages}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className='TeamModalOne' show={show} onHide={handleClose1} style={{ paddingRight: 0 }}>
                <form onSubmit={handleAddCardSubmit(onClickVerifyModal)}>
                    <Modal.Body>
                        <div className='d-flex justify-content-between align-items-start green-header p-2 ps-3'>
                            <div className='col-9'>
                                <p className='text-green modal-title' style={{ fontSize: 20 }}>
                                    {t('teams.add_card')}
                                </p>
                            </div>
                            <div onClick={handleClose1} className='clo-2' style={{ cursor: 'pointer' }}>
                                <img src={ImagesCross} style={{ width: 20, objectFit: 'contain' }} />
                            </div>
                        </div>
                        <p className='text-grey' style={{ fontWeight: 400 }}>{t('teams.add_card_desc')}</p>
                        <div className={`physical-debit-card-background  mt-2 ${addFormValues?.cardType === 'physical' ? 'selected' : ''}`} onClick={() => handleCardClick("physical")}>
                            <DebitCard freezeDisabled={true} />
                        </div>
                        <div className='d-flex align-items-center justify-content-center mt-3'>
                            <div className="form-check">
                                <Controller
                                    name="cardType"
                                    control={controlAddCardForm}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            className="form-check-input ml-2"
                                            type="radio"
                                            value="physical"
                                            checked={field.value === 'physical'}
                                            onChange={(e) => field.onChange(e.target.value)}
                                            style={{ width: 20, height: 20 }}
                                        />
                                    )}
                                />
                                <label className="form-check-label" htmlFor="flexRadioDefault1" style={{ fontWeight: 400 }}>
                                    {t('teams.physical_card')}
                                </label>
                                <p className="text-danger">{errorsAddCardForm?.cardType?.message}</p>
                            </div>
                        </div>
                        <div className={`virtual-debit-card-background mt-3 ${addFormValues?.cardType === 'virtual' ? 'selected' : ''}`} onClick={() => handleCardClick("virtual")}>
                            <DebitCard type={`virtual`} freezeDisabled={true} />
                        </div>
                        <div className='d-flex align-items-center justify-content-center mt-4'>
                            <div className="form-check">

                                <Controller
                                    name="cardType"
                                    control={controlAddCardForm}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            className="form-check-input ml-2"
                                            type="radio"
                                            value="virtual"
                                            checked={field.value === 'virtual'}
                                            onChange={(e) => field.onChange(e.target.value)}
                                        />
                                    )}
                                />
                                <label className="form-check-label" htmlFor="flexRadioDefault2" style={{ fontWeight: 400 }}>
                                    {t('teams.virtual_card')}
                                </label>
                                <p className="text-danger">{errorsAddCardForm?.cardType?.message}</p>
                            </div>
                        </div>
                        <div className="form-outline mt-4 mb-4">
                            <label className="form-label" htmlFor="selectEmployee" style={{ fontWeight: 600 }}>{t('cards.employee_name')}</label>
                            <Controller
                                name="employeeId"
                                control={controlAddCardForm}
                                render={({ field }) => (
                                    <select {...field} ref={employeeRef} id='selectEmployee' className="form-control form-select form-control-lg text-capitalize" style={{ color: !field?.value ? "grey" : "black" }}>
                                        <option value={""}>{t('cards.select_employee_name')}</option>
                                        {
                                            employees != null && employees.map((obj, index) => { return <option className='text-capitalize' key={index} value={obj.employeeId}>{obj.firstName + ' ' + obj.lastName}</option>; })
                                        }
                                    </select>
                                )}
                            />
                            <p className="text-danger">{errorsAddCardForm?.employeeId?.message}</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='button' onClick={handleClose1} className='btn-secondary-small mt-3' >{t('close')}</button>
                        <button type="submit" className='btn-primary-small mt-3' >{t('teams.issue_new_card')}</button>
                    </Modal.Footer>
                </form>
            </Modal>
            <Modal
                aria-labelledby="contained-modal-title-center"
                centered
                show={showVerifyModal}
                onHide={handleCloseVerifyModal}
            >

                <form className='form'
                    style={{ padding: 24 }}
                    onSubmit={handleOtpSubmit(handleAddCard)}
                >
                    <div className='d-flex align-items-start justify-content-between'>
                        <img src={Logo2} className="logoso mt-1 mb-4" alt="Sample image" />
                        <div onClick={handleCloseVerifyModal}>
                            <img src={ImagesCross} className='small-addjust' style={{ width: 20, objectFit: 'contain', marginLeft: 15 }} />
                        </div>
                    </div>
                    <h1 className='text-blue-login mb-2'>
                        {t('login.verify_your_mobile_number')}
                    </h1>
                    <div className="divider d-flex align-items-center my-2">
                        <p className="mb-0 text-subtitle2" style={{ color: '#8B9299' }} >
                            {t('login.verify_your_mobile_number_desc')}
                        </p>
                    </div>

                    <div className="otp form-outline d-flex justify-content-center mt-4 mb-3">
                        <Controller
                            name="otp"
                            control={controlVerifyModal}
                            defaultValue={0}
                            render={({ field: { onChange, value, ref }, }) => (
                                <OtpInput
                                    inputStyle="inputStyle"
                                    ref={ref}
                                    value={value}
                                    onChange={onChange}
                                    numInputs={6}
                                    shouldAutoFocus={true}
                                    renderInput={(props) => <input {...props} onInput={(e) => {
                                        const value = e.target.value;
                                        if (!/^\d*$/.test(value)) {
                                            e.target.value = value.slice(0, -1);
                                        }
                                    }} />}
                                />
                            )}
                        />
                    </div>
                    <p className="text-danger">{errorsOtpForm?.otp?.message}</p>
                    <div className="d-flex justify-content-end">
                        {timer === 0 ? (
                            <a href="javascript:void(0)" >Resend OTP</a>
                        ) : (
                            <p>Resend OTP in <span className='text-blue'>{timer} seconds</span> </p>
                        )}
                    </div>
                    <div className="text-center text-lg-start mt-4 w-100">
                        <button type="submit" className="btn btn-primary btn-lg btn-block w-100" style={{ position: 'relative', zIndex: '1' }}>{'Continue to Issue Card'} </button>
                    </div>

                </form>

            </Modal>
        </>
    );
}

export default Cards;
