// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auditLogViewerCss .form-label {
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: left;
  color: #333333;
}
.auditLogViewerCss select option {
  color: black;
}
.auditLogViewerCss .text1title {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: left;
  color: #1C1C1E;
}
.auditLogViewerCss .text2title {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  color: #333333;
}`, "",{"version":3,"sources":["webpack://./src/pages/AuditLogViewer/AuditLogViewer.scss"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,gBAAA;EACA,cAAA;AAAR;AAOI;EACI,YAAA;AALR;AAQI;EACI,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,gBAAA;EACA,cAAA;AANR;AASI;EACI,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,cAAA;AAPR","sourcesContent":[".auditLogViewerCss {\n    .form-label {\n        font-size: 16px;\n        font-weight: 600;\n        line-height: 21.79px;\n        text-align: left;\n        color: #333333;\n    }\n\n    // select {\n    //     color: #9BA9B0;\n    // }\n\n    select option {\n        color: black;\n    }\n\n    .text1title {\n        font-family: Open Sans;\n        font-size: 16px;\n        font-weight: 600;\n        line-height: 21.79px;\n        text-align: left;\n        color: #1C1C1E;\n    }\n\n    .text2title {\n        font-family: Open Sans;\n        font-size: 16px;\n        font-weight: 400;\n        line-height: 21.79px;\n        color: #333333;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
