
//English Desktop Images
import WalkDashboard1 from '../../assets/images/WalkDashboard-1.png';
import WalkDashboard2 from '../../assets/images/WalkDashboard-2.png';
import WalkDashboard3 from '../../assets/images/WalkDashboard-3.png';
import WalkAccounts1 from '../../assets/images/WalkAccounts-1.png';
import WalkAccounts2 from '../../assets/images/WalkAccounts-2.png';
import WalkTeams1 from '../../assets/images/WalkTeams-1.png';
import WalkTeams2 from '../../assets/images/WalkTeams-2.png';
import WalkTeams3 from '../../assets/images/WalkTeams-3.png';
import WalkTeams4 from '../../assets/images/WalkTeams-4.png';
import WalkTeams5 from '../../assets/images/WalkTeams-5.png';
import WalkCards1 from '../../assets/images/WalkCards-1.png';
import WalkCards2 from '../../assets/images/WalkCards-2.png';
import WalkCards3 from '../../assets/images/WalkCards-3.png';
import WalkCards4 from '../../assets/images/WalkCards-4.png';
import WalkCards5 from '../../assets/images/WalkCards-5.png';
import WalkExpense1 from '../../assets/images/WalkExpense-1.png';
import WalkExpense2 from '../../assets/images/WalkExpense-2.png';
import WalkExpense3 from '../../assets/images/WalkExpense-3.png';
import WalkExpense4 from '../../assets/images/WalkExpense-4.png';
import WalkExpense5 from '../../assets/images/WalkExpense-5.png';
import WalkExpense6 from '../../assets/images/WalkExpense-6.png';
import WalkExpense7 from '../../assets/images/WalkExpense-7.png';
import WalkExpense8 from '../../assets/images/WalkExpense-8.png';
import WalkExpense9 from '../../assets/images/WalkExpense-9.png';
import WalkTopUp1 from '../../assets/images/WalkTopUp-1.png';
import WalkTopUp2 from '../../assets/images/WalkTopUp-2.png';
import WalkAnalytics1 from '../../assets/images/WalkAnalytics-1.png';
import WalkExport1 from '../../assets/images/WalkExport-1.png';
import WalkExport2 from '../../assets/images/WalkExport-2.png';
import WalkCompany1 from '../../assets/images/WalkCompany-1.png';
import WalkSettings1 from '../../assets/images/WalkSettings-1.png';
import WalkSettings2 from '../../assets/images/WalkSettings-2.png';
import WalkSettings3 from '../../assets/images/WalkSettings-3.png';
import WalkSettings4 from '../../assets/images/WalkSettings-4.png';

//English Responisve Images
import WalkDashboardMobile1 from '../../assets/images/WalkDashboard-1-mobile.png';
import WalkDashboardMobile2 from '../../assets/images/WalkDashboard-1-mobile.png';
import WalkDashboardMobile3 from '../../assets/images/WalkDashboard-1-mobile.png';

//Arabic Desktop Images
import WalkDashboardArabicDesktop1 from '../../assets/images/WalkDashboard-1-arabicDesktop.png';
import WalkDashboardArabicDesktop2 from '../../assets/images/WalkDashboard-2-arabicDesktop.png';
import WalkDashboardArabicDesktop3 from '../../assets/images/WalkDashboard-3-arabicDesktop.png';

//Arabic Responisve Images
import WalkDashboardArabicMobile1 from '../../assets/images/WalkDashboard-1-arabicMobile.png';
import WalkDashboardArabicMobile2 from '../../assets/images/WalkDashboard-1-arabicMobile.png';
import WalkDashboardArabicMobile3 from '../../assets/images/WalkDashboard-1-arabicMobile.png';


export const imagesDesktopEnglish = [
    { image: WalkDashboard1 },
    { image: WalkDashboard2 },
    { image: WalkDashboard3 },
    { image: WalkAccounts1 },
    { image: WalkAccounts2 },
    { image: WalkTeams1 },
    { image: WalkTeams2 },
    { image: WalkTeams3 },
    { image: WalkTeams4 },
    { image: WalkTeams5 },
    { image: WalkCards1 },
    { image: WalkCards2 },
    { image: WalkCards3 },
    { image: WalkCards4 },
    { image: WalkCards5 },
    { image: WalkExpense1 },
    { image: WalkExpense2 },
    { image: WalkExpense3 },
    { image: WalkExpense4 },
    { image: WalkExpense5 },
    { image: WalkExpense6 },
    { image: WalkExpense7 },
    { image: WalkExpense8 },
    { image: WalkExpense9 },
    { image: WalkTopUp1 },
    { image: WalkTopUp2 },
    { image: WalkAnalytics1 },
    { image: WalkExport1 },
    { image: WalkExport2 },
    { image: WalkCompany1 },
    { image: WalkSettings1 },
    { image: WalkSettings2 },
    { image: WalkSettings3 },
    { image: WalkSettings4 },
];

export const imagesMobileEnglish = [
    { image: WalkDashboardMobile1 },
    { image: WalkDashboardMobile2 },
    { image: WalkDashboardMobile3 },
];

export const imagesArbicDesktop = [
    { image: WalkDashboardArabicDesktop1 },
    { image: WalkDashboardArabicDesktop2 },
    { image: WalkDashboardArabicDesktop3 },
];

export const imagesMobileArabic = [
    { image: WalkDashboardArabicMobile1 },
    { image: WalkDashboardArabicMobile2 },
    { image: WalkDashboardArabicMobile3 },
];