import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import { MdKeyboardArrowRight } from 'react-icons/md';
import Tab from 'react-bootstrap/Tab';
import { useTranslation } from 'react-i18next';
import './index.scss';
import verified from '../../assets/images/complete.png';
import complete from '../../assets/images/complete2.png';
import progress from '../../assets/images/progress.png';
import compnay_verification_image from '../../assets/images/company_verification.png';
import ownership from '../../assets/images/ownership_verification.png';
import cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchQuestionaire, fetchCompanyStatus, getAllDocuments, generateInvitationLink, getCompanyDetails, getCompanyOwners, submitAnswer, updateCompanyProfile, updateOwnerDetails, updateKybStatus, uploadCompanyDoc, deleteDoc, deleteOwner } from './companySlice';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader/Loader';
import CompanyDetails from '../../components/CompanyVerification/CompanyDetails';
import UsageDetails from '../../components/CompanyVerification/UsageDetails';
import Attachments from '../../components/CompanyVerification/Attachments';
import OwnersDetails from '../../components/CompanyVerification/OwnersDetails';
import { Modal, OverlayTrigger, Stack, Tooltip } from 'react-bootstrap';
import { Chip, Divider, Grid, Typography } from '@mui/material';
import checked from '../../assets/images/Check_icon_green.png';
import Logo2 from '../../assets/images/polo.png';
import CrossImage from '../../assets/images/close-square-8.png';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import DOMPurify from 'dompurify';

function CompanyVerification() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showSplit, setShowSplit] = useState(false);
  const [questionaire, setQuestionaire] = useState([]);
  const [companyStatusDetails, setCompanyStatusDetails] = useState(null);
  const currentLanguageCode = cookies.get('i18next') || 'en';
  const [activeKey, setActiveKey] = useState(null);
  const [activeOwnerId, setActiveOwnerId] = useState(null);
  const [showAddingPane, setShowAddingPane] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [KYBStatus, setKYBStatus] = useState(null);
  const [companyStatus, setCompanyStatus] = useState(null);
  const [companyComment, setCompanyComment] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [attachedDocs, setAttachedDocs] = useState([]);
  const [remainingDocs, setRemainingDocs] = useState([]);
  const [companyOwners, setCompanyOwners] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [submittedFields, setSubmittedFields] = useState(null);
  const [deleteDetails, setDeleteDetails] = useState({ showModal: false, ownerID: null });
  const [showSubmittedModal, setShowSubmittedModal] = useState(false);
  const handleShowSubmittedModal = () => setShowSubmittedModal(true);
  const handleCloseSubmittedModal = () => setShowSubmittedModal(false);
  const { isLoading, companyDetail, selectedQuestionare } = useSelector(state => state?.companyVerification);


  useEffect(() => {
    fetchQuestionAnswer();
    fetchCompanyStatusDetails();
    fetchDocumentTypes();
    fetchCompanyOwners();
    dispatch(getCompanyDetails());
    setShowSplit(true);
  }, []);

  useEffect(() => {
    if (companyStatusDetails) {
      setSubmittedFields(companyStatusDetails.submittedFields);
      setIsChecked(companyStatusDetails.kybStatus === 'Submitted');
    }
  }, [companyStatusDetails]);

  useEffect(() => {
    if (submittedFields?.companyDetails) {
      if (submittedFields?.usageDetails) {
        if (submittedFields?.attachments) {
          const hasMandatory = remainingDocs?.some(item => item.isMandatory === true);
          if (hasMandatory) {
            setActiveKey(3);
          } else {
            setActiveKey(4);
            if (companyOwners.length > 0) {
              setActiveOwnerId(companyOwners[companyOwners.length - 1].employeeId);
              setIsDisabled(false);
            }
          }
        } else {
          setActiveKey(3);
        }
      }
      else {
        setActiveKey(2);
      }
    } else {
      setActiveKey(1);
    }
  }, [submittedFields, companyOwners]);

  useEffect(() => {
    setKYBStatus(companyDetail?.kybStatus);
    setCompanyStatus(companyDetail?.status);
    setCompanyComment(companyDetail?.comment);
  }, [companyDetail]);

  const fetchQuestionAnswer = () => {
    dispatch(fetchQuestionaire())
      .unwrap()
      .then((res) => {
        setQuestionaire(res?.data);
      }).catch((error) => {
        toast.error(error?.message);
      });
  };

  const fetchCompanyOwners = () => {
    dispatch(getCompanyOwners())
      .unwrap()
      .then((res) => {
        setCompanyOwners(res?.data);
      }).catch((error) => {
        toast.error(error?.message);
      });
  };

  const fetchCompanyStatusDetails = () => {
    dispatch(fetchCompanyStatus())
      .unwrap()
      .then((res) => {
        setCompanyStatusDetails(res?.data);
      }).catch((error) => {
        toast.error(error?.message);
      });
  };

  const fetchDocumentTypes = () => {
    dispatch(getAllDocuments())
      .unwrap()
      .then((res) => {
        setAttachedDocs(res?.data.attached);
        setRemainingDocs(res?.data.remaining);
      }).catch((error) => {
        toast.error(error?.message);
      });
  };

  const onSubmit2 = (data) => {
    if (companyStatus === 'Active' && companyStatusDetails.kybStatus === 'Verified') {
      return;
    }
    dispatch(submitAnswer(data?.answers))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        setSubmittedFields((prevFields) => ({
          ...prevFields,
          usageDetails: true
        }));
        setActiveKey(3);
      }).catch((error) => {
        toast.error(error?.message);
      });
  };

  const handleBackButton = () => {
    if (companyStatus === 'Active' && companyStatusDetails.kybStatus === 'Verified') {
      return;
    }
    if (activeKey == 2) {
      setActiveKey(1);
    } else if (activeKey == 3) {
      setActiveKey(2);
    }
  };
  const languages = [
    {
      code: 'en',
      name: 'English',
      country_code: 'gb',
    },
    {
      code: 'ar',
      name: 'العربية',
      dir: 'rtl',
      country_code: 'sa',
    },
  ];
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr';
    document.title = t('app_title');
  }, [currentLanguage, t]);

  const routeChange = () => {
    let path = `/`;
    navigate(path);
  };

  const onSubmit = (data) => {
    if (companyStatus === 'Active' && companyStatusDetails.kybStatus === 'Verified') {
      return;
    }
    data.postalCode = data.postalCode ? parseInt(data.postalCode) : null;
    data.companyPhone = parseInt(data.companyPhone);

    const requestData = Object.fromEntries(
      Object.entries(data).filter(([key, value]) =>
        (key !== "postalCode" || value) &&
        (key !== "addressLine2" || value)
      )
    );

    dispatch(updateCompanyProfile(requestData))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        setSubmittedFields((prevFields) => ({
          ...prevFields,
          companyDetails: true
        }));
        if (KYBStatus !== 'Submitted') {
          setKYBStatus('Verification in progress');
        }
        setActiveKey(2);
      }).catch((error) => {
        toast.error(error?.message);
      });
  };

  const onUpdate = (ownerID, data) => {
    data.mobileNumber = '+974' + data.mobileNumber;
    const requestData = Object.fromEntries(Object.entries(data));

    dispatch(updateOwnerDetails({ ownerID, requestData: requestData }))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
      }).catch((error) => {
        toast.error(error?.message);
      });
  };

  const onDelete = () => {
    let ownerID = deleteDetails?.ownerID;
    dispatch(deleteOwner(ownerID))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        setCompanyOwners(prev => prev.filter(employee => employee.employeeId !== ownerID));
        setShowAddingPane(false);
        setIsDisabled(false);
        setDeleteDetails((prev) => ({ ...prev, showModal: false, ownerID: null }));
      }).catch((error) => {
        toast.error(error?.message);
      });
  };

  const handleNext = () => {
    if (companyStatus === 'Active' && companyStatusDetails.kybStatus === 'Verified') {
      return;
    }
    let hasMandatory = false;
    if (remainingDocs?.length != 0) {
      hasMandatory = remainingDocs?.some(item => item.isMandatory === true);
    }

    if (hasMandatory) {
      toast.error(t("company.attachments_error"));
    }
    else {
      setSubmittedFields((prevFields) => ({
        ...prevFields,
        attachments: true
      }));
      setActiveKey(4);
      setIsDisabled(false);
      if (companyOwners.length > 0) {
        setActiveOwnerId(companyOwners[companyOwners.length - 1].employeeId);
        setIsDisabled(false);
      }
    }
  };

  const uploadDocument = (selectedFile) => {
    const formData = [];
    const companyId = companyDetail?.companyId;
    const transformedArray = [];
    const typeID = selectedFile.typeID;
    const file = selectedFile.file;
    let element = {
      companyId,
      typeID,
      file
    };
    transformedArray.push(element);
    formData.push(...transformedArray);

    dispatch(uploadCompanyDoc(formData))
      .unwrap()
      .then((res) => {
        const message = res[0]?.message;
        if (message) {
          toast.success(message);
          fetchDocumentTypes();
        }
      }).catch((error) => {
        toast.error(error?.message);
      });
  };

  const removeDocument = (attachmentTypeId) => {
    dispatch(deleteDoc(attachmentTypeId))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        fetchDocumentTypes();
        setSubmittedFields((prevFields) => ({
          ...prevFields,
          attachments: false
        }));
        setActiveKey(3);
        setActiveOwnerId(null);
        setIsDisabled(true);
      }).catch((error) => {
        toast.error(error?.message);
      });
  };

  const sendInvitationLink = (data) => {
    const requestData = {
      ...data,
      isOwner: true,
      mobileNumber: `+974${data.mobileNumber}`
    };

    //const splittedName = data.fullName.split(" ");
    const viewData = {
      "employeeId": "0",
      "fullName": `${data.firstName} ${data.lastName}`,
      "firstName": data.firstName,
      "lastName": data.lastName,
      "email": data.email,
      "mobileNumber": data.mobileNumber,
      "companyId": 0
    };
    dispatch(generateInvitationLink(requestData))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        setCompanyOwners(prev => [...prev, viewData]);
        fetchCompanyOwners();
        setIsDisabled(false);
        setShowAddingPane(false);
        setActiveOwnerId("0");
      }).catch((error) => {
        toast.error(error?.message);
      });
    setIsDisabled(false);
    setShowAddingPane(false);
  };

  const handleshowAddingPane = () => {
    setShowAddingPane(true);
    setIsDisabled(true);
    setActiveOwnerId(null);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleVerificationSubmit = () => {
    const requestData = {
      "kybStatus": 'Submitted'
    };
    dispatch(updateKybStatus(requestData))
      .unwrap()
      .then(() => {
        fetchCompanyStatusDetails();
        handleShowSubmittedModal();
      }).catch((error) => {
        toast.error(error?.message);
      });
  };

  const HtmlContent = ({ htmlString, KYBStatus }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const sanitizedHtml = DOMPurify.sanitize(htmlString);
    const truncateText = (text, limit) => {
      return text.length > limit ? text.slice(0, limit) + '...' : text;
    };
    const needsSeeMore = sanitizedHtml.length > 100;
    const displayedContent = isExpanded ? sanitizedHtml : truncateText(sanitizedHtml, 100) + ` <span class="see-more" style="color: ${KYBStatus === 'Unverified' ? "#FF0F00" : KYBStatus === 'Revise' ? "#FFA500" : "#0075FF"}; cursor: pointer;">See More</span>`;
    return (
      <Stack className='statusBar mt-3' style={{ background: KYBStatus === 'Unverified' ? "#F4DDDB" : KYBStatus === 'Revise' ? "#F4EBDB" : "#DBE7F4" }}>
        <div
          dangerouslySetInnerHTML={{ __html: displayedContent }}
          onClick={() => {
            if (!isExpanded && needsSeeMore) {
              setIsExpanded(true);
            }
          }}
        />
        {isExpanded && (
          <span
            className='see-more'
            onClick={() => setIsExpanded(false)}
            style={{ color: KYBStatus === 'Unverified' ? "#FF0F00" : KYBStatus === 'Revise' ? "#FFA500" : "#0075FF", cursor: 'pointer' }}>
            See Less
          </span>
        )}
      </Stack>
    );
  };

  return (
    <div className={`${currentLanguageCode === 'en' ? 'white-container' : 'white-container-arabic'}`}>
      {
        isLoading && <Loader />
      }
      <h1 className='text-green mb-3'>{t('sidebar.companyVerification')}</h1>
      <Row>
        <Col sm={6}>
          <Stack direction="row" spacing={2} sx={{ alignItems: "center" }} className='statusBar'>
            <Grid container sx={{ alignItems: "center", justifyContent: "space-between" }}>
              <Grid item xs={12} md={5.9} sx={{
                display: 'flex',
                justifyContent: {
                  xs: 'flex-start',
                  md: 'flex-start',
                },
                alignItems: 'baseline',
                gap: 1,
              }}>
                <Typography
                  variant="body1"
                  className='statusBarText'
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {t('company.KYBStatus')}
                </Typography>
                <Chip className='inProgressStatus styledChip' label={KYBStatus === 'Verification in progress' ? t('company.KYBStatus_inProgress') : t(`${KYBStatus}`)} />
              </Grid>
              <Grid item sm={0.2} display="flex" justifyContent="center">
                <Divider orientation="vertical" flexItem style={{ height: '29.5px', color: '#DDD9EE', width: '1px' }} />
              </Grid>
              <Grid item xs={12} md={5.9}
                sx={{
                  display: 'flex',
                  justifyContent: {
                    xs: 'flex-start',
                    md: 'flex-end',
                  },
                  alignItems: 'baseline',
                  gap: 1,
                }}
              >
                <Typography
                  variant="body1"
                  className='statusBarText'
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >{t('company.status')}
                </Typography>
                <Chip className='styledChip' label={t(`company.status_${companyStatus}`)} />
              </Grid>
            </Grid>
          </Stack>
          {(KYBStatus === 'Unverified' || KYBStatus === 'Revise') && companyComment && (
            <div>
              <HtmlContent htmlString={companyComment} KYBStatus={KYBStatus} />
            </div>
          )}
        </Col>
      </Row>
      <div className='company-verification mt-3'>
        <Tab.Container id="company-verification-tabs" >
          <Row>
            <Col sm={6}>
              <Nav
                variant="pills"
                className="flex-column"
                activeKey={activeKey}
                onSelect={(selectedKey) => {
                  setActiveKey(selectedKey);
                  setShowSplit(true);
                  if (selectedKey >= 1 && selectedKey <= 3) {
                    setActiveOwnerId(null);
                  }
                }}
              >
                <div className='white_container'>
                  <>
                    <div className='d-flex green-header mt-2 mb-3'>
                      <img
                        src={compnay_verification_image}
                        alt=''
                        srcSet=''
                        width={32}
                        height={32}
                        className='ms-2 mt-1 me-2'
                      />
                      <p className='text-green green-header-text mt-1'>
                        {t('company.company_information')}
                      </p>
                    </div>
                    <Nav.Item>
                      <Nav.Link
                        disabled={!showSplit}
                        onClick={() => setShowSplit(true)}
                        eventKey={1}
                        active={activeKey == 1}
                        style={{ pointerEvents: !showSplit ? 'none' : 'auto' }}
                      >
                        <div className='d-flex align-items-center justify-content-between'>
                          <Stack direction="horizontal" gap={1}>
                            {
                              activeKey == 1 ? (
                                <img src={progress} style={{ width: '24px', height: '24px' }} />
                              ) : submittedFields?.companyDetails ? (
                                <img src={verified} style={{ width: '24px', height: '24px' }} />
                              ) : null}
                            <p className='mb-0' style={{ fontSize: 16, fontWeight: '600' }}>{t('company.company_details')}</p>
                          </Stack>
                          <MdKeyboardArrowRight fontSize={34} width={24} height={24} />
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <OverlayTrigger
                      placement="top"
                      overlay={!submittedFields?.companyDetails ? <Tooltip>Please complete the company details first</Tooltip> : <></>}
                    >
                      <Nav.Item>
                        <Nav.Link
                          eventKey={2}
                          disabled={!submittedFields?.companyDetails}
                          active={activeKey == 2}
                          style={{ pointerEvents: !submittedFields?.companyDetails ? 'none' : 'auto' }}>
                          <div className='d-flex align-items-center justify-content-between'>
                            <Stack direction="horizontal" gap={1}>
                              {
                                activeKey == 2 ? (
                                  <img src={progress} style={{ width: '24px', height: '24px' }} />
                                ) : submittedFields?.usageDetails ? (
                                  <img src={verified} style={{ width: '24px', height: '24px' }} />
                                ) : null
                              }
                              <p className='mb-0 ' style={{ fontSize: 16, fontWeight: '600' }}>{t('company.usage_details')}</p>
                            </Stack>
                            <MdKeyboardArrowRight fontSize={34} width={24} height={24} />
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      overlay={!submittedFields?.usageDetails ? <Tooltip>Please complete the usage details first</Tooltip> : <></>}
                    >
                      <Nav.Item>
                        <Nav.Link
                          eventKey={3}
                          disabled={!submittedFields?.usageDetails}
                          active={activeKey == 3}
                          style={{ pointerEvents: !submittedFields?.usageDetails ? 'none' : 'auto' }}>
                          <div className='d-flex align-items-center justify-content-between'>
                            <Stack direction="horizontal" gap={1}>
                              {
                                activeKey == 3 ? (
                                  <img src={progress} style={{ width: '24px', height: '24px' }} />
                                ) : submittedFields?.attachments ? (
                                  <img src={verified} style={{ width: '24px', height: '24px' }} />
                                ) : null}
                              <p className='mb-0' style={{ fontSize: 16, fontWeight: '600' }}>{t('company.attachments')}</p>
                            </Stack>
                            <MdKeyboardArrowRight fontSize={34} width={24} height={24} />
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>
                  </>
                  {showSplit &&
                    <>
                      <div style={{ borderBottom: '1px solid #EAECF0', marginBottom: 24 }}></div>
                      <div className="d-flex green-header mt-2 mb-3">
                        <img src={ownership} alt="" srcSet="" width={32} height={32} className='ms-2 mt-1 me-2' />
                        <p className='text-green green-header-text mt-1'>{t('company.ownership_and_admin_verification')}</p>
                      </div>
                      {companyOwners?.map((owner) => (
                        <Nav.Item key={owner.employeeId} className='tab_nav_item'>
                          <Nav.Link
                            eventKey={owner.employeeId}
                            className={`tab_nav_link ${activeOwnerId === owner.employeeId ? 'active' : ''}`} // Check activeOwnerId
                            onClick={() => {
                              setActiveOwnerId(owner.employeeId); // Set active owner
                              setActiveKey(4);
                            }}
                            disabled={isDisabled}
                          >
                            <div className='d-flex align-items-center justify-content-between'>
                              <Stack direction="vertical" gap={1}>
                                <p className='mb-0 ' style={{ fontSize: 16, fontWeight: '600' }}>{owner.firstName} {owner.lastName}</p>
                                <Stack direction="horizontal">
                                  {
                                    owner.eKYCStatus ?
                                      <>
                                        <p className='lightText'>{`${t('teams.eKYC_status')} : ${t('teams.eKYC_completed')}`} </p>
                                        <img src={complete} alt="complete" srcSet="" width={16} height={16} className='ms-2 mb-2 me-2' />
                                      </> : <>
                                        <p className='lightText'>{`${t('teams.eKYC_status')} : ${t('teams.eKYC_pending')}`} </p>
                                      </>
                                  }
                                </Stack>
                              </Stack>
                              <MdKeyboardArrowRight fontSize={34} width={24} height={24} />
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                      {companyStatus === 'InActive' && <Nav.Item eventKey={4} active={activeKey == 4}>
                        <div className='d-flex align-items-center justify-content-end'>
                          <button
                            className={isDisabled ? 'disabledButton' : 'btn-primary-small'}
                            onClick={handleshowAddingPane}
                            id='add_owner'
                          >
                            {t('add')}
                          </button>
                        </div>
                      </Nav.Item>}
                      <h3 className='mb-3 fw-normal' style={{ color: "#8B9299" }}>{t('company.provide_usage_details_desc')}</h3>
                    </>
                  }
                </div>
              </Nav>
            </Col>
            <Col sm={6}>
              {!showSplit && <Loader />}
              {showSplit && <div className='border-grey p-3 white_container' >
                <Tab.Content>
                  <Tab.Pane eventKey={1} active={activeKey == 1}>
                    <CompanyDetails companyDetail={companyDetail} onSubmit={onSubmit} companyStatus={companyStatus} />
                  </Tab.Pane>
                  <Tab.Pane eventKey={2} active={activeKey == 2}>
                    <UsageDetails questionaire={questionaire} selectedQuestionare={selectedQuestionare} onSubmit={onSubmit2} handleBackButton={handleBackButton} companyStatus={companyStatus} />
                  </Tab.Pane>
                  <Tab.Pane eventKey={3} active={activeKey == 3}>
                    <Attachments handleBackButton={handleBackButton} onSubmit={handleNext} uploadDocument={uploadDocument} attachedDocs={attachedDocs} remainingDocs={remainingDocs} removeDocument={removeDocument} companyStatus={companyStatus} />
                  </Tab.Pane>
                  {companyOwners?.map((currentOwner) => (
                    <Tab.Pane key={currentOwner.employeeId} eventKey={currentOwner.employeeId} active={activeOwnerId == currentOwner.employeeId}>
                      {!showAddingPane && <OwnersDetails
                        owner={currentOwner}
                        onSubmit={sendInvitationLink}
                        onUpdate={onUpdate}
                        //onDelete={onDelete}
                        setDeleteDetails={setDeleteDetails}
                        companyStatus={companyStatus}
                        companyStatusDetails={companyStatusDetails}
                      />}
                    </Tab.Pane>
                  ))}

                  <Tab.Pane eventKey={4} active={activeKey >= 4}>
                    {showAddingPane && <OwnersDetails
                      onSubmit={sendInvitationLink}
                      onUpdate={onUpdate}
                      setDeleteDetails={setDeleteDetails}
                      companyStatus={companyStatus}
                      companyStatusDetails={companyStatusDetails}
                    />}
                  </Tab.Pane>
                </Tab.Content>
              </div>}
            </Col>
          </Row>
        </Tab.Container>
      </div>
      {(activeKey >= 4 && companyStatus === 'InActive') && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div className="mb-3 form-check" style={{ marginRight: 20, display: 'flex', alignItems: 'center', gap: '8px' }}>
          <input checked={isChecked} type="checkbox" className="form-check-input" id="condition_agreement" onChange={handleCheckboxChange} />
          <label className="form-check-label" htmlFor="condition_agreement">{t('condition_agreement')}</label>
        </div>
        <div>
          <h4 className='mb-3 lightText'>{t('verification_desc')}</h4>
        </div>
        <div>
          <button
            className={isChecked ? 'btn-primary-small' : 'disabledButton'}
            style={{ width: '592px', paddingBlock: '0px', paddingInline: '32px' }}
            onClick={handleVerificationSubmit}
            id='submit_verification'
          >
            {t('verification_submit')}
          </button>
        </div>
      </div>}
      <Modal
        aria-labelledby="contained-modal-title-center"
        centered
        show={showSubmittedModal}
        onHide={handleCloseSubmittedModal}
      >
        <div className='form' style={{ padding: 24, justifyContent: 'center', alignItems: 'center' }}>
          <div className='d-flex align-items-start justify-content-between'>
            <img src={Logo2} className="logoso mt-1 mb-4" alt="Sample image" />
            <div onClick={handleCloseSubmittedModal} id='close_modal'>
              <img src={CrossImage} className='small-addjust' style={{ width: 20, objectFit: 'contain', marginLeft: 15 }} />
            </div>
          </div>
          <Stack direction='vertical' spacing={3} className='justify-content-center'>
            <div className='d-flex justify-content-center mb-2'>
              <img src={checked} className='centered-img' style={{ width: '38px', height: '38px' }} />
            </div>

            <h1 className='text-center mb-2' style={{ fontSize: '20px !important' }}>
              {t('company.submitted')}
            </h1>
            <p className="text-center mb-0 text-subtitle2" style={{ color: '#8B9299' }} >
              {t('company.submitted_p')}
            </p>
          </Stack>
          <div className="text-center text-lg-start mt-4 w-100">
            <button
              type="button"
              onClick={() => { routeChange(); }}
              className="btn btn-primary btn-lg btn-block w-100"
              style={{ position: 'relative', zIndex: '1' }}
              id='continue_verification'
            >
              {'Continue'}
            </button>
          </div>
        </div>
      </Modal>
      <ConfirmationDialog
        show={deleteDetails.showModal}
        onConfirm={onDelete}
        onClose={() => setDeleteDetails((prev) => ({ ...prev, showModal: false }))}
        message="Are you sure you want to Delete the owner?"
      />
    </div>
  );
}

export default CompanyVerification;
