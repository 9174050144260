import React from 'react';
import { Navigate, useNavigate, useLocation } from "react-router-dom";
// import { responseInterceptor } from './api';
// import { store } from '../..';
import NewSidebar from '../components/Sidebar/NewSidebar';
import { responseInterceptor } from './api';

const PrivateRoute = ({
    component: Component,
}) => {
    const history = useNavigate();
    const location = useLocation();
    // const isAdmin = location.pathname.includes("/admin")
    responseInterceptor(history);
    const isAuthenticated = localStorage.getItem('token');
    // const isAuthenticated = true

    if (isAuthenticated) {
        return (
        <div className='container-main-class row'>
            <NewSidebar component={Component} />
          </div>);
        
    } else {
        return <Navigate
            to={"/login"}
            replace
            state={{ from: location.pathname }}
        />;
    }
};

export default PrivateRoute;
