import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utils/apiUrls";
import api from "../../utils/api";

const initialState = {
    fetchAllEmployees: [],
    employeeDetails: [],
    reimbursementForReview: [],
    reimbursementDetails: [],
    reimbursementActivityLogs: [],
    reimbursementSettled: [],
    reimbursementDetailLoader: false,
    isLoading: false,
};

export const getAllEmployees = createAsyncThunk("reimbursement/getAllEmployees", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getFetchEmployees + queryParams,
        };
        const response = await api(data);
        return response.data.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getEmployeeDetails = createAsyncThunk("reimbursement/getEmployeeDetails", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getreimbursementEmployeeDetails + queryParams,
        };
        const response = await api(data);
        return response.data.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getreimbursementForReview = createAsyncThunk("reimbursement/getreimbursementForReview", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getreimbursementForReview + queryParams,
        };
        const response = await api(data);
        return response.data.data;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const fetchReimbursementDetails = createAsyncThunk("reimbursement/fetchReimbursementDetails", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchReimbursementDetails + queryParams,
        };
        const response = await api(data);
        return response.data.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const updateReimbursementTransaction = createAsyncThunk("reimbursement/updateReimbursementTransaction", async (postData) => {
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.updateReimbursementTransaction,
            data: postData
        };
        console.log(postData);
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const updateReimbursementByIdAndFile = createAsyncThunk("reimbursement/updateReimbursementByIdAndFile", async ({ requestData, reimbursementRequestId }) => {
    try {
        const formData = new FormData();
        formData.append('file', requestData);
        formData.append('reimbursementRequestId', reimbursementRequestId);
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.updateReimbursementByIdAndFile,
            headers: { "Content-Type": 'multipart/form-data' },
            data: formData,
        };
        console.log(JSON.stringify(formData));
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const fetchReimbursementActivityLog = createAsyncThunk("reimbursement/fetchReimbursementActivityLog", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchReimbursementActivityLog + queryParams,
        };
        const response = await api(data);
        return response.data.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const approveRejectReimbursement = createAsyncThunk("card/approveRejectReimbursement", async (postData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.approveRejectReimbursement,
            data: postData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const fetchSettledReimbursement = createAsyncThunk("reimbursement/fetchSettledReimbursement", async (employeeId) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: `${API_ENDPOINTS.fetchsettledReimbursement}?employeeId=${employeeId}`,
        };
        const response = await api(data);
        return response.data.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const UploadSettleReimbursement = createAsyncThunk("card/UploadSettleReimbursement", async ({ formData, queryParams }) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.uploadSettleReimbursement + queryParams,
            data: formData,
            headers: { "Content-Type": 'multipart/form-data' },
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        const message = error?.response?.data[0]?.message || error?.response?.data?.message;
        throw message;
    }
});

export const UploadSettleWithoutDoc = createAsyncThunk("/reimbursement/settleReimbursementWithoutFile", async ({ postData, queryParams }) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.uploadSettleReimbursementWithoutDoc + queryParams,
            data: postData,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        const message = error?.response?.data[0]?.message || error?.response?.data?.message;
        throw message;
    }
});

export const updateConversionRate = createAsyncThunk("reimbursement/updateConversionRate", async (formData) => {
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.updateRate,
            data: formData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

const reimbursementSlice = createSlice({
    name: "reimbursement",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        const handleLoading = (state, action) => {
            state.isLoading = action.meta.requestStatus === 'pending';
        };
        const reimbursementLoader = (state, action) => {
            state.reimbursementDetailLoader = action.meta.requestStatus === 'pending';
        };
        builder
            .addCase(getAllEmployees.fulfilled, (state, action) => {
                state.fetchAllEmployees = action.payload;
            })
            .addCase(getEmployeeDetails.fulfilled, (state, action) => {
                state.employeeDetails = action.payload;
            })
            .addCase(getreimbursementForReview.fulfilled, (state, action) => {
                state.reimbursementForReview = action.payload;
            })
            .addCase(fetchReimbursementDetails.fulfilled, (state, action) => {
                state.reimbursementDetails = action.payload;
                state.reimbursementDetailLoader = false;
            })
            .addCase(fetchReimbursementActivityLog.fulfilled, (state, action) => {
                state.reimbursementActivityLogs = action.payload;
            })
            .addCase(fetchSettledReimbursement.fulfilled, (state, action) => {
                state.reimbursementSettled = action.payload;
            })
            .addMatcher(
                (action) =>
                    action.type === fetchReimbursementDetails.pending.type ||
                    action.type === fetchReimbursementDetails.fulfilled.type ||
                    action.type === fetchReimbursementDetails.rejected.type,
                reimbursementLoader
            )
            .addMatcher(
                (action) =>
                    action.type === getAllEmployees.pending.type ||
                    action.type === getAllEmployees.fulfilled.type ||
                    action.type === getAllEmployees.rejected.type ||
                    action.type === getEmployeeDetails.pending.type ||
                    action.type === getEmployeeDetails.fulfilled.type ||
                    action.type === getEmployeeDetails.rejected.type ||
                    action.type === getreimbursementForReview.pending.type ||
                    action.type === getreimbursementForReview.fulfilled.type ||
                    action.type === getreimbursementForReview.rejected.type ||
                    action.type === updateReimbursementTransaction.pending.type ||
                    action.type === updateReimbursementTransaction.fulfilled.type ||
                    action.type === updateReimbursementTransaction.rejected.type ||
                    action.type === updateReimbursementByIdAndFile.pending.type ||
                    action.type === updateReimbursementByIdAndFile.fulfilled.type ||
                    action.type === updateReimbursementByIdAndFile.rejected.type ||
                    action.type === fetchReimbursementActivityLog.pending.type ||
                    action.type === fetchReimbursementActivityLog.fulfilled.type ||
                    action.type === fetchReimbursementActivityLog.rejected.type ||
                    action.type === approveRejectReimbursement.pending.type ||
                    action.type === approveRejectReimbursement.fulfilled.type ||
                    action.type === approveRejectReimbursement.rejected.type ||
                    action.type === fetchSettledReimbursement.pending.type ||
                    action.type === fetchSettledReimbursement.fulfilled.type ||
                    action.type === fetchSettledReimbursement.rejected.type ||
                    action.type === UploadSettleReimbursement.pending.type ||
                    action.type === UploadSettleReimbursement.fulfilled.type ||
                    action.type === UploadSettleReimbursement.rejected.type ||
                    action.type === updateConversionRate.pending.type ||
                    action.type === updateConversionRate.fulfilled.type ||
                    action.type === updateConversionRate.rejected.type,
                handleLoading
            );
    }
});
export default reimbursementSlice.reducer;
