import React, { useEffect, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ExpensesComponent from './ExpensesComponent';
import moment from 'moment-timezone';
import { useLocation } from 'react-router-dom';
import notFound from '../../assets/images/not_found.png';

function MytrascationTabs(props) {
    const { state } = useLocation();
    const { t } = useTranslation();
    const [activeKey, setActiveKey] = useState('first');
    const { listData, clickOnTabChange, parentTab, currencies, activeTab, showExpenseDetailModal, selectedItems, handleCheckboxChange, filePermission, prevPath } = props;

    useEffect(() => {
        console.log(state)
        if ((state?.prevPath && state?.prevPath === "/expenses/expense-details")) {
            setActiveKey(state?.activeKey);
        }
        else {
            setActiveKey('first');
        }
    }, []);

    return (
        <Tab.Container activeKey={activeKey}>
            <Nav variant="pills">
                <Nav.Item onClick={() => { setActiveKey('first'); clickOnTabChange(parentTab, 'all', 'first'); }}>
                    <Nav.Link eventKey="first" >{t('expenses.all_pending')}</Nav.Link>
                </Nav.Item>
                <Nav.Item onClick={() => { setActiveKey('second'); clickOnTabChange(parentTab, 'noreceipt', 'second'); }}>
                    <Nav.Link eventKey="second" >{t('expenses.no_receipts')}</Nav.Link>
                </Nav.Item>
                <Nav.Item onClick={() => { setActiveKey('third'); clickOnTabChange(parentTab, 'reject', 'third'); }}>
                    <Nav.Link eventKey="third" >{t('expenses.rejected')}</Nav.Link>
                </Nav.Item>
                <Nav.Item onClick={() => { setActiveKey('fourth'); clickOnTabChange(parentTab, 'approve', 'fourth'); }}>
                    <Nav.Link eventKey="fourth" >{t('expenses.approved')}</Nav.Link>
                </Nav.Item>
            </Nav>
            <Tab.Content className='mt-4'>
                <Tab.Pane eventKey="first">
                    {(listData && listData.length > 0) ?
                        (listData.map((item, i) => {
                            return (
                                <div className='export-border table-border p-3 mt-3' key={'listData' + i}>
                                    <h2 className='text-grey'>{moment(item?.date).format('DD MMM YYYY')}</h2>
                                    <ExpensesComponent
                                        data={item?.records}
                                        showExpenseDetailModal={showExpenseDetailModal}
                                        currencies={currencies}
                                        showCheckBox={true}
                                        selectedItems={selectedItems}
                                        handleCheckboxChange={handleCheckboxChange}
                                        parentTab={parentTab}
                                        filePermission={filePermission}
                                        prevPath={prevPath}
                                        activeKey={activeKey}
                                    />
                                </div>
                            );
                        })) : (
                            <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                                <img src={notFound} width={200} height={200} />
                            </div>
                        )
                    }
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                    {(listData && listData.length > 0) ?
                        (listData.map((item, i) => {
                            return (
                                <div className='export-border table-border p-3 mt-3' key={'listData' + i}>
                                    <h2 className='text-grey'>{moment(item?.date).format('DD MMM YYYY')}</h2>
                                    <ExpensesComponent
                                        data={item?.records}
                                        showExpenseDetailModal={showExpenseDetailModal}
                                        currencies={currencies}
                                        showCheckBox={true}
                                        selectedItems={selectedItems}
                                        handleCheckboxChange={handleCheckboxChange}
                                        parentTab={parentTab}
                                        filePermission={filePermission}
                                        prevPath={prevPath}
                                        activeKey={activeKey}
                                    />
                                </div>
                            );
                        })) : (
                            <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                                <img src={notFound} width={200} height={200} />
                            </div>
                        )
                    }
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                    {(listData && listData.length > 0) ?
                        (listData.map((item, i) => {
                            return (
                                <div className='export-border table-border p-3 mt-3' key={'listData' + i}>
                                    <h2 className='text-grey'>{moment(item?.date).format('DD MMM YYYY')}</h2>
                                    <ExpensesComponent
                                        data={item?.records}
                                        showExpenseDetailModal={showExpenseDetailModal}
                                        currencies={currencies}
                                        showCheckBox={true}
                                        selectedItems={selectedItems}
                                        handleCheckboxChange={handleCheckboxChange}
                                        parentTab={parentTab}
                                        filePermission={filePermission}
                                        prevPath={prevPath}
                                        activeKey={activeKey}
                                    />
                                </div>
                            );
                        })) : (
                            <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                                <img src={notFound} width={200} height={200} />
                            </div>
                        )
                    }
                </Tab.Pane>
                <Tab.Pane eventKey="fourth">
                    {listData && listData.length > 0 &&
                        listData.map((item, i) => {
                            return (
                                <div className='export-border table-border p-3 mt-3' key={'listData' + i}>
                                    <h2 className='text-grey'>{moment(item?.date).format('DD MMM YYYY')}</h2>
                                    <ExpensesComponent
                                        data={item?.records}
                                        showExpenseDetailModal={showExpenseDetailModal}
                                        currencies={currencies}
                                        showCheckBox={true}
                                        selectedItems={selectedItems}
                                        handleCheckboxChange={handleCheckboxChange}
                                        parentTab={parentTab}
                                        filePermission={filePermission}
                                        prevPath={prevPath}
                                        activeKey={activeKey}
                                    />
                                </div>
                            );
                        })
                    }
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
    );
}

export default MytrascationTabs;