import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';
import { createAuditTrail } from '../TopUpRequest/slice/topUpSlice';
import { useDispatch } from 'react-redux';
const analyticsData = [
    { "date": "2023-10-08", "balance": 466316.203333 },
    { "date": "2023-10-15", "balance": 460362.870000 },
    { "date": "2023-10-22", "balance": 452190.036667 },
    { "date": "2023-10-29", "balance": 443851.870000 },
    { "date": "2023-11-05", "balance": 445411.626667 },
    { "date": "2023-11-12", "balance": 56828.246667 },
    { "date": "2023-11-19", "balance": 50205.802222 },
    { "date": "2023-11-26", "balance": 55354.776667 },
    { "date": "2023-12-03", "balance": 43999.241429 },
    { "date": "2023-12-10", "balance": 58256.520000 }
];
function Analytics() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [graphData, setGraphData] = useState();
    useEffect(() => {
        dispatch(createAuditTrail({eventType: 'access', module: 'analytic', description: `Viewed analyics.`}))

        axios.post('https://dev.xpendless.com/ai/forecast_balance', {
            input_data: analyticsData
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((data) => {
                setGraphData(data.data);
            }).catch((error) => {
                // toast.error(error?.message || "Request Failed Please Try Again ");
            });
    }, []);


    useEffect(() => {
        // Access the canvas element using a ref after the component has mounted.
        const ctx = document.getElementById('myChart').getContext('2d');

        // Check if a chart instance already exists
        const existingChart = Chart.getChart(ctx);

        // Define gradient colors
        const gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
        gradientStroke.addColorStop(0, 'rgba(21, 112, 239, 1)');
        gradientStroke.addColorStop(1, 'rgba(21, 112, 239, 1)');

        const gradientFill = ctx.createLinearGradient(0, 0, 0, 200);
        gradientFill.addColorStop(0, 'rgba(21, 112, 239, 1)');
        gradientFill.addColorStop(1, 'rgba(21, 112, 239, 0.1)');

        // Create the chart
        if (existingChart) {
            existingChart.destroy();
        }
        const XAxis = graphData?.forecast?.map((obj) => moment(obj?.date).format('DD MMM YYYY'));
        const YAxis = graphData?.forecast?.map((obj) => obj?.balance);

        new Chart(ctx, {
            type: 'line',
            data: {
                labels: XAxis,
                datasets: [
                    {
                        borderColor: gradientStroke,
                        pointBorderColor: 'white',
                        pointBackgroundColor: gradientStroke,
                        pointHoverBackgroundColor: gradientStroke,
                        pointHoverBorderColor: gradientStroke,
                        pointBorderWidth: 2,
                        pointHoverRadius: 2,
                        pointHoverBorderWidth: 3,
                        pointRadius: 5,
                        fill: true,
                        backgroundColor: gradientFill,
                        borderWidth: 2,
                        data: YAxis,
                        tension: 0.3
                    },
                ],
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                scales: {
                    y: {
                        ticks: {
                            fontColor: 'rgba(0,0,0,0.5)',
                            fontStyle: 'bold',
                            beginAtZero: true,
                            maxTicksLimit: 5,
                            padding: 20,
                        },
                        grid: {
                            display: true,
                            color: '#D1E9FF',
                            drawBorder: false,
                        },
                        title: {
                            display: true,
                            text: `Balance`,
                            padding: 0,
                        },
                        angleLines: {
                            display: false
                        }
                    },
                    x: {
                        grid: {
                            display: false,
                            drawBorder: false,
                        },
                        ticks: {
                            padding: 20,
                            fontColor: 'rgba(0,0,0,0.5)',
                            fontStyle: 'bold',
                        },
                        // title:{
                        //   display:true,
                        //   text:`${t('teams.date')}`,
                        //   padding:0,
                        // }
                    },
                },
            },
        });
    }, [graphData]);
    return (
        <div className='white-container'>
            <div>
                <h1 className='text-black'>Forecasted Balance</h1>
                <h4 className='text-grey'>{t('dashboard.by_amount')}</h4>
            </div>
            <div className='graph-dashboard'>
                <canvas id="myChart"></canvas>
            </div>
        </div>
    );
}

export default Analytics;
