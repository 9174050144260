// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle-text-blue {
  background-color: #D8FFF4;
  color: #00D094;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 24.51px;
  letter-spacing: 0em;
  text-align: center;
  padding: 6px;
  border-radius: 15%;
  margin-left: 5px;
  margin-right: 5px;
}

.export-border {
  border: 1px solid rgb(234, 236, 240);
  padding-bottom: 20px;
  padding: 16px;
  border-radius: 15px;
}

.export-btn-style {
  font-size: 16px;
  height: 40px;
  font-weight: 600;
  padding: 0 20px;
}

.exportDropdown {
  width: 100px;
  margin-right: 20px;
}

.form-group {
  width: 100%;
  height: 48px;
}`, "",{"version":3,"sources":["webpack://./src/components/Export/Export.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,cAAA;EACA,0BAAA;EACA,2BAAA;EACA,oBAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACJ;;AACA;EACI,oCAAA;EACA,oBAAA;EACA,aAAA;EACA,mBAAA;AAEJ;;AACA;EACI,eAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;AAEJ;;AAAA;EACI,YAAA;EACA,kBAAA;AAGJ;;AADA;EACI,WAAA;EACA,YAAA;AAIJ","sourcesContent":[".circle-text-blue {\n    background-color: #D8FFF4;\n    color: #00D094;\n    font-size: 18px !important;\n    font-weight: 600 !important;\n    line-height: 24.51px;\n    letter-spacing: 0em;\n    text-align: center;\n    padding: 6px;\n    border-radius: 15%;\n    margin-left: 5px;\n    margin-right: 5px;\n}\n.export-border {\n    border: 1px solid rgba(234, 236, 240, 1);\n    padding-bottom: 20px;\n    padding: 16px;\n    border-radius: 15px;\n}\n\n.export-btn-style{\n    font-size: 16px;\n    height: 40px;\n    font-weight: 600;\n    padding: 0 20px;\n}\n.exportDropdown{\n    width: 100px;\n    margin-right: 20px;\n}\n.form-group{\n    width: 100%;\n    height: 48px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
