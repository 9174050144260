import React from 'react';
import './Login.scss';
import Logo2 from '../../assets/images/polo.png';
import LoginLeftImage from '../../assets/images/login_left_image.png';
import login_bottom_image from '../../assets/images/login_bottom_image.png';
import { useForm } from 'react-hook-form';
import Loader from '../../components/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInvitationCodeDetails } from './loginSlice';
import { toast } from 'react-toastify';

function InvitationCode() {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const date =  new Date().getFullYear();

    const { isLoading } = useSelector(state => state.loginSliceReducer);
    
    const { register, handleSubmit, formState: { errors } } = useForm();

    const routeLoginChange = () => {
        let path = `/login`;
        navigate(path);
    };

    const onSubmit = (data) => {
        dispatch(fetchInvitationCodeDetails(data?.inviteCode)).unwrap()
        .then(res => {
            let path = `/signUp`;
            navigate(path, { state:{ fromInvite: true, ...res?.data[0], inviteCode: data?.inviteCode }});
        })
        .catch(error => {
            toast.error(error?.message);
        });
    };

    return (
        <>
            <section>
                {isLoading && <Loader />}
                <div className="container-fluid" style={{ backgroundColor: '#24473D', position: 'relative' }}>
                    <div className="row  login-forms" >
                        <div className="col-md-7 col-lg-5 col-xl-6  my-2 soop">
                            <div className='d-flex flex-column justify-content-between align-items-center h-100'>
                                <div></div>
                                <form className='form w-50' onSubmit={handleSubmit(onSubmit)}>
                                    <div>
                                    </div>
                                    <div>
                                        <img src={Logo2} className="logo mt-1 mb-4" alt="Sample image" />
                                    </div>
                                    <h1 className='text-blue-login my-2'>
                                        {t('login.join_company')}
                                    </h1>
                                    <div className="divider d-flex align-items-center my-2">
                                        <p className="mb-0 text-subtitle">
                                            {t('login.invitation_code_desc')}
                                        </p>
                                    </div>
                                    <div className="form-outline mt-4 mb-3">
                                        <label className="form-label" htmlFor="inviteCode">{t('login.invitation_code')}</label>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                id="inviteCode"
                                                className={`form-control form-control-lg ${errors?.inviteCode ? 'is-invalid' : ''}`}
                                                placeholder={`${t('login.invitation_code')}`}
                                                {...register('inviteCode', {
                                                    required: `Invitation code is required`
                                                })}
                                                onInput={(e) => {
                                                    const value = e.target.value;
                                                    if (!/^\d*$/.test(value)) {
                                                        e.target.value = value.slice(0, -1);
                                                    }
                                                }}
                                            />
                                            {errors?.inviteCode && (
                                                <div className="invalid-feedback">{errors?.inviteCode?.message}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="text-center text-lg-start mt-4 w-100">
                                        <button type="submit" className="btn btn-primary btn-lg btn-block w-100" id='next'>{t('Next')}</button>
                                    </div>
                                    <div className="divider d-flex align-items-center justify-content-center mt-4 pt-2">
                                        <p className="text-center mx-3 mb-0 text-or">
                                            {t('login.have_an_account?')} <a onClick={routeLoginChange} className='text-blue forgotPassword' id='login'> {t('login.log_in')}</a>
                                        </p>
                                    </div>
                                </form>

                                <div className='copyright'>
                                    <p className='copyright-text'>{`© Xpendless ${date}`}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 col-lg-7 col-xl-6  d-flex justify-content-center align-items-center imagecontainer">
                            <img src={LoginLeftImage} className='loginImage' />
                            <img className='login_bottom_image' src={login_bottom_image} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default InvitationCode;
