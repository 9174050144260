import { createAsyncThunk, createSlice, isAnyOf} from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../../utils/apiUrls";
import api from "../../../utils/api";

const initialState = {
    masterBalance: 0,
    masterBalanceIsLoading: false,
    masterBalanceError: null
};

export const fetchMasterAccountBalance = createAsyncThunk("masterAccount/fetchMasterAccountBalance", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchMasterAccountBalance
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const withDrawFund = createAsyncThunk("masterAccount/withDrawFund", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.withDrawFund,
            data : requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const addFund = createAsyncThunk("masterAccount/addFund", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.addFund,
            data: requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

const accountBalance = createSlice({
    name: "masterAccount",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isAnyOf(fetchMasterAccountBalance.pending, addFund.pending, withDrawFund.pending), (state) => {
                state.masterBalanceIsLoading = true;
            }
            )
            .addMatcher(isAnyOf(fetchMasterAccountBalance.rejected, addFund.rejected, withDrawFund.rejected), (state, { error }) => {
                state.masterBalanceIsLoading = false;
                state.masterBalanceError = error.message ? error.message : "Request Failed Please Try Again ";
                // if (error.message === "Request failed with status code 415") {
                //     return (localStorage.removeItem("token"), window.location.reload());
                // }
            }
            )
            .addMatcher(isAnyOf(fetchMasterAccountBalance.fulfilled, addFund.fulfilled, withDrawFund.fulfilled), (state, { payload }) => {
                state.masterBalanceIsLoading = false;
                state.masterBalanceError = null;
                state.masterBalance =payload.data;
            });
    }
});

// export const { addData } = accountBalance.actions;
const masterAccountBalanceReducer = accountBalance.reducer; // Corrected the export

export default masterAccountBalanceReducer;