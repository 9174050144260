import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Nav, Row, Tab, Tabs } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import ExpensesModalTabs from '../../components/Expenses/ExpensesModalTabs';
import LeftImage from '../../assets/images/left.png';
import { useDispatch, useSelector } from 'react-redux';
import { UploadSettleReimbursement, UploadSettleWithoutDoc, approveRejectReimbursement, fetchReimbursementDetails, fetchSettledReimbursement, getAllEmployees, getEmployeeDetails, getreimbursementForReview, updateReimbursementTransaction, updateReimbursementByIdAndFile } from './reimbursementSlice';
import Loader from '../../components/Loader/Loader';
import { getAllTagByGroup, getCategoryList, fetchCurrencies } from '../Expenses/expensesSlice';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import ForYourReviewTabs from '../../components/Expenses/ForYourReviewTabs';
import { formatQAR, formatTime } from '../../utils/XpendlessUtils';
import ImagesCross from '../../assets/images/close-square-8.png';
import cookies from 'js-cookie';
import { decodeToken } from "react-jwt";
import '../../components/Expenses/Expenses.scss';
import './reimbursement.scss';
import { Chip, Stack } from '@mui/material';
import download from '../../assets/images/download_linear.png';
import view from '../../assets/images/view_icon.png';
import upload from '../../assets/images/upload_reimbursement.png';
import upload_icon from '../../assets/images/upload_ico.png';
import uploaded from '../../assets/images/docAcept.png';
import notFound from '../../assets/images/not_found.png';
import { useLocation } from 'react-router-dom';
import CurrencyInput from 'react-currency-input-field';
import { MAX_VALUE } from '../../utils/constants';

function Reimbursement() {
    const token = localStorage.getItem('token');
    const [show, setShow] = useState(false);
    const [showTwo, setShowTwo] = useState(false);
    const [showSettleDetails, setShowSettleDetails] = useState(false);
    const [showTransDetails, setShowTransDetails] = useState(false);
    const [showThree, setShowThree] = useState(false);
    const [selectedTab, setSelectedTab] = useState('1');
    const [selectedItems, setSelectedItems] = useState([]);
    const { t } = useTranslation();
    const languages = [
        {
            code: 'en',
            name: 'English',
            country_code: 'gb',
        },
        {
            code: 'ar',
            name: 'العربية',
            dir: 'rtl',
            country_code: 'sa',
        },
    ];
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr';
        document.title = t('app_title');
    }, [currentLanguage, t]);

    const dispatch = useDispatch();
    const handleClose = () => { setShow(false); setSelectedItems([]); setSelectAllChecked(false); };
    const handleCloseTwo = () => setShowTwo(false);
    const handleCloseModalThree = () => setShowThree(false);
    const handleShowThree = () => setShowThree(true);
    const [pageNo, setPageNo] = useState(1);
    const [pageNoReview, setPageNoReview] = useState(1);
    const [employeeId, setEmployeeId] = useState(null);
    const [activeTab, setActiveTab] = useState('1');
    const [defaultActiveTab] = useState('2');
    const [filterType, setFilterType] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);
    const [reimbursementRequestId, setReimbursementRequestId] = useState(null);
    const { fetchAllEmployees, employeeDetails, reimbursementSettled, isLoading, reimbursementForReview, reimbursementDetails, reimbursementDetailLoader, reimbursementRequestLoader } = useSelector((state) => state?.reimbursementSlice);
    const { categoryList, tags, currencies } = useSelector(state => state.expenseReducer);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const decodedToken = decodeToken(token);
    const [selectedTotalAmount, setSelectedTotalAmount] = useState();
    const [currentRecord, setCurrentRecord] = useState([]);
    const [comment, setComment] = useState('');
    const [empDetails, setEmpDetails] = useState(null);
    const [settledCashOnHand, setSettledCashOnHand] = useState(0);
    const [settledOtherSources, setSettledOtherSources] = useState(0);
    const [error, setError] = useState('');
    const { state } = useLocation();
    useEffect(() => {
        let total = 0;

        selectedItems.forEach((item) => total += parseFloat(item.amount));
        setSelectedTotalAmount(total);

        if (empDetails?.pettyCashBalance && empDetails?.pettyCashBalance >= total) {
            setSettledCashOnHand(total);
            setSettledOtherSources(0);
        } else {
            setSettledCashOnHand(empDetails?.pettyCashBalance || 0);
            setSettledOtherSources(total - empDetails?.pettyCashBalance);
        }
    }, [selectedItems, empDetails]);

    useEffect(() => {
        let queryParams = `?page=${pageNo}`;
        dispatch(getAllEmployees(queryParams));
    }, [pageNo]);

    useEffect(() => {
        dispatch(fetchCurrencies());
        console.log(state);
        if (state) {
            if (state.activeKey === 'first') {
                setFilterType('pending');
            } else if (state.activeKey === 'second') {
                setFilterType('reject');
            } else if (state.activeKey === 'third') {
                setFilterType('approve');
            }
        } else {
            if (defaultActiveTab === '2') {
                if(state?.prevPath && state?.prevPath.includes("/reimbursement/reimbursement-details")){
                    setFilterType(filterType ? filterType : 'pending');
                }
                else{
                    setFilterType('pending');
                }
            }
            else if (defaultActiveTab === '1') {
                let queryParams = `?page=${pageNo}`;
                dispatch(getAllEmployees(queryParams));
            }
        }
    }, []);

    useEffect(() => {
        if (filterType) {
            dispatch(getreimbursementForReview(`?filterType=${filterType}&page=${pageNoReview ? pageNoReview : 1}`))
                .unwrap()
                .then(() => {
                }).catch((error) => {
                    toast.error(error?.message);
                });
        }
    }, [filterType, pageNoReview]);

    const {
        control: controlRejectForm,
        handleSubmit: handleRejectSubmit,
        formState: { errors: errorsRejectForm },
        reset: resetRejectForm, } = useForm({
            resolver: yupResolver(yup.object().shape({
                comments: yup.string().required()
            })
            )
        });

    const handleShow = (employee) => {
        setEmpDetails(employee);
        setShow(true);
        setEmployeeId(employee?.employeeId);
        if (employee?.employeeId) {
            dispatch(getEmployeeDetails(`?employeeId=${employee.employeeId}`));
            dispatch(fetchSettledReimbursement(employee.employeeId));
        }
    };

    const handleShowSettleDetails = () => {
        setShowSettleDetails(true);
    };

    const handleCloseShowSettleDetails = () => {
        setShowSettleDetails(false);
        setSelectedFile(null);
    };

    const handleShowTransDetails = (record) => {
        setCurrentRecord(record);
        setShowTransDetails(true);
    };

    const handleCloseTransDetails = () => {
        setShowTransDetails(false);
        setCurrentRecord([]);
    };

    const handleShowTwo = (reimbursementRequestId) => {
        setShowTwo(true);
        setReimbursementRequestId(reimbursementRequestId);
        if (reimbursementRequestId) {
            dispatch(fetchReimbursementDetails(`?reimbursementRequestId=${reimbursementRequestId}`));
            dispatch(getAllTagByGroup());
            dispatch(getCategoryList());
        }
    };

    const handleTab = (key) => {
        switch (key) {
            case '1':
                let queryParams = `?page=${pageNo}`;
                dispatch(getAllEmployees(queryParams));
                setActiveTab('1');
                //setFilterType('all');
                break;
            case '2':
                dispatch(getreimbursementForReview(`?filterType=${filterType ? filterType : 'pending'}&page=1`))
                    .unwrap()
                    .then(() => {
                    }).catch((error) => {
                        toast.error(error?.message);
                    });
                setActiveTab('2');
                setFilterType(filterType);
                break;
            default:
                break;
        }
    };

    const onPreviousClick = () => {
        setPageNo(prev => prev - 1);
    };

    const onNextClick = () => {
        setPageNo(prev => prev + 1);
    };

    const searchListByEmployee = (searchVal) => {
        if (searchVal === '') {
            dispatch(getAllEmployees(''));
        } else {
            let queryParams = `?search=${searchVal}`;
            dispatch(getAllEmployees(queryParams));
        }
    };

    const updateTransaction = (requestData, imageUpload) => {
        if (reimbursementRequestId && requestData && requestData.categoryId !== '' && !imageUpload) {
            requestData.reimbursementRequestId = reimbursementRequestId;
            if (requestData.categoryId === 'Select category')
                requestData.categoryId = null;
            dispatch(updateReimbursementTransaction(requestData)).unwrap().then(() => {
                toast.success('Data updated successfully');
                if (reimbursementRequestId) {
                    dispatch(fetchReimbursementDetails(`?reimbursementRequestId=${reimbursementRequestId}`));
                    dispatch(getAllTagByGroup());
                }
            }).catch((error) => {
                toast.error(error.message || "Request Failed Please Try Again ");
            });
        }
        if (reimbursementRequestId && requestData && (imageUpload && imageUpload === 'imageUpload')) {
            dispatch(updateReimbursementByIdAndFile({ requestData, reimbursementRequestId })).unwrap().then(() => {
                if (reimbursementRequestId) {
                    dispatch(fetchReimbursementDetails(`?reimbursementRequestId=${reimbursementRequestId}`));
                    dispatch(getAllTagByGroup());
                }
                toast.success('Data updated successfully');

            }).catch((error) => {
                toast.error(error.message || "Receipt Upload Failed Please Try Again ");
            });
        }
    };

    const approveRejectRequest = (data) => {
        if (reimbursementRequestId) {
            let requestData = {
                approveStatus: data.type,
                reimbursementRequestId
            };

            if (typeof data.comments !== 'undefined') {
                requestData.comments = data.comments;
            }

            dispatch(approveRejectReimbursement(requestData)).unwrap().then(() => {
                let queryParams = `?filterType=${filterType}`;
                dispatch(getreimbursementForReview(queryParams));
                toast.success('Status Changed successfully');
                resetRejectForm();
                handleCloseTwo();
                handleCloseModalThree();
            }).catch((error) => {
                toast.error(error.message || "Request Failed Please Try Again ");
            });
        }
    };

    const changeTabOnTabClick = (eventKey, filterPkey) => {
        setFilterType(filterPkey);
        if (eventKey === '2') {
            let queryParams = `?filterType=${filterPkey}`;
            dispatch(getreimbursementForReview(queryParams));
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
        } else {
            setSelectedFile(null);
        }
    };

    const handleUpload = () => {
        if (settledOtherSources > 0) {
            if (selectedFile) {
                const ids = selectedItems.map(item => item.reimbursementRequestId);
                const formData = new FormData();
                formData.append('file', selectedFile);
                formData.append('total', selectedTotalAmount);
                formData.append('cashOnHand', settledCashOnHand);
                formData.append('otherSources', settledOtherSources);
                let queryParams = `?employeeId=${employeeId}&ids=${JSON.stringify(ids)}&comments=${encodeURIComponent(comment)}&total=${selectedTotalAmount}&cashOnHand=${settledCashOnHand}&otherSources=${settledOtherSources}`;
                dispatch(UploadSettleReimbursement({ formData, queryParams })).unwrap().then((data) => {
                    let queryParams = `?page=${pageNo}`;
                    dispatch(getAllEmployees(queryParams));
                    toast.success(data?.message || "Reimbursement Successfully Settled");
                    handleCloseShowSettleDetails();
                    handleClose();
                }).catch((error) => {
                    toast.error(error?.message || "Request Failed Please Try Again ");
                });
            } else {
                toast.error('Please Upload an Evidence File');
            }
        } else {
            const ids = selectedItems.map(item => item.reimbursementRequestId);
            let queryParams = comment
                ? `?employeeId=${employeeId}&ids=${JSON.stringify(ids)}&comments=${encodeURIComponent(comment)}`
                : `?employeeId=${employeeId}&ids=${JSON.stringify(ids)}`;

            let postData = {
                ids: ids,
                employeeId: employeeId,
                comments: comment ? encodeURIComponent(comment) : "",
                "total": selectedTotalAmount,
                "cashOnHand": settledCashOnHand,
                "otherSources": settledOtherSources
            };
            dispatch(UploadSettleWithoutDoc({ postData, queryParams })).unwrap().then((data) => {
                let queryParams = `?page=${pageNo}`;
                dispatch(getAllEmployees(queryParams));
                toast.success(data?.message || "Reimbursement Settled ");
                handleCloseShowSettleDetails();
                handleClose();
            }).catch((error) => {
                toast.error(error?.message || "Request Failed Please Try Again ");
            });
        }
    };

    const downloadImage = (imageUrl) => {
        fetch(imageUrl)
            .then(response => response?.blob())
            .then(blob => {
                const blobUrl = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = 'reimbursementReceipt.jpg';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(() => toast.error("Error in downloading receipt, please try again"));
    };

    const handleUploadButtonClick = () => {
        fileInputRef.current.click();
    };

    const hasAccess = useMemo(() => {
        return decodedToken?.isAccountant || decodedToken?.isAdmin || decodedToken?.isGlobalReviewer || decodedToken?.reviewer?.length > 0;

    }, [decodedToken]);

    const handleSelectAllCheckbox = () => {
        if (selectAllChecked) {
            setSelectedItems([]);
            setSelectAllChecked(false);
        }
        else {
            const selected = employeeDetails[0]?.records && employeeDetails[0]?.records?.length > 0
                ? employeeDetails[0]?.records.filter(record => record.isApproved === true)
                : [];
            setSelectedItems(selected);
            setSelectAllChecked(true);
        }
    };

    const handleCheckboxChange = (id) => {
        const selected = [...selectedItems];
        const index = selected.findIndex((record) => record.reimbursementRequestId === id);

        if (index === -1) {
            const record = employeeDetails[0]?.records.find((record) => record.reimbursementRequestId === id);
            selected.push(record);
        } else {
            selected.splice(index, 1);
        }
        setSelectedItems(selected);
    };

    return (
        <div className={`${currentLanguageCode === 'en' ? 'white-container' : 'white-container-arabic'}`}>
            {(isLoading || reimbursementDetailLoader || reimbursementRequestLoader) && <Loader />}
            <h1 className='text-green mb-3'>{t('sidebar.reimbursement_title')}</h1>
            <div className='settings mt-3'>
                <Tabs onSelect={(key) => handleTab(key)} defaultActiveKey={defaultActiveTab} animation="true" id="reimbursement-tabs" style={{ marginBottom: 24 }}>
                    {
                        hasAccess &&
                        <Tab eventKey={2} title={`${t('expenses.for_your_review')}`}>
                            <ForYourReviewTabs
                                listData={reimbursementForReview?.rows}
                                clickOnTabChange={changeTabOnTabClick}
                                activeTab={activeTab}
                                parentTab={'2'}
                                showExpenseDetailModal={handleShowTwo}
                                type={'reimbursement'}
                                showCheckBox={false}
                                currencies={currencies}
                                prevPath={'/reimbursement'}
                            />
                            <div className='d-flex align-items-center justify-content-between'
                                style={{ width: "100%", borderTop: '0px !imporant' }}>
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination my-3 pe-0 pt-1">
                                        <li className="page-item">
                                            <button className="btn page-link" id='previous_review' disabled={pageNoReview === 1} onClick={() => setPageNoReview(prev => prev - 1)}>Previous</button>
                                        </li>
                                        <li className="page-item mx-3">
                                            <button className="btn page-link" id='next_review' disabled={pageNoReview == reimbursementForReview?.totalPages || reimbursementForReview?.totalPages == 0} onClick={() => setPageNoReview(prev => prev + 1)}>Next</button>
                                        </li>
                                    </ul>
                                </nav>
                                <div className='page-count d-flex align-items-center'>
                                    <h4 className='pagination-textcss'>Page {reimbursementForReview?.currentPage} of {reimbursementForReview?.totalPages || 0}</h4>
                                </div>
                            </div>
                        </Tab>
                    }
                    <Tab eventKey={1} title={`${t('for_settlement')}`} className='inner-tabs'>
                        <div className='col-lg-4 col-md-12 mt-1'>
                            {
                                hasAccess &&
                                <div className="form-group has-search" >
                                    <span className="fa fa-search form-control-feedback mt-1"></span>
                                    <input style={{ height: 48 }} type="text" className="form-control" id='searchEmployee' placeholder={`${t('search_employee')}`} onKeyUp={(event) => searchListByEmployee(event.target.value)} />
                                </div>
                            }
                        </div>
                        <div className='mt-1 pt-2 reimbursement'>
                            <div className='table-responsive inner-tabs pb-0'>
                                <table id="reimbursement_requestes" style={{ borderCollapse: 'separate', borderSpacing: '0 16px' }} cellSpacing="0" width="100%">
                                    <thead>
                                        <tr className='table-head'>
                                            <th scope="col">{t('cards.no')}</th>
                                            <th scope="col">{t('reimbursement.employee')}</th>
                                            <th scope="col">{t('reimbursement.requested_amount')}</th>
                                            <th scope="col">{t('reimbursement.pending_amount')}</th>
                                            <th scope="col">{t('reimbursement.cash_on_hand')}</th>
                                            <th scope="col">{t('reimbursement.actions')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='table-body'>
                                        {
                                            (Object.keys(fetchAllEmployees).length > 0 && Object.keys(fetchAllEmployees['rows']).length > 0) && fetchAllEmployees['rows'].map((employeeList, index) => {
                                                return <tr
                                                    key={index}
                                                    className={`${currentLanguageCode === 'en' ? 'table-body-tr' : 'table-body-tr-arabic'}`}
                                                    style={{
                                                        cursor: 'pointer',
                                                        backgroundColor: `${employeeList?.totalAmount > 0 ? `#6BB7B31A` : `#EAECF0`}`
                                                    }}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <div className='d-flex align-items-center'>
                                                            {
                                                                employeeList.profilePic &&
                                                                <img onError={(e) => {
                                                                    e.target.onerror = null;
                                                                    e.target.style.display = 'none';
                                                                    e.target.nextSibling.style.display = 'flex';
                                                                }} src={employeeList?.profilePic} style={{ width: 32, height: 32, objectFit: 'fill', borderRadius: 20, marginRight: 10, marginLeft: 10 }} />
                                                            }
                                                            <span className='mb-0 align-items-center justify-content-center text-uppercase' style={{ display: employeeList.profilePic ? 'none' : 'flex', fontSize: 14, fontWeight: 600, backgroundColor: '#F2F4F7', borderRadius: 20, width: 35, height: 35, marginRight: 10, marginLeft: 10 }}
                                                            >
                                                                {employeeList?.firstName.slice(0, 1) + employeeList?.lastName.slice(0, 1)}
                                                            </span>
                                                            <div>
                                                                <h5 className='text-black fw-bold'>{`${employeeList?.firstName + ` ` + employeeList?.lastName}`}</h5>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{formatQAR(employeeList?.totalAmount || 0)} {t('teams.QAR')}</td>
                                                    <td>{formatQAR(employeeList?.pendingAmount || 0)} {t('teams.QAR')}</td>
                                                    <td>{formatQAR(employeeList?.pettyCashBalance || 0)} {t('teams.QAR')}</td>
                                                    <td>
                                                        <button className='btn-primary-small table-action-button' id={`view_${index}`} onClick={() => handleShow(employeeList)}>
                                                            {t('view')}
                                                        </button>
                                                    </td>
                                                </tr>;
                                            }
                                            )
                                        }
                                    </tbody>
                                </table>
                                {!(fetchAllEmployees?.rows && fetchAllEmployees?.rows?.length > 0) && (
                                    <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                                        <img src={notFound} width={200} height={200} />
                                    </div>)
                                }
                            </div>
                            <div className='d-flex align-items-center justify-content-between'
                                style={{ width: "100%", borderTop: '0px !imporant' }}>
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination my-3 pe-0 pt-1">
                                        <li className="page-item">
                                            <button className="btn page-link" disabled={pageNo === 1} onClick={() => onPreviousClick()} id='previous'>Previous</button>
                                        </li>
                                        <li className="page-item mx-3">
                                            <button className="btn page-link" disabled={pageNo == fetchAllEmployees?.totalPages || fetchAllEmployees?.totalPages == 0} onClick={() => onNextClick()} id='next'>Next</button>
                                        </li>
                                    </ul>
                                </nav>
                                <div className='page-count d-flex align-items-center'>
                                    <h4 className='pagination-textcss'>Page {fetchAllEmployees?.currentPage} of {fetchAllEmployees?.totalPages || 0}</h4>
                                </div>
                            </div>
                        </div>
                    </Tab>
                </Tabs>

                <Modal className='wide-modal' show={show} onHide={handleClose}>
                    <Modal.Body className='wide-modal-content' style={{ overflowY: 'hidden' }}>
                        <div className='d-flex justify-content-between align-items-start green-header w-100 px-2 py-3'>
                            <h2 className='green-header-text ps-3'> {t('reimbursement.settle_modal_title1')}</h2>
                            <div onClick={handleClose} className='pe-3' style={{ cursor: 'pointer' }}>
                                <img src={ImagesCross} style={{ width: 24, objectFit: 'contain' }} />
                            </div>
                        </div>
                        <div className='reimbursement'>
                            <Tabs id='settlements-tabs'>
                                <Tab eventKey={2} title='' style={{ border: '0px', backgroundColor: '#FFF' }} id='tab'>
                                    <div className='inner-tabs pb-1'>
                                        <Tab.Container defaultActiveKey="first">
                                            <Nav variant="pills" id='settlements-nav'>
                                                <Nav.Item id='settlements-tabs-first'>
                                                    <Nav.Link eventKey="first" onClick={() => { setSelectedTab('1'); }} id='outstanding'>{t('reimbursement.outstanding')}</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item id='settlements-tabs-second'>
                                                    <Nav.Link eventKey="second" id='settled' onClick={() => { setSelectedTab('2'); }}>{t('reimbursement.settled')}</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <Tab.Content className='mt-0'>
                                                <Tab.Pane eventKey="first">
                                                    <Stack className='mt-2 bordered-container' direction={'column'} spacing={'16px'} >
                                                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                                            <Stack direction={'row'} spacing={'12px'} alignItems={'center'}>
                                                                {isLoading && <Loader />}
                                                                {empDetails?.profilePic && <img onError={(e) => {
                                                                    e.target.onerror = null;
                                                                    e.target.style.display = 'none';
                                                                    e.target.nextSibling.style.display = 'flex';
                                                                }} src={empDetails[0]?.profilePic} style={{ width: 40, objectFit: 'cover', borderRadius: 10.71, height: 40 }} />}

                                                                <span className='mb-0 d-flex align-items-center justify-content-center text-uppercase' style={{ display: empDetails?.profilePic ? 'none' : 'flex', fontSize: 14, fontWeight: 600, backgroundColor: '#F2F4F7', borderRadius: 20, width: 35, height: 35, marginRight: 10 }}
                                                                >
                                                                    {empDetails?.firstName.slice(0, 1) + empDetails?.lastName.slice(0, 1)}
                                                                </span>

                                                                <div>
                                                                    <h5 className='header-title text-capitalize'>{`${empDetails?.firstName + ` ` + empDetails?.lastName}`}</h5>
                                                                </div>
                                                            </Stack>
                                                            <Stack>
                                                                <h3 className='header-title'><span className='light-text'>{t('reimbursement.requested_amount')}</span>{empDetails?.totalAmount ? formatQAR(empDetails?.totalAmount) + ' ' : '0.00 '}</h3>
                                                            </Stack>
                                                        </Stack>
                                                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                                            <h3 className='header-title'><span className='light-text'>{t('reimbursement.cash_on_hand')}</span>{empDetails?.pettyCashBalance ? formatQAR(empDetails?.pettyCashBalance) + ' ' : '0.00 '}</h3>
                                                            <h3 className='header-title'><span className='light-text'>{t('reimbursement.pending_amount')}</span>{empDetails?.pendingAmount ? formatQAR(empDetails?.pendingAmount) + ' ' : '0.00 '}</h3>
                                                        </Stack>
                                                    </Stack>
                                                    <div className='mt-1 reimbursement'>
                                                        <h2 className='sub-title'>{t('expenses.expense_details')}</h2>
                                                        <div className="form-check mt-3">
                                                            <input className="form-check-input" type="checkbox" value=""
                                                                id="checkAllExported"
                                                                onChange={handleSelectAllCheckbox} />
                                                            <label className="form-check-label header-title" htmlFor="checkAllExported">
                                                                {t('export.select_all')}
                                                            </label>
                                                        </div>
                                                        <div className='table-container border-zero p-3 pb-0 mt-3'>
                                                            <div className='table-responsive'>
                                                                <table id="outstanding_requests" style={{ borderCollapse: 'separate', borderSpacing: '0 16px' }} cellSpacing="0" width="100%">
                                                                    <thead>
                                                                        <tr style={{ borderTopWidth: 0 }} className='table-head'>
                                                                            <th scope="col">{t('company.select')}</th>
                                                                            <th scope="col">{t('reimbursement.merchant')}</th>
                                                                            <th scope="col">{t('reimbursement.data_time')}</th>
                                                                            <th scope="col" className="text-end">{t('settingstab.Amount')}</th>
                                                                            <th scope="col">{t('reimbursement.status')}</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {employeeDetails[0]?.records?.length > 0 && employeeDetails[0]?.records?.map((record) => {
                                                                            return <tr className={`${currentLanguageCode === 'en' ? 'table-body-tr' : 'table-body-tr-arabic'}`} key={record.reimbursementRequestId}>
                                                                                <td>
                                                                                    <div>
                                                                                        {
                                                                                            record?.isApproved
                                                                                                ? <input className="form-check-input m-2" type="checkbox"
                                                                                                    id={record?.reimbursementRequestId}
                                                                                                    checked={selectedItems?.some(item => item.reimbursementRequestId === record?.reimbursementRequestId)}
                                                                                                    onChange={() => handleCheckboxChange(record?.reimbursementRequestId)}
                                                                                                />
                                                                                                : <input className="form-check-input m-2" type="checkbox"
                                                                                                    id={record?.reimbursementRequestId}
                                                                                                    disabled
                                                                                                />
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                                <td>{record?.merchantName}</td>
                                                                                <td>{formatTime(record?.date)}</td>
                                                                                <td>{formatQAR(record?.amount || 0)}</td>
                                                                                <td>
                                                                                    {record?.isApproved
                                                                                        ? <Chip className='approved styledChip' label={'Approved'} />
                                                                                        : <Chip className='pending styledChip' label={'Pending'} />}
                                                                                </td>
                                                                            </tr>;
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className='mt-2' style={{ position: 'sticky', bottom: '0px' }}>
                                                            <h1 className='bottom-text'>{t('reimbursement.total_amount')}
                                                                <span className='bottom-text' style={{ paddingLeft: '8px' }}>{`${Math.floor(selectedTotalAmount || 0).toLocaleString()}.`}</span>
                                                                <span className='modal-text-gray-line'>{`${(selectedTotalAmount?.toFixed(2).split('.')[1]) !== "0000" ? selectedTotalAmount?.toFixed(2).split('.')[1] : '00'}`}</span>
                                                            </h1>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <div className='reimbursement mt-4'>
                                                        <h2 className='sub-title'>{t('reimbursement.history')}</h2>
                                                        <div className='mt-2 table-container' style={{ maxHeight: '405px' }}>
                                                            <div className='table-responsive border-zero p-3 pb-0'>
                                                                <table id="settled_requests" style={{ borderCollapse: 'separate', borderSpacing: '0 16px' }} cellSpacing="0" width="100%">
                                                                    <thead>
                                                                        <tr style={{ borderTopWidth: 0 }} className='table-head'>
                                                                            <th scope="col">{t('reimbursement.settlemnt_date')}</th>
                                                                            <th scope="col">{t('settingstab.Amount')}</th>
                                                                            <th scope="col">{t('reimbursement.settled_by')}</th>
                                                                            <th scope="col">{t('reimbursement.cash_on_hand')}</th>
                                                                            <th scope="col">{t('reimbursement.other_sources')}</th>
                                                                            <th scope="col">{t('actions')}</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {reimbursementSettled?.map((record, index) => {
                                                                            return <tr key={index} className={`${currentLanguageCode === 'en' ? 'table-body-tr' : 'table-body-tr-arabic'}`}>
                                                                                <td>{formatTime(record.createdAt)}</td>
                                                                                <td>{formatQAR(record.totalAmount)}</td>
                                                                                <td className='text-capitalize'>{`${empDetails?.firstName + ` ` + empDetails?.lastName}`}</td>
                                                                                <td>{formatQAR(record.cashOnHandAmount)}</td>
                                                                                <td>{formatQAR(record.otherSourcesAmount)}</td>
                                                                                <td>
                                                                                    <Stack direction={'row'} spacing={'14px'}>
                                                                                        <img src={download} onClick={() => downloadImage(record?.evidenceDocumentPath)} />
                                                                                        <img src={view} onClick={() => { handleShowTransDetails(record); }} />
                                                                                    </Stack>
                                                                                </td>
                                                                            </tr>;
                                                                        })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </Modal.Body>
                    {
                        selectedTab === '1' &&
                        <Modal.Footer className='reimbursement p-0 pt-3' style={{ display: 'flex', justifyContent: 'right', position: 'sticky', bottom: '0px' }}>
                            <button onClick={() => { handleClose(); handleCloseTwo(); }} className='btn-secondary-small' id='cancel_settle'>{t('cancel')}</button>
                            <button onClick={handleShowSettleDetails} disabled={!(selectedItems?.length > 0)} className={!(selectedItems?.length > 0) ? 'disabled-filled-button' : 'btn-primary-small'} id='settle'>{t('reimbursement.settle')}</button>
                        </Modal.Footer>
                    }
                </Modal>
                <Modal
                    className='wide-modal'
                    show={showSettleDetails}
                    onHide={handleCloseShowSettleDetails}
                >
                    <Modal.Body className='wide-modal-content'>
                        <div className='d-flex justify-content-between align-items-start green-header w-100 px-2 py-3'>
                            <h2 className='green-header-text ps-3'> {t('reimbursement.settlement_details_title')}</h2>
                            <div onClick={handleCloseShowSettleDetails} className='pe-3' style={{ cursor: 'pointer' }}>
                                <img src={ImagesCross} style={{ width: 24, objectFit: 'contain' }} />
                            </div>
                        </div>
                        <div className='reimbursement mt-2'>
                            <Stack direction={'row'} alignItems={'center'} spacing={'4px'}>
                                <h3 className='inner-title'>{t('reimbursement.total_amount')}</h3>
                                <h3 className='strong-text'>
                                    <span className='bottom-text' style={{ paddingLeft: '8px' }}>{`${Math.floor(selectedTotalAmount || 0).toLocaleString()}.`}</span>
                                    <span className='modal-text-gray-line'>{`${(selectedTotalAmount?.toFixed(2).split('.')[1]) !== "0000" ? selectedTotalAmount?.toFixed(2).split('.')[1] : '00'}`}</span>
                                </h3>
                            </Stack>
                            <h2 className='green-header-text mt-2'> {t('reimbursement.breakdown')}</h2>
                            <div className='bordered-container mt-2 p-2'>
                                <h3 className='header-title'>
                                    <span className='light-text' style={{ paddingRight: '3px' }}>
                                        {t('reimbursement.amount_settled_from_cash')}
                                    </span>
                                    <CurrencyInput
                                        type="text"
                                        className='form-control customized-input'
                                        id='settledCashOnHand'
                                        placeholder={settledCashOnHand?.toFixed(2) || 0}
                                        decimalsLimit={2}
                                        decimalScale={2}
                                        defaultValue={settledCashOnHand?.toFixed(2) || ''}
                                        maxLength={6}
                                        disablenegative={'true'}
                                        onKeyDown={(e) => {
                                            if (e.key === '-') {
                                                e.preventDefault();
                                            }
                                        }}
                                        onValueChange={(value) => {
                                            const cashAmount = parseFloat(value?.replace(/,/g, '')) || 0;
                                            if (cashAmount < MAX_VALUE) {
                                                let otherSourcesAmount = selectedTotalAmount - cashAmount;
                                                if (cashAmount > selectedTotalAmount) {
                                                    setError('Cash on hand cannot exceed the total amount.');
                                                    setSettledOtherSources(selectedTotalAmount);
                                                } else if (cashAmount >= 0 && otherSourcesAmount >= 0) {
                                                    setError('');
                                                    setSettledCashOnHand(cashAmount);
                                                    setSettledOtherSources(otherSourcesAmount);
                                                }
                                            }
                                        }}
                                        style={{ width: '150px' }}
                                    />
                                    {error && <p className="text-danger">{error}</p>}
                                </h3>
                            </div>
                            <div className='bordered-container mt-2 p-2'>
                                <h3 className='header-title'>
                                    <span className='light-text' style={{ paddingRight: '3px' }}>
                                        {t('reimbursement.amount_settles_from_others')}
                                    </span>
                                    <CurrencyInput
                                        type="text"
                                        className='form-control'
                                        value={settledOtherSources || 0}
                                        decimalsLimit={2}
                                        decimalScale={2}
                                        readOnly
                                        style={{ width: '150px' }}
                                        id='settledOtherSources'
                                    />
                                </h3>
                            </div>
                            <Row className="settlement-upload-area mt-2">
                                <div className='upload-label' onClick={handleUploadButtonClick}>
                                    <Col sm={12} md={4} className="d-flex justify-content-center align-items-center">
                                        <div className="settlement-upload-icon">
                                            <img src={selectedFile ? uploaded : upload} alt="Document upload icon" style={{ width: '32px', height: '32px' }} />
                                        </div>
                                    </Col>
                                    <Col sm={12} md={8} className="d-flex flex-column justify-content-center">
                                        <Stack className="settlement-upload-text" direction={'row'} justifyContent={'space-between'}>
                                            {t('company.click_to_upload_doc')}
                                            <label htmlFor='upload-input'>
                                                <img src={upload_icon} alt="Upload icon" style={{ width: '11.81px', height: '11.81px' }} />
                                            </label>
                                        </Stack>
                                        <div style={{ gap: '2.62px' }}>
                                            <h2 className="settlement-upload-title truncated-text" style={{ color: selectedFile ? '#00D094' : '#333333' }}>
                                                {selectedFile ? selectedFile.name : 'Upload Document'}
                                            </h2>
                                            <h4 className="settlement-upload-text" style={{ fontWeight: '400' }}>
                                                {'Upload Evidence of settlement (like bank statement, bank SMS confirmation, etc…)'}
                                            </h4>
                                            <input
                                                type="file"
                                                id="upload-input"
                                                accept="image/*"
                                                ref={fileInputRef}
                                                onChange={handleFileChange}
                                                multiple={false}
                                                hidden
                                            />
                                        </div>
                                    </Col>
                                </div>
                            </Row>
                            <div className='mt-3'>
                                <h3 className='header-title'>{t('reimbursement.add_comment')}</h3>
                                <input
                                    type="text"
                                    className="form-control settlement-input"
                                    id='addComment'
                                    placeholder={t('reimbursement.add_comment')}
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='p-0 pt-3' style={{ display: 'flex', justifyContent: 'right', position: 'sticky', bottom: '0px' }}>
                        <button onClick={() => { handleCloseShowSettleDetails(); handleCloseTwo(); }} className='btn-secondary-small' id='cancel_settlement'>{t('cancel')}</button>
                        <button onClick={() => { handleUpload(); }} disabled={error !== ''} className={error !== '' ? 'disabled-filled-button' : 'btn-primary-small'} id='confirm_settlement'>{t('reimbursement.confirm_settlement')}</button>
                    </Modal.Footer>
                </Modal>
                <Modal className='wide-modal' show={showTransDetails} onHide={handleCloseTransDetails} style={{ height: '100%' }}>
                    <Modal.Body className='wide-modal-content'>
                        <div className='d-flex justify-content-between align-items-start green-header w-100 px-2 py-3'>
                            <h2 className='green-header-text ps-3'> {t('reimbursement.trans_details_title')}</h2>
                            <div onClick={handleCloseTransDetails} className='pe-3' style={{ cursor: 'pointer' }}>
                                <img src={ImagesCross} style={{ width: 24, objectFit: 'contain' }} />
                            </div>
                        </div>
                        <div className='reimbursement mt-3'>
                            <div className='bordered-container mt-3 p-3'>
                                <div className='table-responsive border-zero p-3 pb-0'>
                                    <table id="settlements_details" style={{ borderCollapse: 'separate', borderSpacing: '0 16px' }} cellSpacing="0" width="100%">
                                        <thead>
                                            <tr style={{ borderTopWidth: 0 }} className='table-head'>
                                                <th scope="col">{t('reimbursement.merchant')}</th>
                                                <th scope="col">{t('reimbursement.data_time')}</th>
                                                <th scope="col">{t('settingstab.Amount')}</th>
                                                <th scope="col">{t('reimbursement.comments')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentRecord?.records && currentRecord?.records[0].subRecords?.length > 0 && currentRecord?.records[0]?.subRecords?.map((subRecord, index) => {
                                                return <tr key={index} className={`${currentLanguageCode === 'en' ? 'table-body-tr' : 'table-body-tr-arabic'}`}>
                                                    <td>{subRecord.merchantName}</td>
                                                    <td>{formatTime(subRecord.createdAt)}</td>
                                                    <td>{formatQAR(subRecord.amount)}</td>
                                                    <td>{currentRecord.comments}</td>
                                                </tr>;
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal className='UserModal' show={showTwo} onHide={handleCloseTwo} style={{ paddingRight: 0 }}>
                    <Modal.Body>
                        <div className='d-flex justify-content-between align-items-start'>
                            <div className='col-9'>
                                <p className='text-black modal-title' style={{ fontSize: 20 }}>
                                    {t('sidebar.reimbursement_title')}
                                </p>
                                <p className='text-grey'>{t('expenses.reimbursement_details_desc')}</p>
                            </div>
                            {filterType === 'all' ?
                                <div onClick={handleCloseTwo} className='clo-2' style={{ cursor: 'pointer' }}>
                                    <img src={LeftImage} style={{ width: 20, objectFit: 'contain' }} />
                                </div>
                                :
                                <div onClick={handleCloseTwo} className='clo-2' style={{ cursor: 'pointer' }}>
                                    <img src={ImagesCross} style={{ width: 20, objectFit: 'contain' }} />
                                </div>
                            }
                        </div>
                        {
                            reimbursementDetails && !reimbursementDetailLoader && <ExpensesModalTabs cardTransactionId={reimbursementRequestId} handleClose={handleCloseTwo} isLoading={isLoading} tagGroups={tags} details={reimbursementDetails} dispatch={dispatch} categoryList={categoryList} type={'reimbursement'} updateCardTransactionData={updateTransaction} />
                        }
                    </Modal.Body>

                    {filterType === 'pending' && activeTab === '2' && reimbursementDetails && <Modal.Footer>
                        <button onClick={() => { handleCloseTwo(); handleShowThree(); }} className='btn-secondary-small' id='reject'>{t('reject')}</button>
                        <button onClick={() => approveRejectRequest({ type: 'approve' })} className='btn-primary-small' id='approve'>{t('approve')}</button>
                    </Modal.Footer>}
                </Modal>

                <Modal className='TaxcodeModalOne' show={showThree} onHide={handleCloseModalThree} style={{ paddingRight: 0 }}>
                    <form onSubmit={handleRejectSubmit(approveRejectRequest)}>
                        <Modal.Body>
                            <div className='d-flex justify-content-between align-items-start'>
                                <div className='col-9'>
                                    <p className='text-black modal-title' style={{ fontSize: 20 }}>
                                        {t('reject')}
                                    </p>
                                    <p className='text-grey'>{t('reject')}</p>
                                </div>
                                <div onClick={handleCloseModalThree} className='clo-2' style={{ cursor: 'pointer' }}>
                                    <img src={ImagesCross} style={{ width: 20, objectFit: 'contain' }} />
                                </div>
                            </div>
                            <Controller
                                name="type"
                                control={controlRejectForm}
                                defaultValue="reject"
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        type="hidden"
                                        id="comments"
                                        className="form-control form-control-lg"
                                        placeholder={t('cards.comments')}
                                    />
                                )}
                            />
                            <div className="form-outline mt-4 mb-3">
                                <label className="form-label" htmlFor="comments">{t('cards.comments')}</label>
                                <Controller
                                    name="comments"
                                    control={controlRejectForm}
                                    render={({ field }) => (
                                        <input type="text"  {...field} id="comments" className="form-control form-control-lg"
                                            placeholder={`${t('cards.comments')}`} />
                                    )}
                                />
                                <p className="text-danger">{errorsRejectForm?.comments?.message}</p>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button onClick={handleCloseModalThree} className='btn-secondary-small' id='cancel_reject'>{t('cancel')}</button>
                            <button className='btn-primary-small' id='save_reject'>{t('save')}</button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </div>
        </div >
    );
}

export default Reimbursement;
