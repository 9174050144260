import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../../utils/apiUrls";
import api from "../../../utils/api";

const initialState = {
    userDetails: null,
    isLoading: false,
    isError: false
};

export const getEmployeeDetailsById = createAsyncThunk("department/getEmployeeDetailsById", async ({employeeId,eventType}) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url:`${API_ENDPOINTS.getEmployeeDetails + employeeId}?eventType=${eventType}`,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const updateEmployeeDetails = createAsyncThunk("department/updateEmployeeDetails", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.updateEmployeeDetails,
            data: requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const resendInviteCode = createAsyncThunk("department/resendInviteLink", async (postData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.resendInviteLink,
            data: postData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

const departmentSlice = createSlice({
    name: "department",
    initialState,
    reducers: {
        removeUserData: (state) => {
            return {
                ...state,
                userDetails: null
            };
        }
    },
    extraReducers: (builder) => {
        const handleLoading = (state, action) => {
            state.isLoading = action.meta.requestStatus === 'pending';
        };

        builder
            .addCase(getEmployeeDetailsById.fulfilled, (state, action) => {
                state.userDetails = action.payload.data;
            });
            builder
            .addCase(updateEmployeeDetails.fulfilled, (state) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(updateEmployeeDetails.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateEmployeeDetails.rejected, (state) => {
                state.isLoading = false;
            })
            .addMatcher(
                (action) =>
                    action.type === getEmployeeDetailsById.pending.type ||
                    action.type === getEmployeeDetailsById.fulfilled.type ||
                    action.type === getEmployeeDetailsById.rejected.type ||
                    action.type === updateEmployeeDetails.rejected.type ||
                    action.type === updateEmployeeDetails.rejected.type ||
                    action.type === updateEmployeeDetails.rejected.type ||
                    action.type === resendInviteCode.pending.type ||
                    action.type === resendInviteCode.fulfilled.type ||
                    action.type === resendInviteCode.rejected.type ,
                handleLoading
            );
    }

});
export const { removeUserData } = departmentSlice.actions;
export default departmentSlice.reducer;