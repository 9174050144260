import React from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

function PdfComponent(props) {
    const { url, handleImageClick, height, width } = props;
    const thumbnailPluginInstance = thumbnailPlugin();
    const { Cover } = thumbnailPluginInstance;

    const pageThumbnailPlugin = (props) => {
        const { PageThumbnail } = props;

        return {
            renderViewer: (renderProps) => {
                let { slot } = renderProps;

                slot.children = PageThumbnail;

                slot.subSlot.attrs = {};
                slot.subSlot.children = React.createElement(React.Fragment, null);

                return slot;
            },
        };
    };
    const pageThumbnailPluginInstance = pageThumbnailPlugin({
        PageThumbnail: <Cover getPageIndex={() => 0} />,
    });
    return (
        <>
            <div onClick={handleImageClick} style={{
                height: '100%',
            }}><div style={{ height: height ? height : 175, objectFit: 'cover', width: width ? width : 175, cursor: 'pointer' }}>
                    <Worker workerUrl={pdfjsWorker}>
                        <Viewer
                            fileUrl={url}
                            plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance]}
                        />
                    </Worker>
                </div>
            </div>
        </>
    );
}

export default PdfComponent;