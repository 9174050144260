import React from 'react';
import './Login.scss';
import Logo from '../../assets/images/final-logo.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function CompanyEmployeeCounting() {
    const { t } = useTranslation();

    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/mobileNumber`;
        navigate(path);
    };

    return (
        <section className="login-forms">
            <div className="container-fluid" style={{ backgroundColor: '#fff' }}>
                <div className="row d-flex justify-content-between align-items-center h-100 " >
                    <div className=" d-flex flex-column justify-content-between align-items-center CompanyEmployeeCounting-forms my-2">
                        <div className='form-div'>
                            <form className='form' >
                                <div>
                                    <img src={Logo} className="logo mb-4" alt="Sample image" />
                                </div>
                                <h1 className='text-blue-login mb-4'>
                                {t('login.how_many_people_work_at_your_company?')}
                                </h1>

                                <div className="row my-2">
                                    <div className='col-xl-6'>
                                        <div class="form-check ps-4 py-4 d-flex align-items-center" style={{ borderBottom: 'solid 1px #EAECF0'}}>
                                            <input class="form-check-input me-2" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                            <label class="form-check-label" for="inlineRadio1">{t('login.just_me_i_am_solo_trader')}</label>
                                        </div>
                                        <div class="form-check ps-4 py-4 d-flex align-items-center" style={{ borderBottom: 'solid 1px #EAECF0'}}>
                                            <input class="form-check-input me-2" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                            <label class="form-check-label" for="inlineRadio1">{t('login.2_-_4_employees')}</label>
                                        </div>
                                        <div class="form-check ps-4 py-4 d-flex align-items-center" style={{ borderBottom: 'solid 1px #EAECF0'}}>
                                            <input class="form-check-input me-2" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                            <label class="form-check-label" for="inlineRadio1">{t('login.10_-_49_employees')}</label>
                                        </div>
                                        <div class="form-check ps-4 py-4 d-flex align-items-center" style={{ borderBottom: 'solid 1px #EAECF0'}}>
                                            <input class="form-check-input me-2" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                            <label class="form-check-label" for="inlineRadio1">{t('login.100_-_249_employees')}</label>
                                        </div>
                                        <div class="form-check ps-4 py-4 d-flex align-items-center" style={{ borderBottom: 'solid 1px #EAECF0'}}>
                                            <input class="form-check-input me-2" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                            <label class="form-check-label" for="inlineRadio1">{t('login.500+_employees')}</label>
                                        </div>
                                    </div>
                                    <div className='col-xl-6'>
                                        <div class="form-check ps-4 py-4 d-flex align-items-center" style={{ borderBottom: 'solid 1px #EAECF0'}}>
                                            <input class="form-check-input me-2" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                            <label class="form-check-label" for="inlineRadio1">{t('login.just_me,_in_a_limited_liability_company')}</label>
                                        </div>
                                        <div class="form-check ps-4 py-4 d-flex align-items-center" style={{ borderBottom: 'solid 1px #EAECF0'}}>
                                            <input class="form-check-input me-2" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                            <label class="form-check-label" for="inlineRadio1">{t('login.5_-_9_employees')}</label>
                                        </div>
                                        <div class="form-check ps-4 py-4 d-flex align-items-center" style={{ borderBottom: 'solid 1px #EAECF0'}}>
                                            <input class="form-check-input me-2" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                            <label class="form-check-label" for="inlineRadio1">{t('login.50_-_99_employees')}</label>
                                        </div>
                                        <div class="form-check ps-4 py-4 d-flex align-items-center" style={{ borderBottom: 'solid 1px #EAECF0'}}>
                                            <input class="form-check-input me-2" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                            <label class="form-check-label" for="inlineRadio1">{t('login.250_-_499_employees')}</label>
                                        </div>
                                    </div>
                                </div>


                                <div className="text-center mt-4 w-100">
                                    <button onClick={routeChange}  type="button" class="text-center btn btn-primary btn-lg btn-block w-50">{t('login.continue')}</button>
                                </div>

                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default CompanyEmployeeCounting;
