import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utils/apiUrls";
import api from "../../utils/api";

const initialState = {
    transactionByVendorsLoading: false,
    transactionByVendors: [],
    spendPerCategoryLoading: false,
    spendPerCategory: [],
    cardIssueCountLoading: false,
    cardIssueCount: 0,
    totalUsersCountLoading: false,
    totalUsersCount: 0,
    totalTransactionLoading: false,
    totalTransaction: 0,
    totalTransactionCountLoading: false,
    totalTransactionCount: 0,
    fetchTransactionByVolumeLoading: false,
    fetchTransactionByVolume: [],
    mostSpendPercategory: [],
    mostSpendPerCategoryLoading: false,
    approvedReimbursmentTransaction: [],
    approvedReimbursmentTransactionLoading: false,
    pendingReimbursmentTransaction: [],
    pendingReimbursmentTransactionLoading: false,
    rejectedReimbursmentTransaction: [],
    rejectedReimbursmentTransactionLoading: false,
    settledReimbursmentTransaction: [],
    settledReimbursmentTransactionLoading: false,
};

export const dashboardFetchTransactionByVendors = createAsyncThunk("transactionByVendors", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: `${API_ENDPOINTS.dashboardFetchTransactionByVendors}?startDate=${queryParams.startDate}&endDate=${queryParams.endDate}&type=${queryParams.type}`
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const dashboardSpendPerCategory = createAsyncThunk("dashboardSpendPerCategory", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: `${API_ENDPOINTS.dashboardSpendPerCategory}?startDate=${queryParams.startDate}&endDate=${queryParams.endDate}&type=${queryParams.type}`
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const dashboardCardIssueCount = createAsyncThunk("dashboardCardIssueCount", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: `${API_ENDPOINTS.dashboardCardIssueCount}?startDate=${queryParams.startDate}&endDate=${queryParams.endDate}`
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const dashboardTotalUsersCount = createAsyncThunk("dashboardTotalUsersCount", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: `${API_ENDPOINTS.dashboardTotalUsersCount}?startDate=${queryParams.startDate}&endDate=${queryParams.endDate}`
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const dashboardTotalTransaction = createAsyncThunk("dashboardTotalTransaction", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.dashboardTotalTransaction
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const dashboardTransactionCount = createAsyncThunk("dashboardTransactionCount", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: `${API_ENDPOINTS.dashboardTransactionCount}?startDate=${queryParams.startDate}&endDate=${queryParams.endDate}&type=${queryParams.type}`
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const dashboardFetchTransactionByVolume = createAsyncThunk("dashboardFetchTransactionByVolume", async (queryParams) => {
    const { filterType, startDate, endDate, type } = queryParams;
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: `${API_ENDPOINTS.dashboardFetchTransactionByVolume}?filterType=${filterType}&startDate=${startDate}&endDate=${endDate}&type=${type}`
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const dashboardFetchMostSpend = createAsyncThunk("dashboardFetchMostSpend", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: `${API_ENDPOINTS.dashboardMostSpend}?startDate=${queryParams.startDate}&endDate=${queryParams.endDate}&filterType=${queryParams.filter}&type=${queryParams.type}`
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const dashboardReimbursmentTransaction = createAsyncThunk("dashboardReimbursmentTransaction", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: `${API_ENDPOINTS.dashboardReimbursmentTransaction}?startDate=${queryParams.startDate}&endDate=${queryParams.endDate}&type=${queryParams.type}`
        };
        const response = await api(data);
        const cardsData = {
            data: response.data.data,
            type: queryParams.type
        };
        return cardsData;

    } catch (error) {
        throw error?.response?.data;
    }
});

const dashboard = createSlice({
    name: "dashboard",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(dashboardFetchTransactionByVendors.fulfilled, (state, { payload }) => {
                state.transactionByVendorsLoading = false;
                state.error = null;
                state.transactionByVendors = payload.data;
            })
            .addCase(dashboardSpendPerCategory.fulfilled, (state, { payload }) => {
                state.spendPerCategoryLoading = false;
                state.error = null;
                state.spendPerCategory = payload.data;
            })
            .addCase(dashboardFetchMostSpend.fulfilled, (state, { payload }) => {
                state.mostSpendPerCategoryLoading = false;
                state.error = null;
                state.mostSpendPercategory = payload.data;
            })
            .addCase(dashboardCardIssueCount.fulfilled, (state, { payload }) => {
                state.cardIssueCountLoading = false;
                state.error = null;
                state.cardIssueCount = payload.data;
            })
            .addCase(dashboardTotalUsersCount.fulfilled, (state, { payload }) => {
                state.totalUsersCountLoading = false;
                state.error = null;
                state.totalUsersCount = payload.data;
            })
            .addCase(dashboardTotalTransaction.fulfilled, (state, { payload }) => {
                state.totalTransactionLoading = false;
                state.error = null;
                state.totalTransaction = payload.data;
            })
            .addCase(dashboardTransactionCount.fulfilled, (state, { payload }) => {
                state.totalTransactionCountLoading = false;
                state.error = null;
                state.totalTransactionCount = payload.data;
            })
            .addCase(dashboardFetchTransactionByVolume.fulfilled, (state, { payload }) => {
                state.fetchTransactionByVolumeLoading = false;
                state.error = null;
                state.fetchTransactionByVolume = payload.data;
            })
            .addCase(dashboardReimbursmentTransaction.fulfilled, (state, { payload }) => {
                state.error = null;
                if (payload.type === 'approved') {
                    state.approvedReimbursmentTransactionLoading = false;
                    state.approvedReimbursmentTransaction = payload;
                } else if (payload.type === 'pending') {
                    state.pendingReimbursmentTransactionLoading = false;
                    state.pendingReimbursmentTransaction = payload;
                } else if (payload.type === 'rejected') {
                    state.rejectedReimbursmentTransactionLoading = false;
                    state.rejectedReimbursmentTransaction = payload;
                } else if (payload.type === 'settled') {
                    state.settledReimbursmentTransactionLoading = false;
                    state.settledReimbursmentTransaction = payload;
                }
            })

            .addCase(dashboardFetchTransactionByVendors.pending, (state) => {
                state.transactionByVendorsLoading = true;
            })
            .addCase(dashboardSpendPerCategory.pending, (state) => {
                state.spendPerCategoryLoading = true;
            })
            .addCase(dashboardFetchMostSpend.pending, (state) => {
                state.mostSpendPerCategoryLoading = true;
            })
            .addCase(dashboardCardIssueCount.pending, (state) => {
                state.cardIssueCountLoading = true;
            })
            .addCase(dashboardTotalUsersCount.pending, (state) => {
                state.totalUsersCountLoading = true;
            })
            .addCase(dashboardTotalTransaction.pending, (state) => {
                state.totalTransactionLoading = true;
            })
            .addCase(dashboardTransactionCount.pending, (state) => {
                state.totalTransactionCountLoading = true;
            })
            .addCase(dashboardFetchTransactionByVolume.pending, (state) => {
                state.fetchTransactionByVolumeLoading = true;
            })
            .addCase(dashboardReimbursmentTransaction.pending, (state, action) => {
                const { meta } = action;
                if (meta.arg && meta.arg.type === 'approved') {
                    state.approvedReimbursmentTransactionLoading = true;
                } else if (meta.arg && meta.arg.type === 'pending') {
                    state.pendingReimbursmentTransactionLoading = true;
                } else if (meta.arg && meta.arg.type === 'rejected') {
                    state.rejectedReimbursmentTransactionLoading = true;
                } else if (meta.arg && meta.arg.type === 'settled') {
                    state.settledReimbursmentTransactionLoading = true;
                }
            })
            .addCase(dashboardFetchTransactionByVendors.rejected, (state, { error }) => {
                state.transactionByVendorsLoading = false;
                state.error = error.message ? error.message : "Request Failed Please Try Again";
            })
            .addCase(dashboardSpendPerCategory.rejected, (state, { error }) => {
                state.spendPerCategoryLoading = false;
                state.error = error.message ? error.message : "Request Failed Please Try Again";
            })
            .addCase(dashboardFetchMostSpend.rejected, (state, { error }) => {
                state.mostSpendPerCategoryLoading = false;
                state.error = error.message ? error.message : "Request Failed Please Try Again";
            })
            .addCase(dashboardCardIssueCount.rejected, (state, { error }) => {
                state.cardIssueCountLoading = false;
                state.error = error.message ? error.message : "Request Failed Please Try Again";
            })
            .addCase(dashboardTotalUsersCount.rejected, (state, { error }) => {
                state.totalUsersCountLoading = false;
                state.error = error.message ? error.message : "Request Failed Please Try Again";
            })
            .addCase(dashboardTotalTransaction.rejected, (state, { error }) => {
                state.totalTransactionLoading = false;
                state.error = error.message ? error.message : "Request Failed Please Try Again";
            })
            .addCase(dashboardTransactionCount.rejected, (state, { error }) => {
                state.totalTransactionCountLoading = false;
                state.error = error.message ? error.message : "Request Failed Please Try Again";
            })
            .addCase(dashboardFetchTransactionByVolume.rejected, (state, { error }) => {
                state.fetchTransactionByVolumeLoading = false;
                state.error = error.message ? error.message : "Request Failed Please Try Again";
            })
            .addCase(dashboardReimbursmentTransaction.rejected, (state, { error, meta }) => {
                if (meta.arg && meta.arg.type === 'approved') {
                    state.approvedReimbursmentTransactionLoading = false;
                } else if (meta.arg && meta.arg.type === 'pending') {
                    state.pendingReimbursmentTransactionLoading = false;
                } else if (meta.arg && meta.arg.type === 'rejected') {
                    state.rejectedReimbursmentTransactionLoading = false;
                } else if (meta.arg && meta.arg.type === 'settled') {
                    state.settledReimbursmentTransactionLoading = false;
                }
                state.error = error.message ? error.message : "Request Failed Please Try Again";
            });
    }
});

// const { updateCardDetails } = dashboard.actions;
const dashboardReducer = dashboard.reducer;

export { dashboardReducer };
export default dashboardReducer;